import { __assign, __read } from "tslib";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useGetDebtorSendingOptions } from 'api';
import { Notification, SendingMediaNames } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useSelector } from 'react-redux';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconName } from 'shared/components/Icon';
import Loader from 'shared/components/Loader';
import { addressesToAttributes, emailValues, getDefaultAddresses, getDefaultEmails, transformEmailsAndSubmit, } from 'shared/utils/contacts';
import { useForceUpdate } from 'shared/utils/hooks';
import { invoiceActions } from 'store/invoice/invoice.actions';
import FormalNoticeForm from './FormalNoticeForm';
import styleIdentifiers from './formalNotice.scss';
var styles = classNames.bind(styleIdentifiers);
export default function FormalNotice(_a) {
    var _b;
    var onSubmit = _a.onSubmit, invoice = _a.invoice, items = _a.items, invoiceActionData = _a.invoiceActionData;
    var t = useTranslation().t;
    var invoiceActionDataStore = useSelector(function (state) { var _a; return (_a = state.invoice.invoiceActionData) === null || _a === void 0 ? void 0 : _a.data; });
    var company = useSelector(function (state) { return state.account.company.data; });
    var refContainer = useRef(null);
    var _c = __read(useState(true), 2), previewEmail = _c[0], setPreviewEmail = _c[1];
    var _d = __read(useState(false), 2), displayEmail = _d[0], setDisplayEmail = _d[1];
    var _e = __read(useState(true), 2), displayPost = _e[0], setDisplayPost = _e[1];
    var defaultTemplateId = company === null || company === void 0 ? void 0 : company.default_templates.formal_notice.id;
    var _f = __read(useState(defaultTemplateId), 2), currentTemplateId = _f[0], setCurrentTemplateId = _f[1];
    var invoiceData = invoiceActionData || invoiceActionDataStore;
    var _g = __read(useState({
        pageNumber: 1,
        numberOfPages: 1,
        url: '',
        email: '',
    }), 2), state = _g[0], _setState = _g[1];
    var forceUpdate = useForceUpdate();
    var setState = function (newState) {
        _setState(__assign(__assign({}, state), newState));
        forceUpdate();
    };
    useEffect(function () {
        if (currentTemplateId == null)
            return;
        getEmail(currentTemplateId);
        getPdf(currentTemplateId);
    }, [currentTemplateId]);
    var getPdf = function (template_id) {
        invoiceActions.document({
            id: invoice.id,
            noLoading: true,
            data: __assign({ document_type: 'formal_notice_pdf', template_id: template_id }, items),
            callback: function (data) {
                var url = URL.createObjectURL(data);
                setState({
                    pageNumber: 1,
                    url: url,
                });
            },
        });
    };
    var getEmail = function (template_id) {
        invoiceActions.previewTemplate({
            id: invoice.id,
            noLoading: true,
            data: __assign({ document_type: 'formal_notice', template_id: template_id, manual_reminder: true }, items),
            callback: function (data) {
                var email = data.email;
                setState(__assign(__assign({}, state), { email: email }));
            },
        });
    };
    var onDocumentLoadSuccess = function (pdf) {
        setState({
            numberOfPages: pdf._pdfInfo.numPages,
        });
    };
    var url = state.url, pageNumber = state.pageNumber, numberOfPages = state.numberOfPages, email = state.email;
    var _h = useGetDebtorSendingOptions(invoice.debtor_id), sendingOptionsResponse = _h.data, isError = _h.isError, isLoading = _h.isLoading, refetch = _h.refetch;
    var _j = sendingOptionsResponse || {
        emails: [],
        addresses: [],
    }, emails = _j.emails, addresses = _j.addresses;
    var defaultEmails = getDefaultEmails(emails, Notification.formal_notice);
    var defaultEmailValues = emailValues(defaultEmails);
    var defaultAddresses = getDefaultAddresses(addresses, Notification.formal_notice);
    var addressesAttributes = addressesToAttributes(defaultAddresses);
    var initialValues = useMemo(function () { return (__assign(__assign({}, invoiceData.sending_options), { addresses_attributes: addressesAttributes, emails: defaultEmailValues, send_email: true, template_id: defaultTemplateId })); }, [invoiceData, sendingOptionsResponse]);
    if (isLoading && !sendingOptionsResponse) {
        return React.createElement(Loader, null);
    }
    if (isError || !sendingOptionsResponse) {
        return React.createElement("span", null, "Error");
    }
    var onChangeTemplate = function (templateId) {
        setCurrentTemplateId(Number(templateId));
        if (displayEmail)
            getEmail(templateId);
        if (displayPost)
            getPdf(templateId);
    };
    var setPreview = function (media) {
        setPreviewEmail(media.email);
        if (!media.email) {
            setDisplayEmail(false);
            setDisplayPost(true);
        }
    };
    var switchDisplay = function (medium) {
        if (medium === SendingMediaNames.email && !displayEmail)
            getEmail(currentTemplateId);
        if (medium === SendingMediaNames.post && !displayPost)
            getPdf(currentTemplateId);
        setDisplayEmail(medium === SendingMediaNames.email);
        setDisplayPost(medium !== SendingMediaNames.email);
    };
    return (React.createElement("div", { className: styles('FormalNotice') },
        React.createElement("div", { className: styles('left'), ref: refContainer },
            React.createElement("div", { className: styles('tab-wrapper') },
                previewEmail && (React.createElement("div", { className: styles('tab-item', displayEmail && 'active'), onClick: function () {
                        switchDisplay(SendingMediaNames.email);
                    } }, t(i18nKeys.EMAIL))),
                React.createElement("div", { className: styles('tab-item', displayPost && 'active'), onClick: function () {
                        switchDisplay(SendingMediaNames.post);
                    } }, t(i18nKeys.POST))),
            displayEmail && email && (React.createElement("div", { className: styles('email-preview') },
                React.createElement(HTML, { className: "email-preview", html: email }))),
            displayPost && (React.createElement("div", { className: styles('pdf-actions'), style: { border: url ? '1px solid #bbb' : '' } }, url ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles('container-actions') },
                    pageNumber > 1 && (React.createElement(Icon, { name: IconName.MINIMAL_UP, onClick: function () {
                            setState({ pageNumber: pageNumber - 1 });
                        } })),
                    numberOfPages > pageNumber && (React.createElement(Icon, { name: IconName.MINIMAL_DOWN, onClick: function () {
                            setState({ pageNumber: pageNumber + 1 });
                        } }))),
                React.createElement(Document, { file: url, onLoadSuccess: onDocumentLoadSuccess },
                    React.createElement(Page, { width: (((_b = refContainer === null || refContainer === void 0 ? void 0 : refContainer.current) === null || _b === void 0 ? void 0 : _b.offsetWidth) || 680) - 10, pageNumber: pageNumber })))) : (React.createElement("div", { className: styles('loading') },
                React.createElement("div", null, t(i18nKeys.LOADING))))))),
        React.createElement("div", { className: styles('right') },
            React.createElement(FormalNoticeForm, { onChangeTemplate: onChangeTemplate, initialValues: initialValues, debtorEmails: emails, setPreview: setPreview, onSubmit: function (values) { return transformEmailsAndSubmit(emails, values, onSubmit); }, addresses: addresses, refetchSendingOptions: refetch, debtorId: invoice.debtor_id }))));
}
