import classNames from 'classnames/bind';
import React from 'react';
import Amount from 'shared/components/Amount';
import styleIdentifiers from './ClientDetailTabsBalance.scss';
import { ClientDetailTabsBalanceItemInvoiceLinkedCreditNotes } from './ClientDetailTabsBalanceItemInvoiceLinkedCreditNotes';
import { ClientDetailTabsBalanceItemInvoiceLinkedMatchedPayments } from './ClientDetailTabsBalanceItemInvoiceLinkedMatchedPayments';
import { ClientDetailTabsBalanceItemInvoiceLinkedPayments } from './ClientDetailTabsBalanceItemInvoiceLinkedPayments';
var styles = classNames.bind(styleIdentifiers);
export var ClientDetailTabsBalanceItemInvoice = function (_a) {
    var item = _a.item, _b = _a.showDetails, showDetails = _b === void 0 ? false : _b;
    var _c = item.attributes, total_tvac = _c.total_tvac, late_fees = _c.late_fees, remaining_balance_with_fees = _c.remaining_balance_with_fees, localized_money_object = _c.localized_money_object, currency = _c.currency, linked_credit_notes = _c.linked_credit_notes, payments = _c.payments, matched_payments = _c.matched_payments;
    var lateFees = Number(late_fees);
    var totalTvac = Number(total_tvac);
    var total = totalTvac + lateFees;
    var localizedTotal = Number(localized_money_object.total_tvac) + Number(localized_money_object.late_fees);
    var remainingBalanceWithFees = Number(remaining_balance_with_fees);
    var localizedRemainingBalanceWithFees = Number(localized_money_object.remaining_balance_with_fees);
    return (React.createElement(React.Fragment, null,
        React.createElement("td", { className: styles('debit') },
            React.createElement(Amount, { localizedValue: total, value: localizedTotal, suffix: currency }),
            showDetails && (React.createElement("div", { className: styles('details') },
                React.createElement(Amount, { localizedValue: totalTvac, value: Number(localized_money_object.total_tvac), suffix: currency }),
                lateFees !== 0 && (React.createElement(Amount, { localizedValue: lateFees, value: Number(localized_money_object.late_fees), suffix: currency }))))),
        React.createElement("td", { className: styles('credit') },
            total - remainingBalanceWithFees > 0 && (React.createElement(Amount, { localizedValue: total - remainingBalanceWithFees, value: localizedTotal - localizedRemainingBalanceWithFees, suffix: currency })),
            showDetails && (React.createElement("div", { className: styles('details') },
                React.createElement("p", null, "\u200B"),
                lateFees !== 0 && React.createElement("p", null, "\u200B"),
                React.createElement(ClientDetailTabsBalanceItemInvoiceLinkedCreditNotes, { linkedCreditNotes: linked_credit_notes, currency: currency }),
                React.createElement(ClientDetailTabsBalanceItemInvoiceLinkedPayments, { linkedPayments: payments, currency: currency }),
                React.createElement(ClientDetailTabsBalanceItemInvoiceLinkedMatchedPayments, { linkedMatchedPayments: matched_payments, currency: currency })))),
        React.createElement("td", { className: styles('balance') },
            React.createElement(Amount, { localizedValue: remainingBalanceWithFees, value: localizedRemainingBalanceWithFees, suffix: currency }))));
};
