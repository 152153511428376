import { z } from 'zod';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { axiosInstance } from './utils';
var CreateReportSchema = z.object({
    viewId: z.string(),
    name: z.string(),
    emails: z.array(z.string()),
    periodicity: z.object({
        type: z.union([z.literal('weekly'), z.literal('monthly')]),
        days: z.array(z.number()),
    }),
});
export var useCreateReport = function () {
    var client = useQueryClient();
    var mutation = useMutation({
        mutationFn: function (data) {
            return axiosInstance.post('reports/export_tasks', CreateReportSchema.parse(data));
        },
        onSuccess: function (response) {
            notifications.show({
                message: response.data.message,
                color: 'green',
            });
            client.invalidateQueries(['reports']);
        },
    });
    return addActionNameToMutationResult('createReport', mutation);
};
