import { __assign, __read, __spreadArray } from "tslib";
import { treatActivity, treatInvoice } from 'shared/serializer';
import { removeAttributes } from 'shared/utils/view';
import { invoiceConstants as events } from 'store/invoice/invoice.actions';
import { baseReducerData, baseReducerInfinitePage, baseReducerListPage, handlePageRequest, handlePageResponse, handleRequest, handleReset, handleResponse, handleUpdateMultiplePaginationResponse, } from 'store/reducers';
import { sessionConstants as session } from 'store/session/session.actions';
export var initialState = {
    toHandle: baseReducerListPage,
    list: __assign(__assign({}, baseReducerInfinitePage), { pages: [] }),
    postponable: baseReducerListPage,
    current: baseReducerData,
    currentActivity: baseReducerData,
    toConfirm: baseReducerListPage,
    invoiceActionData: baseReducerData,
    isLoading: false,
};
var addActivities = function (state, _a) {
    var payload = _a.payload;
    var currentActivity = __assign({}, state.currentActivity);
    currentActivity.data = __spreadArray(__spreadArray([], __read(state.currentActivity.data), false), __read(payload.data.map(function (activity) { return treatActivity(activity); })), false);
    currentActivity.metadata = payload.metadata;
    return __assign(__assign({}, state), { currentActivity: currentActivity });
};
var onCommentInvoice = function (state, _a) {
    var data = _a.data;
    return __assign(__assign({}, state), { current: __assign(__assign({}, state.current), { data: __assign(__assign({}, state.current.data), { comments_attributes: __spreadArray(__spreadArray([], __read(state.current.data.comments_attributes), false), [
                    removeAttributes(data.comment.data),
                ], false) }) }), currentActivity: __assign(__assign({}, state.currentActivity), { data: __spreadArray([data.activity], __read(state.currentActivity.data), false) }) });
};
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case events.listPage.request:
            return handlePageRequest(state, action, 'list');
        case events.listPage.result:
            return handlePageResponse(state, action, 'list', removeAttributes);
        case events.detail.request:
            return handleRequest(state, action, 'current', true);
        case events.setSendingOptions.result:
        case events.detail.result:
            return handleResponse(state, action, 'current', treatInvoice);
        case events.send.request:
        case events.actions.thirdParty.request:
        case events.actions.formalNotice.request:
        case events.updateOriginalFile.request:
        case events.actions.additionalReminder.request:
        case events.actions.batchAction.request:
            return __assign(__assign({}, state), { isLoading: true });
        case events.send.result:
        case events.actions.thirdParty.result:
        case events.actions.formalNotice.result:
        case events.updateOriginalFile.result:
        case events.actions.additionalReminder.result:
        case events.actions.batchAction.result:
            return __assign(__assign({}, state), { isLoading: false });
        case events.addAdditionalFile.result:
            var files = state.current.data.additional_files_attributes;
            files.push(__assign(__assign({}, action.payload.data.attributes), { id: action.payload.data.id }));
            return __assign(__assign({}, state), { current: __assign(__assign({}, state.current), { data: __assign(__assign({}, state.current.data), { additional_files_attributes: files.slice() }) }) });
        case events.deleteAdditionalFile.result:
            var files1 = state.current.data.additional_files_attributes;
            var fileIndex = files1.findIndex(function (file) { return action.payload.document_id === file.id; });
            files1.splice(fileIndex, 1);
            return __assign(__assign({}, state), { current: __assign(__assign({}, state.current), { data: __assign(__assign({}, state.current.data), { additional_files_attributes: files1.slice() }) }) });
        case events.detail.reset:
            return handleReset(state, action, 'current');
        // to handle
        case events.toHandle.request:
            return handlePageRequest(state, action, 'toHandle');
        case events.toHandle.result:
            return handlePageResponse(state, action, 'toHandle', function (item) { return item; });
        // postponable
        case events.postponable.request:
            return handlePageRequest(state, action, 'postponable');
        case events.postponable.result:
            return handlePageResponse(state, action, 'postponable', function (item) { return item; });
        // current activity
        case events.activity.request:
            return handleRequest(state, action, 'currentActivity', true);
        case events.activity.result:
            return handleResponse(state, action, 'currentActivity', treatActivity);
        case events.getActivityPage.result:
            return addActivities(state, action);
        case events.getGroupedInvoices.request:
            return handleRequest(state, action, 'invoiceActionData', true);
        case events.actions.forceReminder.result:
            return handleUpdateMultiplePaginationResponse(state, action, 'postponable', treatInvoice);
        case events.actions.comment.result:
            return onCommentInvoice(state, action.payload);
        case events.getAllToConfirm.request:
            return handlePageRequest(state, action, 'toConfirm');
        case events.getAllToConfirm.result:
            return handlePageResponse(state, action, 'toConfirm', treatInvoice);
        case events.getToConfirm.request:
            return handleRequest(state, action, 'current', true);
        case events.getToConfirm.result:
            return handleResponse(state, action, 'current', treatInvoice);
        case events.validateAllImportedInvoices.result:
            var newState = __assign({}, state);
            if (action.status === 'fulfilled') {
                newState.toConfirm = baseReducerListPage;
            }
            return newState;
        case events.previewTemplate.result:
            return handleRequest(state, action, 'preview_template');
        // others
        case session.logout.result:
            return initialState;
        default:
            return state;
    }
};
export default reducer;
