import { __assign, __read, __spreadArray, __values } from "tslib";
import React, { useEffect, useReducer } from 'react';
import { useProfile } from 'app/App';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useHistory, useLocation } from 'react-router-dom';
import { useChangeLocale, useIsConnectorActive, useLoadNotificationsCount } from 'shared/hooks';
import { isDemo, isProduction } from 'shared/utils/environment';
import { useGetCompany } from 'shared/utils/selectors';
import { reducerState } from 'shared/utils/view';
import { Badge, Indicator, rem } from '@mantine/core';
import { Spotlight } from '@mantine/spotlight';
import { IconAdjustmentsAlt, IconAt, IconBell, IconBuilding, IconBuildingBank, IconCalendarClock, IconChartAreaLine, IconDashboard, IconEdit, IconFileAlert, IconFileDollar, IconFileImport, IconFolders, IconHistory, IconInvoice, IconLayersLinked, IconLayoutKanban, IconListCheck, IconMailCog, IconMapDollar, IconPackage, IconPlugConnected, IconReport, IconSearch, IconSettings, IconShoppingCart, IconTemplate, IconTimeline, IconUser, IconUsers, IconWallet, } from '@tabler/icons-react';
import { INTEGRATIONS_ROUTE } from '../Integrations';
import NavContent from '../NavContent';
import styleIdentifiers from './nav.scss';
var styles = classNames.bind(styleIdentifiers);
function menuToSpotlightActions(menu, navigate) {
    return menu.reduce(function (acc, item) {
        if (item.menu) {
            return __spreadArray(__spreadArray([], __read(acc), false), __read(menuToSpotlightActions(item.menu, navigate)), false);
        }
        return __spreadArray(__spreadArray([], __read(acc), false), [
            {
                id: item.text,
                label: item.text,
                onClick: function () { var _a; return navigate((_a = item.url) !== null && _a !== void 0 ? _a : ''); },
            },
        ], false);
    }, []);
}
export default function Nav(_a) {
    var _b, _c, _d;
    var onClickItem = _a.onClickItem;
    var _e = useTranslation(), t = _e.t, currentLang = _e.currentLang;
    var history = useHistory();
    var location = useLocation();
    var company = useGetCompany();
    var profile = useProfile();
    var isConnectorActive = useIsConnectorActive();
    var changeLocale = useChangeLocale();
    var notificationsCount = useLoadNotificationsCount().notificationsCount;
    var welcomeText = function () {
        var text = t(i18nKeys.WELCOME);
        if (profile) {
            text += ", ".concat(profile.name.first);
        }
        return text;
    };
    var _f = __read(useReducer(reducerState, {
        data: null,
        current: {
            icon: '',
            text: '',
        },
    }), 2), state = _f[0], setState = _f[1];
    useEffect(function () {
        var _a;
        var data = {
            first: true,
            text: welcomeText(),
            menu: [
                {
                    url: '/dashboard',
                    text: t(i18nKeys.COMMON.DASHBOARD),
                    icon: React.createElement(IconDashboard, { size: 27, stroke: 1.5 }),
                },
                {
                    text: t(i18nKeys.COMMON.INVOICING),
                    icon: React.createElement(IconFileDollar, { size: 27, stroke: 1.5 }),
                    count: company === null || company === void 0 ? void 0 : company.unvalidated_invoices_count,
                    menu: __spreadArray([
                        {
                            url: '/invoices/listing',
                            text: t(i18nKeys.NAV.ALL_INVOICE),
                            icon: React.createElement(IconFileDollar, { size: 27, stroke: 1.5 }),
                        },
                        {
                            url: '/credit-notes/listing',
                            text: t(i18nKeys.NAV.CREDIT_NOTES),
                            icon: React.createElement(IconInvoice, { size: 27, stroke: 1.5 }),
                        }
                    ], __read((!isConnectorActive
                        ? [
                            {
                                url: '/products',
                                text: t(i18nKeys.NAV.PRODUCTS),
                                icon: React.createElement(IconShoppingCart, { size: 27, stroke: 1.5 }),
                            },
                            {
                                url: '/invoices/import',
                                text: t(i18nKeys.INVOICES_IMPORT),
                                count: company.unvalidated_invoices_count,
                                icon: React.createElement(IconFileImport, { size: 27, stroke: 1.5 }),
                            },
                        ]
                        : [])), false),
                },
                {
                    url: '/analytics',
                    text: 'Analytics',
                    icon: React.createElement(IconChartAreaLine, { size: 27, stroke: 1.5 }),
                    badge: (React.createElement(Badge, { radius: "sm", size: "sm", color: "violet.4", variant: "light" }, "BETA")),
                },
                {
                    url: '/clients',
                    text: t(i18nKeys.CLIENTS),
                    icon: React.createElement(IconUsers, { size: 27, stroke: 1.5 }),
                    count: company === null || company === void 0 ? void 0 : company.unvalidated_debtors_count,
                },
                {
                    tooltip: "Hidden in production (and demo), don't panic",
                    isHidden: isProduction() || isDemo(),
                    url: '/reports',
                    text: 'Reports',
                    icon: React.createElement(IconReport, { size: 27, stroke: 1.5 }),
                    badge: (React.createElement(Badge, { radius: "sm", variant: "light", size: "sm" }, "New!")),
                },
                {
                    url: '/activities',
                    text: t(i18nKeys.NAV.ACTIVITY_BOOK),
                    icon: (React.createElement(Indicator, { h: 27, color: "blue", disabled: notificationsCount === 0, offset: 3, size: 7 },
                        React.createElement(IconBell, { size: 23, stroke: 1.5 }))),
                },
                {
                    text: t(i18nKeys.NAV.SETTINGS),
                    icon: React.createElement(IconSettings, { size: 27, stroke: 1.5 }),
                    menu: [
                        {
                            url: '/settings/user',
                            text: t(i18nKeys.SETTINGS.PROFILE.TITLE),
                            icon: React.createElement(IconUser, { size: 27, stroke: 1.5 }),
                        },
                        {
                            url: '/settings/company',
                            text: t(i18nKeys.SETTINGS.COMPANY.TITLE),
                            icon: React.createElement(IconBuilding, { size: 27, stroke: 1.5 }),
                        },
                        {
                            url: '/settings/preferences',
                            text: t(i18nKeys.PREFERENCES),
                            icon: React.createElement(IconAdjustmentsAlt, { size: 27, stroke: 1.5 }),
                        },
                        {
                            url: '/settings/package',
                            text: t(i18nKeys.SETTINGS.PACKAGE.TITLE),
                            icon: React.createElement(IconPackage, { size: 27, stroke: 1.5 }),
                        },
                        {
                            url: '/settings/external-mailbox',
                            text: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.TITLE),
                            icon: React.createElement(IconMailCog, { size: 27, stroke: 1.5 }),
                        },
                        {
                            url: '/settings/billing',
                            text: t(i18nKeys.COMMON.INVOICING),
                            icon: React.createElement(IconFileDollar, { size: 27, stroke: 1.5 }),
                        },
                    ],
                },
            ],
        };
        data.menu.splice(1, 0, {
            text: t(i18nKeys.NAV.MY_FOLLOW_UP),
            icon: React.createElement(IconTimeline, { size: 27, stroke: 1.5 }),
            menu: [
                {
                    url: '/follow-up',
                    text: t(i18nKeys.NAV.SUMMARY_TABLE),
                    icon: React.createElement(IconLayoutKanban, { size: 27, stroke: 1.5 }),
                },
                {
                    url: '/invoices/to-handle',
                    text: t(i18nKeys.INVOICE_TO_PROCESS),
                    icon: React.createElement(IconFileAlert, { size: 27, stroke: 1.5 }),
                },
                {
                    url: '/invoices/actions',
                    text: t(i18nKeys.NAV.IMINENT_REMINDERS),
                    icon: React.createElement(IconCalendarClock, { size: 27, stroke: 1.5 }),
                    count: (_a = company.reminders_to_send_count) === null || _a === void 0 ? void 0 : _a.next_five_days,
                },
                {
                    url: '/thirdparty_cases',
                    text: t(i18nKeys.NAV.THIRDPARTY),
                    icon: React.createElement(IconFolders, { size: 27, stroke: 1.5 }),
                },
                {
                    url: '/payment-plans/listing?status=in_progress',
                    match: '/payment-plan',
                    text: t(i18nKeys.NAV.PAYMENT_PLAN),
                    icon: React.createElement(IconWallet, { size: 27, stroke: 1.5 }),
                },
            ],
        });
        if (company.package.can_use_tasks) {
            data.menu[1].menu.push({
                url: '/tasks',
                match: '/tasks',
                text: t(i18nKeys.NAV.TASKS),
                icon: React.createElement(IconListCheck, { size: 20, stroke: 1.5 }),
            });
        }
        data.menu[1].menu.push({
            url: '/mails',
            match: '/mails',
            text: t(i18nKeys.MAILS.NAV),
            icon: React.createElement(IconAt, { size: 27, stroke: 1.5 }),
        });
        if (company.banking_method !== 'no_bank' && !isConnectorActive) {
            data.menu.splice(4, 0, {
                text: t(i18nKeys.COMMON.BANK_ACCOUNT),
                icon: React.createElement(IconBuildingBank, { size: 27, stroke: 1.5 }),
                disabled: company.banking_method === 'no_bank',
                menu: [
                    {
                        url: '/transactions/reconciliation',
                        text: t(i18nKeys.NAV.RECONCILIATION),
                        icon: React.createElement(IconLayersLinked, { size: 27, stroke: 1.5 }),
                    },
                    {
                        url: '/transactions/history',
                        text: t(i18nKeys.NAV.PAYMENT_BOOK),
                        icon: React.createElement(IconHistory, { size: 27, stroke: 1.5 }),
                    },
                ],
            });
        }
        data.menu[data.menu.length - 1].menu.push({
            url: '/settings/workflows',
            match: '/settings/workflows',
            text: t(i18nKeys.AUTOMATED_PLANS),
            icon: React.createElement(IconMapDollar, { size: 27, stroke: 1.5 }),
        });
        data.menu[data.menu.length - 1].menu.push({
            url: '/settings/document-customization',
            match: '/settings/document-customization',
            text: t(i18nKeys.TEMPLATES),
            icon: React.createElement(IconTemplate, { size: 27, stroke: 1.5 }),
        });
        data.menu[data.menu.length - 1].menu.push({
            url: '/settings/customization',
            match: '/settings/customization',
            text: t(i18nKeys.NAV.CUSTOMIZATION),
            icon: React.createElement(IconEdit, { size: 27, stroke: 1.5 }),
        });
        data.menu[data.menu.length - 1].menu.push({
            url: '/settings/collaborators',
            text: t(i18nKeys.SETTINGS.STAFF.TITLE),
            icon: React.createElement(IconUsers, { size: 27, stroke: 1.5 }),
        });
        data.menu[data.menu.length - 1].menu.push({
            url: INTEGRATIONS_ROUTE,
            match: '/settings/integration',
            text: t(i18nKeys.SETTINGS.INTEGRATIONS.TITLE),
            icon: React.createElement(IconPlugConnected, { size: 27, stroke: 1.5 }),
        });
        setState({ data: data });
    }, [
        currentLang,
        company === null || company === void 0 ? void 0 : company.unvalidated_invoices_count,
        company === null || company === void 0 ? void 0 : company.unvalidated_debtors_count,
        company === null || company === void 0 ? void 0 : company.custom_variables_attributes,
        (_b = company.reminders_to_send_count) === null || _b === void 0 ? void 0 : _b.next_five_days,
        notificationsCount,
    ]);
    useEffect(function () {
        var current = state.data;
        if (state.data) {
            state.data.menu.some(function (item) {
                if (item.menu) {
                    var matchRoute = handleRoute(item);
                    if (matchRoute) {
                        current = matchRoute;
                        return true;
                    }
                }
                else if (item.url === location.pathname) {
                    return true;
                }
            });
            if (current)
                setState({ current: current });
        }
    }, [location.pathname, state.data]);
    var handleRoute = function (data) {
        var e_1, _a;
        try {
            for (var _b = __values(data.menu), _c = _b.next(); !_c.done; _c = _b.next()) {
                var item = _c.value;
                if (location.pathname.match(/\/clients\/\d+\/tasks/))
                    return false;
                if (item.menu) {
                    var matchRoute = handleRoute(item);
                    if (matchRoute) {
                        return item;
                    }
                }
                else if (item.match) {
                    if (location.pathname.indexOf(item.match) !== -1) {
                        return data;
                    }
                }
                else if (item.url === location.pathname) {
                    return data;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return false;
    };
    var previous = function () {
        setState({
            data: state.data,
            current: __assign({}, state.data),
        });
    };
    var goSubMenu = function (item) {
        setState({
            data: state.data,
            current: __assign({}, item),
        });
    };
    var spotlightActions = menuToSpotlightActions((_d = (_c = state.data) === null || _c === void 0 ? void 0 : _c.menu) !== null && _d !== void 0 ? _d : [], history.push);
    spotlightActions.push.apply(spotlightActions, [
        {
            id: 'setLangEn',
            label: t(i18nKeys.SPOTLIGHT.SET_LOCALE, { locale: 'EN' }),
            onClick: function () { return changeLocale('en'); },
        },
        {
            id: 'setLangFr',
            label: t(i18nKeys.SPOTLIGHT.SET_LOCALE, { locale: 'FR' }),
            onClick: function () { return changeLocale('fr'); },
        },
        {
            id: 'setLangNl',
            label: t(i18nKeys.SPOTLIGHT.SET_LOCALE, { locale: 'NL' }),
            onClick: function () { return changeLocale('nl'); },
        },
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Spotlight, { limit: 10, shortcut: ['mod+p', '/'], actions: spotlightActions, centered: true, highlightQuery: true, styles: {
                search: {
                    borderRadius: '0px',
                    border: 'none',
                    borderBottom: '1px solid lightgrey',
                },
                action: {
                    width: '100%',
                    padding: '10px 0px 10px 20px',
                },
                content: {
                    borderRadius: '10px',
                },
            }, searchProps: {
                leftSection: React.createElement(IconSearch, { style: { width: rem(20), height: rem(20) }, stroke: 1.5 }),
                placeholder: t(i18nKeys.SPOTLIGHT.PLACEHOLDER),
            } }),
        React.createElement("div", { className: styles('nav') },
            React.createElement(NavContent, { state: state, goSubMenu: goSubMenu, previous: previous, onClickItem: onClickItem }),
            company.testing_period && (React.createElement("span", { className: styles('test') }, t(i18nKeys.TEST_PERIOD))))));
}
