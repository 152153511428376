import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, axiosInstance } from './utils';
var BalanceValueSchema = z.preprocess(function (val) { return Number(val); }, z.number());
var BalanceSchema = z.object({
    '0': BalanceValueSchema,
    '30': BalanceValueSchema,
    '60': BalanceValueSchema,
    '90': BalanceValueSchema,
    notDue: BalanceValueSchema,
});
var AgedBalanceOverTimeSchema = z.object({
    computedAt: z.string(),
    isComputing: z.boolean(),
    data: z.record(z.object({
        credits: BalanceSchema,
        debits: BalanceSchema,
    })),
});
export function useLoadAgedBalanceOverTimeQueryFn() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance.get('/analytics/aged_balances/stacked')];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, AgedBalanceOverTimeSchema.parse(data)];
            }
        });
    });
}
export var useLoadAgedBalanceOverTime = function () {
    var queryResult = useQuery({
        queryKey: ['aged-balance', 'over-time'],
        queryFn: useLoadAgedBalanceOverTimeQueryFn,
    });
    return addResourceNameToQueryResult('agedBalanceOverTime', queryResult);
};
// const src = {
//   computedAt: '2024-03-05T03:26:16.925Z',
//   isComputing: false,
//   data: {
//     '2024-02-12': {
//       credits: {
//         '0': '6804.23',
//         '30': '6079.92',
//         '60': '5604.84',
//         '90': '73774.14',
//         notDue: '19511.13',
//       },
//       debits: {
//         '0': '310022.29',
//         '30': '265215.78',
//         '60': '329843.13',
//         '90': '2770422.24',
//         notDue: '1208.08',
//       },
//     },
//     '2024-01-29': {
//       credits: {
//         '0': '11178.41',
//         '30': '1540.1',
//         '60': '2688.57',
//         '90': '30158.27',
//         notDue: '0.0',
//       },
//       debits: {
//         '0': '157388.54',
//         '30': '174190.76',
//         '60': '135583.33',
//         '90': '1311520.06',
//         notDue: '0.0',
//       },
//     },
//     '2024-02-26': {
//       credits: {
//         '0': '6260.84',
//         '30': '10051.66',
//         '60': '4181.08',
//         '90': '77273.72',
//         notDue: '22671.74',
//       },
//       debits: {
//         '0': '252303.05',
//         '30': '340326.29',
//         '60': '338132.46',
//         '90': '2866953.64',
//         notDue: '0.0',
//       },
//     },
//     '2024-03-04': {
//       credits: {
//         '0': '7503.6',
//         '30': '8915.92',
//         '60': '7016.04',
//         '90': '78438.16',
//         notDue: '23150.6',
//       },
//       debits: {
//         '0': '252818.41',
//         '30': '339184.88',
//         '60': '341742.85',
//         '90': '2911041.82',
//         notDue: '4688.8',
//       },
//     },
//     '2024-01-22': {
//       credits: {
//         '0': '5870.8',
//         '30': '1434.19',
//         '60': '2575.78',
//         '90': '30184.34',
//         notDue: '0.0',
//       },
//       debits: {
//         '0': '127659.44',
//         '30': '176622.45',
//         '60': '135895.31',
//         '90': '1294157.19',
//         notDue: '0.0',
//       },
//     },
//     '2024-02-05': {
//       credits: {
//         '0': '3433.92',
//         '30': '2166.1',
//         '60': '2884.11',
//         '90': '34461.29',
//         notDue: '9213.84',
//       },
//       debits: {
//         '0': '150284.58',
//         '30': '172231.58',
//         '60': '128011.35',
//         '90': '1357502.62',
//         notDue: '0.0',
//       },
//     },
//     '2024-02-19': {
//       credits: {
//         '0': '7400.87',
//         '30': '7509.78',
//         '60': '4856.18',
//         '90': '75945.2',
//         notDue: '21302.6',
//       },
//       debits: {
//         '0': '238092.98',
//         '30': '312885.48',
//         '60': '352321.59',
//         '90': '2823144.07',
//         notDue: '0.0',
//       },
//     },
//     '2024-01-15': {
//       credits: {
//         '0': '4136.79',
//         '30': '3004.55',
//         '60': '9370.62',
//         '90': '260419.18',
//         notDue: '0.0',
//       },
//       debits: {
//         '0': '114661.38',
//         '30': '187656.7',
//         '60': '152133.85',
//         '90': '1505638.56',
//         notDue: '0.0',
//       },
//     },
//   },
// };
