import { __assign, __read } from "tslib";
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useGetTemplates } from 'api';
import { useProfile } from 'app/App';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { omit, pick } from 'lodash-es';
import CustomTable from 'shared/components/CustomTable';
import TemplateListItem from './TemplateListItem';
import styleIdentifiers from './TemplateList.scss';
var styles = classNames.bind(styleIdentifiers);
var Table = CustomTable(TemplateListItem);
export default function TemplateList(_a) {
    var plan_type = _a.plan_type, filters = _a.filters;
    var t = useTranslation().t;
    var profile = useProfile();
    var _b = __read(useState({
        plan_type: plan_type,
        page_limit: profile.preferences.itemsPerPage || 20,
        page: 1,
    }), 2), params = _b[0], setParams = _b[1];
    useLayoutEffect(function () {
        setParams(function (currentParams) { return (__assign(__assign({}, currentParams), pick(Object.fromEntries(new URLSearchParams(location.search)), [
            'page',
            'page_limit',
            'sort_by',
            'sort_order',
            'name',
            'step_type',
        ]))); });
    }, []);
    useEffect(function () {
        setParams(function (currentParams) { return (__assign(__assign(__assign({}, omit(currentParams, ['name', 'step_type'])), filters), { page: 1 })); });
    }, [filters]);
    var _c = useGetTemplates(params), templatesListResponse = _c.data, isLoading = _c.isLoading, isFetched = _c.isFetched, refetch = _c.refetch;
    var onSortChange = function (newParams) {
        setParams(function (currentParams) { return (__assign(__assign({}, currentParams), pick(newParams, ['sort_by', 'sort_order']))); });
    };
    var handleFilters = function (newParams) {
        setParams(function (currentParams) { return (__assign(__assign({}, currentParams), pick(newParams, ['page_limit', 'page']))); });
    };
    var headers = [
        {
            title: t(i18nKeys.NAME),
            key: 'name',
        },
        {
            title: t(i18nKeys.FORM.TYPE),
            key: 'step_type',
        },
        {
            title: t(i18nKeys.UPDATE_DATE),
            key: 'updated_at',
        },
        {
            title: t(i18nKeys.FORM.ACTIONS),
            width: 100,
        },
    ];
    return (React.createElement(Table, { headers: headers, className: styles('templates-list'), items: (templatesListResponse === null || templatesListResponse === void 0 ? void 0 : templatesListResponse.data) || [], pagination: templatesListResponse === null || templatesListResponse === void 0 ? void 0 : templatesListResponse.metadata.pagination, loading: isLoading, loaded: isFetched, onSortChange: onSortChange, handleFilters: handleFilters, itemProps: { refetch: refetch }, noCheckbox: true, noShadow: true }));
}
