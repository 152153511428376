import { __read } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Card from 'shared/components/Card';
import GradientBorder from 'shared/components/GradientBorder';
import HTML from 'shared/components/HTML/HTML';
import BankConnection from './BankConnection';
import ColorLogo from './ColorLogo';
import GeneralInfo from './GeneralInfo/GeneralInfo';
import Integrations from './Integrations';
import { AVAILABLE_STEPS, OnBoardingSteps } from './OnBoarding.constants';
import styleIdentifiers from './onBoarding.scss';
import PaymentMethod from './PaymentMethod';
import SetupDebtCollection from './SetupDebtCollection';
var styles = classNames.bind(styleIdentifiers);
export default function OnBoarding() {
    var t = useTranslation().t;
    var history = useHistory();
    var company = useSelector(function (state) { return state.account.company.data; });
    var _a = __read(useState((company === null || company === void 0 ? void 0 : company.onboarding_step) || OnBoardingSteps.STEP_1), 2), step = _a[0], setStep = _a[1];
    useEffect(function () {
        if (company === null || company === void 0 ? void 0 : company.onboarding_done) {
            history.push('/dashboard');
        }
    }, [company, history]);
    if (!company) {
        return null;
    }
    var stepNumber = Number(step.charAt(step.length - 1));
    var vat_number = company.vat_number;
    return (React.createElement("div", { className: styles('on-boarding', 'wrapper') },
        React.createElement("div", null,
            React.createElement(Card, { title: t(i18nKeys.ONBOARDING.TITLE) }, step !== OnBoardingSteps.DONE && (React.createElement("div", { className: styles('header-card') },
                React.createElement("div", { className: styles('step-name') },
                    vat_number && "".concat(t(i18nKeys.ONBOARDING.STEP), " ").concat(stepNumber, " :").concat(' '),
                    t(i18nKeys.ONBOARDING["STEP".concat(vat_number ? stepNumber : 0)].TITLE)),
                vat_number && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles('progress-bar') },
                        React.createElement(GradientBorder, { className: styles('progress') }),
                        React.createElement("div", { className: styles('anti-progress'), style: {
                                left: "".concat(stepNumber * (100 / AVAILABLE_STEPS.length), "%"),
                            } })),
                    React.createElement("div", { className: styles('summary') },
                        React.createElement(HTML, { html: t(i18nKeys.ONBOARDING["STEP".concat(stepNumber)].SUMMARY) }))))))),
            step === OnBoardingSteps.STEP_1 && vat_number && (React.createElement(PaymentMethod, { onValidStep: function (nextStep) {
                    return setStep(nextStep || OnBoardingSteps.STEP_2);
                } })),
            step === OnBoardingSteps.STEP_1 && !vat_number && React.createElement(GeneralInfo, null),
            step === OnBoardingSteps.STEP_2 && (React.createElement(BankConnection, { onValidStep: function (nextStep) {
                    return setStep(nextStep || OnBoardingSteps.STEP_3);
                }, disableIntegration: !(company === null || company === void 0 ? void 0 : company.ponto_integration.can_activate) && !(company === null || company === void 0 ? void 0 : company.codabox_integration.can_activate) })),
            step === OnBoardingSteps.STEP_3 && (React.createElement(SetupDebtCollection, { onValidStep: function (nextStep) {
                    setStep(nextStep || OnBoardingSteps.STEP_4);
                } })),
            step === OnBoardingSteps.STEP_4 && (React.createElement(ColorLogo, { onValidStep: function (nextStep) {
                    return setStep(nextStep || OnBoardingSteps.STEP_5);
                } })),
            step === OnBoardingSteps.STEP_5 && (React.createElement(Integrations, { onValidStep: function (nextStep) { return setStep(nextStep || OnBoardingSteps.DONE); } })))));
}
