import { __assign, __read, __spreadArray } from "tslib";
import React, { useEffect, useMemo, useReducer } from 'react';
import AnimateNumber from 'animated-number-react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Amount from 'shared/components/Amount';
import CustomTable from 'shared/components/CustomTable';
import HTML from 'shared/components/HTML/HTML';
import { Button } from 'shared/io';
import { treatBillingStatement, treatInvoiceActionData } from 'shared/serializer';
import { currencySymbol, formatAmount } from 'shared/utils/normalization';
import { reducerState } from 'shared/utils/view';
import ChooseGroupedItem from './ChooseInvoiceItem/ChooseGroupedItem';
import styleIdentifiers from './ChooseInvoicesForm.scss';
var styles = classNames.bind(styleIdentifiers);
var GroupedTable = CustomTable(ChooseGroupedItem);
export default function ChooseInvoicesForm(_a) {
    var onSubmit = _a.onSubmit, type = _a.type, getData = _a.getData;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var constants = useSelector(function (state) { return state.app.constants; });
    var text = useMemo(function () { return ({
        summary: {
            lawyer: 'FORM.CHOOSE_INVOICES.BAILIFF_DESCRIPTION',
            bailiff: 'FORM.CHOOSE_INVOICES.BAILIFF_DESCRIPTION',
        },
    }); }, []);
    var form = useForm();
    var _b = __read(useReducer(reducerState, {
        formValues: {
            additional_invoices: [],
            credit_notes: [],
            tp_payments: [],
            accounting_payments: [],
        },
        data: null,
        loading: true,
    }), 2), _c = _b[0], formValues = _c.formValues, loading = _c.loading, data = _c.data, _setFormValues = _b[1];
    useEffect(function () {
        getData(function (res) {
            var values = treatBillingStatement(res.data);
            if (values.additional_invoices.length +
                values.tp_payments.length +
                values.credit_notes.length +
                values.accounting_payments.length ===
                0) {
                onSubmit({
                    invoiceActionData: treatInvoiceActionData(res.data),
                });
            }
            else {
                _setFormValues({
                    formValues: {
                        additional_invoices: values.additional_invoices.map(function (item) { return ({
                            id: item.id,
                        }); }),
                        tp_payments: values.tp_payments.map(function (item) { return ({ id: item.id }); }),
                        credit_notes: values.credit_notes.map(function (item) { return ({ id: item.id }); }),
                        accounting_payments: values.accounting_payments.map(function (item) { return ({
                            id: item.id,
                        }); }),
                    },
                    data: values,
                });
            }
        });
        setTimeout(function () {
            _setFormValues({
                loading: false,
            });
        }, 300);
    }, []);
    var setFormValues = function (newFormValue) {
        _setFormValues({
            formValues: __assign(__assign({}, formValues), newFormValue),
        });
    };
    var invoices = data
        ? __spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read(data.additional_invoices), false), __read((data.credit_notes || [])), false), __read(data.accounting_payments), false), __read(data.tp_payments), false) : null;
    var mainInvoice = data === null || data === void 0 ? void 0 : data.main_invoice;
    var totalAmount = useMemo(function (localized) {
        if (localized === void 0) { localized = true; }
        if (!data)
            return 0;
        var invoiceAmount = 0;
        if (mainInvoice) {
            var priceObject = localized ? mainInvoice.localized_money_object : mainInvoice;
            invoiceAmount = priceObject.remaining_balance;
        }
        var invoiceAmountTotal = 0;
        invoices.forEach(function (invoiceAcc) {
            var itemValue = formValues[invoiceAcc.itemType].find(function (item) { return item.id === invoiceAcc.id; });
            var priceObject = localized ? invoiceAcc.localized_money_object : invoiceAcc;
            if (itemValue.add) {
                if (invoiceAcc.type === 'fees_preview_invoice') {
                    var value = Number(priceObject.remaining_balance);
                    invoiceAmountTotal += value;
                }
                else if (invoiceAcc.type === 'grouped_credit_note') {
                    var value = priceObject.remaining_balance
                        ? Number(priceObject.remaining_balance)
                        : -(Number(priceObject.remaining_balance) || Number(priceObject.amount));
                    invoiceAmountTotal += value;
                }
                else {
                    var value = -(Number(priceObject.remaining_balance) || Number(priceObject.amount));
                    invoiceAmountTotal += value;
                }
            }
        });
        return +invoiceAmount + invoiceAmountTotal;
    }, [formValues, invoices]);
    var toggleCheckAll = function (checked) {
        var newFormValues = {};
        for (var item in formValues) {
            newFormValues[item] = formValues[item].map(function (item) { return (__assign(__assign({}, item), { add: checked })); });
        }
        setFormValues(newFormValues);
    };
    var submit = function () {
        onSubmit({
            tp_payments: formValues.tp_payments.filter(function (value) { return value.add; }).map(function (item) { return item.id; }),
            accounting_payments: formValues.accounting_payments
                .filter(function (value) { return value.add; })
                .map(function (item) { return item.id; }),
            credit_notes: formValues.credit_notes.filter(function (value) { return value.add; }).map(function (item) { return item.id; }),
            additional_invoices: formValues.additional_invoices
                .filter(function (value) { return value.add; })
                .map(function (item) { return item.id; }),
            invoiceActionData: treatInvoiceActionData(data),
        });
    };
    var updatedAmountBalanceWithFees = function (localized) {
        if (localized === void 0) { localized = false; }
        var priceObject = localized ? mainInvoice.localized_money_object : mainInvoice;
        return priceObject.remaining_balance;
    };
    var toggleAdd = function (type, id) { return function (checked) {
        var _a;
        var newFormValues = formValues[type].slice();
        var itemIndex = newFormValues.findIndex(function (item) { return item.id === id; });
        newFormValues[itemIndex].add = checked;
        setFormValues((_a = {},
            _a[type] = newFormValues,
            _a));
    }; };
    return data && !loading ? (React.createElement("div", { className: styles('choose-invoice-form') },
        React.createElement("div", { className: styles('description') },
            React.createElement(HTML, { html: t(text.summary[type] || i18nKeys.FORM.CHOOSE_INVOICES.DESCRIPTION) })),
        React.createElement("div", { className: styles('description') },
            React.createElement(HTML, { html: t(i18nKeys.FORM.CHOOSE_INVOICES.LATE_FEE_DISCLAIMER) })),
        React.createElement("div", { className: styles('main-invoice') },
            React.createElement("div", { className: styles('title') }, t(i18nKeys.FORM.CHOOSE_INVOICES.MAIN_INVOICE)),
            React.createElement("div", { className: styles('info') },
                React.createElement("div", null,
                    React.createElement("span", null,
                        t(i18nKeys.FORM.REFERENCE),
                        ":"),
                    " ",
                    mainInvoice.reference),
                React.createElement("div", null,
                    React.createElement("span", null,
                        t(i18nKeys.TOTAL_VAT_PRICE),
                        ":"),
                    React.createElement(Amount, { localizedValue: mainInvoice.total_tvac, value: mainInvoice.localized_money_object.total_tvac, suffix: mainInvoice === null || mainInvoice === void 0 ? void 0 : mainInvoice.currency, className: styles('value', 'bigger') })),
                React.createElement("div", null,
                    React.createElement("span", null,
                        t(i18nKeys.INVOICING.ALREADY_PAID),
                        ":"),
                    React.createElement(Amount, { localizedValue: mainInvoice.already_paid_amount, value: mainInvoice.localized_money_object.already_paid_amount, suffix: mainInvoice === null || mainInvoice === void 0 ? void 0 : mainInvoice.currency, className: styles('value', 'bigger') })),
                React.createElement("div", null,
                    React.createElement("span", null,
                        " ",
                        t(i18nKeys.BALANCE),
                        ": "),
                    React.createElement(Amount, { localizedValue: updatedAmountBalanceWithFees(), value: updatedAmountBalanceWithFees(true), suffix: mainInvoice === null || mainInvoice === void 0 ? void 0 : mainInvoice.currency, className: styles('value', 'bigger') })))),
        React.createElement(FormProvider, __assign({}, form),
            React.createElement("div", null,
                React.createElement(GroupedTable, { className: styles('invoice-table'), customKey: function (item) { return "".concat(item.itemType, "_").concat(item.id); }, onToggleCheckAll: function (check) { return toggleCheckAll(check); }, items: invoices, noShadow: true, noMargin: true, itemProps: { formValues: formValues, toggleAdd: toggleAdd }, headers: [
                        {
                            title: t(i18nKeys.FORM.TYPE),
                        },
                        {
                            title: t(i18nKeys.FORM.REFERENCE),
                        },
                        {
                            title: t(i18nKeys.DATE),
                        },
                        {
                            title: t(i18nKeys.AMOUNT),
                        },
                        {
                            title: t(i18nKeys.INVOICING.ALREADY_PAID),
                        },
                        {
                            title: t(i18nKeys.BALANCE),
                        },
                    ] }))),
        React.createElement("div", { className: styles('resume') },
            React.createElement("div", null,
                React.createElement("div", { className: styles('title') },
                    React.createElement("div", null,
                        t(i18nKeys.FORM.CHOOSE_INVOICES.AMOUNT_CLAIMED),
                        " :"),
                    React.createElement("div", { className: styles('late-fee-disclaimer') }, t(i18nKeys.FORM.CHOOSE_INVOICES.NO_LATE_FEE_CLAIMED))),
                React.createElement(AnimateNumber, { duration: 400, value: totalAmount, formatValue: function (value) {
                        return "".concat(formatAmount(value, ',', '.', 2, currencySymbol(undefined, company, constants)));
                    } })),
            React.createElement(Button, { onClick: submit, className: styles('button'), label: t(i18nKeys.CONTINUE) })))) : null;
}
