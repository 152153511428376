import { __assign, __read } from "tslib";
import { styled } from '@stitches/react';
import { i18nKeys, useTranslation } from 'locales';
import { flow, map, sum, omit } from 'lodash/fp';
import React from 'react';
import CustomTable from 'shared/components/CustomTable';
import { sortByDate } from 'shared/utils/date';
import AnimateNumber from 'animated-number-react';
import { currencySymbol, formatAmount } from 'shared/utils/normalization';
import { useSelector } from 'react-redux';
import { Button } from 'shared/io';
import { useManualReconciliation } from 'api';
import { InvoiceTableItem } from './InvoiceTableItem';
var ModalBody = styled('div', { padding: '20px' });
var Tally = styled('div', {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginTop: '20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    color: '#343944',
    '& span:nth-of-type(0)': {
        color: '#eb6d2f',
    },
    variants: {
        isSaturated: {
            true: {
                color: '#25c1a4',
            },
        },
    },
});
var ActionRow = styled('div', {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    gap: '15px',
});
var InvoiceTable = CustomTable(InvoiceTableItem);
function buildHeaders(t) {
    return [
        { title: t(i18nKeys.FORM.REFERENCE) },
        { title: t(i18nKeys.ISSUE_DATE) },
        { title: t(i18nKeys.FORM.CHOOSE_INVOICES.REMAINING_AMOUNT) },
        { title: t(i18nKeys.WITH_FEES) },
        { title: t(i18nKeys.AMOUNT) },
    ];
}
function getInitialAmounts(invoices, totalAssignableAmount) {
    var _a = __read(sortByDate(invoices, 'issue_date', 'oldestFirst').reduce(function (_a, invoice) {
        var _b;
        var _c = __read(_a, 2), amounts = _c[0], remainingAssignableAmount = _c[1];
        var amount = Math.min(remainingAssignableAmount, Number(invoice.remaining_balance));
        var fixedPrecisionAmount = Number(amount.toFixed(2));
        return [
            __assign(__assign({}, amounts), (_b = {}, _b[invoice.id] = String(fixedPrecisionAmount), _b)),
            remainingAssignableAmount - fixedPrecisionAmount,
        ];
    }, [{}, totalAssignableAmount]), 1), finalAmounts = _a[0];
    return finalAmounts;
}
function handleSetAmount(invoiceId, _newAmount, amounts, invoices, totalAssignableAmount, setAmounts) {
    // Allow fully erasing the field to avoid sticky leading 0s
    if (_newAmount === '') {
        setAmounts(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[invoiceId] = '', _a)));
        });
        return;
    }
    _newAmount = _newAmount.replaceAll(',', '.');
    var newAmount = Number(_newAmount);
    if (newAmount < 0) {
        setAmounts(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[invoiceId] = '0', _a)));
        });
        return;
    }
    var currentlyAssignedAmountWithoutThisField = flow(omit([invoiceId]), Object.values, map(Number), sum)(amounts);
    var amountMissingFromTotalWithoutThisField = Number((totalAssignableAmount - currentlyAssignedAmountWithoutThisField).toFixed(2));
    var invoice = invoices.find(function (inv) { return inv.id === invoiceId; });
    if (invoice == null)
        return;
    var isNewAmountTooHigh = newAmount > amountMissingFromTotalWithoutThisField ||
        newAmount > invoice.remaining_balance_with_fees;
    var clampedAmount = Math.min(invoice.remaining_balance_with_fees, amountMissingFromTotalWithoutThisField);
    setAmounts(function (prev) {
        var _a;
        return (__assign(__assign({}, prev), (_a = {}, _a[invoiceId] = isNewAmountTooHigh ? clampedAmount.toString() : newAmount.toString(), _a)));
    });
}
export var ManualReconciliationModal = function (_a) {
    var payments = _a.payments, invoices = _a.invoices, onSuccess = _a.onSuccess;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var constants = useSelector(function (state) { return state.app.constants; });
    var _b = useManualReconciliation(), reconcile = _b.mutate, isLoading = _b.isLoading;
    var totalAssignableAmount = flow(map('remaining_balance'), map(Number), sum)(payments);
    var _c = __read(React.useState(function () {
        return getInitialAmounts(invoices, totalAssignableAmount);
    }), 2), assignedAmounts = _c[0], setAssignedAmounts = _c[1];
    var currentlyAssignedAmount = flow(Object.values, map(Number), sum)(assignedAmounts);
    var isSaturated = currentlyAssignedAmount === totalAssignableAmount;
    var handleReconciliation = function () {
        reconcile({
            data: {
                invoices: Object.entries(assignedAmounts).map(function (_a) {
                    var _b = __read(_a, 2), id = _b[0], amount = _b[1];
                    return ({
                        id: id,
                        amount: Number(amount),
                    });
                }),
                payment_ids: payments.map(function (payment) { return payment.id; }),
            },
        }, {
            onSuccess: function (res) {
                onSuccess();
            },
        });
    };
    return (React.createElement(ModalBody, null,
        React.createElement(InvoiceTable, { noMinHeight: true, style: { fontSize: '12px' }, headers: buildHeaders(t), items: invoices, itemProps: {
                handleSetAmount: function (id, amount) {
                    return handleSetAmount(id, amount, assignedAmounts, invoices, totalAssignableAmount, setAssignedAmounts);
                },
                amounts: assignedAmounts,
                disableButtons: isSaturated,
            }, noCheckbox: true }),
        React.createElement(Tally, { isSaturated: isSaturated },
            React.createElement("span", { style: { color: isSaturated ? '#25c1a4' : '#eb6d2f' } },
                React.createElement(AnimateNumber, { duration: 400, value: currentlyAssignedAmount, formatValue: function (value) {
                        return formatAmount(value, ',', '.', 2, currencySymbol(undefined, company, constants));
                    } })),
            ' ',
            React.createElement("span", { style: { color: isSaturated ? '#25c1a4' : 'inherit' } }, "/ ".concat(formatAmount(totalAssignableAmount, ',', '.', 2, currencySymbol(undefined, company, constants))))),
        React.createElement(ActionRow, null,
            React.createElement(Button, { disabled: currentlyAssignedAmount === 0 || isLoading, onClick: handleReconciliation }, t(i18nKeys.RECONCILE)))));
};
