import { __assign, __read } from "tslib";
import React, { useEffect, useState } from 'react';
import { useUpdateInvoice } from 'api';
import CustomVariables from 'app/Logged/CustomVariables/CustomVariables';
import CustomVariablesForm from 'app/Logged/CustomVariables/CustomVariablesForm/CustomVariablesForm';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ActivityDetail from 'shared/components/ActivityDetail';
import Conversation from 'shared/components/ActivityDetail/Conversation';
import Card from 'shared/components/Card';
import DebtorInfo from 'shared/components/DebtorInfo';
import { Icon, IconName } from 'shared/components/Icon';
import UnmatchPayment from 'shared/components/InvoiceActionsDropdown/UnmatchPayment';
import UnmatchCreditNote from 'shared/components/InvoiceActionsDropdown/UnmatchPayment/UnmatchCreditNote';
import StatusItem from 'shared/components/StatusItem';
import { Button } from 'shared/io';
import { treatActivity } from 'shared/serializer';
import { accountActions } from 'store/account/account.actions';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog, sideMenuHide, sideMenuShow, } from 'store/view/view.actions';
import InvoiceDebtorSituation from './InvoiceDebtorSituation/InvoiceDebtorSituation';
import InvoiceDetailComment from './InvoiceDetailComment/InvoiceDetailComment';
import InvoiceDetailHeader from './InvoiceDetailHeader/InvoiceDetailHeader';
import InvoiceTimeline from './InvoiceTimeline/InvoiceTimeline';
import InvoiceDetailContext from './InvoiceDetail.context';
import InvoiceInfo from './InvoiceInfo';
import InvoiceRecoveryPlan from './InvoiceRecoveryPlan';
import { LatePaymentInfo } from './LatePaymentInfo';
import ProductsDetail from './ProductsDetail';
import styleIdentifiers from './ToHandleDetail.scss';
var styles = classNames.bind(styleIdentifiers);
var nextStepTitle = {
    Callcenter: 'THIRD_PARTY_CASES.CASE_TYPE.CALLCENTER',
    Lawyer: 'THIRD_PARTY_CASES.CASE_TYPE.LAWYER',
    Bailiff: 'THIRD_PARTY_CASES.CASE_TYPE.BAILIFF',
    'Debt collector': 'THIRD_PARTY_CASES.CASE_TYPE.DEBT_COLLECTOR', // I know. -Leo.
    common: 'INVOICE.NEXT_STEP',
};
export default function ToHandleDetail(_a) {
    var _b, _c, _d, _e;
    var isCreditNote = _a.isCreditNote, id = _a.id, modal = _a.modal;
    var history = useHistory();
    var t = useTranslation().t;
    var _f = __read(useState(), 2), selectedActivityId = _f[0], setSelectedActivityId = _f[1];
    var params = useRouteMatch().params;
    var updateInvoice = useUpdateInvoice().mutate;
    var creditNote = useSelector(function (state) { var _a; return (_a = state.creditNote.current) === null || _a === void 0 ? void 0 : _a.data; });
    var invoice = useSelector(function (state) { var _a; return (_a = state.invoice.current) === null || _a === void 0 ? void 0 : _a.data; });
    var activities = useSelector(function (state) { var _a; return (_a = state[isCreditNote ? 'creditNote' : 'invoice'].currentActivity) === null || _a === void 0 ? void 0 : _a.data; });
    var activitiesMetadata = useSelector(function (state) { var _a; return (_a = state[isCreditNote ? 'creditNote' : 'invoice'].currentActivity) === null || _a === void 0 ? void 0 : _a.metadata; });
    var data = isCreditNote ? creditNote : invoice;
    useEffect(function () {
        var idResource = id !== null && id !== void 0 ? id : params.id;
        var action = isCreditNote ? creditNoteActions : invoiceActions;
        action.info({
            id: idResource,
            callback: function (_a) {
                var _data = _a.data;
                if (_data.attributes.draft) {
                    if (modal)
                        dialogHide(DialogShowId.INVOICE);
                    history.push("/".concat(isCreditNote ? 'credit-notes' : 'invoices', "/detail/").concat(idResource));
                }
            },
        });
    }, []);
    var queryString = qs.parse(location.search);
    var activityId = (_b = queryString.activityId) !== null && _b !== void 0 ? _b : selectedActivityId;
    useEffect(function () {
        if (activityId) {
            accountActions.activityDetail({
                id: Number(activityId),
                callback: function (res) {
                    showDialog({
                        id: DialogShowId.CUSTOM,
                        size: DialogShowSize.MEDIUM,
                        children: React.createElement(ActivityDetail, { item: treatActivity(res.data) }),
                        onClose: function () {
                            setSelectedActivityId(null);
                            var url = new URL(window.location.href);
                            url.searchParams.delete('activityId');
                            window.history.pushState({}, '', url.toString());
                        },
                    });
                },
            });
        }
    }, [activityId]);
    useEffect(function () {
        if (queryString.show_case && data && activities) {
            history.push(location.pathname);
            // eslint-disable-next-line
            showConversation(data)();
        }
    }, [activities, data]);
    if (!data) {
        return null;
    }
    var reloadInfo = function () {
        if (isCreditNote) {
            creditNoteActions.info({
                id: data.id,
                noReset: true,
                noLoading: true,
            });
        }
        else {
            invoiceActions.info({
                id: data.id,
                noReset: true,
                noLoading: true,
            });
        }
    };
    var getMoreActivities = function () {
        invoiceActions.getActivityPage({
            id: data.id,
            noLoading: true,
            data: {
                page: activitiesMetadata.pagination.current_page + 1,
            },
        });
    };
    var onSendMessage = function () {
        reloadInfo();
    };
    var showConversation = function (currentInvoice) { return function () {
        var _a;
        if (activities) {
            var invoiceFeedback = (_a = activities === null || activities === void 0 ? void 0 : activities.find(function (activity) { return activity.invoice_feedback; })) === null || _a === void 0 ? void 0 : _a.invoice_feedback;
            var title = currentInvoice.next_step_information.type;
            showDialog({
                id: DialogShowId.CUSTOM,
                size: DialogShowSize.MEDIUM,
                title: t(nextStepTitle[title]),
                children: (React.createElement("div", { style: { padding: 20 } }, invoiceFeedback != null ? (React.createElement(Conversation, { invoiceId: data.id, onSubmit: onSendMessage, conversation: invoiceFeedback })) : (React.createElement(Conversation, { onSubmit: onSendMessage, invoiceId: data.id, conversation: {
                        conversation: currentInvoice.next_step_information
                            .conversation,
                        id: currentInvoice.next_step_information
                            .invoice_feedback_id,
                    } })))),
            });
        }
    }; };
    var unmatchPayment = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.LARGE,
            title: t(i18nKeys.PAYMENT),
            children: isCreditNote ? (React.createElement(UnmatchCreditNote, { reload: reloadInfo, creditNote: data })) : (React.createElement(UnmatchPayment, { reload: reloadInfo, invoice: data })),
        });
    };
    var clientData = data === null || data === void 0 ? void 0 : data.debtor_attributes;
    var editInvoice = function (values) {
        if (isCreditNote) {
            creditNoteActions.update({
                data: __assign({ id: data.id }, values),
                callback: function () {
                    sideMenuHide();
                    reloadInfo();
                },
            });
        }
        else {
            updateInvoice({ id: data.id, data: values }, {
                onSuccess: function () {
                    sideMenuHide();
                    reloadInfo();
                },
            });
        }
    };
    var handleCustomVariables = function () {
        sideMenuShow({
            unmount: true,
            content: React.createElement(CustomVariablesForm, { onSubmit: editInvoice, initialValues: data }),
        });
    };
    return (React.createElement(InvoiceDetailContext.Provider, { value: {
            data: data,
            credit: isCreditNote,
            unmatchPayment: unmatchPayment,
            clientData: clientData,
            reloadInfo: reloadInfo,
        } },
        React.createElement("div", { className: styles('ToHandleDetail', data && modal && 'modal') }, data && !data.draft && (React.createElement("div", { className: styles('invoice-wrapper') },
            React.createElement(InvoiceDetailHeader, null),
            React.createElement(Card, { noHead: true, className: styles('information-card') }, isCreditNote || data.step_collection === null ? (React.createElement("div", { className: styles('check') },
                data.status,
                " ",
                data.paid && React.createElement(Icon, { name: IconName.CHECK }),
                !isCreditNote &&
                    ((_c = data.next_step_information) === null || _c === void 0 ? void 0 : _c.conversation) && (React.createElement(Button, { onClick: showConversation(data), noMargin: true, noShadow: true, small: true, label: t(i18nKeys.SHOW_CONVERSATION) })))) : (React.createElement(React.Fragment, null,
                React.createElement("span", null,
                    React.createElement(StatusItem, { step: data.step_collection, isLate: true })),
                React.createElement("span", null,
                    data.status,
                    ((_d = data.next_step_information) === null || _d === void 0 ? void 0 : _d.conversation) && (React.createElement(Button, { onClick: showConversation(data), noMargin: true, noShadow: true, small: true, label: t(i18nKeys.SHOW_CONVERSATION) })))))),
            React.createElement("div", { className: styles('line-wrapper') },
                React.createElement(InvoiceInfo, { modal: modal }),
                React.createElement(LatePaymentInfo, null),
                clientData && (React.createElement(React.Fragment, null,
                    React.createElement(DebtorInfo, { clientData: clientData, invoiceData: data }),
                    React.createElement(InvoiceDebtorSituation, null)))),
            Object.keys(data.custom_variables).length > 0 && (React.createElement(CustomVariables, { data: data.custom_variables, handleSettings: handleCustomVariables, invoiceView: true })),
            React.createElement(InvoiceTimeline, { nextAction: !data.paid && ((_e = data.next_step_information) === null || _e === void 0 ? void 0 : _e.description), metadata: activitiesMetadata, activities: activities, invoiceId: data.id, seeMore: getMoreActivities, onClickActivity: setSelectedActivityId }),
            !isCreditNote && (React.createElement(React.Fragment, null,
                React.createElement(InvoiceRecoveryPlan, null),
                React.createElement(InvoiceDetailComment, null))),
            data.product_lines_attributes.length > 0 && (React.createElement(ProductsDetail, { products: data.product_lines_attributes })))))));
}
