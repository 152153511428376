import { __assign } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import styleIdentifiers from './Avatar.scss';
var styles = classNames.bind(styleIdentifiers);
export default function Avatar(_a) {
    var name = _a.name, secondName = _a.secondName, logoUrl = _a.logoUrl, size = _a.size;
    var title = (name && secondName ? "".concat(name.slice(0, 1)).concat(secondName.slice(0, 1)) : "".concat(name.slice(0, 2))).toLocaleUpperCase();
    if (logoUrl) {
        return (React.createElement("div", { className: styles('avatar'), title: title, style: __assign({ backgroundImage: "url(".concat(logoUrl, ")") }, (size != null ? { width: size, height: size } : {})) }));
    }
    return (React.createElement("div", { style: __assign({}, (size != null ? { width: size, height: size } : {})), className: styles('avatar') },
        React.createElement("span", { className: styles('initials') }, title)));
}
