import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import Card from 'shared/components/Card';
import { accountActions } from 'store/account/account.actions';
import { onBoardingActions } from 'store/onBoarding/onBoarding.actions';
import styleIdentifiers from './GeneralInfo.scss';
import GeneralInfoForm from './GeneralInfoForm';
var styles = classNames.bind(styleIdentifiers);
export default function GeneralInfo() {
    var t = useTranslation().t;
    var onSubmit = function (data) {
        onBoardingActions.setupDebtCollectionSettings({
            data: __assign(__assign({}, data), { no_vat: true }),
            callback: function () {
                accountActions.getCompany();
            },
        });
    };
    return (React.createElement("div", { className: styles('general-info') },
        React.createElement(Card, { title: t(i18nKeys.ONBOARDING.STEP4.TITLE), className: styles('small') },
            React.createElement(GeneralInfoForm, { onSubmit: onSubmit }))));
}
