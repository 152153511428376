import { __assign, __read, __rest } from "tslib";
import classNames from 'classnames/bind';
import React, { useState } from 'react';
import uniqid from 'uniqid';
import styleIdentifiers from './CustomRadio.scss';
var styles = classNames.bind(styleIdentifiers);
export var CustomRadio = function (_a) {
    var label = _a.label, valueItem = _a.valueItem, nameItem = _a.nameItem, className = _a.className, contentClassName = _a.contentClassName, onChange = _a.onChange, ref = _a.ref, props = __rest(_a, ["label", "valueItem", "nameItem", "className", "contentClassName", "onChange", "ref"]);
    var _b = __read(useState(uniqid()), 1), id = _b[0];
    var handleChange = function (e) {
        onChange(valueItem);
    };
    return (React.createElement("div", { className: styles('CustomRadio', className) },
        React.createElement("label", { htmlFor: id },
            React.createElement("input", __assign({ type: "radio", name: nameItem, value: valueItem, id: id, onChange: handleChange }, props)),
            React.createElement("div", { className: styles('single', contentClassName) }, label))));
};
