import { __assign, __read } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Cropper from 'shared/components/Cropper';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import Percentage from 'shared/components/Percentage';
import { Button } from 'shared/io';
import { validationMessage } from 'shared/utils/validation';
import { dialogHide, showDialog, DialogShowId, DialogShowSize, error as notifyError, } from 'store/view/view.actions';
import cameraIcon from './assets/photo-camera.svg';
import styleIdentifiers from './UploadZone.scss';
var styles = classNames.bind(styleIdentifiers);
export var UploadZone = function (_a) {
    var accept = _a.accept, info = _a.info, subinfo = _a.subinfo, className = _a.className, profile = _a.profile, small = _a.small, img = _a.img, roundedPreview = _a.roundedPreview, progress = _a.progress, onRemove = _a.onRemove, noMargin = _a.noMargin, showError = _a.showError, value = _a.value, pdf = _a.pdf, multiple = _a.multiple, noResize = _a.noResize, noPreview = _a.noPreview, errorMessage = _a.errorMessage, onChange = _a.onChange, action = _a.action, _b = _a.icon, icon = _b === void 0 ? IconName.CLOUD_DOWNLOAD : _b, error = _a.error, logo = _a.logo;
    var t = useTranslation().t;
    var _c = __read(React.useState(''), 2), url = _c[0], setUrl = _c[1];
    useEffect(function () {
        if (img && value)
            setUrl(value);
    }, [value, img]);
    var onDropAccepted = function (acceptedFiles) {
        if (multiple) {
            onChange(acceptedFiles);
        }
        else {
            var file_1 = acceptedFiles[0];
            setUrl('');
            if (img) {
                // read file
                var reader_1 = new FileReader();
                reader_1.onload = function () {
                    var fileAsBinaryString = reader_1.result;
                    if (!noResize) {
                        resizeImage(fileAsBinaryString);
                    }
                    else {
                        setUrl(fileAsBinaryString);
                    }
                };
                reader_1.readAsDataURL(file_1);
            }
            else if (pdf) {
                setUrl(URL.createObjectURL(file_1));
            }
            onChange === null || onChange === void 0 ? void 0 : onChange(file_1);
        }
    };
    var onDropRejected = function () {
        notifyError({
            data: [errorMessage || t(i18nKeys.ERROR.CHOOSE_FILE_NOT_ACCEPTED)],
        });
    };
    var _d = useDropzone({
        onDropAccepted: onDropAccepted,
        onDropRejected: onDropRejected,
        accept: accept,
        multiple: multiple,
    }), getRootProps = _d.getRootProps, getInputProps = _d.getInputProps, isDragActive = _d.isDragActive;
    var setCropped = function (_url) {
        setUrl(_url);
        dialogHide(DialogShowId.CUSTOM);
    };
    var removeFile = function (e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        onChange(null);
        setUrl('');
        onRemove === null || onRemove === void 0 ? void 0 : onRemove();
    };
    var resizeImage = function (_url) {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            keepMountOnExit: true,
            children: React.createElement(Cropper, { aspectRatio: 2.6, src: _url, action: setCropped, options: {} }),
        });
    };
    var file = value;
    return (React.createElement("div", { className: styles('UploadZone', className, noMargin && 'no-margin', error && showError && 'error', profile && 'profile', logo && 'logo', roundedPreview && 'rounded', isDragActive && 'document-hover') }, !noPreview && url ? (img ? (React.createElement("div", { className: styles('preview-wrapper', logo && 'logo') },
        React.createElement("div", { className: styles('preview') },
            React.createElement("img", { src: url, alt: "" }),
            React.createElement("div", { className: styles('delete', 'absolute-fill'), onClick: removeFile },
                React.createElement("div", { className: styles('button') },
                    React.createElement(Icon, { name: IconName.TRASH_SIMPLE, color: IconColor.WHITE, size: "16px" })))))) : (React.createElement("div", { className: styles('preview-wrapper') },
        React.createElement("div", { className: styles('preview') },
            React.createElement(Document, { file: url },
                React.createElement(Page, { width: 660, pageNumber: 1 })),
            React.createElement("div", { className: styles('delete'), onClick: removeFile },
                React.createElement("div", { className: styles('button') },
                    React.createElement(Icon, { name: IconName.TRASH_SIMPLE, color: IconColor.WHITE, size: "16px" }))))))) : (React.createElement(React.Fragment, null,
        React.createElement("div", __assign({}, getRootProps({
            className: styles('Dropzone', error && showError && 'error', small && 'small', profile && 'drop-profile'),
        })),
            React.createElement("input", __assign({}, getInputProps({ className: styles('dropdown-input') }))),
            profile ? (React.createElement("img", { src: cameraIcon, alt: "icon", className: styles('camera') })) : (React.createElement("div", { className: styles('content') },
                React.createElement(Icon, { name: icon, className: styles('icon'), size: "34px" }),
                file && noPreview ? (React.createElement("div", { className: styles('info', 'with-preview') },
                    !multiple && React.createElement("span", null, file.name),
                    multiple && (React.createElement("span", null,
                        t(i18nKeys.FILE_NUMBER),
                        " ",
                        file.length)),
                    React.createElement("div", { onClick: removeFile, className: styles('button-delete') },
                        React.createElement(Icon, { name: IconName.SIMPLE_REMOVE })),
                    action && (React.createElement(Button, { className: styles('import-button'), label: t(i18nKeys.NEXT), onClick: action })))) : (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: styles('info', small && 'small') }, info || t(i18nKeys.FORM.CHOOSE_FILE)),
                    subinfo && (React.createElement("div", { className: styles('subinfo', small && 'small') }, subinfo)),
                    error && showError && (React.createElement("div", { className: styles('error-message') }, t(validationMessage[error.type] ||
                        error.message ||
                        error)))))))),
        file && (progress === null || progress === void 0 ? void 0 : progress.active) && (React.createElement("div", { className: styles('progress-wrapper') },
            React.createElement("div", { className: styles('progress-content', 'value'), style: { width: "".concat(progress.value, "%") } },
                React.createElement(Percentage, { value: progress.value })))),
        file && (progress === null || progress === void 0 ? void 0 : progress.errors) && (React.createElement("div", { className: styles('progress-errors') },
            React.createElement("div", { className: styles('title-error') }, t(i18nKeys.ERROR.IMPORT_ERROR)),
            progress.errors.map(function (item, index) { return (React.createElement("div", { key: index, className: styles('error') },
                React.createElement(HTML, { html: item }))); })))))));
};
