import { __assign, __read, __spreadArray } from "tslib";
import React, { useEffect, useState } from 'react';
import { useUploadDebtorCSV, useUploadInvoiceCSV } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import papaparse from 'papaparse';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import { Button, ButtonColor, Checkbox, CustomSelect } from 'shared/io';
import { dialogHide, DialogShowId } from 'store/view/view.actions';
import styleIdentifiers from './ImportCsvForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ImportCsvForm(_a) {
    var file = _a.file, update = _a.update, debtor = _a.debtor;
    var t = useTranslation().t;
    var history = useHistory();
    var constants = useSelector(function (state) { return state.app.constants; });
    var company = useSelector(function (state) { return state.account.company.data; });
    var customVariables = useSelector(function (state) { var _a; return (_a = state.account.company.data) === null || _a === void 0 ? void 0 : _a.custom_variables_attributes; }) ||
        [];
    var _b = __read(useState({
        index: -1,
        data: [],
        headerIndex: 0,
        nonEmptyColumn: [],
        importError: 0,
        importedResource: 0,
    }), 2), currentFile = _b[0], setCurrentFile = _b[1];
    var _c = __read(useState(0), 2), pageNumber = _c[0], setPageNumber = _c[1];
    var _d = __read(useState(false), 2), isUploading = _d[0], setIsUploading = _d[1];
    var _e = __read(useState(false), 2), shouldForceBalanceUpdate = _e[0], setShouldForceBalanceUpdate = _e[1];
    var form = useForm({
        mode: 'onBlur',
    });
    var uploadDebtorCSV = useUploadDebtorCSV().mutate;
    var uploadInvoiceCSV = useUploadInvoiceCSV().mutate;
    useEffect(function () {
        var reader = new FileReader();
        reader.onload = function (event) {
            var data;
            try {
                data = decodeURIComponent(escape(event.target.result));
            }
            catch (e) {
                data = event.target.result;
            }
            var csvData = papaparse.parse(data, {
                skipEmptyLines: 'greedy',
                error: function () { },
            });
            csvData.data.forEach(function (row) { });
            setData(__assign(__assign({ index: 0 }, csvData), { nonEmptyColumn: searchNonEmptyColumn(csvData.data, 0), headerIndex: 0, importedResource: -1, importError: -1 }));
            var attributes = attributesAvailable.slice();
            csvData.data[0].forEach(function (headerText, index) {
                var attrIndex = attributes.findIndex(function (attribute) { return attribute.description === headerText; });
                if (attrIndex !== -1) {
                    onChooseAttr(attributes[attrIndex], index);
                    attributes.splice(attrIndex, 1);
                }
            });
        };
        reader.readAsText(file, 'ISO-8859-1');
    }, []);
    var requiredFields = update
        ? []
        : (debtor ? constants.debtor_import_columns : constants.invoice_import_columns).required_fields;
    var flatRequiredField = requiredFields.flat();
    var getValues = form.getValues, setValue = form.setValue;
    var setColKeys = function (newColKeys) {
        newColKeys.forEach(function (value, i) {
            setValue("colkeys[".concat(i, "]"), value);
        });
    };
    var filteredCustomVariables = customVariables
        .filter(function (customVar) { return customVar.model_type === (debtor ? 'debtor' : 'invoice'); })
        .map(function (customVar) { return ({
        description: customVar.name,
        value: customVar.column_name,
    }); });
    var fieldsUnsorted = (debtor ? constants.debtor_import_columns.fields : constants.invoice_import_columns.fields).concat(filteredCustomVariables || []);
    var sortFieldList = function (arr) {
        return arr.sort(function (a, b) {
            var desc1 = a.description.toLowerCase();
            var desc2 = b.description.toLowerCase();
            if (a.required !== b.required) {
                return a.required ? -1 : 1;
            }
            return desc1 > desc2 ? 1 : desc2 > desc1 ? -1 : 0;
        });
    };
    var _f = __read(useState(sortFieldList(fieldsUnsorted.map(function (item) { return (__assign(__assign({}, item), { required: flatRequiredField.indexOf(item.value) !== -1 })); }))), 2), attributesAvailable = _f[0], setAttributesAvailable = _f[1];
    var searchNonEmptyColumn = function (arr, headerIndex) {
        var nonEmptyColumn = [];
        for (var column = 0; column < arr[0].length; column++) {
            for (var row = 0; row < arr.length; row++) {
                if (row !== headerIndex && arr[row][column]) {
                    nonEmptyColumn.push(column);
                    break;
                }
            }
        }
        return nonEmptyColumn;
    };
    var setData = function (_a) {
        var data = _a.data, headerIndex = _a.headerIndex, nonEmptyColumn = _a.nonEmptyColumn, importedResource = _a.importedResource, importError = _a.importError, error = _a.error;
        setCurrentFile({
            index: currentFile.index,
            data: data || currentFile.data,
            headerIndex: headerIndex === 0 ? 0 : headerIndex || currentFile.headerIndex || 0,
            nonEmptyColumn: nonEmptyColumn || currentFile.nonEmptyColumn,
            importedResource: importedResource || currentFile.importedResource,
            importError: importError || currentFile.importError,
            error: error,
        });
    };
    var removeRow = function (indexRowToDelete) {
        var data = currentFile.data;
        data.splice(indexRowToDelete, 1);
        setData({
            data: data,
            headerIndex: indexRowToDelete <= currentFile.headerIndex
                ? currentFile.headerIndex - 1
                : currentFile.headerIndex,
            nonEmptyColumn: searchNonEmptyColumn(data, 0),
        });
    };
    var pageNumberTable = function (arr) {
        var beginIndex = pageNumber * 10;
        return arr.slice(beginIndex, beginIndex +
            (currentFile.headerIndex >= beginIndex && currentFile.headerIndex < beginIndex + 10
                ? 11
                : 10));
    };
    var colKeys = getValues().colkeys || [];
    var onChooseAttr = function (value, col) {
        var newColKeys = __spreadArray([], __read(colKeys), false);
        newColKeys[col] = value;
        var newAttributesAvailable = attributesAvailable;
        // Si l'ancien attribut était requis, vérifier qu'il y a au moins un autre requis
        if (colKeys[col] && flatRequiredField.indexOf(colKeys[col].value) !== -1) {
            var requiredGroup = requiredFields.find(function (group) { return group.indexOf(colKeys[col].value) !== -1; });
            if (!requiredGroup.some(function (field) {
                return colKeys.some(function (colKey) { return colKey && colKeys[col].value !== colKey.value && colKey.value === field; });
            })) {
                requiredGroup.forEach(function (attr) {
                    var att = newAttributesAvailable.find(function (attribute) { return attribute.value === attr; });
                    if (att)
                        att.required = true;
                });
            }
        }
        // si l'élément est dans un groupe de requis alors enlever ceux qui ne sont plus requis
        if (value === null || value === void 0 ? void 0 : value.required) {
            var requiredGroup = requiredFields.find(function (group) { return group.indexOf(value.value) !== -1; });
            requiredGroup.forEach(function (field) {
                var fieldIndex = newAttributesAvailable.findIndex(function (attr) { return attr.value === field; });
                if (fieldIndex != -1)
                    newAttributesAvailable[fieldIndex].required = false;
            });
        }
        setAttributesAvailable(newAttributesAvailable.sort(function (a, b) { return (a.required ? 1 : -1); }));
        setColKeys(newColKeys);
        return true;
    };
    var dropDownItem = function (item) {
        var isDisabled = colKeys.find(function (value) { return value === item.value; }) && 'disabled';
        return (item && (React.createElement("div", { onClick: function (e) {
                isDisabled && e.stopPropagation();
            }, className: styles('dropdown-item', isDisabled, item.value.required && 'required') }, item.text)));
    };
    var canValidate = function () {
        return !attributesAvailable.some(function (attr) {
            return attr.required ? !colKeys.find(function (col) { return col && col.value === attr.value; }) : false;
        });
    };
    var goToValidatePage = function (modelType) {
        dialogHide(DialogShowId.CUSTOM);
        history.push("/".concat(modelType, "/to-confirm"));
    };
    var parsedData = currentFile.data
        .filter(function (row, rowIndex) { return rowIndex !== currentFile.headerIndex; })
        .map(function (row) {
        var value = {};
        colKeys.forEach(function (colKey, colIndex) {
            if (colKey) {
                value[colKey.value] = row[colIndex];
            }
        });
        return value;
    });
    var getHasBankingIntegration = function () {
        if (company.codabox_integration.active)
            return true;
        if (company.ponto_integration.active)
            return true;
        if (company.digiteal_integration.status === 'activated')
            return true;
        return false;
    };
    var sendData = function () {
        if (canValidate()) {
            setIsUploading(true);
            if (debtor) {
                var debtors = parsedData;
                uploadDebtorCSV({ data: { debtors: debtors } }, {
                    onError: function () {
                        setIsUploading(false);
                    },
                    onSuccess: function () {
                        goToValidatePage('client');
                    },
                });
            }
            else {
                var invoices = parsedData;
                uploadInvoiceCSV({ data: { invoices: invoices, force_balance_update: shouldForceBalanceUpdate } }, {
                    onError: function () {
                        setIsUploading(false);
                    },
                    onSuccess: function () {
                        goToValidatePage('invoices');
                    },
                });
            }
        }
        else {
            setData({
                error: t(i18nKeys.INVOICING.IMPORT.SELECT_ALL_REQUIRED_FIELDS),
            });
        }
    };
    var isRemainingBalancePresent = colKeys.some(function (colKey) { return (colKey === null || colKey === void 0 ? void 0 : colKey.value) === 'remaining_balance'; });
    var hasBankingIntegration = getHasBankingIntegration();
    var showForceBalanceUpdateCheckbox = isRemainingBalancePresent && hasBankingIntegration;
    return (React.createElement("div", { className: styles('import-csv-form') },
        React.createElement("div", { className: styles('head') },
            React.createElement("div", null,
                React.createElement(HTML, { html: t(i18nKeys.INVOICING.IMPORT[update && company.package.can_use_custom_variables ? 'EDIT_SUMMARY' : 'SUMMARY']) })),
            React.createElement("div", null,
                currentFile.importedResource > 0 && (React.createElement(Button, { noMargin: true, label: t(i18nKeys.INVOICING.IMPORT.GO_TO_VALIDATION_PAGE), onClick: function () { return goToValidatePage(debtor ? 'client' : 'invoices'); } })),
                React.createElement("div", { style: {
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: '15px',
                    } },
                    React.createElement(Button, { noMargin: true, color: ButtonColor.MAIN, disabledClick: true, disabled: !canValidate(), label: t(i18nKeys.SUBMIT), onClick: sendData, isLoading: isUploading }),
                    showForceBalanceUpdateCheckbox && (React.createElement(Checkbox, { noMargin: true, checked: shouldForceBalanceUpdate, label: t(i18nKeys.FORM.INVOICING.FORCE_BALANCE_UPDATE), onChange: function (val) { return setShouldForceBalanceUpdate(val); } }))))),
        React.createElement("div", { className: styles('error-line') },
            " ",
            currentFile.error),
        React.createElement("div", { className: styles('content') },
            React.createElement(FormProvider, __assign({}, form), currentFile.data.length > 0 && (React.createElement("table", { className: styles('csv-list') },
                React.createElement("thead", null,
                    React.createElement("tr", { className: styles('no-border') },
                        React.createElement("th", null),
                        currentFile.data[currentFile.headerIndex].map(function (cell, i) { return (React.createElement("th", { key: i },
                            React.createElement("div", null, cell))); })),
                    React.createElement("tr", { className: styles('no-border') },
                        React.createElement("th", null),
                        currentFile.data[currentFile.headerIndex].map(function (cell, i) { return (React.createElement("th", { key: i },
                            React.createElement("div", null,
                                React.createElement(Controller, { defaultValue: "", name: "colkeys[".concat(i, "]"), render: function () { return (React.createElement(CustomSelect, { name: "colkeys[".concat(i, "]"), onValueChanged: function (value) {
                                            value && onChooseAttr(value, i);
                                        }, noMargin: true, className: styles('select-key'), valueClassName: styles('select-value'), selectClassName: styles('dropdown'), errorClassName: styles('invoice-select-error'), items: attributesAvailable, noBorder: true, filter: true, itemRendering: dropDownItem, size: "small", keyText: "description", placeholder: t('INVOICING.IMPORT.MATCH_WITH'), itemClassName: styles('select-item') })); } })))); })),
                    React.createElement("tr", null,
                        React.createElement("th", null),
                        currentFile.data[currentFile.headerIndex].map(function (cell, i) { return (React.createElement("th", { key: i },
                            React.createElement("div", { className: styles('empty-col') }, currentFile.nonEmptyColumn &&
                                currentFile.nonEmptyColumn.indexOf(i) === -1 &&
                                '(colonne vide)'))); }))),
                React.createElement("tbody", null, pageNumberTable(currentFile.data).map(function (line, i) {
                    return i !== currentFile.headerIndex && (React.createElement(React.Fragment, { key: i },
                        React.createElement("tr", null,
                            React.createElement("td", null,
                                React.createElement("div", null,
                                    React.createElement(Icon, { name: IconName.TRASH_SIMPLE, color: IconColor.GREY, onClick: function () { return removeRow(pageNumber * 10 + i); }, boldHover: true }))),
                            line.map(function (cell, cellIndex) { return (React.createElement("td", { key: cellIndex },
                                React.createElement("div", { className: styles('value-cell') }, cell))); }))));
                })))))),
        React.createElement("div", { className: styles('footer') },
            React.createElement("div", null,
                currentFile.data.length > 0 && (React.createElement("div", null,
                    currentFile.data.length - 1,
                    ' ',
                    currentFile.data.length < 2
                        ? t(i18nKeys.INVOICING.IMPORT.LINE)
                        : t(i18nKeys.INVOICING.IMPORT.LINES))),
                currentFile.importedResource !== -1 && (React.createElement("div", null,
                    currentFile.importedResource,
                    ' ',
                    debtor
                        ? t(i18nKeys.CLIENT.SUCCESSFUL_IMPORT)
                        : t(i18nKeys.INVOICE.SUCCESSFUL_IMPORT))),
                currentFile.importError !== -1 && (React.createElement("div", null,
                    currentFile.importError,
                    ' ',
                    debtor ? t(i18nKeys.CLIENT.NOT_IMPORTED) : t(i18nKeys.INVOICE.NOT_IMPORTED)))),
            React.createElement(ReactPaginate, { pageCount: Math.ceil((currentFile.data.length - 1) / 10), pageRangeDisplayed: 3, marginPagesDisplayed: 1, forcePage: +pageNumber, disableInitialCallback: true, breakLabel: "...", onPageChange: function (_a) {
                    var selected = _a.selected;
                    return setPageNumber(selected);
                }, previousLabel: React.createElement(Icon, { name: IconName.CIRCLE_LEFT, size: "18px" }), nextLabel: React.createElement(Icon, { name: IconName.CIRCLE_RIGHT, size: "18px" }), containerClassName: styles('pagination'), pageClassName: styles('page'), activeClassName: styles('active'), disabledClassName: styles('disabled') }))));
}
