import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, axiosInstance } from './utils';
var DisputedInvoicesSchema = z
    .object({
    totalInvoicesDisputed: z.preprocess(function (val) { return Number(val); }, z.number()),
})
    .transform(function (_a) {
    var totalInvoicesDisputed = _a.totalInvoicesDisputed;
    return totalInvoicesDisputed;
});
export function useLoadDisputedInvoicesFn() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance.get('analytics/disputed_invoices')];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, DisputedInvoicesSchema.parse(data)];
            }
        });
    });
}
export var useLoadDisputedInvoices = function () {
    var queryResult = useQuery({
        queryKey: ['disputed-invoices'],
        queryFn: useLoadDisputedInvoicesFn,
    });
    return addResourceNameToQueryResult('disputedInvoices', queryResult);
};
