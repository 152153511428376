import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import { useLoadCompany } from 'shared/hooks';
import { Alert, Button, Flex } from '@mantine/core';
import { styled } from '@stitches/react';
import { IconAlertTriangle, IconExternalLink } from '@tabler/icons-react';
// Aless doesn't like how the button makes the alert "too tall"
var BannerHeightFix = styled('div', {
    '.mantine-Alert-title': { marginBottom: '0px' },
});
export var WarningBanners = function () {
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var companyV2 = useLoadCompany().company;
    return (React.createElement(React.Fragment, null,
        (companyV2 === null || companyV2 === void 0 ? void 0 : companyV2.payment.isLate) && (companyV2 === null || companyV2 === void 0 ? void 0 : companyV2.payment.clientPortalLink) && (React.createElement(BannerHeightFix, null,
            React.createElement(Alert, { m: "md", variant: "filled", color: "red", title: t(i18nKeys.PAYMENT_BANNER.TITLE), icon: React.createElement(IconAlertTriangle, null) },
                React.createElement(Flex, { justify: "space-between", align: "center" },
                    t(i18nKeys.PAYMENT_BANNER.LATE_PAYMENT_BANNER_TEXT),
                    React.createElement(Button, { color: "white", variant: "outline", rightSection: React.createElement(IconExternalLink, { size: "22px" }), component: "a", target: "_blank", href: companyV2.payment.clientPortalLink }, t(i18nKeys.PAYMENT_BANNER.GO_TO_PAYMENT_PORTAL)))))),
        company.vat_number === null && company.onboarding_done && (React.createElement(Alert, { color: "red", title: "Late payment", m: "md", icon: React.createElement(IconAlertTriangle, null) },
            t(i18nKeys.VAT_NUMBER_MISSING),
            ' ',
            React.createElement("a", { href: "/settings/company" }, t(i18nKeys.NAV.SETTINGS).toLowerCase()))),
        company.ponto_integration.active && !company.ponto_integration.synced && (React.createElement(Alert, { title: "Ponto integration", color: "red", m: "md", icon: React.createElement(IconAlertTriangle, null) },
            React.createElement("span", null,
                t(i18nKeys.PONTO_NOT_SYNCED),
                React.createElement("a", { href: "https://myponto.com/", target: "_blank", rel: "noreferrer" }, "My Ponto"))))));
};
