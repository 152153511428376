import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import Card from 'shared/components/Card';
import { accountActions } from 'store/account/account.actions';
import { onBoardingActions } from 'store/onBoarding/onBoarding.actions';
import styleIdentifiers from './ColorLogo.scss';
import ColorLogoForm from './ColorLogoForm';
var styles = classNames.bind(styleIdentifiers);
export default function ColorLogo(_a) {
    var onValidStep = _a.onValidStep;
    var t = useTranslation().t;
    var onSubmit = function (data) {
        var _a;
        data.color = ((_a = data.color) === null || _a === void 0 ? void 0 : _a.hex) ? data.color.hex.replace('#', '') : data.color;
        onBoardingActions.companyIdentity({
            data: data,
            callback: function () {
                accountActions.getCompany({
                    callback: function () {
                        onValidStep();
                    },
                    actionFailure: function (response) {
                        onValidStep(response.data.next_step);
                    },
                });
            },
        });
    };
    return (React.createElement("div", { className: styles('color-logo') },
        React.createElement("div", { className: styles('steps') },
            React.createElement(Card, { title: t(i18nKeys.ONBOARDING.STEP4.TITLE) },
                React.createElement(ColorLogoForm, { onSubmit: onSubmit })))));
}
