import { __read } from "tslib";
import React from 'react';
import { useProfile } from 'app/App';
import { upperFirst } from 'lodash-es';
import { BarChartTooltip } from 'shared/charts';
import { Text, useMantineTheme } from '@mantine/core';
import { ResponsiveLine } from '@nivo/line';
var Y_STEPS = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
export var CeiChart = function (_a) {
    var cei = _a.cei;
    var profile = useProfile();
    var theme = useMantineTheme();
    return (React.createElement(ResponsiveLine, { isInteractive: true, enableSlices: "x", enableArea: true, defs: [
            {
                colors: [
                    {
                        color: 'inherit',
                        offset: 0,
                    },
                    {
                        color: 'inherit',
                        offset: 100,
                        opacity: 0,
                    },
                ],
                id: 'gradientA',
                type: 'linearGradient',
            },
        ], fill: [
            {
                id: 'gradientA',
                match: '*',
            },
        ], useMesh: true, curve: "monotoneX", enableCrosshair: true, margin: { top: 10, right: 15, bottom: 35, left: 55 }, axisBottom: {
            tickPadding: 15,
            tickSize: 0,
            format: function (date) { return date.toLocaleDateString(profile.locale, { month: 'short' }); },
        }, axisLeft: {
            tickPadding: 15,
            tickValues: Y_STEPS,
            tickSize: 0,
            format: function (value) { return "".concat(value, " %"); },
        }, sliceTooltip: function (d) {
            setTimeout(function () {
                var tooltip = document.getElementById('tooltip-fix');
                if (tooltip)
                    tooltip.style.opacity = '1';
            }, 50);
            return (React.createElement(BarChartTooltip, { style: { opacity: 0, transition: 'all 0.1s ease-in-out' }, id: "tooltip-fix" },
                React.createElement(Text, { fw: 700, mr: 7 }, "".concat(upperFirst(d.slice.points[0].data.x.toLocaleDateString(profile.locale, {
                    month: 'long',
                })), ":")),
                React.createElement(Text, null, "".concat(d.slice.points[0].data.y, " %"))));
        }, yScale: { type: 'linear', min: 0, max: 100 }, gridYValues: Y_STEPS, enableGridX: false, pointSize: 8, pointBorderWidth: 2, pointBorderColor: { from: 'serieColor' }, colors: [theme.colors.blue[7]], pointColor: "white", theme: {
            grid: {
                line: {
                    stroke: theme.colors.gray[3],
                    strokeDasharray: 7,
                    strokeDashoffset: 15,
                },
            },
            labels: {
                text: {
                    fontSize: 14,
                    fontFamily: 'Work Sans',
                    fill: theme.colors.gray[6],
                },
            },
            text: {
                fontSize: 12,
                fontFamily: 'Work Sans',
                fill: theme.colors.gray[6],
            },
        }, data: [
            {
                id: 'cei',
                color: theme.colors.cyan[4],
                data: Object.entries(cei).map(function (_a) {
                    var _b = __read(_a, 2), key = _b[0], value = _b[1];
                    return ({ x: new Date(key), y: value });
                }),
            },
        ] }));
};
