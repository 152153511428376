import { __assign, __read } from "tslib";
import React, { useEffect, useState } from 'react';
import { TasksList } from 'app/Logged/Tasks/TasksList/TasksList';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ActivityDetail from 'shared/components/ActivityDetail';
import Card from 'shared/components/Card';
import Tabs from 'shared/components/Tabs';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { TabInfo } from './TabInfo/TabInfo';
import { ThirdPartyCaseTable } from './ThirdpartyCaseTable/ThirdpartyCaseTable';
import { ClientDetailTabsBalance } from './ClientDetailTabsBalance';
import { ClientDetailTabsTimeline } from './ClientDetailTabsTimeline';
import styleIdentifiers from './ClientDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export var ClientDetailTabs = function (props) {
    var t = useTranslation().t;
    var history = useHistory();
    var company = useSelector(function (state) { return state.account.company.data; });
    var _a = __read(useState(0), 2), tabIndex = _a[0], setTabIndex = _a[1];
    useEffect(function () {
        if (tabItems.some(function (e) { return e.path === location.pathname; })) {
            setTabIndex(tabItems.map(function (e) { return e.path; }).indexOf(location.pathname));
        }
    }, []);
    var can_use_tasks = company.package.can_use_tasks;
    var debtor = props.debtor;
    var id = debtor.id, _b = debtor.attributes, _c = _b.tp_cases_count, tp_cases_count = _c === void 0 ? 0 : _c, alert_activities = _b.alert_activities;
    var detailActivity = function (item) {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            children: React.createElement(ActivityDetail, { item: item }),
        });
    };
    var tabItems = [
        {
            title: React.createElement("h3", null, t(i18nKeys.CLIENT.GENERAL_INFO)),
            path: "/clients/".concat(id),
            children: React.createElement(TabInfo, __assign({}, props)),
        },
        {
            title: React.createElement("h3", null, t(i18nKeys.DASHBOARD.BALANCE_DETAIL)),
            path: "/clients/".concat(id, "/balance"),
            children: React.createElement(ClientDetailTabsBalance, __assign({}, props)),
        },
        {
            title: React.createElement("h3", null, t(i18nKeys.INVOICE.ACTIVITIES)),
            path: "/clients/".concat(id, "/timeline"),
            children: React.createElement(ClientDetailTabsTimeline, __assign({}, props)),
        },
    ];
    if (can_use_tasks) {
        tabItems.push({
            title: React.createElement("h3", null, t(i18nKeys.NAV.TASKS)),
            path: "/clients/".concat(id, "/tasks"),
            children: (React.createElement("div", null,
                React.createElement(TasksList, __assign({}, props)))),
        });
    }
    if (tp_cases_count > 0) {
        tabItems.push({
            title: (React.createElement("div", { className: styles('tab-header-item') },
                React.createElement("h3", null, t(i18nKeys.THIRD_PARTY_CASES.CURRENT_CASES)))),
            path: "/clients/".concat(id, "/cases"),
            children: React.createElement(ThirdPartyCaseTable, { debtorId: id }),
        });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Tabs, { className: styles('workflows__tabs'), tabIndex: tabIndex, items: tabItems.map(function (e) { return (React.createElement(React.Fragment, null, e.title)); }), noBody: true, onChange: function (e) {
                setTabIndex(e);
                history.push(tabItems[e].path);
            } }),
        tabItems.map(function (_a, i) {
            var title = _a.title, children = _a.children, path = _a.path;
            return tabIndex === i && (React.createElement(Card, { key: "tab-".concat(title), noRadiusTop: true, noPadding: path.includes('/cases'), noHead: true, noBorder: true }, children));
        })));
};
