import { __read } from "tslib";
import React, { useEffect } from 'react';
import { useProfile } from 'app/App';
import Activities from 'app/Logged/Activities';
import CreditNotesListing from 'app/Logged/CreditNotes/CreditNoteListing';
import Dashboard from 'app/Logged/Dashboard';
import InvoicesDetail from 'app/Logged/Invoices/Detail';
import ImminentReminders from 'app/Logged/Invoices/ImminentReminders';
import InvoicesListing from 'app/Logged/Invoices/Listing';
import InvoicesToHandle from 'app/Logged/Invoices/ToHandle';
import ToHandleDetail from 'app/Logged/Invoices/ToHandleDetail';
import MyFollowup from 'app/Logged/MyFollowup';
import ProductsListing from 'app/Logged/Products/Listing';
import SettingsChunk from 'app/Logged/Settings';
import ThirdpartyCasesListing from 'app/Logged/ThirdpartyCases/ThirdpartyCaseListing';
import TransactionHistory from 'app/Logged/Transactions/History';
import Reconciliation from 'app/Logged/Transactions/Reconciliation';
import classNames from 'classnames';
import { i18nKeys, useTranslation } from 'locales/';
import { upperFirst } from 'lodash-es';
import { Reports } from 'modules/Reports';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import { Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import NavigationPrompt from 'shared/components/BlockNavigation';
import Header from 'shared/components/Header';
import Loader from 'shared/components/Loader';
import Loading from 'shared/components/Loading';
import Nav from 'shared/components/Nav';
import { useIsConnectorActive, useLoadCompany } from 'shared/hooks';
import apiService from 'shared/service/api.service';
import { Environment, getEnv, isOneOfEnvs } from 'shared/utils/environment';
import { success } from 'store/view/view.actions';
import { AppShell, Box, Burger } from '@mantine/core';
import { useDisclosure, useHotkeys, useMediaQuery } from '@mantine/hooks';
import { Analytics } from '../../modules/Analytics';
import { DuplicatesClientDetail } from './Clients/Duplicates/DuplicatesClientDetail';
import { DuplicatesClients } from './Clients/Duplicates/DuplicatesClients';
import ManageCreditNote from './CreditNotes/Manage/Manage';
import ImportedInvoiceDetail from './Invoices/ImportedInvoiceDetail';
import ImportedInvoices from './Invoices/ImportedInvoices';
import InvoiceListToConfirm from './Invoices/InvoiceListToConfirm';
import ManageInvoice from './Invoices/Manage/Manage';
import { ClientDetail, ClientsImportedDetail, ClientsList, ClientsToConfirmList } from './Clients';
import { ExternalMailDetail, ExternalMailList } from './ExternalMailbox';
import ImportPDFInvoice from './ImportPDFInvoice';
import { OnboardingWrapper } from './OnboardingWrapper';
import { PaymentPlanDetail, PaymentPlanEditForm, PaymentPlanForm, PaymentPlansList, } from './PaymentPlans';
import { CustomTasksList, TaskDetail } from './Tasks';
import { WarningBanners } from './WarningBanners';
// TODO: Only contains background loader styles, move it to a separate component
import styleIdentifiers from './logged.scss';
var styles = classNames.bind(styleIdentifiers);
var RedirectInvoiceActivity = function () {
    var _a = useRouteMatch(), _b = _a.params, id = _b.id, activityId = _b.activityId, path = _a.path;
    return (React.createElement(Redirect, { to: "/".concat(path.includes('invoices') ? 'invoices' : 'credit-notes', "/").concat(id, "?activityId=").concat(activityId) }));
};
export default function Logged() {
    var t = useTranslation().t;
    var location = useLocation();
    var company = useSelector(function (state) { return state.account.company.data; });
    //! The Id contained in the company object above is wrong, and actually a leak of the CompanyConfiguration model's Id.
    //! Use this one instead:
    var companyV2 = useLoadCompany().company;
    var backgroundLoader = useSelector(function (state) { return state.view.backgroundLoader; });
    var isConnectorActive = useIsConnectorActive();
    var history = useHistory();
    var _a = __read(useDisclosure(), 2), isMobileOpened = _a[0], _b = _a[1], toggleMobile = _b.toggle, closeMobile = _b.close;
    var _c = __read(useDisclosure(true), 2), isDesktopOpened = _c[0], toggleDesktop = _c[1].toggle;
    var isMobile = useMediaQuery('(max-width: 48em)');
    useHotkeys([
        ['mod+B', toggleDesktop],
        ['mod+H', function () { return history.push('/dashboard'); }],
    ]);
    var profile = useProfile();
    useEffect(function () {
        if (companyV2 != null &&
            profile != null &&
            window.Intercom != null &&
            isOneOfEnvs(Environment.PRODUCTION)) {
            window.Intercom('boot', {
                api_base: 'https://api-iam.intercom.io',
                app_id: __INTERCOM_APP_ID__,
                name: profile.name.full,
                company: {
                    id: companyV2 === null || companyV2 === void 0 ? void 0 : companyV2.id,
                    name: "".concat(companyV2.name).concat(companyV2.name.toLowerCase() === apiService.tenant ? '' : "(".concat(apiService.tenant, ")")),
                },
                user_id: profile.id,
                email: profile.email,
                environment: getEnv(),
                user_hash: profile.intercomHash,
            });
        }
    }, [profile, companyV2]);
    return (React.createElement(AppShell, { padding: "xl", header: { height: 60 }, navbar: {
            width: 258,
            breakpoint: 'sm',
            collapsed: { mobile: !isMobileOpened, desktop: !isDesktopOpened },
        }, styles: {
            header: { zIndex: 10 },
            navbar: { zIndex: 10 },
            main: { zIndex: 0 },
        } },
        React.createElement(AppShell.Header, null,
            React.createElement(Header, { burger: isMobile ? (React.createElement(Box, { c: "white", pl: isMobile ? 'lg' : '20px' },
                    React.createElement(Burger, { color: "white", opened: isMobileOpened, onClick: toggleMobile, size: "sm" }))) : null })),
        company.onboarding_done && (React.createElement(AppShell.Navbar, null,
            React.createElement(Nav, { onClickItem: isMobile ? closeMobile : function () { } }))),
        React.createElement(AppShell.Main, { style: { overflowY: 'auto', height: 'calc(100vh - 60px)' } },
            React.createElement(WarningBanners, null),
            company.onboarding_done ? (React.createElement(React.Fragment, null,
                backgroundLoader.active && (React.createElement("div", { className: styles('bg-loader', 'card') },
                    React.createElement(NavigationPrompt, { canChangePage: true, when: true, onSaveAndQuit: function () { } }),
                    React.createElement("div", { className: styles('bg-loader-title') }, backgroundLoader.title),
                    React.createElement("div", { className: styles('bg-loader-body') },
                        React.createElement("span", null, backgroundLoader.description),
                        React.createElement("div", null,
                            React.createElement(Loading, { active: true, size: "small" }))))),
                React.createElement(Switch, null,
                    React.createElement(Route, { path: "/dashboard", component: Dashboard }),
                    React.createElement(Route, { path: "/clients/:id", component: ClientDetail }),
                    React.createElement(Route, { path: "/clients", component: ClientsList }),
                    React.createElement(Route, { path: "/client/duplicates", component: DuplicatesClients }),
                    React.createElement(Route, { path: "/client/to-confirm", component: ClientsToConfirmList }),
                    React.createElement(Route, { path: "/client-to-confirm/:id", component: ClientsImportedDetail }),
                    React.createElement(Route, { path: "/client-to-merge/:id", component: DuplicatesClientDetail }),
                    React.createElement(Route, { path: "/follow-up", component: MyFollowup }),
                    React.createElement(Route, { path: "/invoices/actions", component: ImminentReminders }),
                    React.createElement(Route, { path: "/invoices/listing", component: InvoicesListing }),
                    React.createElement(Route, { path: "/invoices/to-handle", component: InvoicesToHandle }),
                    React.createElement(Route, { path: "/invoices/detail/:id", component: InvoicesDetail }),
                    React.createElement(Route, { path: "/invoices/import", component: ImportedInvoices }),
                    React.createElement(Route, { path: "/invoices/to-confirm", component: InvoiceListToConfirm }),
                    React.createElement(Route, { path: "/invoice/to-confirm/:id", component: ImportedInvoiceDetail }),
                    React.createElement(Route, { path: "/invoices/create", component: ManageInvoice }),
                    React.createElement(Route, { path: "/invoices/edit/:id", component: ManageInvoice }),
                    React.createElement(Route, { path: "/invoices/import-pdf", component: ImportPDFInvoice }),
                    React.createElement(Route, { path: "/invoices/:id/:activityId", component: RedirectInvoiceActivity }),
                    React.createElement(Route, { path: "/invoices/:id", component: ToHandleDetail }),
                    React.createElement(Route, { path: "/credit-notes/listing", component: CreditNotesListing }),
                    React.createElement(Route, { path: "/credit-notes/create", component: ManageCreditNote }),
                    React.createElement(Route, { path: "/credit-notes/edit/:id", component: ManageCreditNote }),
                    React.createElement(Route, { path: "/credit-notes/detail/:id", render: function () { return React.createElement(InvoicesDetail, { credit: true }); } }),
                    React.createElement(Route, { path: "/credit-notes/:id/:activityId", component: RedirectInvoiceActivity }),
                    React.createElement(Route, { path: "/credit-notes/:id", render: function () { return React.createElement(ToHandleDetail, { isCreditNote: true }); } }),
                    React.createElement(Route, { path: "/activities", component: Activities }),
                    React.createElement(Route, { path: "/transactions/reconciliation", component: Reconciliation }),
                    React.createElement(Route, { path: "/transactions/history", component: TransactionHistory }),
                    !isConnectorActive ? React.createElement(Route, { path: "/products", component: ProductsListing }) : null,
                    React.createElement(Route, { path: "/thirdparty_cases", component: ThirdpartyCasesListing }),
                    React.createElement(Route, { path: "/payment-plans/listing", component: PaymentPlansList }),
                    React.createElement(Route, { path: "/payment-plans/create", component: PaymentPlanForm }),
                    React.createElement(Route, { path: "/payment-plan/:id/edit", component: PaymentPlanEditForm }),
                    React.createElement(Route, { path: "/payment-plan/:id", component: PaymentPlanDetail }),
                    React.createElement(Route, { path: "/mails/:id", component: ExternalMailDetail }),
                    React.createElement(Route, { path: "/mails", component: ExternalMailList }),
                    React.createElement(Route, { path: "/tasks/:id", component: TaskDetail }),
                    React.createElement(Route, { path: "/tasks", component: CustomTasksList }),
                    React.createElement(Route, { path: "/analytics", component: Analytics }),
                    React.createElement(Route, { path: "/reports", component: Reports }),
                    React.createElement(Route, { path: "/settings", render: function () { return (React.createElement(React.Suspense, { fallback: React.createElement(Loader, null) },
                            React.createElement(SettingsChunk, null))); } }),
                    React.createElement(Route, { path: "/callback", render: function () {
                            var queryParams = qs.parse(location.search);
                            var integration = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
                            if (JSON.parse(queryParams.successfulConnection || false)) {
                                success({
                                    data: t(i18nKeys.FORM.SUCCESSFULLY_CONNEXION, {
                                        text: upperFirst(integration),
                                    }),
                                });
                            }
                            return (React.createElement(Redirect, { to: "/settings/integration/".concat(integration, "?").concat(queryParams.errorMessage ? "errorMessage=".concat(queryParams.errorMessage) : '') }));
                        } }),
                    React.createElement(Redirect, { to: "/dashboard" })))) : (React.createElement(OnboardingWrapper, null)))));
}
