import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { get, useForm } from 'react-hook-form';
import { Button, Checkbox, Input } from 'shared/io';
import { email } from 'shared/utils/validation';
import { config } from 'store/constants';
import styleIdentifiers from './createPasswordForm.scss';
var styles = classNames.bind(styleIdentifiers);
export function CreatePasswordForm(_a) {
    var _b, _c, _d, _e;
    var onSubmit = _a.onSubmit, initialValues = _a.initialValues, withName = _a.withName;
    var _f = useTranslation(), t = _f.t, currentLang = _f.currentLang;
    var _g = useForm({
        shouldUnregister: true,
        defaultValues: initialValues,
    }), register = _g.register, watch = _g.watch, handleSubmit = _g.handleSubmit, errors = _g.formState.errors, getValues = _g.getValues;
    // If we don't register this field which comes from defaultValues, it is erased on subsequent
    // updates of the form.
    register('user.invitation_token');
    return (React.createElement("form", { className: styles('CreatePasswordForm'), onSubmit: handleSubmit(onSubmit) },
        React.createElement(Input, { register: register('user.email', {
                required: true,
                validate: { email: email },
            }), type: "email", label: t(i18nKeys.EMAIL), readOnly: true }),
        withName && (React.createElement(React.Fragment, null,
            React.createElement(Input, { errorMessage: (_b = errors.user) === null || _b === void 0 ? void 0 : _b.first_name, register: register('user.first_name', { required: true }), type: "text", label: t(i18nKeys.FORM.FIRSTNAME) }),
            React.createElement(Input, { errorMessage: (_c = errors.user) === null || _c === void 0 ? void 0 : _c.last_name, register: register('user.last_name', { required: true }), type: "text", label: t(i18nKeys.FORM.LASTNAME) }))),
        React.createElement(Input, { errorMessage: (_d = errors.user) === null || _d === void 0 ? void 0 : _d.password, register: register('user.password', { required: true }), type: "password", label: t(i18nKeys.PASSWORD) }),
        React.createElement(Input, { errorMessage: get(errors, 'user.confirm-password'), register: register('user.confirm-password', {
                required: true,
                validate: {
                    passwordNotMatch: function (value) { return getValues().user.password === value; },
                },
            }), type: "password", label: t(i18nKeys.FORM.SIGNUP.CONFIRM_PASSWORD) }),
        React.createElement(Checkbox, { watch: watch, errorMessage: (_e = errors.user) === null || _e === void 0 ? void 0 : _e.has_accepted_gc, register: register('user.has_accepted_gc', { required: true }) },
            t(i18nKeys.READ_AND_APPROVE),
            ' ',
            React.createElement("a", { href: config.terms[currentLang], target: "_blank", rel: "noopener noreferrer" }, t(i18nKeys.FORM.GENERAL_CONDITIONS_OF_USE))),
        React.createElement("div", { className: styles('buttons') },
            React.createElement(Button, { label: t(i18nKeys.SUBMIT), type: "submit" }))));
}
