import { __assign, __read, __rest } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getQueryVariable } from 'shared/utils/view';
import { accountActions } from 'store/account/account.actions';
import { appActions } from 'store/app/app.actions';
import { translateAction } from 'store/translate/translate.actions';
import styleIdentifiers from './signUp.scss';
import SignupAccount from './SignupAccount';
import SignupCompany from './SignupCompany';
var styles = classNames.bind(styleIdentifiers);
export default function SignUp() {
    var history = useHistory();
    var location = useLocation();
    var _a = __read(useState({
        step: 0,
        lastAdressVatFetched: '',
        signupInfo: {
            user: { email: getQueryVariable(location.search, 'email') },
        },
    }), 2), state = _a[0], setState = _a[1];
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang, availableLang = _b.availableLang;
    useEffect(function () {
        var urlLang = getQueryVariable(location.search, 'locale');
        if (currentLang !== urlLang &&
            availableLang.findIndex(function (lang) { return lang.value === urlLang; }) !== -1) {
            translateAction.changeLang(urlLang);
        }
    }, []);
    var submitAccount = function (_a) {
        var _b = _a.user, currency = _b.currency, vat_number = _b.vat_number, userInfo = __rest(_b, ["currency", "vat_number"]);
        if (state.lastAdressVatFetched !== vat_number) {
            appActions.fetchAddress({
                data: {
                    vat_number: vat_number,
                },
                // noLoading: true,
                callback: function (res) {
                    setState({
                        step: 1,
                        lastAdressVatFetched: vat_number,
                        signupInfo: __assign({ user: userInfo, currency: currency, vat_number: vat_number }, res),
                    });
                },
            });
        }
        else {
            setState({
                step: 1,
                signupInfo: __assign(__assign({}, state.signupInfo), { currency: currency, vat_number: vat_number, user: userInfo }),
            });
        }
    };
    var signup = function (_a) {
        var phoneNumber = _a.phoneNumber, address_attributes = __rest(_a, ["phoneNumber"]);
        accountActions.signupInfo({
            data: {
                user: __assign(__assign({}, state.signupInfo.user), { invitation_token: getQueryVariable(location.search, 'invitation_token') }),
                company_detail: {
                    address_attributes: address_attributes,
                    phones_attributes: {
                        number: phoneNumber,
                    },
                    currency: state.signupInfo.currency,
                    vat_number: state.signupInfo.vat_number.replace(/ /g, ''),
                },
            },
            callback: function () {
                history.push('/login');
            },
        });
    };
    var onPrevious = function (values) {
        setState(__assign(__assign({}, state), { step: 0, signupInfo: __assign(__assign({}, state.signupInfo), values) }));
    };
    return (React.createElement("div", { className: styles('SignUp') },
        React.createElement("div", { className: styles('title') }, t(i18nKeys.WELCOME)),
        React.createElement("div", { className: styles('container-form') },
            React.createElement(SignupAccount, { className: styles('account', { active: state.step === 0 }), active: state.step === 0, initialValues: state.signupInfo, onSubmit: submitAccount }),
            React.createElement(SignupCompany, { className: styles('company', { active: state.step === 1 }), back: onPrevious, active: state.step === 1, onSubmit: signup, initialValues: state.signupInfo }))));
}
