export var OnBoardingSteps;
(function (OnBoardingSteps) {
    OnBoardingSteps["STEP_1"] = "step_1";
    OnBoardingSteps["STEP_2"] = "step_2";
    OnBoardingSteps["STEP_3"] = "step_3";
    OnBoardingSteps["STEP_4"] = "step_4";
    OnBoardingSteps["STEP_5"] = "step_5";
    OnBoardingSteps["DONE"] = "done";
})(OnBoardingSteps || (OnBoardingSteps = {}));
export var AVAILABLE_STEPS = [
    OnBoardingSteps.STEP_1,
    OnBoardingSteps.STEP_2,
    OnBoardingSteps.STEP_3,
    OnBoardingSteps.STEP_4,
    OnBoardingSteps.STEP_5,
];
export var INTEGRATOR_API_STEPS = [
    OnBoardingSteps.STEP_1,
    OnBoardingSteps.STEP_2,
    OnBoardingSteps.STEP_3,
];
