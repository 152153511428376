import { useGetTemplates } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Loader from 'shared/components/Loader';
import styleIdentifiers from './WorkflowFormStep.scss';
var styles = classNames.bind(styleIdentifiers);
export var WorkflowFormStepReminderTemplate = function (_a) {
    var params = _a.params, onClick = _a.onClick, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var t = useTranslation().t;
    var watch = useFormContext().watch;
    var _c = useGetTemplates(params), templatesListResponse = _c.data, isLoading = _c.isLoading, isError = _c.isError;
    if (isLoading && !templatesListResponse) {
        return React.createElement(Loader, null);
    }
    if (isError || !templatesListResponse) {
        return React.createElement("span", null, "Error");
    }
    var templates = templatesListResponse.data;
    if (disabled || !templates.length) {
        return (React.createElement("div", { className: styles('recovr-list') },
            React.createElement("div", { className: styles('no-result') }, t(i18nKeys.NO_RESULT))));
    }
    var currentTemplate = templates.find(function (el) { var _a; return el.id === ((_a = watch('template')) === null || _a === void 0 ? void 0 : _a.id); });
    if (currentTemplate) {
        return (React.createElement("div", { className: styles('recovr-list') },
            React.createElement("div", { onClick: function () { return onClick(undefined); }, className: styles('choosed') },
                React.createElement("div", { className: styles('name') }, currentTemplate.attributes.name))));
    }
    return (React.createElement("div", { className: styles('recovr-list') }, templates.map(function (template) { return (React.createElement("div", { key: "template-".concat(template), onClick: function () { return onClick(template); } },
        React.createElement("div", { className: styles('name') }, template.attributes.name))); })));
};
