import React from 'react';
import OnBoardingChunk from 'app/Logged/Onboarding';
import { i18nKeys, useTranslation } from 'locales';
import { capitalize } from 'lodash-es';
import qs from 'query-string';
import { Redirect, Route, Switch } from 'react-router';
import { success } from 'store/view/view.actions';
export var OnboardingWrapper = function () {
    var t = useTranslation().t;
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: "/onboarding", component: OnBoardingChunk }),
        React.createElement(Route, { path: "/callback", render: function () {
                var queryParams = qs.parse(location.search);
                var integration = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
                if (JSON.parse(qs.parse(location.search).successfulConnection)) {
                    success({
                        data: t(i18nKeys.FORM.SUCCESSFULLY_CONNEXION, {
                            text: capitalize(integration),
                        }),
                    });
                }
                return (React.createElement(Redirect, { to: "/onboarding?integration=".concat(integration, "&").concat(queryParams.errorMessage ? "errorMessage=".concat(queryParams.errorMessage) : '') }));
            } }),
        React.createElement(Redirect, { to: "/onboarding" })));
};
