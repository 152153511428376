import { __extends } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys } from 'locales/';
import { Button } from 'shared/io';
import * as Sentry from '@sentry/browser';
import { IconName } from '../Icon';
import styleIdentifiers from './errorBoundary.scss';
var styles = classNames.bind(styleIdentifiers);
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { error: null };
        return _this;
    }
    ErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        this.setState({ error: error });
        Sentry.withScope(function (scope) {
            Object.keys(errorInfo).forEach(function (key) {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });
    };
    ErrorBoundary.prototype.render = function () {
        var error = this.state.error;
        var _a = this.props, children = _a.children, t = _a.t;
        if (error) {
            return (React.createElement("div", { className: styles('error-wrapper') },
                React.createElement("div", { className: styles('content-wrapper') },
                    React.createElement("div", { className: styles('content', 'card') },
                        React.createElement("h1", null, t.text(i18nKeys.ERROR.HAS_PROBLEM)),
                        React.createElement("p", null, t.text(i18nKeys.ERROR.SUMMARY)),
                        React.createElement("div", { className: styles('button-wrapper') },
                            React.createElement(Button, { onClick: function () { return location.reload(); }, label: t.text(i18nKeys.ERROR.RESTART), iconLeft: IconName.SPINNER }),
                            React.createElement(Button, { onClick: function () {
                                    Sentry.showReportDialog({
                                        eventId: error === null || error === void 0 ? void 0 : error.event_id,
                                    });
                                }, label: t.text(i18nKeys.ERROR.REPORT_PROBLEM) }))))));
        }
        return children;
    };
    return ErrorBoundary;
}(React.Component));
export default ErrorBoundary;
