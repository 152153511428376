import { makeAc, makeReqCons, makeResAc } from 'store/actions';
export var sessionConstants = {
    login: makeReqCons('SESSION_LOGIN'),
    logout: makeReqCons('SESSION_LOGOUT'),
    resetPassword: makeReqCons('PASSWORD_RESET'),
    editPassword: makeReqCons('EDIT_PASSWORD'),
    getTokenToResetPassword: makeReqCons('GET_TOKEN_TO_RESET_PASSWORD'),
};
export var login = makeAc(sessionConstants.login.request);
export var loginRes = makeResAc(sessionConstants.login.result);
export var logout = makeAc(sessionConstants.logout.request);
export var logoutRes = makeResAc(sessionConstants.logout.result);
export var resetPassword = makeAc(sessionConstants.resetPassword.request);
export var editPassword = makeAc(sessionConstants.editPassword.request);
export var getTokenToResetPassword = makeAc(sessionConstants.getTokenToResetPassword.request);
export function updateEmail(email) {
    return {
        type: 'updateEmail',
        payload: email,
    };
}
