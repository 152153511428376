import { __read } from "tslib";
import React, { useState } from 'react';
import { useGetMergeableDebtors, useIgnoreDuplicates, useMergeSimilarDebtors } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useHistory, useParams } from 'react-router-dom';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconName } from 'shared/components/Icon';
import Loader from 'shared/components/Loader';
import { Button, ButtonColor } from 'shared/io';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { AttributesSelection } from './AttributesSelection';
import { MergeableDebtor } from './MergeableDebtor';
import styleIdentifiers from './DuplicatesClientDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export var DuplicatesClientDetail = function () {
    var t = useTranslation().t;
    var history = useHistory();
    var baseDebtorId = useParams().id;
    var _a = useGetMergeableDebtors(baseDebtorId), mergeableDebtorsListResponse = _a.data, isLoading = _a.isLoading, isError = _a.isError;
    var mergeDebtors = useMergeSimilarDebtors().mutate;
    var ignoreDuplicates = useIgnoreDuplicates().mutate;
    var _b = __read(useState(''), 2), checkedDebtorId = _b[0], setCheckedDebtorId = _b[1];
    if (isLoading && !mergeableDebtorsListResponse) {
        return React.createElement(Loader, null);
    }
    if (isError || !mergeableDebtorsListResponse) {
        return React.createElement("span", null, "Error");
    }
    var debtorsToMerge = mergeableDebtorsListResponse.data || [];
    var goToList = function () {
        history.push('/client/duplicates');
    };
    var skipMerge = function () {
        ignoreDuplicates({
            id: baseDebtorId,
        }, {
            onSuccess: function () {
                goToList();
            },
        });
    };
    var submitMerge = function (data) {
        mergeDebtors({
            data: data,
        }, {
            onSuccess: function () {
                dialogHide(DialogShowId.CUSTOM);
                goToList();
            },
        });
    };
    var openAttributesSelectionModal = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.LARGE,
            title: t(i18nKeys.CLIENT.MERGE.TITLE),
            children: (React.createElement(AttributesSelection, { baseDebtorId: checkedDebtorId, debtors: debtorsToMerge, onSubmit: function (data) { return submitMerge(data); } })),
        });
    };
    return (React.createElement("div", { className: styles('mergeable-clients') },
        React.createElement("div", { className: styles('title-line') },
            React.createElement(Icon, { name: IconName.MINIMAL_LEFT, onClick: goToList }),
            " ",
            React.createElement("h1", null, t(i18nKeys.CLIENTS)),
            React.createElement("div", { className: styles('buttons-wrapper') },
                React.createElement(Button, { label: t(i18nKeys.CLIENT.MERGE.IGNORE), onClick: skipMerge, color: ButtonColor.GREY, noMargin: true }),
                React.createElement(Button, { label: t(i18nKeys.MERGE_CLIENTS), disabled: checkedDebtorId === '', onClick: openAttributesSelectionModal, noMargin: true }))),
        React.createElement(HTML, { className: styles('warning'), html: t(i18nKeys.CLIENT.MERGE.SELECT_CLIENT) }),
        React.createElement("div", { className: styles('clients-wrapper') }, debtorsToMerge.map(function (client) { return (React.createElement(MergeableDebtor, { key: client.id, client: client, checkedDebtorId: checkedDebtorId, onCheck: function (e) {
                setCheckedDebtorId(e);
            } })); }))));
};
