import { DisplayType, VarType } from 'app/Logged/CustomVariables/CustomVariables.types';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import EditCustomVariable from 'shared/action-component/EditCustomVariable';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import { formattedDate } from 'shared/utils/view';
import { settingsActions } from 'store/settings/settings.actions';
import { showDialog, DialogShowId, DialogShowSize } from 'store/view/view.actions';
import styleIdentifiers from './CustomizationSettingsCustomVariablesItem.scss';
import { captureMessage } from '@sentry/browser';
import { useSelector } from 'react-redux';
var styles = classNames.bind(styleIdentifiers);
export var CustomizationSettingsCustomVariablesItem = function (_a) {
    var item = _a.item;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    // For diagnostics below
    var customVariableList = useSelector(function (state) { return state.settings.customVariableList; });
    var handleClick = function (item) { return function (e) {
        e.stopPropagation();
        EditCustomVariable({
            customVariable: item,
        });
    }; };
    var handleDelete = function () {
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRMATION),
            children: (React.createElement(AYSModal, { text: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.AYS_TO_DELETE), onConfirm: function () { return settingsActions.deleteCustomVariable({ id: item.id }); } })),
        });
    };
    var renderValue = function () {
        var defaultValue = item.default_value;
        if (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.toString()) {
            switch (item.var_type) {
                case VarType.BOOLEAN:
                    return t(i18nKeys[defaultValue.toString() === 'true' ? 'YES' : 'NO']);
                case VarType.DATE:
                    return formattedDate(Date.parse(defaultValue), currentLang);
                default:
                    switch (item.display) {
                        case DisplayType.MONEY:
                            return (React.createElement(NumericFormat, { value: defaultValue, displayType: "text", suffix: "\u20AC", decimalSeparator: ".", fixedDecimalScale: true, decimalScale: 2, thousandSeparator: " " }));
                        case DisplayType.PERCENTAGE:
                            return (React.createElement(NumericFormat, { value: defaultValue, suffix: "%", decimalScale: 2, displayType: "text" }));
                        default:
                            return defaultValue;
                    }
            }
        }
        else {
            return 'N/A';
        }
    };
    if (item == null || item.var_type == null) {
        captureMessage('In CustomizationSettingsCustomVariablesItem, item was nullish or missing `var_type` property', { extra: { item: item, customVariables: customVariableList.pages } });
        return null;
    }
    return (React.createElement("tr", { className: styles('custom-variable-item') },
        React.createElement("td", null),
        React.createElement("td", null, item.name),
        React.createElement("td", null, item.column_name),
        React.createElement("td", null, t(i18nKeys.COLUMN_TYPE[item.var_type.toUpperCase()])),
        React.createElement("td", null, t(i18nKeys.COMMON[item.model_type === 'invoice' ? 'INVOICE' : 'CLIENT'])),
        React.createElement("td", null, renderValue()),
        React.createElement("td", { className: styles('actions') },
            React.createElement("span", { onClick: handleClick(item) },
                React.createElement(Icon, { name: IconName.PENCIL, size: "18px", className: styles('action') })),
            React.createElement("span", { onClick: handleDelete },
                React.createElement(Icon, { name: IconName.TRASH_SIMPLE, size: "18px", className: styles('action') })))));
};
