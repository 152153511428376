import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, ButtonColor, VatInput } from 'shared/io';
import { appActions } from 'store/app/app.actions';
import AddressForm from './AddressForm/AddressForm';
import styleIdentifiers from './GeneralInfoForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function GeneralInfoForm(_a) {
    var onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var form = useForm({ shouldUnregister: true });
    var watch = form.watch, setValue = form.setValue;
    var vat_number = watch('vat_number');
    var getAddress = function () {
        appActions.fetchAddress({
            data: {
                vat_number: vat_number,
            },
            callback: function (res) {
                var keys = Object.keys(res);
                for (var i = 0; i < keys.length; i += 1) {
                    var key = keys[i];
                    var elem = res[key];
                    if (key === 'company_name')
                        continue;
                    setValue("address_attributes.".concat(key), elem);
                }
            },
        });
    };
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('general-info-form'), onSubmit: form.handleSubmit(onSubmit) },
            React.createElement("div", { className: "grid-row" },
                React.createElement("div", { className: styles('col-12', 'flex') },
                    React.createElement(VatInput, { className: styles('input', 'vat-input'), withBorder: true, name: "vat_number", label: t(i18nKeys.FORM.VAT), noDropdownMargin: true }),
                    vat_number && vat_number.indexOf('_') === -1 && (React.createElement("div", { className: styles('info') },
                        React.createElement(Button, { noMargin: true, noShadow: true, color: ButtonColor.BLUE, className: styles('link'), label: t(i18nKeys.FORM.CLIENT.PRE_FILL), onClick: getAddress })))),
                React.createElement("div", { className: "col-12" },
                    React.createElement(AddressForm, null))),
            React.createElement(Button, { className: styles('button'), label: t(i18nKeys.ONBOARDING.NEXT_STEP), type: "submit" }))));
}
