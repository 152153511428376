import { __assign } from "tslib";
import loadLang from 'locales/';
import { isDevelopment } from 'shared/utils/environment';
import { appConstants } from 'store/app/app.actions';
import { translateActions } from 'store/translate/translate.actions';
import { captureMessage } from '@sentry/browser';
import { storeSetup } from '../../clientEntry';
var text = loadLang('fr');
var fallbackText = {};
var defaultState = {
    availableLang: [
        { description: 'Français', value: 'fr' },
        { description: 'English', value: 'en' },
        { description: 'Nederlands', value: 'nl' },
    ],
    currentLang: 'fr',
    text: function (key) { return searchText(key, text); },
};
export default (function (state, _a) {
    if (state === void 0) { state = defaultState; }
    var type = _a.type, payload = _a.payload;
    switch (type) {
        case translateActions.loadLiterals.request:
            text = payload;
            return __assign({}, state);
        case translateActions.changeLang.request:
            if (state.currentLang !== payload) {
                text = loadLang(payload);
            }
            return __assign(__assign({}, state), { currentLang: payload });
        case appConstants.constants.result:
            if (payload.available_languages == null || payload.available_languages.length === 0) {
                captureMessage('We tried to set the available languages but the payload was empty or null. Would cause a repeat of Sentry 14x');
                return __assign({}, state);
            }
            return __assign(__assign({}, state), { availableLang: payload.available_languages });
        default:
            return state;
    }
});
var reportProblem = function (key) {
    if (Object.keys(text).length !== 0) {
        var currentLang = storeSetup.store.getState().translate.currentLang;
        if (isDevelopment()) {
            throw new Error("Missing translation for ".concat(currentLang, " - ").concat(key));
        }
        captureMessage("Missing translation for ".concat(currentLang, " - ").concat(key));
        if (Object.keys(fallbackText).length === 0) {
            fallbackText = loadLang('fr');
        }
        return searchText(key, fallbackText);
    }
    return 'Missing translation';
};
var searchText = function (key, text) {
    return key.split('.').reduce(function (prev, curr) { return (prev && prev[curr]) || reportProblem(key); }, text);
};
