import { __assign } from "tslib";
import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useForm } from 'react-hook-form';
import ListResModal from 'shared/components/DeleteModal';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, Checkbox, Input } from 'shared/io';
import { filterToText } from 'shared/serializer';
import { useGetTableTypes } from 'shared/utils/selectors';
import { appActions } from 'store/app/app.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { ActionIcon, Group, Tooltip } from '@mantine/core';
import { IconStar, IconStarFilled } from '@tabler/icons-react';
import styleIdentifiers from './ViewSettings.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ViewSettings(_a) {
    var tableName = _a.tableName;
    var t = useTranslation().t;
    var tableViews = useGetTableTypes()[tableName];
    var _b = useForm({
        defaultValues: { views: tableViews },
    }), register = _b.register, getValues = _b.getValues, watch = _b.watch, setValue = _b.setValue, reset = _b.reset;
    useEffect(function () {
        reset({ views: tableViews });
    }, [tableViews]);
    var tabViews = watch('views');
    var setAsDefault = function (viewIndex) {
        var existingDefaultViewIndex = tabViews.findIndex(function (view) { return view.is_default; });
        if (existingDefaultViewIndex !== -1) {
            setValue("views.".concat(existingDefaultViewIndex, ".is_default"), false);
        }
        setValue("views.".concat(viewIndex, ".is_default"), true);
    };
    var removeDefault = function (viewIndex) {
        setValue("views.".concat(viewIndex, ".is_default"), false);
    };
    var updateViews = function () {
        var viewsAttributes = getValues().views.map(function (view) { return (__assign(__assign({}, view), { view_type: tableName })); });
        appActions.updateViews({
            data: {
                views_attributes: viewsAttributes,
                batch: true,
            },
            callback: function (data) {
                if (data.logs.length > 0) {
                    showDialog({
                        id: DialogShowId.CUSTOM,
                        size: DialogShowSize.MEDIUM,
                        title: t(i18nKeys.RESULT),
                        children: React.createElement(ListResModal, { data: data.logs }),
                    });
                }
                else {
                    dialogHide(DialogShowId.CUSTOM);
                }
                appActions.getViewsRes(data, 'fulfilled', { view_type: tableName });
            },
        });
    };
    var addView = function () {
        var views = getValues().views;
        views.push({
            name: '',
            shared: false,
            deletable: true,
            editable: true,
        });
        setValue('views', views);
    };
    var deleteView = function (index) { return function () {
        var views = getValues().views;
        views[index]._destroy = true;
        setValue('views', views);
    }; };
    var duplicateView = function (index) { return function () {
        var views = getValues().views;
        views.push(__assign(__assign({}, views[index]), { id: undefined, deletable: true, editable: true, is_default: false, name: "".concat(views[index].name, "_copy"), shared: views[index].shared, base_view_id: views[index].id }));
        setValue('views', views);
    }; };
    return (React.createElement("div", { className: styles('view-settings') },
        tabViews.map(function (view, index) {
            var _a, _b;
            return !view._destroy && (React.createElement("div", { key: index, className: styles('view-item') },
                React.createElement(Group, { justify: "space-between", pb: "md" },
                    React.createElement(Group, null,
                        view.shared && (React.createElement(Tooltip, { withArrow: true, label: t(i18nKeys.ACTIONS.FAVORITE) }, view.is_default ? (React.createElement(ActionIcon, { variant: "transparent", onClick: function () { return removeDefault(index); } },
                            React.createElement(IconStarFilled, null))) : (React.createElement(ActionIcon, { variant: "transparent", onClick: function () { return setAsDefault(index); } },
                            React.createElement(IconStar, { color: "var(--mantine-color-gray-5)" }))))),
                        React.createElement(Input, { noMargin: true, noBorder: true, register: register("views.".concat(index, ".name"), { required: true }) }),
                        React.createElement(Checkbox, { register: register("views.".concat(index, ".shared")), onChange: function (val) {
                                if (!val)
                                    removeDefault(index);
                            }, noMargin: true, watch: watch, label: t(i18nKeys.PUBLIC) })),
                    React.createElement(Group, null,
                        React.createElement(Icon, { name: IconName.COPY, onClick: duplicateView(index) }),
                        view.deletable && (React.createElement(Icon, { name: IconName.TRASH_SIMPLE, onClick: deleteView(index) })))),
                React.createElement("div", { className: styles('view-body') },
                    React.createElement("div", null,
                        React.createElement("h4", null,
                            t(i18nKeys.FILTERS),
                            ":"),
                        React.createElement("div", { className: styles('filter-container') }, (_a = view.filter_fields) === null || _a === void 0 ? void 0 : _a.map(function (_a) {
                            var applied_filter = _a.applied_filter, name_translated = _a.name_translated, id = _a.id, filter_type = _a.filter_type;
                            return (React.createElement("div", { key: id, className: styles('label') },
                                React.createElement("span", null,
                                    name_translated,
                                    ":"),
                                React.createElement("span", null, filterToText(filter_type, applied_filter.value, undefined, applied_filter.operator, applied_filter.value[0], applied_filter.value[1]))));
                        }))),
                    React.createElement("div", null,
                        React.createElement("h4", null,
                            t(i18nKeys.COLUMNS),
                            ":"),
                        React.createElement("div", { className: styles('columns-container') }, (_b = view.table_columns) === null || _b === void 0 ? void 0 : _b.map(function (_a) {
                            var id = _a.id, name_translated = _a.name_translated;
                            return (React.createElement("div", { key: id, className: styles('label', 'blue') },
                                React.createElement("span", null, name_translated)));
                        }))))));
        }),
        React.createElement("div", { className: styles('view-item', 'add'), onClick: addView },
            React.createElement(Icon, { name: IconName.PLUS }),
            " ",
            t(i18nKeys.ACTIONS.ADD)),
        React.createElement("div", null,
            React.createElement(Button, { label: t(i18nKeys.SAVE), onClick: updateViews }))));
}
