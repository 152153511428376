import { __assign, __read } from "tslib";
import React, { useMemo, useState } from 'react';
import { TasksListItemStatus } from 'app/Logged/Tasks';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { NumericFormat } from 'react-number-format';
import { Document, Page } from 'react-pdf';
import EditClient from 'shared/action-component/EditClient';
import Amount from 'shared/components/Amount';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import StatusItem from 'shared/components/StatusItem';
import Tooltip from 'shared/components/Tooltip';
import { DropdownDirection } from 'shared/io/Dropdown/Dropdown';
import { formatCommunication } from 'shared/utils/normalization';
import { formattedDate, reloadCustomView } from 'shared/utils/view';
import { clientActions } from 'store/client/client.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import styleIdentifiers from './TableItem.scss';
var styles = classNames.bind(styleIdentifiers);
export default function TableItem(_a) {
    var _b, _c;
    var columns = _a.columns, item = _a.item, noCheckbox = _a.noCheckbox, onClickCheckbox = _a.onClickCheckbox, onClickRow = _a.onClickRow, actionsCol = _a.actionsCol, groupedInvoices = _a.groupedInvoices, isRemindersView = _a.isRemindersView, isSelected = _a.isSelected;
    var _d = useTranslation(), t = _d.t, currentLang = _d.currentLang;
    var _e = __read(useState(false), 2), isPreviewLoading = _e[0], setIsPreviewLoading = _e[1];
    item = item.attributes
        ? __assign({ id: item.id, type: item.type }, item.attributes) : item;
    var columnParser = useMemo(function () { return ({
        date: function (value) { return formattedDate(value, currentLang); },
        boolean: function (value) { return (value ? t(i18nKeys.YES) : t(i18nKeys.NO)); },
        money: function (value) { return (React.createElement(NumericFormat, { value: value, displayType: "text", suffix: "\u20AC", decimalSeparator: ".", fixedDecimalScale: true, decimalScale: 2, thousandSeparator: " " })); },
        percentage: function (value) { return (React.createElement(NumericFormat, { value: value, suffix: "%", decimalScale: 2, displayType: "text" })); },
        days: function (value) { return t(i18nKeys.DAY_S, { count: value }); },
        structured_communication: function (value) { return formatCommunication(value); },
    }); }, []);
    var editClient = function (item) { return function (e) {
        e.stopPropagation();
        EditClient({
            client: {
                id: item.debtor_id || item.id,
            },
            callback: function () {
                reloadCustomView(clientActions.listPageRes);
            },
        });
    }; };
    var parser = function (item, column) {
        var _a, _b;
        var value = ((_a = item.debtor_custom_variables) === null || _a === void 0 ? void 0 : _a[column.name.replace('debtor__', '')]) ||
            ((_b = item.custom_variables) === null || _b === void 0 ? void 0 : _b[column.name]) ||
            item[column.name];
        if (columnParser[column.format] && column.name !== 'next_step_date' && value !== null) {
            return columnParser[column.format](value);
        }
        return value;
    };
    var renderColumn = function (column, item) {
        var _a;
        if (column.name === 'status') {
            return (React.createElement("td", { key: column.name }, column.cleaned_view === 'tasks' ? (React.createElement(TasksListItemStatus, { status: item.status, statusKey: item.status_key })) : (React.createElement(StatusItem, { status: item.status, step: item.step_collection }))));
        }
        if ([
            'total_ca',
            'annual_ca',
            'total_tvac',
            'total_htva',
            'late_fees',
            'remaining_late_fees',
            'remaining_balance',
            'remaining_balance_with_fees',
            'remaining_balance_without_late_fees',
            'total_discount_amount',
            'overdue_amount',
            'interest_amount',
            'credit_notes_total',
            'payments_sum',
            'penalty_clause_amount',
        ].includes(column.name)) {
            var value = ((_a = item === null || item === void 0 ? void 0 : item.localized_money_object) === null || _a === void 0 ? void 0 : _a[column.name]) || (item === null || item === void 0 ? void 0 : item[column.name]);
            if (value == null)
                return null;
            return (React.createElement("td", { key: column.name },
                React.createElement(Amount, { localizedValue: item === null || item === void 0 ? void 0 : item[column.name], value: value, suffix: item.currency })));
        }
        return (React.createElement("td", { className: styles(column.column_type === 'actions' && 'actions', column.column_type !== 'actions' &&
                parser(item, column) === t(i18nKeys.INVOICING.IMPENDING_REMINDERS.WAITING_VALIDATION) &&
                'red'), key: column.name }, column.column_type === 'actions' ? actionsCol(item) : parser(item, column) || '-'));
    };
    var handlePdfView = function () {
        invoiceActions.document({
            id: item.id,
            type: 'invoice_pdf',
            callback: function (data) {
                var url = URL.createObjectURL(data);
                showDialog({
                    id: DialogShowId.CUSTOM,
                    size: DialogShowSize.LARGE,
                    title: item.reference,
                    children: (React.createElement(Document, { file: url },
                        React.createElement(Page, { className: styles('pdf-pages'), width: 1100, pageNumber: 1 }))),
                });
            },
        });
    };
    var handleReminderPreview = function () {
        setIsPreviewLoading(true);
        invoiceActions.previewTemplate({
            id: item.id,
            noLoading: true,
            data: {
                document_type: item.preview_template.step_type,
                template_id: item.preview_template.id,
                manual_reminder: false,
            },
            callback: function (data) {
                var email = data.email;
                setIsPreviewLoading(false);
                showDialog({
                    id: DialogShowId.CUSTOM,
                    size: DialogShowSize.LARGE,
                    title: item.recovery_step_id,
                    children: (React.createElement("div", { className: styles('email-preview') },
                        React.createElement(HTML, { className: "email-preview", html: email }))),
                });
            },
        });
    };
    return (React.createElement("tr", { className: styles('table-item'), onClick: onClickRow },
        React.createElement("td", { className: styles(noCheckbox && 'small', 'status', item.step_collection ? "status-".concat(item.step_collection + 1) : 'status-0') },
            React.createElement("div", { className: styles('checkbox-wrapper') },
                !noCheckbox && (React.createElement("div", { className: styles('checkbox', isSelected && 'checked'), onClick: onClickCheckbox })),
                isRemindersView && (React.createElement(Icon, { name: IconName[isPreviewLoading ? 'SYNC' : 'EYE'], rotate: isPreviewLoading, color: IconColor[isPreviewLoading ? 'BLUE' : 'DARK'], size: "18px", title: t(i18nKeys.SHOW_TEMPLATE), onClick: function (e) {
                        e.stopPropagation();
                        if (!isPreviewLoading) {
                            handleReminderPreview();
                        }
                    } })),
                (item.address_missing || item.email_missing) && (React.createElement(Tooltip, { className: styles('action-drop'), contentClass: styles('warning'), item: React.createElement("div", { className: styles('circle'), onClick: editClient(item) }, "!") },
                    React.createElement("ul", null,
                        item.address_missing && React.createElement("li", null, t(i18nKeys.INCOMPLETE_ADRESS)),
                        item.email_missing && React.createElement("li", null,
                            " ",
                            t(i18nKeys.MISSING_EMAIL))))),
                item.has_pdf && (React.createElement(Icon, { name: IconName.PDF, color: IconColor.DARK, size: "18px", title: t(i18nKeys.INVOICE.VIEW_PDF), onClick: function (e) {
                        e.stopPropagation();
                        handlePdfView();
                    } })),
                (groupedInvoices === null || groupedInvoices === void 0 ? void 0 : groupedInvoices.length) > 1 && (React.createElement(Tooltip, { item: React.createElement("div", { className: styles('grouped-invoices-count') }, groupedInvoices === null || groupedInvoices === void 0 ? void 0 : groupedInvoices.length), size: "14px", text: t(i18nKeys.TOOLTIP.IMMINENT_REMINDER_INVOICES, {
                        references: groupedInvoices.join(' '),
                    }), direction: DropdownDirection.TOP, oneLine: true })),
                ((_b = item.off_balance_since) !== null && _b !== void 0 ? _b : item.debtor_off_balance_since) && (React.createElement(Tooltip, { className: styles('action-drop'), contentClass: styles('warning'), item: React.createElement("div", { className: styles('circle'), onClick: editClient(item) }, "!") }, t(i18nKeys.OFF_BALANCE, {
                    since: formattedDate((_c = item.off_balance_since) !== null && _c !== void 0 ? _c : item.debtor_off_balance_since, currentLang),
                }))),
                item.paused && (React.createElement(Icon, { name: IconName.PAUSE, color: IconColor.DARK, size: "18px", title: t(i18nKeys.CLIENT.FILTER.PAUSED) })),
                item.has_payment_plan && (React.createElement(Icon, { name: IconName.PAYMENT_BOOK, color: IconColor.DARK, size: "18px", title: t(i18nKeys.ONGOING_PAYMENT_PLAN) })),
                item.disputed && (React.createElement(Icon, { name: IconName.ALERT_CIRCLE, color: IconColor.RED, size: "18px", title: t(i18nKeys.FOLLOW_UP.SUMMARY.CONFLICT) })))),
        columns.map(function (column) { return renderColumn(column, item); }),
        React.createElement("td", null)));
}
