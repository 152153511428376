import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import { Integration } from './Integrations.constants';
import styleIdentifiers from './Integrations.scss';
var styles = classNames.bind(styleIdentifiers);
export var IntegrationsCard = function (_a) {
    var integration = _a.integration, onClick = _a.onClick;
    var t = useTranslation().t;
    var title = integration.title, icon = integration.icon, description = integration.description, canActivate = integration.canActivate, isActive = integration.isActive;
    return (React.createElement("div", { className: styles('card', !canActivate && 'disabled', 'content'), onClick: function () {
            if (canActivate) {
                onClick(integration);
            }
        }, title: title === Integration.DIGITEAL && !isActive ? t(i18nKeys.NOT_INCLUDED) : undefined },
        React.createElement("div", { className: styles('picture', title) },
            React.createElement(Icon, { name: icon, size: "50px" })),
        React.createElement("div", { className: styles('container-info') },
            React.createElement("div", { className: styles('title') }, title),
            React.createElement("div", { className: styles('description') }, description)),
        isActive && (React.createElement("div", { className: styles('install-icon') },
            React.createElement(Icon, { name: IconName.CHECK, color: IconColor.WHITE, size: "20px" })))));
};
