import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, axiosInstance } from './utils';
var PausedInvoicesSchema = z
    .object({
    totalInvoicesPaused: z.preprocess(function (val) { return Number(val); }, z.number()),
})
    .transform(function (_a) {
    var totalInvoicesPaused = _a.totalInvoicesPaused;
    return totalInvoicesPaused;
});
export function useLoadPausedInvoicesFn() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance.get('analytics/paused_invoices')];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, PausedInvoicesSchema.parse(data)];
            }
        });
    });
}
export var useLoadPausedInvoices = function () {
    var queryResult = useQuery({
        queryKey: ['paused-invoices'],
        queryFn: useLoadPausedInvoicesFn,
    });
    return addResourceNameToQueryResult('pausedInvoices', queryResult);
};
