import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, axiosInstance } from './utils';
var BalanceValueSchema = z.preprocess(function (val) { return Number(val); }, z.number());
var BalanceSchema = z.object({
    '0': BalanceValueSchema,
    '30': BalanceValueSchema,
    '60': BalanceValueSchema,
    '90': BalanceValueSchema,
    notDue: BalanceValueSchema,
});
var AgedDebtorSchema = z.object({
    debtorId: z.number(),
    debtorName: z.string().min(1),
    credits: BalanceSchema,
    debits: BalanceSchema,
});
var AgedBalanceSchema = z
    .object({
    computedAt: z.string(),
    agedBalances: z.array(AgedDebtorSchema),
})
    .transform(function (_a) {
    var computedAt = _a.computedAt, agedBalances = _a.agedBalances;
    return ({ computedAt: computedAt, data: agedBalances });
});
var AgedBalanceCompactSchema = z.object({
    computedAt: z.string(),
    credits: BalanceSchema,
    debits: BalanceSchema,
});
export function useLoadAgedBalanceQueryFn() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance.get('/analytics/aged_balances?compact=false')];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, AgedBalanceSchema.parse(data)];
            }
        });
    });
}
export var useLoadAgedBalance = function () {
    var queryResult = useQuery({
        queryKey: ['aged-balance', 'regular'],
        queryFn: useLoadAgedBalanceQueryFn,
    });
    return addResourceNameToQueryResult('agedBalance', queryResult);
};
export function useLoadAgedBalanceCompactQueryFn() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance.get('/analytics/aged_balances?compact=true')];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, AgedBalanceCompactSchema.parse(data)];
            }
        });
    });
}
export var useLoadAgedBalanceCompact = function () {
    var queryResult = useQuery({
        queryKey: ['aged-balance', 'compact'],
        queryFn: useLoadAgedBalanceCompactQueryFn,
    });
    return addResourceNameToQueryResult('agedBalanceCompact', queryResult);
};
