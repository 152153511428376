import { __awaiter, __generator } from "tslib";
import dayjs from 'dayjs';
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, axiosInstance } from './utils';
var ReportSchema = z.object({
    id: z.string().optional(),
    name: z.string().min(1),
    periodicity: z.object({
        type: z.enum(['weekly', 'monthly']),
        days: z.array(z.number()),
    }),
    lastExecutedAt: z
        .string()
        .nullable()
        .transform(function (data) { return (data == null ? data : dayjs(data)); }),
});
var ReportsSchema = z
    .object({
    exportTasks: z.array(ReportSchema),
})
    .transform(function (data) { return data.exportTasks; });
export function loadReportsQueryFn() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance.get('/reports/export_tasks')];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, ReportsSchema.parse(data)];
            }
        });
    });
}
export var useLoadReports = function () {
    var queryResult = useQuery({
        queryKey: ['reports'],
        queryFn: loadReportsQueryFn,
    });
    return addResourceNameToQueryResult('reports', queryResult);
};
