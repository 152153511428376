import React from 'react';
import { DueDateDelay } from 'api/models';
import { TaskFormColleague, TaskFormType } from 'app/Logged/Tasks';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { get } from 'lodash-es';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomSelect, Input, Textarea } from 'shared/io';
import styleIdentifiers from './RecoveryStepForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var RecoveryStepFormCreateTask = function (_a) {
    var children = _a.children;
    var t = useTranslation().t;
    var _b = useFormContext(), register = _b.register, errors = _b.formState.errors;
    register('step_type', { required: true, value: 'task_generation' });
    var dueDateDescription = function (days) {
        switch (days) {
            case 0:
                return i18nKeys.CREATION_DATE;
            case 999:
                return i18nKeys.NEXT_STEP_DATE;
            default:
                return i18nKeys.N_DAYS_AFTER_CREATION;
        }
    };
    var dueDateDelays = Object.values(DueDateDelay).map(function (e) { return ({
        description: t(dueDateDescription(e), { days: e }),
        value: e.toString(),
    }); });
    return (React.createElement(React.Fragment, null,
        children,
        React.createElement(Input, { register: register('task_attributes.title', {
                required: true,
            }), errorMessage: get(errors, 'task_attributes.title'), label: t(i18nKeys.TASK.ATTRIBUTES.TITLE), withBorder: true }),
        React.createElement(Textarea, { register: register('task_attributes.description'), className: styles('input'), withBorder: true, label: t(i18nKeys.DESCRIPTION) }),
        React.createElement(TaskFormType, { name: "task_attributes.task_type_id" }),
        React.createElement(TaskFormColleague, { name: "task_attributes.user_id" }),
        React.createElement(Controller, { name: "task_attributes.due_date_delay", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { label: t(i18nKeys.DUE_DATE), keyText: "description", keyValue: "value", name: "task_attributes.due_date_delay", withBorder: true, items: dueDateDelays })); } }),
        React.createElement(Controller, { name: "task_attributes.mandatory", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { label: t(i18nKeys.TASK.ATTRIBUTES.MANDATORY), keyText: "description", keyValue: "value", name: "task_attributes.mandatory", withBorder: true, items: [
                    { description: t(i18nKeys.YES), value: 'true' },
                    { description: t(i18nKeys.NO), value: 'false' },
                ] })); } })));
};
