import { __read, __spreadArray } from "tslib";
import React, { useReducer } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import MoveRecoveryPlanInvoices from 'shared/action-component/MoveRecoveryPlanInvoices';
import AYSModal from 'shared/components/AYSModal';
import { CustomSelect, Input } from 'shared/io';
import { treatRecoveryPlan } from 'shared/serializer';
import { currencySymbol } from 'shared/utils/normalization';
import { reducerState, removeAttributes } from 'shared/utils/view';
import { settingsActions } from 'store/settings/settings.actions';
import { dialogHide, DialogShowId, showDialog } from 'store/view/view.actions';
import styleIdentifiers from './ClientDetailForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var ClientDetailForm = function (_a) {
    var data = _a.data, setData = _a.setData;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var constants = useSelector(function (state) { return state.app.constants; });
    var _b = __read(useReducer(reducerState, {
        recoveryPlans: [],
        invoiceTemplate: [],
        cnTemplate: [],
    }), 2), _c = _b[0], recoveryPlans = _c.recoveryPlans, cnTemplate = _c.cnTemplate, invoiceTemplate = _c.invoiceTemplate, _setState = _b[1];
    var form = useFormContext();
    var setState = function (field) { return function (value) {
        var _a;
        _setState((_a = {},
            _a[field] = value,
            _a));
    }; };
    var getRecoveryPlans = function () {
        settingsActions.recoveryPlanList({
            callback: function (_a) {
                var callbackData = _a.data;
                setState('recoveryPlans')(callbackData.map(function (recoveryPlan) { return treatRecoveryPlan(recoveryPlan); }));
            },
        });
    };
    var getTemplates = function (step_type) { return function () {
        settingsActions.templateList({
            data: {
                step_type: step_type,
            },
            callback: function (_a) {
                var callbackData = _a.data;
                setState(step_type === 'invoice' ? 'invoiceTemplate' : 'cnTemplate')(callbackData.map(function (item) { return removeAttributes(item); }));
            },
        });
    }; };
    var updateDefaultRecoveryPlan = function (plan_id, steps_switch) {
        dialogHide(DialogShowId.CUSTOM);
        setData({
            recovery_plan_id: plan_id === 'default' ? null : plan_id,
            steps_switch: steps_switch,
        });
    };
    var setDefaultRecoveryPlan = function (plan_id, moveInvoices) {
        if (moveInvoices) {
            MoveRecoveryPlanInvoices({
                title: t(i18nKeys.PLAN.INVOICES),
                newPlanId: plan_id,
                oldPlanId: data.recovery_plan ? data.recovery_plan.id : company.recovery_plan.id,
                debtor_id: data.id,
                onSubmit: function (steps_switch) {
                    updateDefaultRecoveryPlan(plan_id, steps_switch);
                },
            });
        }
        else {
            updateDefaultRecoveryPlan(plan_id);
        }
    };
    var askMoveInvoicesWithPlan = function (plan_id) {
        if (plan_id) {
            if (data.has_invoices_to_switch) {
                showDialog({
                    id: DialogShowId.CONFIRM,
                    title: t(i18nKeys.ATTENTION),
                    children: (React.createElement(AYSModal, { text: t(i18nKeys.CLIENT.MOVE_INVOICES_IN_PLAN), cancelButtonText: t(i18nKeys.NO), confirmButtonText: t(i18nKeys.YES), onCancel: function () {
                            setDefaultRecoveryPlan(plan_id, false);
                        }, onConfirm: function () {
                            setDefaultRecoveryPlan(plan_id, true);
                        } })),
                });
            }
            else {
                setDefaultRecoveryPlan(plan_id, false);
            }
        }
    };
    return (React.createElement("div", { className: styles('client-detail-form', 'wrap') },
        React.createElement(Controller, { name: "send_to_all_emails", defaultValue: "", render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "description", keyValue: "value", withBorder: true, name: "send_to_all_emails", items: [
                    { description: t(i18nKeys.DEFAULT), value: 'default' },
                    {
                        description: t(i18nKeys.YES),
                        value: 'true',
                    },
                    {
                        description: t(i18nKeys.NO),
                        value: 'false',
                    },
                ], label: t(i18nKeys.FORM.PREFERENCES.SEND_TO_ALL_EMAILS) })); } }),
        React.createElement(Controller, { name: "allow_payment_plans", defaultValue: "", render: function () { return (React.createElement(CustomSelect, { info: !company.package.can_use_advanced_payment_plans ? t(i18nKeys.NOT_INCLUDED) : '', notAllowed: !company.package.can_use_advanced_payment_plans, noArrow: !company.package.can_use_advanced_payment_plans, selectClassName: styles('input'), keyText: "description", keyValue: "value", withBorder: true, name: "allow_payment_plans", items: [
                    { description: t(i18nKeys.DEFAULT), value: 'default' },
                    {
                        description: t(i18nKeys.YES),
                        value: 'true',
                    },
                    {
                        description: t(i18nKeys.NO),
                        value: 'false',
                    },
                ], label: t(i18nKeys.FORM.PREFERENCES.ALLOW_PAYMENT_PLANS_DEBTOR) })); } }),
        React.createElement(Controller, { name: "recovery_plan_id", defaultValue: "", render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "name", keyValue: "id", onValueChanged: askMoveInvoicesWithPlan, withBorder: true, name: "recovery_plan_id", load: getRecoveryPlans, items: __spreadArray([{ name: t(i18nKeys.DEFAULT), id: 'default' }], __read(recoveryPlans), false), label: t(i18nKeys.RECOVERY_PLAN) })); } }),
        React.createElement(Controller, { name: "default_invoice_template_id", defaultValue: "", render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "name", keyValue: "id", withBorder: true, name: "default_invoice_template_id", load: getTemplates('invoice'), items: __spreadArray([{ name: t(i18nKeys.DEFAULT), id: 'default' }], __read(invoiceTemplate), false), label: t(i18nKeys.DEFAULT_INVOICE_TEMPLATE) })); } }),
        React.createElement(Controller, { name: "default_credit_note_template_id", defaultValue: "", render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "name", keyValue: "id", withBorder: true, name: "default_credit_note_template_id", load: getTemplates('credit_note'), items: __spreadArray([{ name: t(i18nKeys.DEFAULT), id: 'default' }], __read(cnTemplate), false), label: t(i18nKeys.DEFAULT_CN_TEMPLATE) })); } }),
        React.createElement(Input, { register: form.register('days_between_reminder'), label: t(i18nKeys.CLIENT.DETAIL.MIN_DAYS_BETWEEN_REMINDER), type: "number", withBorder: true, min: "0" }),
        React.createElement(Input, { register: form.register('minimum_saldo_sending'), label: t(i18nKeys.FORM.PREFERENCES.DONT_SEND_REMINDER_TO_DEBTOR_UNDER, {
                text: currencySymbol(undefined, company, constants),
            }), type: "number", withBorder: true })));
};
