import { __assign, __makeTemplateObject, __read, __spreadArray } from "tslib";
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { isEmpty } from 'remeda';
import { useCreateReport, useLoadLightUsers, useLoadReports, useLoadViews, } from 'shared/hooks';
import { PageTitle } from 'shared/layout';
import { css } from '@emotion/css';
import { Avatar, Box, Button, Card, Center, Checkbox, Divider, Group, Loader, MultiSelect, Paper, Radio, Select, SimpleGrid, Stack, Stepper, TagsInput, Text, TextInput, UnstyledButton, } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { isEmail, useForm } from '@mantine/form';
import { upperFirst } from '@mantine/hooks';
import { IconArrowRight, IconCalendarClock, IconCheck, IconFileAlert, IconFileDollar, IconInvoice, IconListCheck, IconReport, IconUsers, } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
var styles = {
    resourceButton: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    border-radius: var(--mantine-radius-md);\n    height: rem(90px);\n    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-7));\n    transition: box-shadow 200ms ease, transform 200ms ease, border-color 200ms ease;\n\n    :hover {\n      box-shadow: var(--mantine-shadow-sm);\n      transform: scale(1.03);\n    }\n  "], ["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    border-radius: var(--mantine-radius-md);\n    height: rem(90px);\n    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-7));\n    transition: box-shadow 200ms ease, transform 200ms ease, border-color 200ms ease;\n\n    :hover {\n      box-shadow: var(--mantine-shadow-sm);\n      transform: scale(1.03);\n    }\n  "]))),
    resourceButtonText: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    transition: color 200ms ease;\n  "], ["\n    transition: color 200ms ease;\n  "]))),
    calendar: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: fit-content;\n    [data-weekend] {\n      color: black;\n    }\n\n    .mantine-DatePicker-calendarHeader {\n      display: none;\n    }\n\n    .mantine-DatePicker-day {\n      border-radius: var(--mantine-radius-sm);\n      :hover {\n        background-color: var(--mantine-color-gray-1);\n      }\n    }\n\n    [data-selected] {\n      color: white;\n\n      :hover {\n        background-color: var(--mantine-color-blue-6);\n      }\n    }\n  "], ["\n    width: fit-content;\n    [data-weekend] {\n      color: black;\n    }\n\n    .mantine-DatePicker-calendarHeader {\n      display: none;\n    }\n\n    .mantine-DatePicker-day {\n      border-radius: var(--mantine-radius-sm);\n      :hover {\n        background-color: var(--mantine-color-gray-1);\n      }\n    }\n\n    [data-selected] {\n      color: white;\n\n      :hover {\n        background-color: var(--mantine-color-blue-6);\n      }\n    }\n  "]))),
    dayOfWeekCheckbox: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: start;\n    text-align: center;\n    border-radius: var(--mantine-radius-md);\n    background-color: var(--mantine-color-gray-0);\n    cursor: pointer;\n    padding: var(--mantine-spacing-md);\n    margin-top: var(--mantine-spacing-xs);\n    margin-bottom: var(--mantine-spacing-xs);\n    width: 100%;\n  "], ["\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: start;\n    text-align: center;\n    border-radius: var(--mantine-radius-md);\n    background-color: var(--mantine-color-gray-0);\n    cursor: pointer;\n    padding: var(--mantine-spacing-md);\n    margin-top: var(--mantine-spacing-xs);\n    margin-bottom: var(--mantine-spacing-xs);\n    width: 100%;\n  "]))),
};
var iconStyles = { size: 32, stroke: 1.5, color: 'var(--mantine-color-blue-6)' };
var resources = [
    { slug: 'debtors', name: 'Debtors', icon: React.createElement(IconUsers, __assign({}, iconStyles)) },
    { slug: 'invoices', name: 'Invoices', icon: React.createElement(IconFileDollar, __assign({}, iconStyles)) },
    {
        slug: 'postponable_invoices',
        name: 'Reminders',
        icon: React.createElement(IconCalendarClock, __assign({}, iconStyles)),
    },
    { slug: 'credit_notes', name: 'Credit notes', icon: React.createElement(IconInvoice, __assign({}, iconStyles)) },
    { slug: 'tasks', name: 'Tasks', icon: React.createElement(IconListCheck, __assign({}, iconStyles)) },
    {
        slug: 'actionable_invoices',
        name: 'Actionable invoices',
        icon: React.createElement(IconFileAlert, __assign({}, iconStyles)),
    },
];
var daysInWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export var CreateReport = function () {
    var _a, _b, _c, _d, _e;
    var history = useHistory();
    var client = useQueryClient();
    var users = useLoadLightUsers().users;
    var _f = useCreateReport(), createReport = _f.createReport, isCreateReportLoading = _f.isCreateReportLoading;
    var persisted = JSON.parse((_a = localStorage.getItem('persist-report-creation')) !== null && _a !== void 0 ? _a : 'null');
    var _g = __read(useState((_b = persisted === null || persisted === void 0 ? void 0 : persisted.step) !== null && _b !== void 0 ? _b : 0), 2), step = _g[0], setStep = _g[1];
    var _h = __read(useState((_c = persisted === null || persisted === void 0 ? void 0 : persisted.highestStepVisited) !== null && _c !== void 0 ? _c : 0), 2), highestStepVisited = _h[0], setHighestStepVisited = _h[1];
    var _j = __read(useState((_d = persisted === null || persisted === void 0 ? void 0 : persisted.subtitles) !== null && _d !== void 0 ? _d : { 0: '', 1: '', 2: '' }), 2), subtitles = _j[0], setSubtitles = _j[1];
    var setSubtitle = function (index, subtitle) {
        setSubtitles(function (state) {
            var _a;
            return (__assign(__assign({}, state), (_a = {}, _a[index] = subtitle, _a)));
        });
    };
    // it's only here to guarantee that the data will be in cache for the form validation function below
    // eslint-disable-next-line
    var _k = useLoadReports();
    useEffect(function () {
        if (step > highestStepVisited)
            setHighestStepVisited(step);
    }, [step]);
    var form = useForm({
        validateInputOnChange: true,
        initialValues: (_e = persisted === null || persisted === void 0 ? void 0 : persisted.form) !== null && _e !== void 0 ? _e : {
            name: '',
            viewId: null,
            resourceType: 'debtors',
            periodicity: 'weekly',
            daysWeek: [],
            daysMonth: [],
            collaborators: [],
            emails: [],
        },
        validate: {
            name: function (value) {
                var _a;
                var exportTasks = (_a = client.getQueryData(['reports'])) !== null && _a !== void 0 ? _a : [];
                var exportTasksNames = exportTasks.map(function (task) { return task.name; });
                if (exportTasksNames.includes(value))
                    return 'A report by the same name already exists';
                return null;
            },
            emails: function (value) {
                var error = null;
                value.forEach(function (email) {
                    if (isEmail(true)(email))
                        error = "".concat(email, " is not a valid email address");
                });
                return error;
            },
        },
    });
    useEffect(function () {
        localStorage.setItem('persist-report-creation', JSON.stringify({
            form: form.values,
            subtitles: subtitles,
            highestStepVisited: highestStepVisited,
            step: step,
        }));
    }, [JSON.stringify(form.values), subtitles, highestStepVisited, step]);
    var handleToggleDay = function (day) {
        if (form.values.daysWeek.includes(day)) {
            form.setFieldValue('daysWeek', form.values.daysWeek.filter(function (d) { return d !== day; }));
        }
        else {
            form.setFieldValue('daysWeek', __spreadArray(__spreadArray([], __read(form.values.daysWeek), false), [day], false));
        }
    };
    var _l = useLoadViews({ resourceType: form.values.resourceType }), views = _l.views, isViewsFetching = _l.isViewsFetching;
    var viewsForSelect = (views !== null && views !== void 0 ? views : []).map(function (view) { return ({ value: view.id, label: view.name }); });
    useEffect(function () {
        var _a;
        // The extra logic below is to ensure that when we don't override persisted state when the effect runs
        if (isViewsFetching)
            return;
        if (viewsForSelect.map(function (v) { return v.value; }).includes(persisted.form.viewId))
            form.setFieldValue('viewId', persisted.form.viewId);
        else
            form.setFieldValue('viewId', (_a = viewsForSelect[0]) === null || _a === void 0 ? void 0 : _a.value);
    }, [isViewsFetching]);
    var renderMultiSelectUser = function (_a) {
        var option = _a.option, checked = _a.checked;
        var user = users === null || users === void 0 ? void 0 : users.find(function (u) { return u.email === option.value; });
        return (React.createElement(Group, { w: "100%", gap: "sm", justify: "space-between" },
            React.createElement(Group, null,
                React.createElement(Avatar, { color: "blue", src: user.profilePicture, size: 36, radius: "xl" }, user.name.initials),
                React.createElement("div", null,
                    React.createElement(Text, { size: "sm" }, user.name.full),
                    React.createElement(Text, { size: "xs", opacity: 0.5 }, user.email))),
            checked && React.createElement(IconCheck, { color: "var(--mantine-color-blue-6)", size: 24 })));
    };
    var handleCreateReport = function () {
        createReport({
            viewId: form.values.viewId,
            name: form.values.name,
            emails: __spreadArray(__spreadArray([], __read(form.values.emails), false), __read(form.values.collaborators), false),
            periodicity: {
                type: form.values.periodicity,
                days: form.values.periodicity === 'weekly'
                    ? form.values.daysWeek
                    : form.values.daysMonth.map(function (date) { return date.getDate(); }),
            },
        }, {
            onSuccess: function () {
                history.push('/reports');
                localStorage.removeItem('persist-report-creation');
            },
        });
    };
    var wasStepVisited = function (index) { return index <= highestStepVisited; };
    return (React.createElement(Stack, null,
        React.createElement(PageTitle, null,
            "New report",
            React.createElement(PageTitle.Actions, null,
                React.createElement(Button, { onClick: function () {
                        history.push('/reports');
                        localStorage.removeItem('persist-report-creation');
                    }, color: "orange", variant: "light" }, "Cancel"))),
        React.createElement(Card, { flex: 1, radius: "md", shadow: "sm" },
            React.createElement(Stepper, { active: step, onStepClick: function (newStep) {
                    var _a;
                    setStep(newStep);
                    setSubtitle(0, form.values.name);
                    if (wasStepVisited(1)) {
                        setSubtitle(1, (_a = viewsForSelect.find(function (view) { return view.value === form.values.viewId; })) === null || _a === void 0 ? void 0 : _a.label);
                    }
                    if (wasStepVisited(2))
                        setSubtitle(2, upperFirst(form.values.periodicity));
                } },
                React.createElement(Stepper.Step, { label: "Name", description: subtitles[0], allowStepSelect: true },
                    React.createElement(Center, { my: "xl" },
                        React.createElement(Stack, { miw: "33%" },
                            React.createElement(TextInput, __assign({ label: "Name", description: "Name your new report", placeholder: "Weekly sales export", w: "100%" }, form.getInputProps('name'))),
                            React.createElement(Button, { disabled: !form.values.name || form.errors.name != null, w: "100%", rightSection: React.createElement(IconArrowRight, null), onClick: function () {
                                    setSubtitle(0, form.values.name);
                                    setStep(1);
                                } }, "Next")))),
                React.createElement(Stepper.Step, { label: "Data", allowStepSelect: (highestStepVisited >= 1 && form.values.name) || form.errors.name != null, description: subtitles[1] },
                    React.createElement(Group, { my: "xl", justify: "center", align: "start" },
                        React.createElement(Stack, { w: "33%" },
                            React.createElement(Text, { size: "lg", fw: 450 }, "Resource type:"),
                            React.createElement(Paper, { p: "xl", bg: "gray.1", shadow: "none" },
                                React.createElement(SimpleGrid, { cols: 2 }, resources.map(function (resource) {
                                    var isSelected = form.values.resourceType === resource.slug;
                                    return (React.createElement(UnstyledButton, { onClick: function () { return form.setFieldValue('resourceType', resource.slug); }, style: {
                                            border: isSelected
                                                ? '2px solid var(--mantine-color-blue-4)'
                                                : '2px solid transparent',
                                        }, key: resource.slug, p: "sm", className: styles.resourceButton },
                                        resource.icon,
                                        React.createElement(Text, { mt: "xs", c: isSelected ? 'blue.6' : 'gray.8', fw: isSelected ? 500 : 400, className: styles.resourceButtonText }, resource.name)));
                                })))),
                        React.createElement(Divider, { orientation: "vertical", mx: "lg" }),
                        React.createElement(Stack, { w: "33%" },
                            React.createElement(Text, { size: "lg", fw: 450 }, "View:"),
                            React.createElement(Group, { pos: "relative" },
                                React.createElement(Select, __assign({}, form.getInputProps('viewId'), { w: "100%", disabled: isEmpty(viewsForSelect), checkIconPosition: "right", data: viewsForSelect, searchable: true, allowDeselect: false, placeholder: "Select a view to export" })),
                                isViewsFetching ? (React.createElement(Center, { style: { borderRadius: 'var(--mantine-radius-sm)' }, pos: "absolute", w: "100%", h: "100%", bg: "rgba(225, 225, 225, 0.9)" },
                                    React.createElement(Loader, { size: 25 }))) : null),
                            React.createElement(Text, { c: "dimmed" }, "Choose the resource type and view you want to export. Note that this setting cannot be modified later for this report."),
                            React.createElement(Button, { w: "100%", rightSection: React.createElement(IconArrowRight, null), onClick: function () {
                                    var _a;
                                    setSubtitle(1, (_a = viewsForSelect.find(function (view) { return view.value === form.values.viewId; })) === null || _a === void 0 ? void 0 : _a.label);
                                    setStep(2);
                                } }, "Next")))),
                React.createElement(Stepper.Step, { label: "Recurrence", allowStepSelect: highestStepVisited >= 2, description: subtitles[2] },
                    React.createElement(Center, { my: "xl" },
                        React.createElement(Stack, null,
                            React.createElement(Group, { align: "start" },
                                React.createElement(Box, { onClick: form.values.periodicity === 'monthly'
                                        ? function () {
                                            form.setFieldValue('periodicity', 'weekly');
                                            form.setFieldValue('daysMonth', []);
                                        }
                                        : function () { } },
                                    React.createElement(Radio, { readOnly: true, styles: { body: { alignItems: 'center' } }, checked: form.values.periodicity === 'weekly', label: React.createElement(Text, { fw: 500, size: "lg" }, "Weekly, on the following days:") }),
                                    React.createElement(Box, { ml: 34, style: {
                                            opacity: form.values.periodicity === 'monthly' ? 0.25 : 1,
                                            pointerEvents: form.values.periodicity === 'monthly' ? 'none' : 'auto',
                                        } }, daysInWeek.map(function (day, index) { return (React.createElement(UnstyledButton, { key: day, onClick: function () { return handleToggleDay(index + 1); }, className: styles.dayOfWeekCheckbox },
                                        React.createElement(Checkbox, { readOnly: true, mr: "md", checked: form.values.daysWeek.includes(index + 1) }),
                                        React.createElement(Text, null, day))); }))),
                                React.createElement(Divider, { orientation: "vertical", mx: "lg" }),
                                React.createElement(Box, { onClick: form.values.periodicity === 'weekly'
                                        ? function () {
                                            form.setFieldValue('periodicity', 'monthly');
                                            form.setFieldValue('daysWeek', []);
                                        }
                                        : function () { } },
                                    React.createElement(Radio, { readOnly: true, onClick: function () { return form.setFieldValue('periodicity', 'monthly'); }, styles: { body: { alignItems: 'center' } }, checked: form.values.periodicity === 'monthly', label: React.createElement(Text, { fw: 500, size: "lg" }, "Monthly, on the following days:") }),
                                    React.createElement("div", { style: { opacity: form.values.periodicity === 'weekly' ? 0.25 : 1 } },
                                        React.createElement(Paper, { p: "md", ml: 34, mt: "sm", bg: "gray.0", shadow: "none", w: "fit-content" },
                                            React.createElement(DatePicker, { style: {
                                                    pointerEvents: form.values.periodicity === 'weekly' ? 'none' : 'auto',
                                                }, value: form.values.daysMonth, className: styles.calendar, styles: {
                                                    calendarHeader: { display: 'none' },
                                                }, defaultDate: new Date('01-01-2024'), hideOutsideDates: true, hideWeekdays: true, type: "multiple", onChange: function (val) { return form.setFieldValue('daysMonth', val); } })),
                                        React.createElement(Text, { w: 300, ml: 34, mt: "xs", c: "dimmed", size: "xs" },
                                            "Reports set for a later date than the last day of the month will all be sent on the last day.",
                                            React.createElement("br", null),
                                            React.createElement("br", null),
                                            "If you wish to always send a report on the last day of the month, select the 31st.")))),
                            React.createElement(Button, { disabled: isEmpty(form.values.daysMonth) && isEmpty(form.values.daysWeek), w: "100%", rightSection: React.createElement(IconArrowRight, null), onClick: function () {
                                    setSubtitle(2, upperFirst(form.values.periodicity));
                                    setStep(3);
                                } }, "Next")))),
                React.createElement(Stepper.Step, { label: "Recipients", allowStepSelect: highestStepVisited >= 3 &&
                        !(isEmpty(form.values.daysMonth) && isEmpty(form.values.daysWeek)) },
                    React.createElement(Center, { my: "xl" },
                        React.createElement(Stack, { w: "33%", gap: "lg" },
                            React.createElement(Text, { size: "lg", fw: 500 }, "Send the report"),
                            React.createElement(MultiSelect, __assign({ searchable: true }, form.getInputProps('collaborators'), { renderOption: renderMultiSelectUser, styles: {
                                    label: { fontWeight: 400, marginBottom: '5px' },
                                }, placeholder: isEmpty(form.values.collaborators) ? 'Choose a collaborator' : undefined, w: "100%", label: "... to these collaborators:", data: users === null || users === void 0 ? void 0 : users.map(function (user) { return ({ value: user.email, label: user.name.full }); }) })),
                            React.createElement("div", null,
                                React.createElement(TagsInput, __assign({}, form.getInputProps('emails'), { styles: {
                                        label: { fontWeight: 400, marginBottom: '5px' },
                                    }, placeholder: isEmpty(form.values.emails) ? 'Add an email address' : undefined, w: "100%", label: "... and these external email addresses:" })),
                                React.createElement(Text, { c: "dimmed", size: "sm", mt: 4 }, "Press enter to submit"),
                                React.createElement(Button, { loading: isCreateReportLoading, disabled: (isEmpty(form.values.collaborators) && isEmpty(form.values.emails)) ||
                                        form.errors.emails != null, w: "100%", mt: "xl", rightSection: React.createElement(IconReport, { stroke: 1.5 }), onClick: handleCreateReport }, "Create report")))))))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
