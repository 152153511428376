import { __assign, __awaiter, __generator, __read, __values } from "tslib";
import React, { createRef, Fragment, useEffect, useMemo, useReducer, useState, } from 'react';
import { PlanType, StepType } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import AYSModal from 'shared/components/AYSModal';
import NavigationPrompt from 'shared/components/BlockNavigation';
import { Icon, IconName } from 'shared/components/Icon';
import Tabs from 'shared/components/Tabs';
import { Button, ButtonColor, Checkbox, CustomSelect, Dropdown, Input } from 'shared/io';
import { treatTemplate } from 'shared/serializer';
import { reducerState } from 'shared/utils/view';
import { settingsActions } from 'store/settings/settings.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog, sideMenuHide, } from 'store/view/view.actions';
import SunEditor from 'suneditor-react';
import { styled } from '@stitches/react';
import SelectStatementType from './SelectStatementType';
import Swiper from './Swiper';
import TemplateSendingOptions from './TemplateSendingOptions';
import '!style-loader!css-loader!suneditor/src/assets/css/suneditor-contents.css'; // Import Sun Editor's CSS File
import '!style-loader!css-loader!suneditor/src/assets/css/suneditor.css'; // Import Sun Editor's CSS File
import styleIdentifiers from './TemplateEditor.scss';
var styles = classNames.bind(styleIdentifiers);
var VariablesTitle = styled('div', {
    margin: '20px 0px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    justifyContent: 'center',
    '& span': { display: 'none' },
    pointerEvents: 'none',
    transition: 'all .2s ease-in-out;',
    '&:hover': {
        gap: '20px;',
    },
    variants: {
        isClickable: {
            true: {
                '& span': { display: 'unset' },
                cursor: 'pointer',
                pointerEvents: 'unset',
            },
        },
    },
});
var VariablesList = styled('div', {
    '& >div': {
        borderTop: '1px solid #e8eaee',
        padding: '10px',
        fontSize: '0.875rem',
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },
        '&:last-of-type': {
            borderBottom: '1px solid #e8eaee',
        },
    },
});
var setOptions = {
    resizingBar: false,
    attributesWhitelist: { all: 'style' },
    buttonList: [
        [
            'undo',
            'redo',
            'font',
            'fontSize',
            'formatBlock',
            'paragraphStyle',
            'blockquote',
            'bold',
            'underline',
            'italic',
            'strike',
            'subscript',
            'superscript',
            'fontColor',
            'hiliteColor',
            'removeFormat',
            'outdent',
            'indent',
            'align',
            'horizontalRule',
            'list',
            'lineHeight',
            'table',
            'link',
            'image',
            'showBlocks',
            'codeView',
        ],
    ],
};
export default function TemplateEditor() {
    var _this = this;
    var t = useTranslation().t;
    var _a = useSelector(function (state) { return state.app.constants; }), template_grouped_types = _a.template_grouped_types, recovery_plan_template_variables = _a.recovery_plan_template_variables, payment_plan_template_variables = _a.payment_plan_template_variables, available_languages = _a.available_languages, payment_step_types = _a.payment_step_types, recovery_step_types = _a.recovery_step_types;
    var company = useSelector(function (state) { return state.account.company.data; });
    var availableCustomVariables = useSelector(function (state) { var _a, _b; return (_b = (_a = state.account.company) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.custom_variables_attributes; });
    var _b = __read(useReducer(reducerState, {
        email: { fr: {}, nl: {}, en: {} },
        post: { fr: {}, nl: {}, en: {} },
        grouped_type: '',
        template: null,
        canSet: company.package.can_edit_template,
        editedText: null,
        activeSendingMedium: 'email',
        lang: 0,
        isDirty: false,
        sendingMediumAvailables: ['email'],
    }), 2), state = _b[0], setState = _b[1];
    var _c = __read(useState(), 2), templateVariableGroup = _c[0], setTemplateVariablesGroup = _c[1];
    var form = useForm({ shouldUnregister: true });
    var register = form.register, reset = form.reset, trigger = form.trigger, getValues = form.getValues, dirtyFields = form.formState.dirtyFields, watch = form.watch;
    var _d = __read(useState([]), 2), elRefs = _d[0], setElRefs = _d[1];
    var params = useRouteMatch().params;
    var history = useHistory();
    var isDirty = !!Object.keys(dirtyFields).length;
    useEffect(function () {
        if (available_languages) {
            settingsActions.getTemplate({
                id: params.id,
                callback: function (_a) {
                    var e_1, _b, e_2, _c;
                    var data = _a.data;
                    var treatedTemplate = treatTemplate(data);
                    var editedTemplates = {};
                    var sendingMediumAvailables = ['email'];
                    if (treatedTemplate.letter_template) {
                        sendingMediumAvailables.push('post');
                    }
                    try {
                        for (var sendingMediumAvailables_1 = __values(sendingMediumAvailables), sendingMediumAvailables_1_1 = sendingMediumAvailables_1.next(); !sendingMediumAvailables_1_1.done; sendingMediumAvailables_1_1 = sendingMediumAvailables_1.next()) {
                            var sendingMedium = sendingMediumAvailables_1_1.value;
                            editedTemplates[sendingMedium] = {};
                            var _loop_1 = function (lang_1) {
                                editedTemplates[sendingMedium][lang_1.value] = treatedTemplate[sendingMedium === 'email' ? 'email_template' : 'letter_template'].find(function (templateBody) { return templateBody.locale === lang_1.value; });
                            };
                            try {
                                for (var available_languages_1 = (e_2 = void 0, __values(available_languages)), available_languages_1_1 = available_languages_1.next(); !available_languages_1_1.done; available_languages_1_1 = available_languages_1.next()) {
                                    var lang_1 = available_languages_1_1.value;
                                    _loop_1(lang_1);
                                }
                            }
                            catch (e_2_1) { e_2 = { error: e_2_1 }; }
                            finally {
                                try {
                                    if (available_languages_1_1 && !available_languages_1_1.done && (_c = available_languages_1.return)) _c.call(available_languages_1);
                                }
                                finally { if (e_2) throw e_2.error; }
                            }
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (sendingMediumAvailables_1_1 && !sendingMediumAvailables_1_1.done && (_b = sendingMediumAvailables_1.return)) _b.call(sendingMediumAvailables_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                    editedTemplates.grouped_type = editedTemplates.email.fr.grouped_type;
                    setState(__assign(__assign({}, editedTemplates), { template: treatedTemplate, sendingMediumAvailables: sendingMediumAvailables }));
                    reset(__assign({}, treatedTemplate));
                },
            });
            setElRefs(function (elRefs) {
                return Array(available_languages.length * 2)
                    .fill(null)
                    .map(function (_, i) { return elRefs[i] || createRef(); });
            });
        }
    }, [available_languages]);
    var templateVariables = useMemo(function () {
        if (state.template) {
            var variables = state.template.plan_type === PlanType.payment
                ? payment_plan_template_variables
                : recovery_plan_template_variables;
            var tempVariables = variables[state.template.step_type].slice();
            if (state.activeSendingMedium === 'post') {
                tempVariables.splice(tempVariables.findIndex(function (group) { return group.value === 'email'; }), 1);
            }
            if (!state.grouped_type && state.template.plan_type !== PlanType.payment) {
                tempVariables.splice(tempVariables.findIndex(function (group) { return group.value === 'grouped'; }), 1);
            }
            if (availableCustomVariables.length > 0) {
                tempVariables.push({
                    description: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.LONG_TITLE),
                    value: 'custom_variables',
                    variables: availableCustomVariables.map(function (cusVar) { return ({
                        description: cusVar.name,
                        value: "".concat(cusVar.model_type, "_").concat(cusVar.column_name),
                    }); }),
                });
            }
            return tempVariables;
        }
        return [];
    }, [state.activeSendingMedium, state.grouped_type, state.template]);
    useEffect(function () {
        if (state.editedText) {
            setContent(state.editedText.lang, state.editedText.body, state.editedText.sendingMedium);
        }
    }, [state.editedText]);
    var duplicate = function () {
        settingsActions.duplicateTemplate({
            id: params.id,
            callback: function (_a) {
                var data = _a.data;
                history.push("/settings/document-customization/edit/".concat(data.id));
                sideMenuHide();
            },
        });
    };
    var updateTemplate = function (callback) { return __awaiter(_this, void 0, void 0, function () {
        var isValid, formValues_1, serializeTemplateBodies_1, template_bodies_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, trigger()];
                case 1:
                    isValid = _a.sent();
                    if (isValid) {
                        formValues_1 = getValues();
                        serializeTemplateBodies_1 = function (templateBodies, sendingMedium) {
                            return templateBodies.map(function (value) {
                                var templateBody = __assign({}, value);
                                if (state.activeSendingMedium !== sendingMedium) {
                                    delete templateBody.body;
                                }
                                templateBody.grouped_type = state.grouped_type || null;
                                return templateBody;
                            });
                        };
                        template_bodies_1 = [];
                        state.sendingMediumAvailables.forEach(function (sm) {
                            template_bodies_1 = template_bodies_1.concat(serializeTemplateBodies_1(Object.values(state[sm]), sm));
                        });
                        settingsActions.setTemplate({
                            id: state.template.id,
                            data: __assign(__assign({}, formValues_1), { id: state.template.id, step_type: formValues_1.step_type, template_bodies: template_bodies_1 }),
                            callback: function () {
                                var _a, e_3, _b;
                                sideMenuHide();
                                callback && callback();
                                var editedTemplates = (_a = {}, _a[state.activeSendingMedium] = {}, _a);
                                try {
                                    for (var available_languages_2 = __values(available_languages), available_languages_2_1 = available_languages_2.next(); !available_languages_2_1.done; available_languages_2_1 = available_languages_2.next()) {
                                        var lang_2 = available_languages_2_1.value;
                                        editedTemplates[state.activeSendingMedium][lang_2.value] = __assign(__assign({}, state[state.activeSendingMedium][lang_2.value]), { changed: false });
                                    }
                                }
                                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                                finally {
                                    try {
                                        if (available_languages_2_1 && !available_languages_2_1.done && (_b = available_languages_2.return)) _b.call(available_languages_2);
                                    }
                                    finally { if (e_3) throw e_3.error; }
                                }
                                reset(formValues_1);
                                setState(__assign(__assign({}, editedTemplates), { isDirty: false }));
                            },
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var save = function (saveAndQuit) { return function (callback) {
        var e_4, _a, e_5, _b;
        var _c;
        var variables = state.template.plan_type === PlanType.payment
            ? payment_plan_template_variables
            : recovery_plan_template_variables;
        var warning = state.template.step_type === 'credit_note'
            ? variables.credit_note_warning
            : state.grouped_type || state.template.step_type === 'formal_notice'
                ? variables.grouped_presence_warning
                : variables.presence_warning;
        var missingKeys = { fr: [], nl: [], en: [] };
        if (warning) {
            try {
                for (var warning_1 = __values(warning), warning_1_1 = warning_1.next(); !warning_1_1.done; warning_1_1 = warning_1.next()) {
                    var requiredKey = warning_1_1.value;
                    if (!(state.activeSendingMedium === 'post' && requiredKey.value === 'debtor_portal_link')) {
                        try {
                            for (var available_languages_3 = (e_5 = void 0, __values(available_languages)), available_languages_3_1 = available_languages_3.next(); !available_languages_3_1.done; available_languages_3_1 = available_languages_3.next()) {
                                var value = available_languages_3_1.value.value;
                                var element = document.createElement('div');
                                element.innerHTML = state[state.activeSendingMedium][value].body;
                                if (element.innerText.indexOf("{{".concat(requiredKey.value, "}}")) === -1) {
                                    missingKeys[value].push(requiredKey);
                                }
                                element.remove();
                            }
                        }
                        catch (e_5_1) { e_5 = { error: e_5_1 }; }
                        finally {
                            try {
                                if (available_languages_3_1 && !available_languages_3_1.done && (_b = available_languages_3.return)) _b.call(available_languages_3);
                            }
                            finally { if (e_5) throw e_5.error; }
                        }
                    }
                }
            }
            catch (e_4_1) { e_4 = { error: e_4_1 }; }
            finally {
                try {
                    if (warning_1_1 && !warning_1_1.done && (_a = warning_1.return)) _a.call(warning_1);
                }
                finally { if (e_4) throw e_4.error; }
            }
        }
        if (available_languages.reduce(function (acc, lang) { return acc + missingKeys[lang.value].length; }, 0) > 0 &&
            ((_c = state.template) === null || _c === void 0 ? void 0 : _c.plan_type) !== PlanType.payment) {
            showDialog({
                id: DialogShowId.CONFIRM,
                size: DialogShowSize.MEDIUM,
                title: t(i18nKeys.ATTENTION),
                keepMountOnExit: true,
                children: (React.createElement(AYSModal, { text: React.createElement("div", { className: styles('ays-modal') },
                        React.createElement("p", null,
                            t(i18nKeys.MISSING_VAR),
                            " :"),
                        available_languages.map(function (av_lang) {
                            return missingKeys[av_lang.value].length > 0 && (React.createElement(Fragment, { key: av_lang.value },
                                React.createElement("div", { className: styles('lang') },
                                    av_lang.description,
                                    " : "),
                                React.createElement("div", { className: styles('missing-var') }, missingKeys[av_lang.value].map(function (key) { return (React.createElement("div", { key: key.value }, key.description)); }))));
                        })), confirmButtonText: t(i18nKeys.CONTINUE_ANYWAY), onConfirm: function () {
                        updateTemplate(saveAndQuit && callback);
                    } })),
            });
        }
        else {
            updateTemplate(saveAndQuit && callback);
        }
    }; };
    var setContent = function (lang, text, sendingMedium) {
        var _a, _b;
        if (state[sendingMedium][lang].body !== text) {
            setState((_a = {
                    isDirty: true
                },
                _a[sendingMedium] = __assign(__assign({}, state[sendingMedium]), (_b = {}, _b[lang] = __assign(__assign({}, state[sendingMedium][lang]), { body: text, changed: true }), _b)),
                _a));
        }
    };
    var onChangeSendingMedium = function (index) {
        setState({ activeSendingMedium: index === 0 ? 'email' : 'post' });
    };
    var previewTemplate = function () {
        var formValues = getValues();
        settingsActions.previewTemplate({
            id: state.template.id,
            download: state.activeSendingMedium === 'post',
            data: __assign(__assign({}, state[state.activeSendingMedium][available_languages[state.lang].value]), { grouped_type: state.grouped_type || undefined, template_type: state.activeSendingMedium, step_type: state.template.step_type, include_pdf: formValues.include_pdf, include_ubl: formValues.include_ubl }),
            callback: sideMenuHide,
        });
    };
    var refIndex = useMemo(function () { return state.lang + (state.activeSendingMedium === 'post' ? 3 : 0); }, [state.lang, state.activeSendingMedium]);
    var addText = function (text) { return function () {
        var suneditorElement = document.querySelectorAll('.sun-editor .se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable')[refIndex];
        var scrollEl = document.querySelectorAll('div[class="se-wrapper-inner se-wrapper-wysiwyg sun-editor-editable"]')[refIndex];
        var scrollTop = scrollEl.scrollTop;
        suneditorElement.focus();
        scrollEl.scrollTo(0, scrollTop);
        elRefs[refIndex].current.insertHTML(text, true, true, true);
    }; };
    var getSunEditorInstance = function (lg, activeSendingMedium) { return function (sunEditor) {
        elRefs[lg + (activeSendingMedium === 'post' ? 3 : 0)].current = sunEditor;
    }; };
    var onTextChange = function (lg, sendingMedium) { return function (body) {
        setState({
            editedText: { lang: lg, body: body, sendingMedium: sendingMedium },
        });
    }; };
    var onChangeLang = function (lang) {
        setState({
            lang: lang,
        });
    };
    var onBlur = function (e) {
        e.preventDefault();
        elRefs[refIndex].current.core.getRange();
    };
    var setSendingOptions = function () {
        var lang = available_languages[state.lang];
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            title: "".concat(t(i18nKeys.INVOICE.SENDING_OPTIONS), " (").concat(lang.description, ")"),
            children: (React.createElement(TemplateSendingOptions, { state: state.email, lang: lang, setState: setState, defaultFrom: company.default_from, customDomain: company.custom_domain })),
        });
    };
    var editorContainer = function (sendingMedium) { return (React.createElement("div", { className: styles('template-container', sendingMedium, state.activeSendingMedium !== sendingMedium && 'hidden') }, state.template && (React.createElement(React.Fragment, null,
        React.createElement(Tabs, { className: styles('lang-tabs'), disableScroll: true, noRounded: true, tabIndex: state.lang, onChange: onChangeLang, items: available_languages.map(function (language) { return (React.createElement("div", { key: language.value, className: styles('tab-item') },
                React.createElement("div", null),
                " ",
                React.createElement("div", null, language.description),
                state[sendingMedium][language.value].changed && (React.createElement("div", { className: styles('edit-circle') })))); }) }, []),
        React.createElement("div", { className: styles('body-template-container') },
            React.createElement("div", { style: { overflow: 'hidden' } },
                React.createElement(Swiper, { activeTab: state.lang }, available_languages.map(function (language, lgIndex) { return (React.createElement("div", { key: language.value, className: styles('template-item') },
                    React.createElement(SunEditor, { getSunEditorInstance: getSunEditorInstance(lgIndex, sendingMedium), setContents: state[sendingMedium][language.value].body, onChange: onTextChange(language.value, sendingMedium), onBlur: onBlur, lang: language.value, disable: !state.canSet, setOptions: __assign(__assign({}, setOptions), { defaultStyle: 'font-family: Verdana' }) }))); }))),
            state.canSet && (React.createElement("div", { className: styles('variable-container') },
                React.createElement("div", { style: { padding: '0px 20px' } },
                    sendingMedium === 'email' && templateVariableGroup == null && (React.createElement(Button, { label: t(i18nKeys.INVOICE.SENDING_OPTIONS), onClick: setSendingOptions })),
                    React.createElement(VariablesTitle, { isClickable: templateVariableGroup != null, onClick: function () { return setTemplateVariablesGroup(undefined); } },
                        React.createElement(Icon, { size: "20px", name: IconName.CIRCLE_LEFT }),
                        React.createElement("h3", null, t(i18nKeys.VARIABLES)))),
                templateVariableGroup == null ? (React.createElement("div", { style: { padding: '0px 20px' } }, templateVariables.map(function (group) { return (React.createElement(Button, { key: group.description, style: { marginBottom: '10px' }, label: group.description, noMargin: true, iconRight: IconName.MINIMAL_RIGHT, color: ButtonColor.WHITE, onClick: function () { return setTemplateVariablesGroup(group.variables); } })); }))) : (React.createElement(VariablesList, null, templateVariableGroup.map(function (variable) { return (React.createElement("div", { key: variable.description, onClick: addText("{{".concat(variable.value, "}}")) },
                    React.createElement("div", { className: styles('description') }, variable.description),
                    React.createElement("div", { className: styles('value') },
                        '{{',
                        variable.value,
                        '}}'))); })))))))))); };
    var showStatements = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.FULL,
            keepMountOnExit: true,
            title: t(i18nKeys.TEMPLATE.WHICH_TO_INCLUDE),
            children: (React.createElement(SelectStatementType, { onSelectAction: function (grouped_type) {
                    var _a;
                    var newState = { grouped_type: grouped_type };
                    if (newState.grouped_type !== (((_a = state.template) === null || _a === void 0 ? void 0 : _a.email_template[0].grouped_type) || '')) {
                        newState.isDirty = true;
                    }
                    setState(newState);
                    dialogHide(DialogShowId.CUSTOM);
                }, initialValue: state.template.email_template[0].grouped_type })),
        });
    };
    var typeItems = (state.template && state.template.plan_type === PlanType.payment
        ? payment_step_types.template_types
        : recovery_step_types.template_types).filter(function (el) { return el.value !== StepType.task_generation; });
    return (React.createElement("div", { className: styles('settings') },
        React.createElement("div", { className: styles('template-editor') },
            React.createElement(NavigationPrompt, { when: isDirty || state.isDirty }),
            React.createElement("div", { className: styles('head') },
                React.createElement("h1", null, t(i18nKeys.TEMPLATE_EDITION)),
                React.createElement("div", { className: styles('actions') },
                    React.createElement(Dropdown, { className: styles('dropdown'), sideMenuInMobile: true, sideMenuTitle: t(i18nKeys.FORM.ACTIONS), selectorContent: React.createElement(Button, { noMargin: true, iconRight: IconName.ARROW_BOTTOM_ROUNDED, label: t(i18nKeys.FORM.ACTIONS) }) },
                        React.createElement("div", { className: styles('dropdown-item'), onClick: previewTemplate }, t(i18nKeys.SHOW_TEMPLATE)),
                        React.createElement("div", { className: styles('dropdown-item'), onClick: duplicate }, t(i18nKeys.DUPLICATE)),
                        React.createElement("div", { className: styles('dropdown-item'), onClick: save(false) }, t(i18nKeys.SAVE))),
                    React.createElement(Button, { noMargin: true, label: t(i18nKeys.SHOW_TEMPLATE), onClick: previewTemplate, color: ButtonColor.GREY }),
                    React.createElement(Button, { noMargin: true, label: t(i18nKeys.DUPLICATE), onClick: duplicate }),
                    React.createElement(Button, { onClick: save(false), disabled: !state.canSet, noMargin: true, color: ButtonColor.MAIN, label: t(i18nKeys.SAVE) }))),
            React.createElement("div", { className: styles('template-name') },
                React.createElement("div", { className: styles('input-wrapper') },
                    React.createElement(Input, { register: register('name', { required: true }), readOnly: !state.canSet, noMargin: true, withBorder: true, label: t(i18nKeys.TEMPLATE_NAME) }),
                    React.createElement(FormProvider, __assign({}, form),
                        React.createElement(Controller, { defaultValue: "", name: "step_type", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { name: "step_type", size: "small", items: typeItems, keyText: "description", keyValue: "value", withBorder: true, noMargin: true, label: t(i18nKeys.FORM.TYPE) })); } }))),
                state.template &&
                    state.template.plan_type !== PlanType.payment &&
                    (state.template.step_type === 'invoice' ||
                        state.template.step_type === 'credit_note' ? (React.createElement("div", { className: styles('attachement-container') },
                        React.createElement("div", { className: styles('grouped-type', 'with-border', 'no-margin') },
                            React.createElement(Checkbox, { register: register('include_pdf'), noMargin: true, label: t(i18nKeys.TEMPLATE.ATTACH_FILE, { type: 'PDF' }), watch: watch })),
                        React.createElement("div", { className: styles('grouped-type', 'with-border', 'no-margin') },
                            React.createElement(Checkbox, { register: register('include_ubl'), noMargin: true, label: t(i18nKeys.TEMPLATE.ATTACH_FILE, { type: 'UBL' }), watch: watch })))) : (state.template.step_type !== 'formal_notice' &&
                        state.template.step_type !== 'additional_reminder' &&
                        state.template.step_type !== 'preventive_reminder' &&
                        (state.grouped_type ? (React.createElement("div", { className: styles('grouped-type', 'with-border', 'no-margin') },
                            React.createElement("div", { className: styles('label-input') },
                                " ",
                                t(i18nKeys.STATEMENT)),
                            React.createElement("div", { className: styles('grouped-type-value') },
                                template_grouped_types.find(function (item) { return item.value === state.grouped_type; })
                                    .description,
                                ' ',
                                React.createElement(Icon, { name: IconName.PENCIL, onClick: showStatements })))) : (React.createElement(Button, { label: t(i18nKeys.ADD_STATEMENT), noMargin: true, onClick: showStatements })))))),
            React.createElement(Tabs, { noRounded: true, className: styles('sending-medium'), items: state.sendingMediumAvailables.map(function (sm) { return (React.createElement("div", { className: styles('tab-item') },
                    React.createElement("div", null),
                    " ",
                    React.createElement("div", null, t(i18nKeys[sm.toUpperCase()])),
                    (state[sm].en.changed || state[sm].nl.changed || state[sm].fr.changed) && (React.createElement("div", { className: styles('edit-circle') })))); }), onChange: onChangeSendingMedium, tabIndex: state.sendingMediumAvailables.indexOf(state.activeSendingMedium) }, []),
            editorContainer('email'),
            editorContainer('post'))));
}
