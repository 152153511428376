import { __read } from "tslib";
import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Document, Page } from 'react-pdf';
import { useSelector } from 'react-redux';
import { IconName } from 'shared/components/Icon';
import { Button, ButtonColor, UploadZone } from 'shared/io';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import styleIdentifiers from './ImportOriginalInvoice.scss';
var styles = classNames.bind(styleIdentifiers);
var Viewer = function (_a) {
    var url = _a.url;
    var _b = __read(useState(0), 2), pdfPagesAmount = _b[0], setPdfPagesAmount = _b[1];
    return (React.createElement(Document, { file: url, onLoadSuccess: function (args) {
            setPdfPagesAmount(args._pdfInfo.numPages);
        } }, Array(pdfPagesAmount)
        .fill(null)
        .map(function (_, index) { return (React.createElement(Page, { key: index, className: styles('pdf-pages'), width: 1100, pageIndex: index })); })));
};
export default function ImportOriginalInvoice(_a) {
    var onEditOriginalFile = _a.onEditOriginalFile, invoice = _a.invoice;
    var t = useTranslation().t;
    var isLoadingInvoice = useSelector(function (state) { return state.invoice.isLoading; });
    var isLoadingCreditNote = useSelector(function (state) { return state.creditNote.isLoading; });
    var _b = __read(useState(!invoice.has_pdf), 2), editMode = _b[0], _setEditMode = _b[1];
    var _c = __read(useState(), 2), pdfFile = _c[0], setPdfFile = _c[1];
    var _d = __read(useState(''), 2), error = _d[0], setError = _d[1];
    var onAddFile = function (file) {
        setError('');
        setPdfFile(file);
    };
    var downloadPdf = function () {
        invoiceActions.document({
            id: invoice.id,
            type: 'invoice_pdf',
        });
    };
    var viewPdf = function () {
        invoiceActions.document({
            id: invoice.id,
            type: 'invoice_pdf',
            callback: function (data) {
                var url = URL.createObjectURL(data);
                showDialog({
                    id: DialogShowId.CUSTOM,
                    size: DialogShowSize.LARGE,
                    title: invoice.reference,
                    children: React.createElement(Viewer, { url: url }),
                });
            },
        });
    };
    var setEditMode = function (_editMode) { return function () {
        _setEditMode(_editMode);
    }; };
    var submit = function () {
        if (pdfFile) {
            (invoice.type === 'credit_note' ? creditNoteActions : invoiceActions).updateOriginalFile({
                id: invoice.id,
                data: {
                    file: pdfFile,
                },
                callback: function () {
                    onEditOriginalFile === null || onEditOriginalFile === void 0 ? void 0 : onEditOriginalFile();
                },
            });
        }
        else {
            setError('ERROR.FIELD_REQUIRED');
        }
    };
    return (React.createElement("form", { className: styles('import-original-invoice') }, editMode ? (React.createElement("div", null,
        React.createElement("div", { className: styles('content') },
            React.createElement(UploadZone, { noMargin: true, accept: ".pdf", info: t(i18nKeys.INVOICING.IMPORT.ORIGINAL_FILE), subinfo: t(i18nKeys.FORM.FILE_FORMAT_PDF), noPreview: true, onChange: onAddFile, value: pdfFile, showError: true, error: error })),
        React.createElement("div", { className: styles('button-wrapper') },
            invoice.has_pdf && (React.createElement(Button, { color: ButtonColor.GREY, iconLeft: IconName.MINIMAL_LEFT, label: t(i18nKeys.BACK), onClick: setEditMode(false) })),
            React.createElement(Button, { label: t(i18nKeys.IMPORT), onClick: submit, isLoading: isLoadingCreditNote || isLoadingInvoice })))) : (React.createElement("div", { className: styles('button-container') },
        React.createElement(Button, { color: ButtonColor.GREEN, iconLeft: IconName.PDF, onClick: downloadPdf, label: t(i18nKeys.INVOICE.DOWNLOAD_PDF) }),
        React.createElement(Button, { color: ButtonColor.BLUE, iconLeft: IconName.EYE, onClick: viewPdf, label: t(i18nKeys.INVOICE.VIEW_PDF) }),
        invoice.actions.indexOf('update_pdf') !== -1 && (React.createElement(Button, { onClick: setEditMode(true), label: t(i18nKeys.INVOICE.EDIT_ORGINAL_FILE) }))))));
}
