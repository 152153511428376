import { __assign, __generator } from "tslib";
import { push } from 'connected-react-router';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getQueryParams } from 'shared/utils/url';
import { accountActions } from 'store/account/account.actions';
import { api } from 'store/apis';
import { loginRes, logoutRes, sessionConstants as events } from 'store/session/session.actions';
import { dialogHide, DialogShowId, sideMenuHide } from 'store/view/view.actions';
import { apiCall, sendApi } from '../sagas';
function Login(action) {
    var request, redirectUri, AndIndex, queryParams;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, apiCall({
                    api: api.session.login,
                    noLoading: true,
                    data: action.payload,
                    actionRes: accountActions.signupInfoRes,
                })];
            case 1:
                request = _a.sent();
                if (!((request === null || request === void 0 ? void 0 : request.status) === 200)) return [3 /*break*/, 4];
                return [4 /*yield*/, loginRes(request.headers, 'fulfilled')];
            case 2:
                _a.sent();
                redirectUri = getQueryParams('redirectUri');
                if (!redirectUri) return [3 /*break*/, 4];
                AndIndex = window.location.search.indexOf('&');
                queryParams = AndIndex !== -1 && window.location.search.substring(AndIndex + 1);
                return [4 /*yield*/, put(push("/".concat(redirectUri).concat(queryParams ? "?".concat(queryParams) : '')))];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [2 /*return*/];
        }
    });
}
function Logout(action) {
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!((_a = action.payload) === null || _a === void 0 ? void 0 : _a.api)) return [3 /*break*/, 2];
                return [4 /*yield*/, apiCall({
                        api: api.session.logout,
                        noFeedback: true,
                    })];
            case 1:
                _b.sent();
                _b.label = 2;
            case 2:
                sideMenuHide();
                dialogHide(DialogShowId.INVOICE);
                dialogHide(DialogShowId.CUSTOM);
                dialogHide(DialogShowId.CONFIRM);
                return [4 /*yield*/, put(push('/dashboard'))];
            case 3:
                _b.sent();
                logoutRes();
                return [2 /*return*/];
        }
    });
}
function ResetPassword(action) {
    var data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                data = action.payload;
                data.redirect_url = 'https://recovr.be';
                return [4 /*yield*/, apiCall({
                        api: api.session.resetPassword,
                        data: data,
                    })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function SessionWatchers() {
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = all;
                return [4 /*yield*/, takeLatest(events.login.request, Login)];
            case 1:
                _b = [
                    _c.sent()
                ];
                return [4 /*yield*/, takeLatest(events.logout.request, Logout)];
            case 2:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.resetPassword.request, ResetPassword)];
            case 3:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.editPassword.request, sendApi(api.session.editPassword, undefined, function (payload) { return (__assign(__assign({}, payload), { allOrigin: true })); }))];
            case 4:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getTokenToResetPassword.request, sendApi(api.session.getTokenToResetPassword))];
            case 5: return [4 /*yield*/, _a.apply(void 0, [_b.concat([
                        _c.sent()
                    ])])];
            case 6:
                _c.sent();
                return [2 /*return*/];
        }
    });
}
export default function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(SessionWatchers)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
