import { ObjectType, } from 'api/models';
import classNames from 'classnames/bind';
import { useTranslation } from 'locales';
import React from 'react';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import { formattedDate } from 'shared/utils/view';
import styleIdentifiers from './ClientDetailTabsBalance.scss';
import { ClientDetailTabsBalanceItemDetail } from './ClientDetailTabsBalanceItemDetail';
import { ClientDetailTabsBalanceItemDetailDates } from './ClientDetailTabsBalanceItemDetailDates';
var styles = classNames.bind(styleIdentifiers);
export var ClientDetailTabsBalanceItem = function (_a) {
    var type = _a.type, reference = _a.reference, issueDate = _a.issueDate, dueDate = _a.dueDate, label = _a.label, _b = _a.hasLateFees, hasLateFees = _b === void 0 ? false : _b, _c = _a.isOpen, isOpen = _c === void 0 ? false : _c, toggleDetail = _a.toggleDetail, _d = _a.linkedCreditNotes, linkedCreditNotes = _d === void 0 ? [] : _d, _e = _a.payments, payments = _e === void 0 ? [] : _e, _f = _a.matchedPayments, matchedPayments = _f === void 0 ? [] : _f, children = _a.children, status = _a.status, openInvoice = _a.openInvoice, _g = _a.settlements, settlements = _g === void 0 ? [] : _g, _h = _a.linkedInvoices, linkedInvoices = _h === void 0 ? [] : _h;
    var currentLang = useTranslation().currentLang;
    var isInvoice = type === ObjectType.invoice;
    var isCreditNote = type === ObjectType.credit_note;
    return (React.createElement("tr", null,
        React.createElement("td", { className: styles('item-tag') },
            React.createElement("span", { className: styles('label', isCreditNote && 'red', isInvoice && 'blue') }, label)),
        React.createElement("td", { className: styles('reference', isInvoice && 'invoice-reference'), onClick: toggleDetail },
            React.createElement("div", { className: styles('summary') },
                (isInvoice || isCreditNote) && (React.createElement(Icon, { name: IconName[isOpen ? 'MINUS' : 'PLUS'], className: styles('arrow'), size: "10px" })),
                React.createElement("p", { className: styles('reference-text') }, reference),
                openInvoice && (React.createElement(Icon, { name: IconName.EXTERNAL_LINK, className: styles('arrow'), size: "15px", color: IconColor.BLUE, onClick: openInvoice }))),
            (isInvoice || isCreditNote) && isOpen && (React.createElement(ClientDetailTabsBalanceItemDetail, { type: type, hasLateFees: hasLateFees, linkedCreditNotes: linkedCreditNotes, payments: payments, matchedPayments: matchedPayments, settlements: settlements, linkedInvoices: linkedInvoices }))),
        React.createElement("td", null,
            React.createElement("p", { className: styles('status'), title: status }, status || '-')),
        React.createElement("td", { className: styles('issue-date') },
            React.createElement("span", null,
                issueDate ? formattedDate(issueDate, currentLang, 'DD/MM/YY') : '-',
                (isInvoice || isCreditNote) && isOpen && (React.createElement(ClientDetailTabsBalanceItemDetailDates, { type: type, hasLateFees: hasLateFees, linkedCreditNotes: linkedCreditNotes, payments: payments, matchedPayments: matchedPayments, settlements: settlements, linkedInvoices: linkedInvoices })))),
        React.createElement("td", { className: styles('due-date') },
            React.createElement("span", null, dueDate ? formattedDate(dueDate, currentLang, 'DD/MM/YY') : '-')),
        children));
};
