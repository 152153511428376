import { __assign, __read, __rest } from "tslib";
import React, { useState } from 'react';
import { useCreateAutomaticAssignation, useGetAutomaticAssignations, useGetAvailableConditions, } from 'api';
import { AssignableModel, AssignableTo, } from 'api/models';
import { useProfile } from 'app/App';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import CustomTable from 'shared/components/CustomTable';
import { IconName } from 'shared/components/Icon';
import { Button } from 'shared/io';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import { AutomaticAssignationForm } from '../Forms/AutomaticAssignationForm';
import { AutomaticAssignationListItem } from './AutomaticAssignationsListItem';
import styleIdentifiers from './AutomaticAssignationsList.scss';
var styles = classNames.bind(styleIdentifiers);
var EnhancedTable = CustomTable(AutomaticAssignationListItem);
export var AutomaticAssignationsList = function (_a) {
    var tabItems = _a.tabItems, defaultType = _a.defaultType;
    var t = useTranslation().t;
    var profile = useProfile();
    var company = useSelector(function (state) { return state.account.company.data; });
    var _b = __read(useState({
        model_to_assign: AssignableModel.invoice,
        assign_to_type: AssignableTo[defaultType],
        page_limit: profile.preferences.itemsPerPage,
        page: 1,
    }), 2), params = _b[0], setParams = _b[1];
    var _c = useGetAutomaticAssignations(params), automaticAssignationsListResponse = _c.data, isLoading = _c.isLoading, isFetched = _c.isFetched, refetch = _c.refetch;
    var headers = [
        {
            key: 'model_to_assign',
            title: t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.MODEL_TO_ASSIGN),
            width: 200,
        },
        {
            key: 'name',
            title: t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.ASSIGN_TO),
            width: 300,
        },
        {
            key: 'assignation_conditions',
            title: t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.ASSIGNATION_CONDITIONS),
            width: 400,
        },
        {
            key: 'order',
            title: t(i18nKeys.SETTINGS.AUTOMATIC_ASSIGNATION.ORDER),
            width: 100,
        },
        {
            title: t(i18nKeys.FORM.ACTIONS),
            width: 100,
        },
    ];
    var _d = __read(useState({
        model_type: params.model_to_assign,
    }), 2), conditionParams = _d[0], setConditionParams = _d[1];
    var availableConditionsListResponse = useGetAvailableConditions(conditionParams).data;
    var availableConditions = (availableConditionsListResponse === null || availableConditionsListResponse === void 0 ? void 0 : availableConditionsListResponse.data) || [];
    var createAutomaticAssignationMutation = useCreateAutomaticAssignation().mutate;
    var addAutomaticAssignation = function () {
        sideMenuShow({
            unmount: true,
            content: (React.createElement(AutomaticAssignationForm, { availableConditions: availableConditions, prefill: {
                    modelToAssign: params.model_to_assign,
                    assignToType: params.assign_to_type,
                }, onSubmit: function (data) {
                    return createAutomaticAssignationMutation({ data: data }, {
                        onSuccess: function () {
                            sideMenuHide();
                            refetch();
                        },
                    });
                } })),
        });
    };
    var changeView = function (data) {
        setParams(function (currentParams) { return (__assign(__assign({}, currentParams), data)); });
        setConditionParams(function () { return ({
            model_type: data.model_to_assign,
        }); });
    };
    var isActive = function (_a) {
        var model_to_assign = _a.model_to_assign, assign_to_type = _a.assign_to_type;
        return params.assign_to_type === assign_to_type && params.model_to_assign === model_to_assign;
    };
    return (React.createElement("div", { className: styles('listing') },
        React.createElement("div", { className: styles('head') },
            React.createElement("h3", null, t(i18nKeys.AUTOMATIC_ASSIGNATION)),
            React.createElement(Button, { noMargin: true, label: t(i18nKeys.ACTIONS.ADD), onClick: addAutomaticAssignation, iconRight: IconName.PLUS, disabled: !company.package.can_use_automatic_assignations })),
        React.createElement(EnhancedTable, { notSortable: true, className: styles('listing__table'), headers: headers, items: (automaticAssignationsListResponse === null || automaticAssignationsListResponse === void 0 ? void 0 : automaticAssignationsListResponse.data) || [], pagination: automaticAssignationsListResponse === null || automaticAssignationsListResponse === void 0 ? void 0 : automaticAssignationsListResponse.metadata.pagination, loading: isLoading, loaded: isFetched, noCheckbox: true, itemProps: {
                refetch: refetch,
                metadata: automaticAssignationsListResponse === null || automaticAssignationsListResponse === void 0 ? void 0 : automaticAssignationsListResponse.metadata,
                availableConditions: availableConditions,
            }, noShadow: true, customTabs: React.createElement("div", { className: styles('tabs') }, tabItems.map(function (_a) {
                var key = _a.key, rest = __rest(_a, ["key"]);
                return (React.createElement("div", { key: key, className: styles('tab', isActive(rest) && 'active'), onClick: function () { return changeView(rest); } }, t(key)));
            })) })));
};
