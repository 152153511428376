import { __assign, __read, __spreadArray } from "tslib";
import React, { useMemo, useState } from 'react';
import { useProfile } from 'app/App';
import { ExportDebtorCsv } from 'app/Logged/Clients';
import { i18nKeys, useTranslation } from 'locales';
import { cloneDeep, mergeWith, subtract } from 'lodash-es';
import { Link } from 'react-router-dom';
import { chunk, times } from 'remeda';
import { useAgedBalanceColors } from 'shared/hooks';
import { formatAmount } from 'shared/utils/normalization';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { Anchor, Button, Card, Group, Pagination, Skeleton, Table, Text, useMantineTheme, } from '@mantine/core';
import { IconChevronDown, IconTableExport } from '@tabler/icons-react';
function computeNetAgedBalancePerDebtor(agedBalance) {
    return agedBalance.map(function (_a) {
        var debits = _a.debits, credits = _a.credits, debtorId = _a.debtorId, debtorName = _a.debtorName;
        var merged = mergeWith(cloneDeep(debits), credits, subtract);
        return __assign(__assign({ debtorId: debtorId, debtorName: debtorName }, merged), { totalDue: Object.values(merged).reduce(function (acc, curr) { return acc + curr; }, 0) });
    });
}
function formatAmountWithColor(value, color) {
    if (value === 0)
        return formatAmount(value, '.', ' ', 0);
    return (React.createElement(Text, { fw: 600, c: color }, formatAmount(value, '.', ' ')));
}
function isKeyOfBalance(input) {
    return ['0', '30', '60', '90', 'totalDue', 'notDue'].includes(input);
}
var HeadCell = function (_a) {
    var columnKey = _a.columnKey, onClick = _a.onClick, _b = _a.isSelected, isSelected = _b === void 0 ? false : _b;
    var t = useTranslation().t;
    var theme = useMantineTheme();
    var isClickable = isKeyOfBalance(columnKey) && onClick != null;
    return (React.createElement(Group, { pb: 6, gap: 4, onClick: isClickable ? function () { return onClick(columnKey); } : function () { }, style: { cursor: isClickable ? 'pointer' : undefined, userSelect: 'none' } },
        React.createElement(Text, { fz: 16, fw: isSelected ? 900 : 600, c: isSelected ? theme.colors.blue[7] : theme.colors.gray[7] }, t(i18nKeys.ANALYTICS.AGED_BALANCE[columnKey])),
        isClickable ? (React.createElement(IconChevronDown, { size: 15, stroke: isSelected ? 2 : 1.5, color: isSelected ? theme.colors.blue[6] : theme.colors.gray[7] })) : null));
};
var skeletonRow = function () {
    return [
        React.createElement(Skeleton, { height: 20, w: "80%" }),
        React.createElement(Skeleton, { height: 20, w: "80%" }),
        React.createElement(Skeleton, { height: 20, w: "80%" }),
        React.createElement(Skeleton, { height: 20, w: "80%" }),
        React.createElement(Skeleton, { height: 20, w: "80%" }),
        React.createElement(Skeleton, { height: 20, w: "80%" }),
    ];
};
export var AgedBalanceTable = function (_a) {
    var agedBalance = _a.agedBalance, isLoading = _a.isLoading;
    var t = useTranslation().t;
    var theme = useMantineTheme();
    var colors = useAgedBalanceColors();
    var itemsPerPage = useProfile().preferences.itemsPerPage;
    var _b = __read(useState(1), 2), pageIndex = _b[0], setPageIndex = _b[1];
    var _c = __read(useState('totalDue'), 2), sortingColumn = _c[0], setSortingColumn = _c[1];
    var chunkedRows = useMemo(function () {
        if (isLoading)
            return [times(7, function () { return skeletonRow(); })];
        var netAgedBalancePerDebtor = computeNetAgedBalancePerDebtor(agedBalance.data);
        var sorted = netAgedBalancePerDebtor
            .slice()
            .sort(function (a, b) { return b[sortingColumn] - a[sortingColumn]; });
        // Cant't destructure bare numbers used as keys
        var netAgedBalancePerDebtorRows = sorted.map(function (_a) {
            var debtorName = _a.debtorName, debtorId = _a.debtorId, zero = _a[0], thirty = _a[30], sixty = _a[60], ninety = _a[90], totalDue = _a.totalDue, notDue = _a.notDue;
            return [
                React.createElement(Anchor, { underline: "always", c: theme.colors.gray[9], fw: 400, component: Link, to: "/clients/".concat(debtorId, "/balance") }, debtorName),
                formatAmountWithColor(notDue, theme.colors.gray[9]),
                formatAmountWithColor(zero, colors[0]),
                formatAmountWithColor(thirty, colors[30]),
                formatAmountWithColor(sixty, colors[60]),
                formatAmountWithColor(ninety, colors[90]),
                formatAmountWithColor(totalDue, theme.colors.gray[9]),
            ];
        });
        return chunk(netAgedBalancePerDebtorRows, itemsPerPage);
    }, [agedBalance, itemsPerPage, sortingColumn, theme.colors.gray, isLoading]);
    var isPaginated = chunkedRows.length > 1;
    var onClickColumnHead = function (columnName) {
        setSortingColumn(columnName);
        setPageIndex(1);
    };
    var handleExport = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            title: t(i18nKeys.EXPORT),
            children: (React.createElement(ExportDebtorCsv, { overrideDefaultSelection: [
                    'aged_balance_not_due',
                    'aged_balance_within_30',
                    'aged_balance_between_30_and_60',
                    'aged_balance_between_60_and_90',
                    'aged_balance_above_90',
                    'debtor_remaining_balance',
                    'full_name',
                ] })),
        });
    };
    return (React.createElement(Card, { radius: "md", shadow: "sm", mt: "md" },
        React.createElement(Card.Section, { inheritPadding: true, withBorder: true, py: "xs", mb: "md" },
            React.createElement(Group, { justify: "space-between" },
                React.createElement(Text, { fw: 500, size: "lg", ff: "Roboto" }, t(i18nKeys.DETAILS)),
                React.createElement(Button, { onClick: handleExport, variant: "light", size: "xs", leftSection: React.createElement(IconTableExport, { stroke: "1.5", size: 14 }) }, t(i18nKeys.EXPORT)))),
        React.createElement(Table, { c: theme.colors.gray[6], striped: true, data: {
                head: __spreadArray([
                    React.createElement(HeadCell, { columnKey: "DEBTOR" })
                ], __read(['notDue', '0', '30', '60', '90', 'totalDue'].map(function (key) { return (React.createElement(HeadCell, { columnKey: key, isSelected: key === sortingColumn, onClick: onClickColumnHead })); })), false),
                body: chunkedRows[pageIndex - 1],
            } }),
        isPaginated && (React.createElement(Card.Section, { withBorder: true, inheritPadding: true, pb: "md" },
            React.createElement(Group, { w: "100%", justify: "flex-end", mt: "md" },
                React.createElement(Pagination, { size: "sm", total: chunkedRows.length, value: pageIndex, onChange: setPageIndex }))))));
};
