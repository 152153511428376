import { __assign, __read } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { Button, Input } from 'shared/io';
import { getTokenToResetPassword } from 'store/session/session.actions';
import styleIdentifiers from './editPasswordForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function EditPasswordForm(_a) {
    var onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var _b = useForm(), errors = _b.formState.errors, handleSubmit = _b.handleSubmit, register = _b.register, getValues = _b.getValues;
    var _c = __read(useState({}), 2), state = _c[0], setState = _c[1];
    useEffect(function () {
        var urlParams = new URLSearchParams(location.search);
        getTokenToResetPassword({
            data: {
                reset_password_token: urlParams.get('reset_password_token'),
                redirect_url: window.location.href,
            },
            callback: function (res) {
                setState({
                    email: res.uid,
                    token: res.token,
                    client: res.client,
                    expiry: res.expiry,
                    reset_password_token: res.token,
                });
            },
        });
    }, []);
    var submit = function (values) {
        onSubmit(__assign(__assign({}, values), state));
    };
    return (React.createElement("form", { onSubmit: handleSubmit(submit), className: styles('edit-password-form') },
        React.createElement("div", { className: styles('col-12') },
            React.createElement("div", { className: styles('info') },
                t(i18nKeys.LOGIN.CHANGE_PASSWORD),
                " : ",
                state.email)),
        React.createElement("div", { className: styles('col-12') },
            React.createElement(Input, { errorMessage: errors.password, type: "password", label: t(i18nKeys.FORM.NEW_PASSWORD), className: styles('input'), register: register('password', { required: true }) })),
        React.createElement("div", { className: styles('col-12') },
            React.createElement(Input, { type: "password", label: t(i18nKeys.FORM.CONFIRM_PASSWORD), className: styles('input'), errorMessage: errors.password_confirmation && 'ERROR.INVALID_PASSWORD', register: register('password_confirmation', {
                    required: true,
                    validate: {
                        passwordNotMatch: function (value) { return getValues().password === value; },
                    },
                }) })),
        React.createElement("div", { className: styles('button-wrapper') },
            React.createElement(Button, { label: t(i18nKeys.SAVE), type: "submit" })),
        React.createElement(NavLink, { className: styles('link'), to: "/login" },
            React.createElement("b", null, t(i18nKeys.LOG_IN)))));
}
