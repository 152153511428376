import React from 'react';
import classNames from 'classnames/bind';
import styleIdentifiers from './loader.scss';
var styles = classNames.bind(styleIdentifiers);
export default function Loader() {
    return (React.createElement("div", { className: styles('loader') },
        React.createElement("div", { className: styles('logo') },
            React.createElement("div", { className: styles('circle', 'top') }),
            React.createElement("div", { className: styles('circle', 'middle') }),
            React.createElement("div", { className: styles('circle', 'left') }),
            React.createElement("div", { className: styles('circle', 'right') }),
            React.createElement("div", { className: styles('circle', 'left-bottom') }),
            React.createElement("div", { className: styles('circle', 'right-bottom') }),
            React.createElement("div", { className: styles('circle', 'bottom') }),
            React.createElement("div", { className: styles('line', 'top-left') }),
            React.createElement("div", { className: styles('line', 'top-right') }),
            React.createElement("div", { className: styles('line', 'vertical', 'left-side') }),
            React.createElement("div", { className: styles('line', 'vertical', 'right-side') }),
            React.createElement("div", { className: styles('line', 'vertical', 'middle-top') }),
            React.createElement("div", { className: styles('line', 'bottom-left') }),
            React.createElement("div", { className: styles('line', 'bottom-right') }),
            React.createElement("div", { className: styles('line', 'middle-top-right') }),
            React.createElement("div", { className: styles('line', 'middle-bottom-right') }),
            React.createElement("div", { className: styles('line', 'middle-bottom-left') }),
            React.createElement("div", { className: styles('line', 'middle-top-left') }))));
}
