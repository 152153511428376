import { i18nKeys } from 'locales';
import { translate, useTranslation } from 'locales/i18n';
import { useDispatch } from 'react-redux';
import { updateEmail } from 'store/session/session.actions';
import { translateAction } from 'store/translate/translate.actions';
import { LocaleSchema } from 'types';
import { z, ZodIssueCode } from 'zod';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult, axiosInstance } from './utils';
// https://github.com/colinhacks/zod/blob/master/ERROR_HANDLING.md
z.setErrorMap(function (issue, ctx) {
    if (issue.code === ZodIssueCode.invalid_string && issue.validation === 'email') {
        return { message: translate(i18nKeys.ERROR.INVALID_EMAIL) };
    }
    if (issue.code === ZodIssueCode.too_small && issue.minimum === 1) {
        return { message: translate(i18nKeys.ERROR.FIELD_REQUIRED) };
    }
    return { message: ctx.defaultError };
});
export var UpdateProfileFormSchema = z
    .object({
    avatarUrl: z.string().url().nullable(),
    email: z.string().email(),
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    locale: LocaleSchema,
    pageLimit: z.string().transform(function (val) { return Number(val); }),
    receiveDailyEmails: z.boolean(),
    receiveNotificationEmails: z.boolean(),
    receiveWeeklyEmails: z.boolean(),
    signature: z.string().nullable(),
})
    .partial();
export function useUpdateProfile() {
    var client = useQueryClient();
    var dispatch = useDispatch();
    var t = useTranslation().t;
    var mutation = useMutation({
        mutationFn: function (data) {
            return axiosInstance.put('/profile', UpdateProfileFormSchema.parse(data));
        },
        onSuccess: function (_, ctx) {
            // If the email was modified, update the session headers in the redux store because subsequent requests
            // will fail if we keep providing the old email
            if ('email' in ctx) {
                dispatch(updateEmail(ctx.email));
            }
            if ('locale' in ctx) {
                translateAction.changeLang(ctx.locale);
            }
            notifications.show({
                message: t(i18nKeys.SETTINGS.PROFILE.SUCCESSFULLY_UPDATE),
                color: 'green',
            });
            client.invalidateQueries(['profile']);
        },
        onError: function (error) {
            var _a;
            (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.error_message.forEach(function (message) {
                notifications.show({
                    title: 'Error',
                    color: 'red',
                    message: message,
                });
            });
        },
    });
    return addActionNameToMutationResult('updateProfile', mutation);
}
