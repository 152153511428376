import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { Link } from 'react-router-dom';
import { useLoadAgedBalanceCompact, } from 'shared/hooks';
import { Box, Button, LoadingOverlay } from '@mantine/core';
import { IconArrowRight, IconZoomMoney } from '@tabler/icons-react';
import { AnalyticsCard } from '../AnalyticsCard';
import { AgedBalanceChart } from '../charts';
export var AgedBalanceCard = function () {
    var t = useTranslation().t;
    var _a = useLoadAgedBalanceCompact(), agedBalanceCompact = _a.agedBalanceCompact, isAgedBalanceCompactLoading = _a.isAgedBalanceCompactLoading;
    return (React.createElement(AnalyticsCard, { title: t(i18nKeys.ANALYTICS.AGED_BALANCE.TITLE), actions: React.createElement(Button, { component: Link, to: "/analytics/aged-balance", variant: "light", leftSection: React.createElement(IconZoomMoney, { size: 20, stroke: 1.5 }), rightSection: React.createElement(IconArrowRight, { size: 20, stroke: 1.5 }) }, t(i18nKeys.ANALYTICS.AGED_BALANCE.SEE_BREAKDOWN)) },
        React.createElement(Box, { w: "100%", h: "455px" }, isAgedBalanceCompactLoading ? (React.createElement(LoadingOverlay, { visible: true })) : (React.createElement(AgedBalanceChart, { agedBalanceCompact: agedBalanceCompact })))));
};
