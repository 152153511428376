import { __assign, __generator, __rest } from "tslib";
import { i18nKeys } from 'locales';
import { call, select } from 'redux-saga/effects';
import { logout } from 'store/session/session.actions';
import { error, showMaintenancePage, success } from 'store/view/view.actions';
import apiService from '../shared/service/api.service';
import { getSession } from '../shared/utils/selectors';
export function apiCall(options) {
    var session, response, requestData, data, haveData;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, select(getSession)];
            case 1:
                session = _c.sent();
                if (session && !options.noToken) {
                    options.headers = session;
                }
                else if (!options.headers) {
                    options.headers = {};
                }
                if (options.allOrigin) {
                    options.headers['Access-Control-Allow-Origin'] = '*';
                }
                options.name = apiService.tenant;
                return [4 /*yield*/, call(options.api, options)];
            case 2:
                response = _c.sent();
                requestData = options.data;
                if (!!response) return [3 /*break*/, 9];
                if (!!options.noFeedback) return [3 /*break*/, 4];
                return [4 /*yield*/, error({
                        text: i18nKeys.ERROR.SERVER_NOT_RESPONDING,
                    })];
            case 3:
                _c.sent();
                _c.label = 4;
            case 4:
                if (!options.actionFailure) return [3 /*break*/, 6];
                return [4 /*yield*/, options.actionFailure()];
            case 5:
                _c.sent();
                _c.label = 6;
            case 6:
                if (!options.actionRes) return [3 /*break*/, 8];
                return [4 /*yield*/, options.actionRes({}, 'rejected', requestData)];
            case 7:
                _c.sent();
                _c.label = 8;
            case 8: 
            // todo: could produce a not found error
            return [2 /*return*/, null];
            case 9:
                data = response.data || null;
                if (!(response && data)) return [3 /*break*/, 13];
                if (!data.force_logout) return [3 /*break*/, 13];
                if (!!options.noFeedback) return [3 /*break*/, 11];
                haveData = data.error_message || data.errors || undefined;
                return [4 /*yield*/, error((_a = {},
                        _a[haveData ? 'data' : 'text'] = haveData || 'ERROR.HAVE_BEEN_DISCONNECTED',
                        _a))];
            case 10:
                _c.sent();
                _c.label = 11;
            case 11: return [4 /*yield*/, logout()];
            case 12:
                _c.sent();
                return [2 /*return*/, response];
            case 13:
                if (!(response.status >= 400)) return [3 /*break*/, 27];
                if (!(response.status === 401)) return [3 /*break*/, 17];
                if (!!options.noFeedback) return [3 /*break*/, 15];
                return [4 /*yield*/, error({
                        data: data && (data.error_message || data.errors),
                    })];
            case 14:
                _c.sent();
                _c.label = 15;
            case 15: return [4 /*yield*/, logout()];
            case 16:
                _c.sent();
                return [2 /*return*/, response];
            case 17:
                if (!(response.status === 503)) return [3 /*break*/, 20];
                if (!!options.noFeedback) return [3 /*break*/, 19];
                return [4 /*yield*/, showMaintenancePage()];
            case 18:
                _c.sent();
                _c.label = 19;
            case 19: return [2 /*return*/, response];
            case 20:
                if (!!options.noFeedback) return [3 /*break*/, 22];
                return [4 /*yield*/, error({
                        data: data && (data.error_message || (data.errors && data.errors.full_messages) || data.errors),
                    })];
            case 21:
                _c.sent();
                _c.label = 22;
            case 22:
                if (!options.actionFailure) return [3 /*break*/, 24];
                return [4 /*yield*/, options.actionFailure(response, requestData)];
            case 23:
                _c.sent();
                _c.label = 24;
            case 24:
                if (!options.actionRes) return [3 /*break*/, 26];
                return [4 /*yield*/, options.actionRes(response, 'rejected', requestData)];
            case 25:
                _c.sent();
                _c.label = 26;
            case 26: return [2 /*return*/, response];
            case 27:
                if (!(response.status === 200)) return [3 /*break*/, 31];
                if (!options.actionRes) return [3 /*break*/, 29];
                return [4 /*yield*/, options.actionRes(data, 'fulfilled', requestData, options.reset, options.id)];
            case 28:
                _c.sent();
                _c.label = 29;
            case 29:
                if (options.callback) {
                    options.callback(data);
                }
                if (!(!options.noSuccess && !options.noFeedback && (data.message || options.success))) return [3 /*break*/, 31];
                return [4 /*yield*/, success((_b = {},
                        _b[data.message ? 'data' : 'text'] = data.message || options.success,
                        _b))];
            case 30:
                _c.sent();
                _c.label = 31;
            case 31: return [2 /*return*/, response];
        }
    });
}
export var sendApi = function (api, actionRes, customPayload) {
    return function (action) {
        var _a, id, apiPayload;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = (customPayload ? customPayload(action.payload) : action.payload) || {}, id = _a.id, apiPayload = __rest(_a, ["id"]);
                    return [4 /*yield*/, apiCall(__assign({ api: api, actionRes: actionRes, id: id }, apiPayload))];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    };
};
export default apiCall;
