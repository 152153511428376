import { __generator } from "tslib";
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { getEnv, isDeployedEnvironment, isDevelopment } from 'shared/utils/environment';
import rootSaga from 'store/root.sagas';
import setupStore, { browserHistory } from 'store/setupClientStore';
import * as Sentry from '@sentry/react';
import { globalCss } from '@stitches/react';
import 'styles/main.scss';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/spotlight/styles.css';
import '@mantine/dates/styles.css';
var globalStyles = globalCss({
    // Fix for faulty spotlight css
    '[data-selected="true"]': {
        color: 'white',
        backgroundColor: '#228be6',
    },
});
Sentry.init({
    enabled: isDeployedEnvironment(),
    dsn: 'https://cc0f95132ecc429e864e4df5e818abe9@o123803.ingest.sentry.io/1429249',
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    // These errors are caused by third party libraries or clients and are not actionable
    ignoreErrors: [
        "Unexpected token '<'",
        // Third party, probably intercom or sentry itself
        'postMessage',
        // LastPass
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
        // Outlook SafeLink crawlers
        'Non-Error promise rejection captured with value: Object Not Found Matching Id',
    ],
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllInputs: false,
            maskAllText: false,
            blockAllMedia: false,
            networkDetailAllowUrls: ['recovr'],
        }),
    ],
    environment: getEnv(),
    tracesSampleRate: 1.0,
});
var MOUNT_NODE = document.getElementById('root');
export var storeSetup = setupStore;
var sagaTask = storeSetup.sagaRun(rootSaga);
var render = function () {
    // VERY IMPORTANT FOR HOT RELOADING WITH ROUTER V4
    globalStyles();
    var Root = require('./ClientApp').default;
    var container = document.getElementById('root');
    if (!container)
        return;
    var root = createRoot(container);
    root.render(React.createElement(Root, { store: storeSetup.store, persistor: storeSetup.persistor, history: browserHistory }));
};
if (isDevelopment()) {
    if (module.hot) {
        // Setup hot module replacement
        module.hot.accept(['./ClientApp'], function () {
            return setImmediate(function () {
                ReactDOM.unmountComponentAtNode(MOUNT_NODE);
                render();
            });
        });
        // Setup hot module replacement
        module.hot.accept(['./styles/main.scss'], function () {
            return setImmediate(function () {
                ReactDOM.unmountComponentAtNode(MOUNT_NODE);
                render();
            });
        });
        // SAGA HOT RELOAD
        module.hot.accept('./store/root.sagas', function () {
            var getNewSagas = require('./store/root.sagas').default;
            sagaTask.cancel();
            sagaTask.done.then(function () {
                sagaTask = storeSetup.sagaRun(function replacedSaga() {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, getNewSagas()];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                });
            });
        });
    }
}
// Let's Go!
// ------------------------------------
render();
