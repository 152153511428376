import { __read } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useReducer } from 'react';
import { Icon, IconName } from 'shared/components/Icon';
import { reducerState } from 'shared/utils/view';
import TableFooter from '../CustomTable/TableFooter';
import styleIdentifiers from './ListResModal.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ListResModal(_a) {
    var data = _a.data;
    var t = useTranslation().t;
    var _b = __read(useReducer(reducerState, {
        showElements: data.slice(0, 20),
        currentPage: 1,
        feedback: data.reduce(function (acc, _a) {
            var error_message = _a.error_message;
            if (error_message) {
                acc.error++;
            }
            else {
                acc.success++;
            }
            return acc;
        }, { error: 0, success: 0 }),
    }), 2), _c = _b[0], showElements = _c.showElements, currentPage = _c.currentPage, feedback = _c.feedback, setState = _b[1];
    var setPage = function (_a) {
        var selected = _a.selected;
        setState({
            currentPage: selected + 1,
            showElements: data.slice(selected * 20, (selected + 1) * 20),
        });
    };
    return (React.createElement("div", { className: styles('delete-action-modal') },
        React.createElement("div", { className: styles('feedback') },
            React.createElement("span", null,
                t(i18nKeys.SUCCESSFULL_ACTIONS, { count: feedback.success }),
                " "),
            ' ',
            React.createElement("span", null, t(i18nKeys.FAILED_ACTIONS, { count: feedback.error }))),
        React.createElement("div", { className: styles('table-container') },
            React.createElement("table", null,
                React.createElement("tbody", null, showElements.map(function (_a) {
                    var id = _a.id, key = _a.key, error_message = _a.error_message, message = _a.message;
                    return (React.createElement("tr", { className: styles('item', !error_message && 'success'), key: id },
                        React.createElement("td", null, key),
                        React.createElement(React.Fragment, null,
                            React.createElement("td", null,
                                React.createElement(Icon, { name: error_message ? IconName.SIMPLE_REMOVE : IconName.CHECK })),
                            React.createElement("td", null,
                                React.createElement("div", null, error_message || message)))));
                })))),
        React.createElement(TableFooter, { currentPage: currentPage, className: styles('footer'), noShadow: true, itemsLength: showElements.length, noPageLimit: true, setPage: setPage, pagination: {
                current_page: currentPage,
                last_page: Math.ceil(data.length / 20),
                page_limit: 20,
                total_objects: data.length,
            } })));
}
