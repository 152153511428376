import { __assign, __read } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button, CustomSelect } from 'shared/io';
import { treatTemplate } from 'shared/serializer';
import { settingsActions } from 'store/settings/settings.actions';
import styleIdentifiers from './ChooseDefaultTemplate.scss';
var styles = classNames.bind(styleIdentifiers);
export var ChooseDefaultTemplate = function (_a) {
    var onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var _b = __read(useState([]), 2), templates = _b[0], setTemplates = _b[1];
    var form = useForm();
    var loadTemplates = function () {
        form.setValue('template_id', '');
        settingsActions.templateList({
            data: {
                step_type: form.getValues().template_type,
            },
            callback: function (_a) {
                var data = _a.data;
                setTemplates(data.map(function (item) { return treatTemplate(item); }));
            },
        });
    };
    var submit = function () {
        onSubmit(form.getValues());
    };
    return (React.createElement("div", { className: styles('choose-default-template') },
        React.createElement(FormProvider, __assign({}, form),
            React.createElement(Controller, { name: "template_type", defaultValue: "", render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), onValueChanged: loadTemplates, noMargin: true, keyText: "description", keyValue: "value", withBorder: true, name: "template_type", items: [
                        {
                            description: t(i18nKeys.COMMON.INVOICE),
                            value: 'invoice',
                        },
                        {
                            description: t(i18nKeys.CREDIT_NOTE),
                            value: 'credit_note',
                        },
                    ], label: t(i18nKeys.TEMPLATE_TYPE) })); } }),
            React.createElement(Controller, { name: "template_id", defaultValue: "", render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "name", keyValue: "id", withBorder: true, name: "template_id", items: templates, label: t(i18nKeys.TEMPLATES) })); } })),
        React.createElement("div", { className: styles('button-wrapper') },
            React.createElement(Button, { label: t(i18nKeys.FORM.VALIDATE), onClick: submit }))));
};
