import React from 'react';
import PaymentSettingsForm from 'app/Logged/Settings/PackageSettings/PaymentSettingsForm';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import { PageTitle } from 'shared/layout/PageTitle';
import { accountActions } from 'store/account/account.actions';
import { SettingsCard } from '../SettingsCard';
export var PackageSettings = function () {
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var handleUpdate = function (values) {
        accountActions.updateCompany(values);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, null, t(i18nKeys.SETTINGS.PACKAGE.ACCOUNT_SUMMARY)),
        React.createElement(SettingsCard, null,
            React.createElement(PaymentSettingsForm, { initialValues: company, onSubmit: handleUpdate }))));
};
