import { __assign } from "tslib";
import { DisplayType, ModelType, VarType } from 'app/Logged/CustomVariables/CustomVariables.types';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button, CustomSelect, DateSelector, Input } from 'shared/io';
import styleIdentifiers from './customVariableForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function CustomVariableForm(_a) {
    var onSubmit = _a.onSubmit, initialValues = _a.initialValues;
    var t = useTranslation().t;
    var defaultValues = __assign({}, initialValues);
    var form = useForm({
        defaultValues: defaultValues,
    });
    var watch = form.watch, register = form.register, handleSubmit = form.handleSubmit, setValue = form.setValue, _b = form.formState, isDirty = _b.isDirty, errors = _b.errors;
    var submit = function (data) {
        data = __assign({}, data);
        onSubmit(data);
    };
    var accurateFormInput = function (varType) {
        switch (varType) {
            case VarType.BOOLEAN:
                return (React.createElement(Controller, { defaultValue: "", name: "default_value", render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "description", keyValue: "value", items: [
                            {
                                description: t(i18nKeys.YES),
                                value: 'true',
                            },
                            {
                                description: t(i18nKeys.NO),
                                value: 'false',
                            },
                        ], withBorder: true, name: "default_value", label: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.DEFAULT_VALUE) })); } }));
            case VarType.DATE:
                return (React.createElement(DateSelector, { name: "default_value", className: styles('input'), noMinDate: true, withBorder: true, placeholder: " ", label: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.DEFAULT_VALUE), handleChange: function (value) {
                        setValue('default_value', value);
                    } }));
            default:
                return (React.createElement(Input, { register: register('default_value'), errorMessage: errors.name, className: styles('input'), withBorder: true, noMargin: true, type: varType === VarType.NUMBER ? 'number' : 'text', step: "0.01", label: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.DEFAULT_VALUE) }));
        }
    };
    var isNewForm = typeof initialValues.id === 'undefined';
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('CustomVariableForm'), onSubmit: handleSubmit(submit) },
            React.createElement("div", { className: styles('head') }, t(i18nKeys.FORM.CUSTOM_VARIABLES.TITLE[isNewForm ? 'NEW' : 'EDIT'])),
            React.createElement("div", { className: styles('content') },
                React.createElement("div", { className: "grid-row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement(Input, { register: register('name', { required: true }), errorMessage: errors.name, className: styles('input', 'first-field'), withBorder: true, noMargin: true, type: "text", label: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.NAME) })),
                    React.createElement("div", { className: "col-6" },
                        React.createElement(Controller, { defaultValue: "", name: "var_type", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "description", keyValue: "value", items: [
                                    {
                                        description: t(i18nKeys.COLUMN_TYPE.STRING),
                                        value: VarType.STRING,
                                    },
                                    {
                                        description: t(i18nKeys.COLUMN_TYPE.NUMBER),
                                        value: VarType.NUMBER,
                                    },
                                    {
                                        description: t(i18nKeys.COLUMN_TYPE.BOOLEAN),
                                        value: VarType.BOOLEAN,
                                    },
                                    {
                                        description: t(i18nKeys.COLUMN_TYPE.DATE),
                                        value: VarType.DATE,
                                    },
                                ], withBorder: true, name: "var_type", label: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.VAR_TYPE), notAllowed: !isNewForm })); } })),
                    React.createElement("div", { className: "col-6" },
                        React.createElement(Controller, { defaultValue: "", name: "model_type", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "description", keyValue: "value", items: [
                                    {
                                        description: t(i18nKeys.COMMON.INVOICE),
                                        value: ModelType.INVOICE,
                                    },
                                    {
                                        description: t(i18nKeys.COMMON.CLIENT),
                                        value: ModelType.DEBTOR,
                                    },
                                ], withBorder: true, name: "model_type", label: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.MODEL_TYPE), notAllowed: !isNewForm })); } })),
                    watch('var_type') === VarType.NUMBER && (React.createElement("div", { className: "col-12" },
                        React.createElement(Controller, { defaultValue: "", name: "display", render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "description", keyValue: "value", items: [
                                    { description: t(i18nKeys.DEFAULT), value: '' },
                                    {
                                        description: t(i18nKeys.DEBTOR.CURRENCY),
                                        value: DisplayType.MONEY,
                                    },
                                    {
                                        description: t(i18nKeys.PERCENTAGE),
                                        value: DisplayType.PERCENTAGE,
                                    },
                                ], withBorder: true, name: "display", label: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.DISPLAY) })); } }))),
                    React.createElement("div", { className: "col-12" }, accurateFormInput(watch('var_type'))))),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { disabled: !isDirty, noMargin: true, label: t(i18nKeys.SAVE), type: "submit" })))));
}
