import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { Route, Switch } from 'react-router';
import { Alert, Anchor, Box, Text } from '@mantine/core';
import { IconFlask2 } from '@tabler/icons-react';
import { AgedBalance } from './pages/AgedBalance';
import { Overview } from './pages/Overview';
export var Analytics = function () {
    var t = useTranslation().t;
    return (React.createElement(Box, { w: "80%", mx: "auto" },
        React.createElement(Alert, { mb: "lg", icon: React.createElement(IconFlask2, { size: 32, stroke: 1.5 }), title: t(i18nKeys.ANALYTICS.BETA_TITLE), color: "violet", styles: {
                icon: { width: '32px', height: '32px' },
                title: { fontSize: '20px', fontWeight: 500 },
            } },
            React.createElement(Text, null,
                t(i18nKeys.ANALYTICS.BETA),
                React.createElement(Anchor, { href: "mailto:support@recovr.eu" }, "support@recovr.eu"),
                ".")),
        React.createElement(Switch, null,
            React.createElement(Route, { path: "/analytics/aged-balance", component: AgedBalance }),
            React.createElement(Route, { path: "/analytics", component: Overview }))));
};
