import { __generator } from "tslib";
import { all, call, takeLatest } from 'redux-saga/effects';
import { api } from 'store/apis';
import { apiCall, sendApi } from '../sagas';
import { onBoardingActions, onBoardingConstants } from './onBoarding.actions';
export function codaboxRequest(action) {
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, apiCall({
                    api: ((_a = action.payload) === null || _a === void 0 ? void 0 : _a.settings) ? api.settings.codaboxRequest : api.onBoarding.codaboxRequest,
                    callback: (_b = action.payload) === null || _b === void 0 ? void 0 : _b.callback,
                    actionRes: onBoardingActions.codaboxRequestRes,
                })];
            case 1:
                _c.sent();
                return [2 /*return*/];
        }
    });
}
export function codaboxToken(_a) {
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, apiCall({
                    api: payload.settings ? api.settings.codaboxToken : api.onBoarding.codaboxToken,
                    data: payload.data,
                    callback: payload.callback,
                })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function ponto(_a) {
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, apiCall({
                    api: payload.settings ? api.settings.ponto : api.onBoarding.ponto,
                    data: payload.data,
                    callback: payload.callback,
                })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function uploadTerms(_a) {
    var callback, pdf;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                callback = payload.callback, pdf = payload.pdf;
                return [4 /*yield*/, apiCall({
                        api: api.onBoarding.uploadTerms,
                        fileUpload: true,
                        callback: callback,
                        data: {
                            pdf: pdf,
                        },
                    })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function setupDebtCollectionSettings(_a) {
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, call(apiCall, {
                    api: api.onBoarding.debtCollectionSettings,
                    data: payload.data,
                    callback: payload.callback,
                    actionFailure: payload.actionFailure,
                })];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sendColor(payload) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(apiCall, {
                    api: api.onBoarding.invoicing_settings,
                    data: payload.data,
                    callback: payload.callback,
                })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function companyIdentity(_a) {
    var response;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!payload.data.logo) return [3 /*break*/, 4];
                return [4 /*yield*/, call(apiCall, {
                        api: api.onBoarding.onBoardingFiles,
                        data: {
                            logo: payload.data.logo,
                        },
                    })];
            case 1:
                response = _b.sent();
                if (!(response && response.status === 200)) return [3 /*break*/, 3];
                return [4 /*yield*/, sendColor(payload)];
            case 2:
                _b.sent();
                _b.label = 3;
            case 3: return [3 /*break*/, 6];
            case 4: return [4 /*yield*/, sendColor(payload)];
            case 5:
                _b.sent();
                _b.label = 6;
            case 6: return [2 /*return*/];
        }
    });
}
function UserWatchers() {
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = all;
                return [4 /*yield*/, takeLatest(onBoardingConstants.codaboxRequest.request, codaboxRequest)];
            case 1:
                _b = [
                    _c.sent()
                ];
                return [4 /*yield*/, takeLatest(onBoardingConstants.codaboxToken.request, codaboxToken)];
            case 2:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(onBoardingConstants.ponto.request, ponto)];
            case 3:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(onBoardingConstants.noPayment.request, sendApi(api.onBoarding.noPayment))];
            case 4:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(onBoardingConstants.bankAccount.request, sendApi(api.onBoarding.bankAccount))];
            case 5:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(onBoardingConstants.stripe.request, sendApi(api.onBoarding.stripe))];
            case 6:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(onBoardingConstants.setupDebtCollectionSettings.request, setupDebtCollectionSettings)];
            case 7:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(onBoardingConstants.companyIdentity.request, companyIdentity)];
            case 8:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(onBoardingConstants.yuki.request, sendApi(api.onBoarding.yuki))];
            case 9:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(onBoardingConstants.horus.request, sendApi(api.onBoarding.horus))];
            case 10:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(onBoardingConstants.horusFetchCompanies.request, sendApi(api.onBoarding.horusFetchCompanies))];
            case 11:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(onBoardingConstants.teamleader.request, sendApi(api.onBoarding.teamleader))];
            case 12:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(onBoardingConstants.exact.request, sendApi(api.onBoarding.exact))];
            case 13:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(onBoardingConstants.exactFetchCompanies.request, sendApi(api.onBoarding.exactFetchCompanies))];
            case 14:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(onBoardingConstants.twikey.request, sendApi(api.onBoarding.twikey))];
            case 15:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(onBoardingConstants.billit.request, sendApi(api.onBoarding.billit))];
            case 16:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(onBoardingConstants.uploadTerms.request, uploadTerms)];
            case 17: return [4 /*yield*/, _a.apply(void 0, [_b.concat([
                        _c.sent()
                    ])])];
            case 18:
                _c.sent();
                return [2 /*return*/];
        }
    });
}
export default function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(UserWatchers)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
