import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { RecoveryPlan } from 'shared/components/RecoveryPlan';
import styleIdentifiers from './MoveRecoveryPlanInvoiceModal.scss';
var styles = classNames.bind(styleIdentifiers);
export default function MoveRecoveryPlanInvoiceModal(_a) {
    var plan = _a.plan, onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    return (React.createElement("div", { className: styles('move-recovery-plan-invoice') },
        React.createElement("p", null, t(i18nKeys.PLAN.SELECT_STEP)),
        React.createElement(RecoveryPlan, { recoverPlan: plan, messageInfo: t(i18nKeys.PLAN.STEP_MUST_BE_CREATED), stepClassName: styles('cursor-pointer'), onClickOnStep: onSubmit, canClickPreventiveReminder: false })));
}
