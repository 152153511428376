import { __assign } from "tslib";
import axios from 'axios';
import { omit } from 'remeda';
import apiService from 'shared/service/api.service';
import { store } from 'store/setupClientStore';
import urlJoin from 'url-join';
var legacyApiUrl = urlJoin(apiService.baseUrl, 'private_api');
var apiV2Url = urlJoin(apiService.baseUrl, 'private_api/v2');
// eslint-disable-next-line import/no-mutable-exports
export var axiosInstance = axios.create({
    baseURL: apiV2Url,
    headers: {
        tenant: apiService.tenant,
    },
});
// eslint-disable-next-line import/no-mutable-exports
export var legacyAxiosInstance = axios.create({
    baseURL: legacyApiUrl,
    headers: {
        tenant: apiService.tenant,
    },
});
// Inject session data in axios headers as soon as they become available from the store.
// Without this subscription, this file is invoked before the redux persistor is ran and
// the dependent requests fail.
store.subscribe(function () {
    var session = store.getState().session.data;
    if (session != null) {
        // @ts-ignore
        var sessionCleaned = omit(session, ['content-length']);
        axiosInstance = axios.create({
            baseURL: apiV2Url,
            headers: __assign({ tenant: apiService.tenant }, sessionCleaned),
        });
        legacyAxiosInstance = axios.create({
            baseURL: legacyApiUrl,
            headers: __assign({ tenant: apiService.tenant }, sessionCleaned),
        });
    }
});
