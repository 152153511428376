import { __read } from "tslib";
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { CSSTransition } from 'react-transition-group'; // ES6
import { Icon, IconName } from 'shared/components/Icon';
import { useGetDialogs } from 'shared/utils/selectors';
import { animationClassList } from 'shared/utils/view';
import { dialogHide } from 'store/view/view.actions';
import styleIdentifiers from './modal.scss';
var styles = classNames.bind(styleIdentifiers);
export default function Modal(_a) {
    var dialog = _a.dialog;
    var count = useGetDialogs().count;
    var _b = __read(useState(0), 2), zIndex = _b[0], setZIndex = _b[1];
    var nodeRef = useRef(null);
    useEffect(function () {
        var _a;
        if (dialog.active) {
            document.addEventListener('keydown', handleKeyPress);
        }
        else {
            document.removeEventListener('keydown', handleKeyPress);
            (_a = dialog.onClose) === null || _a === void 0 ? void 0 : _a.call(dialog);
        }
        return function () { return document.removeEventListener('keydown', handleKeyPress); };
    }, [dialog.active]);
    useEffect(function () {
        if (!dialog.active)
            setZIndex(0);
        else
            setZIndex(count);
    }, [dialog.active]);
    var handleKeyPress = function (event) {
        if (event.key === 'Escape') {
            if (dialog.active) {
                dialogClose();
            }
        }
    };
    var dialogClose = function () {
        var _a;
        dialogHide(dialog.id);
        (_a = dialog === null || dialog === void 0 ? void 0 : dialog.onClose) === null || _a === void 0 ? void 0 : _a.call(dialog);
    };
    return (React.createElement(CSSTransition, { in: dialog.active, nodeRef: nodeRef, timeout: 400, mountOnEnter: true, unmountOnExit: !dialog.keepMountOnExit, classNames: animationClassList('modal', styles) },
        React.createElement("div", { ref: nodeRef, style: { zIndex: zIndex }, className: styles('Modal', dialog.id, 'absolute-fill') },
            React.createElement("div", { className: styles('background'), onClick: dialogClose }),
            React.createElement("div", { className: styles('wrapper', dialog.size) },
                React.createElement("div", { className: styles('close'), onClick: dialogClose },
                    React.createElement(Icon, { name: IconName.SIMPLE_REMOVE })),
                dialog.title && React.createElement("div", { className: styles('title') }, dialog.title),
                dialog.children))));
}
