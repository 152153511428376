import { Operator } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Icon, IconName } from 'shared/components/Icon';
import { OperatorValues } from 'shared/forms/PreferencesForm';
import { CustomSelect, DateSelector, Input } from 'shared/io';
import styleIdentifiers from './AssignationConditionsForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function AssignationConditionsForm(_a) {
    var formArray = _a.formArray, removeCondition = _a.removeCondition, availableConditions = _a.availableConditions;
    var _b = useTranslation(), t = _b.t, availableLang = _b.availableLang;
    var countries = useSelector(function (state) { return state.app.constants; }).countries;
    var _c = useFormContext(), register = _c.register, watch = _c.watch, setValue = _c.setValue;
    var conditionName = function (index) {
        return watch("assignation_conditions_attributes.".concat(index, ".name"));
    };
    var operators = Object.values(Operator).map(function (e) { return ({
        description: OperatorValues[e.toUpperCase()],
        value: e,
    }); });
    var numberField = function (index) {
        var _a;
        return (((_a = availableConditions.find(function (condition) { return condition.value === conditionName(index); })) === null || _a === void 0 ? void 0 : _a.var_type) === 'number');
    };
    var booleanField = function (index) {
        var _a;
        return (((_a = availableConditions.find(function (condition) { return condition.value === conditionName(index); })) === null || _a === void 0 ? void 0 : _a.var_type) === 'boolean');
    };
    var dateFields = function (index) {
        var _a;
        return (((_a = availableConditions.find(function (condition) { return condition.value === conditionName(index); })) === null || _a === void 0 ? void 0 : _a.var_type) === 'date');
    };
    var predefinedSelect = function (index) {
        if (numberField(index) || booleanField(index) || dateFields(index)) {
            return false;
        }
        return conditionName(index) && ['locale', 'country_code'].includes(conditionName(index));
    };
    var defaultSelect = function (index) {
        if (predefinedSelect(index) || numberField(index) || booleanField(index) || dateFields(index)) {
            return false;
        }
        return true;
    };
    var setDateArray = function (value, index, arrayIndex) {
        var otherIndex = arrayIndex === 0 ? 1 : 0;
        setValue("assignation_conditions_attributes.".concat(index, ".value[").concat(otherIndex, "]"), watch("assignation_conditions_attributes.".concat(index, ".value[").concat(otherIndex, "]")) || '');
        setValue("assignation_conditions_attributes.".concat(index, ".value[").concat(arrayIndex, "]"), value);
    };
    return (React.createElement("div", { className: styles('assignation-conditions') }, formArray === null || formArray === void 0 ? void 0 : formArray.map(function (item, index) {
        register("assignation_conditions_attributes.".concat(index, ".id"));
        if (watch("assignation_conditions_attributes.".concat(index, "._destroy"))) {
            return undefined;
        }
        return (React.createElement("div", { key: item.id, className: styles('assignation-condition') },
            index > 0 && React.createElement("hr", { className: styles('separator') }),
            React.createElement("div", { className: styles('assignation-condition-item') },
                React.createElement(Controller, { name: "assignation_conditions_attributes.".concat(index, ".name"), rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { name: "assignation_conditions_attributes.".concat(index, ".name"), label: t(i18nKeys.NAME), keyValue: "value", keyText: "description", items: availableConditions, selectClassName: styles('input'), withBorder: true, onChange: function () {
                            setValue("assignation_conditions_attributes.".concat(index, ".value"), null);
                            setValue("assignation_conditions_attributes.".concat(index, ".operator"), null);
                        } })); } }),
                React.createElement(Icon, { name: IconName.TRASH_SIMPLE, onClick: function () { return removeCondition(index); } })),
            conditionName(index) && (React.createElement(React.Fragment, null,
                predefinedSelect(index) && (React.createElement(Controller, { defaultValue: "", rules: { required: true }, name: "assignation_conditions_attributes.".concat(index, ".value"), render: function () { return (React.createElement(CustomSelect, { items: conditionName(index) === 'locale' ? availableLang : countries, name: "assignation_conditions_attributes.".concat(index, ".value"), label: t(i18nKeys.VALUE), withBorder: true, multiple: true, registerMultipleInput: register('valuesinput'), placeholderFilter: t(i18nKeys.FORM.RESEARCH), keyText: "description", keyValue: "value", filter: true })); } })),
                defaultSelect(index) && (React.createElement(Controller, { defaultValue: "", rules: { required: true }, name: "assignation_conditions_attributes.".concat(index, ".value"), render: function () {
                        var _a;
                        return (React.createElement(CustomSelect, { items: (_a = formArray[index]) === null || _a === void 0 ? void 0 : _a.value, name: "assignation_conditions_attributes.".concat(index, ".value"), label: t(i18nKeys.VALUE), withBorder: true, multiple: true, registerMultipleInput: register('valuesinput'), canAdd: true, info: t(i18nKeys.FORM.CONFIRM_VALUE) }));
                    } })),
                numberField(index) && (React.createElement(React.Fragment, null,
                    React.createElement(Controller, { name: "assignation_conditions_attributes.".concat(index, ".operator"), rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { name: "assignation_conditions_attributes.".concat(index, ".operator"), keyValue: "value", keyText: "description", label: "Op\u00E9rateur", items: operators, withBorder: true })); } }),
                    React.createElement(Input, { register: register("assignation_conditions_attributes.".concat(index, ".value[0]")), label: t(i18nKeys.VALUE), type: "number", withBorder: true }))),
                booleanField(index) && (React.createElement(Controller, { name: "assignation_conditions_attributes.".concat(index, ".value[0]"), rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { name: "assignation_conditions_attributes.".concat(index, ".value[0]"), keyValue: "value", keyText: "description", label: t(i18nKeys.VALUE), items: [
                            {
                                description: t(i18nKeys.YES),
                                value: 'true',
                            },
                            {
                                description: t(i18nKeys.NO),
                                value: 'false',
                            },
                        ], withBorder: true })); } })),
                dateFields(index) && (React.createElement(DateSelector, { className: styles('input', 'date-selector'), name: "assignation_conditions_attributes.".concat(index, ".value[0]"), endName: "assignation_conditions_attributes.".concat(index, ".value[1]"), placeholder: t(i18nKeys.OF), endPlaceholder: t(i18nKeys.FORM.TO), withBorder: true, shadow: true, label: t(i18nKeys.DATE), handleChange: function (value) {
                        setDateArray(value, index, 0);
                    }, handleEndChange: function (value) {
                        setDateArray(value, index, 1);
                    } }))))));
    })));
}
