import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import FormSection from 'shared/forms/FormSection';
import Amount from '../Amount';
import styleIdentifiers from './DebtorFinancialDataSummary.scss';
var styles = classNames.bind(styleIdentifiers);
export default function DebtorFinancialDataSummary(_a) {
    var financialData = _a.financialData;
    var t = useTranslation().t;
    var total_ca = financialData.total_ca, credit_notes_total = financialData.credit_notes_total, payments_sum = financialData.payments_sum, remaining_balance = financialData.remaining_balance, remaining_late_fees = financialData.remaining_late_fees, remaining_balance_with_fees = financialData.remaining_balance_with_fees, lost_sum = financialData.lost_sum, claimed_late_fees = financialData.claimed_late_fees, localized_money_object = financialData.localized_money_object;
    var currency = localized_money_object.currency;
    if (!financialData) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement("div", { className: styles('debtor-financial-data') },
        React.createElement(FormSection, { title: t(i18nKeys.DEBTOR.SUMMARY), className: styles('general-view'), startClosed: true },
            React.createElement("div", { className: styles('info-wrapper') },
                React.createElement("div", { className: styles('key-text') },
                    React.createElement("div", { className: styles('attribute') }, t(i18nKeys.AMOUNT_BILLED)),
                    React.createElement("div", { className: styles('value', 'blue') },
                        React.createElement(Amount, { localizedValue: total_ca, value: localized_money_object.total_ca, suffix: currency }))),
                React.createElement("div", { className: styles('key-text') },
                    React.createElement("div", { className: styles('attribute') }, t(i18nKeys.CLAIMED_LATE_FEES)),
                    React.createElement("div", { className: styles('value', 'blue') },
                        React.createElement(Amount, { localizedValue: claimed_late_fees, value: localized_money_object.claimed_late_fees, suffix: currency }))),
                React.createElement("div", { className: styles('key-text') },
                    React.createElement("div", { className: styles('attribute') }, t(i18nKeys.NAV.CREDIT_NOTES)),
                    React.createElement("div", { className: styles('value', 'red') },
                        React.createElement(Amount, { localizedValue: credit_notes_total * -1, value: localized_money_object.credit_notes_total_tvac * -1, suffix: currency }))),
                React.createElement("div", { className: styles('key-text') },
                    React.createElement("div", { className: styles('attribute') }, t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.PAYMENTS)),
                    React.createElement("div", { className: styles('value', 'red') },
                        React.createElement(Amount, { localizedValue: payments_sum, value: localized_money_object.payments_sum, suffix: currency }))),
                React.createElement("div", { className: styles('key-text') },
                    React.createElement("div", { className: styles('attribute') }, t(i18nKeys.LOST_SUM)),
                    React.createElement("div", { className: styles('value', 'red') },
                        React.createElement(Amount, { localizedValue: lost_sum, value: localized_money_object.lost_sum, suffix: currency }))),
                React.createElement("div", { className: styles('key-text') },
                    React.createElement("div", { className: styles('attribute') },
                        t(i18nKeys.CLIENT.DETAIL.REMAIN_BALANCE),
                        React.createElement("br", null),
                        React.createElement("span", null,
                            "(",
                            t(i18nKeys.WITH_FEES),
                            ")")),
                    React.createElement("div", { className: styles('value', 'blue') },
                        React.createElement(Amount, { localizedValue: remaining_balance_with_fees, value: localized_money_object.remaining_balance_with_fees, suffix: currency }))),
                React.createElement("div", { className: styles('key-text') },
                    React.createElement("div", { className: styles('attribute') }, t(i18nKeys.REMAINING_LATE_FEES)),
                    React.createElement("div", { className: styles('value', 'blue') },
                        React.createElement(Amount, { localizedValue: remaining_late_fees, value: localized_money_object.remaining_late_fees, suffix: currency }))),
                React.createElement("div", { className: styles('key-text') },
                    React.createElement("div", { className: styles('attribute') },
                        t(i18nKeys.CLIENT.DETAIL.REMAIN_BALANCE),
                        React.createElement("br", null),
                        React.createElement("span", null,
                            "(",
                            t(i18nKeys.WITHOUT_FEES),
                            ")")),
                    React.createElement("div", { className: styles('value', 'blue') },
                        React.createElement(Amount, { localizedValue: remaining_balance, value: remaining_balance, suffix: currency })))))));
}
