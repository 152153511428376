import React from 'react';
import App from 'app';
import { ConnectedRouter } from 'connected-react-router';
import { DevTools } from 'jotai-devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { isPublicEnvironment } from 'shared/utils/environment';
import { Button, createTheme, MantineProvider, Modal } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { styled } from '@stitches/react';
import { QueryClient as QueryClientV4, QueryClientProvider as QueryClientProviderV4, } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
var queryClient = new QueryClient();
var queryClientV4 = new QueryClientV4({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});
export var mantineTheme = createTheme({
    cursorType: 'pointer',
    fontFamily: 'Work Sans, sans-serif',
    headings: {
        fontFamily: 'Roboto, sans-serif',
    },
    colors: {
        // For the record because my name is attached to it now: I considered looking for another job over this.
        green: [
            '#2ce1be',
            '#2ce1be',
            '#2ce1be',
            '#2ce1be',
            '#2ce1be',
            '#2ce1be',
            '#2ce1be',
            '#2ce1be',
            '#2ce1be',
            '#2ce1be',
        ],
    },
    components: {
        // Without this directive, buttons behave as if they received the fullSize prop, I do not know why.
        Button: Button.extend({
            styles: {
                root: { width: 'fit-content', fontWeight: 500 },
                // Force the default mantine font-family on buttons
                label: {
                    fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
                },
            },
        }),
        Modal: Modal.extend({
            styles: {
                title: {
                    fontWeight: 600,
                    fontSize: '1.2rem',
                },
                header: {
                    borderBottom: '1px solid lightgrey',
                    paddingBottom: '12px',
                    paddingTop: '12px',
                },
                body: {
                    paddingTop: 'var(--mantine-spacing-md)',
                },
            },
        }),
    },
});
var DevtoolStyleFixes = styled('span', {
    '.jotai-devtools-trigger-button': {
        bottom: '60px',
        left: '0px',
        transform: 'scale(0.65)',
    },
});
var DevToolsWrapper = function () {
    if (isPublicEnvironment())
        return null;
    return (React.createElement(DevtoolStyleFixes, null,
        React.createElement(ReactQueryDevtools, { initialIsOpen: false }),
        React.createElement(DevTools, null)));
};
export default function ClientApp(_a) {
    var store = _a.store, persistor = _a.persistor, history = _a.history;
    return (React.createElement(Provider, { store: store },
        React.createElement(PersistGate, { loading: null, persistor: persistor },
            React.createElement(QueryClientProvider, { client: queryClient },
                React.createElement(QueryClientProviderV4, { client: queryClientV4 },
                    React.createElement(ConnectedRouter, { history: history },
                        React.createElement(MantineProvider, { theme: mantineTheme },
                            React.createElement(ModalsProvider, null,
                                React.createElement(App, null),
                                React.createElement(DevToolsWrapper, null),
                                React.createElement(Notifications, { position: "bottom-left" })))))))));
}
