import { __assign, __read, __spreadArray } from "tslib";
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useProfile } from 'app/App';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { isEqual, omit } from 'lodash-es';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import FiltersSideMenu from 'shared/forms/FiltersSideMenu';
import { Button, ButtonColor } from 'shared/io';
import { getEnvIcon } from 'shared/utils/environment';
import { formattedDate } from 'shared/utils/view';
import { sideMenuShow } from 'store/view/view.actions';
import TableFooter from './TableFooter';
import styleIdentifiers from './customTable.scss';
var styles = classNames.bind(styleIdentifiers);
var reducer = function (prevState, updatedProperty) {
    return __assign(__assign({}, prevState), updatedProperty);
};
var initState = function (filters) {
    if (filters === void 0) { filters = {}; }
    return ({
        list: [],
        filters: __assign(__assign({}, filters), { sort_by: '' }),
        masterCheck: false,
    });
};
function CustomTable(ItemComponent, FiltersForm) {
    function Table(_a) {
        var 
        // Structure
        headers = _a.headers, title = _a.title, subtitle = _a.subtitle, pagination = _a.pagination, actions = _a.actions, customTabs = _a.customTabs, 
        // Data
        items = _a.items, itemProps = _a.itemProps, customKey = _a.customKey, loaded = _a.loaded, loading = _a.loading, filtersNames = _a.filtersNames, resetSelection = _a.resetSelection, _b = _a.pendingImport, pendingImport = _b === void 0 ? false : _b, 
        // Overrides
        noCheckbox = _a.noCheckbox, noFooter = _a.noFooter, noMinHeight = _a.noMinHeight, noShadow = _a.noShadow, showShadow = _a.showShadow, noMargin = _a.noMargin, noResultMessage = _a.noResultMessage, notSortable = _a.notSortable, 
        // Style
        className = _a.className, maxHeight = _a.maxHeight, style = _a.style, 
        // Callbacks
        loadFunc = _a.loadFunc, loadMore = _a.loadMore, onCheckBoxChange = _a.onCheckBoxChange, onToggleCheckAll = _a.onToggleCheckAll, handleLoadData = _a.handleLoadData, setSelectAll = _a.setSelectAll, onSortChange = _a.onSortChange, handleFilters = _a.handleFilters, orderField = _a.orderField;
        var history = useHistory();
        var location = useLocation();
        var profile = useProfile();
        var companyName = useSelector(function (state) { var _a, _b; return (_b = (_a = state.account.company) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.name; });
        var _c = useTranslation(), t = _c.t, currentLang = _c.currentLang;
        var _d = __read(useReducer(reducer, initState(qs.parse(location.search, { arrayFormat: 'comma' }))), 2), state = _d[0], setState = _d[1];
        var _e = __read(useState(), 2), timeoutDebounce = _e[0], setTimeoutDebounce = _e[1];
        var _f = __read(useState(false), 1), filterOpen = _f[0];
        var _g = __read(useState(false), 2), selectAll = _g[0], updateSelectAll = _g[1];
        useEffect(function () {
            document.title = "".concat(getEnvIcon()).concat(companyName, " - ").concat(title);
            return function cleanup() {
                document.title = "".concat(getEnvIcon()).concat(companyName !== null && companyName !== void 0 ? companyName : 'Recovr');
            };
        }, [title]);
        useEffect(function () {
            if (resetSelection) {
                list.forEach(function (element) {
                    onItemClick(new Event(''), element.id, false, true);
                });
                setState({
                    masterCheck: false,
                });
                onCheckBoxChange === null || onCheckBoxChange === void 0 ? void 0 : onCheckBoxChange([]);
            }
        }, [resetSelection]);
        var resetFilters = function () {
            filterObject.reset && filterObject.reset();
            applyFilters({});
        };
        useEffect(function () {
            if (timeoutDebounce) {
                clearTimeout(timeoutDebounce);
            }
            var timeout = setTimeout(function () {
                loadData();
            }, 500);
            setTimeoutDebounce(timeout);
        }, [location]);
        useEffect(function () {
            updateList();
        }, [items]);
        var onItemClick = function (e, id, targetValue, notSend) {
            var list = state.list;
            e.stopPropagation();
            var index = list.findIndex(function (item) { return item.id === id; });
            var newElement = list[index];
            var masterCheck = true;
            // toggle part
            if (targetValue !== undefined)
                newElement.checked = targetValue;
            else if (newElement.checked === true) {
                newElement.checked = false;
            }
            else {
                newElement.checked = true;
            }
            list.splice(index, 1, newElement);
            if (!targetValue) {
                list.forEach(function (element) {
                    if (!element.checked)
                        masterCheck = false;
                });
                setState({ list: list, masterCheck: masterCheck });
            }
            if (!notSend)
                onCheckBoxChange(list.filter(function (item) { return item.checked; }));
        };
        // Update location
        var updateUrl = function (filters) {
            history.push({
                pathname: location.pathname,
                search: qs.stringify(filters, { arrayFormat: 'comma' }),
                hash: location.hash,
            });
        };
        // Update the list displayed internally
        var updateList = function () {
            var filters = state.filters;
            setState({
                list: items || [],
                filters: __assign(__assign({}, filters), { sort_by: (orderField ? orderField(filters.sort_by || '') : filters.sort_by) || '', sort_order: filters.sort_order || 'asc' }),
            });
        };
        var loadData = function () {
            loadFunc === null || loadFunc === void 0 ? void 0 : loadFunc(__assign({ view: location.hash.slice(1) }, (handleLoadData ? handleLoadData(qs.parse(location.search)) : qs.parse(location.search))));
        };
        var applyFilters = function (values) {
            var filters = state.filters;
            var newFilters = handleFilters ? handleFilters(values, filters) : values;
            if (newFilters) {
                if (!isEqual(omit(filters, 'page'), omit(newFilters, 'page'))) {
                    newFilters.page = 1;
                }
                for (var key in newFilters) {
                    !newFilters[key] && delete newFilters[key];
                }
                newFilters.page_limit = values.page_limit || filters.page_limit;
                updateUrl(newFilters);
                setState({
                    filters: newFilters,
                });
            }
        };
        var masterCheck = function (e) {
            var masterCheck = state.masterCheck;
            var list = state.list;
            onToggleCheckAll === null || onToggleCheckAll === void 0 ? void 0 : onToggleCheckAll(!masterCheck);
            list.forEach(function (element) {
                onItemClick(e, element.id, !masterCheck, true);
            });
            onCheckBoxChange === null || onCheckBoxChange === void 0 ? void 0 : onCheckBoxChange(list.filter(function (item) { return item.checked; }));
            setState({
                masterCheck: !masterCheck,
            });
        };
        var setOrder = function (field) { return function () {
            var filters = state.filters;
            var sort_by = filters.sort_by, sort_order = filters.sort_order;
            var newFilters = __assign(__assign({}, filters), { sort_by: field, sort_order: sort_by === field && sort_order === 'asc' ? 'desc' : 'asc' });
            setState({
                filters: newFilters,
            });
            updateUrl(newFilters);
            if (onSortChange) {
                onSortChange(newFilters);
            }
        }; };
        var filters = state.filters, list = state.list;
        var filtersNumber = -1;
        for (var key in filters) {
            key !== 'page' && filters[key] && filtersNumber++;
        }
        var filterObject = {
            reset: function () { },
        };
        var setFilterOpen = function (open) {
            if (open) {
                var initValues = filters;
                sideMenuShow({
                    unmount: true,
                    content: (React.createElement(FiltersSideMenu, { onRemoveFilter: resetFilters },
                        React.createElement(FiltersForm, __assign({ filterObject: filterObject, onSubmit: applyFilters, initialValues: initValues }, itemProps)))),
                });
            }
        };
        var onSetSelectAll = function () {
            setSelectAll(!selectAll);
            updateSelectAll(!selectAll);
        };
        var setPage = function (page) {
            var newPage = parseInt(page.selected, 10) + 1;
            var filters = state.filters;
            var newFilters = __assign(__assign({}, filters), { page: newPage });
            applyFilters(newFilters);
            updateUrl(newFilters);
            if (!selectAll) {
                setState({
                    masterCheck: false,
                });
                onCheckBoxChange === null || onCheckBoxChange === void 0 ? void 0 : onCheckBoxChange([]);
            }
        };
        var setPageLimit = function (page_limit) { return function () {
            applyFilters(__assign(__assign({}, state.filters), { page_limit: page_limit }));
        }; };
        var filterToShow = useMemo(function () {
            return Object.entries(filters).filter(function (_a) {
                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                return !['sort_order', 'sort_by', 'page', 'page_limit'].includes(key);
            });
        }, [filters]);
        var noResult = pendingImport ? null : noResultMessage || React.createElement(React.Fragment, null, t(i18nKeys.NO_RESULT));
        return (React.createElement("div", { className: styles('CustomTable', noShadow && 'no-shadow', showShadow && 'show-shadow', noMargin && 'no-margin', className, noMinHeight && 'no-min-height') },
            (title || subtitle) && (React.createElement("div", { className: styles('head', noCheckbox && 'no-checkbox') },
                title && React.createElement("h2", null, title),
                subtitle && React.createElement("h3", null, subtitle),
                React.createElement("div", { className: styles('actions') },
                    actions,
                    (filtersNumber > 0 || FiltersForm) && (React.createElement("div", { className: styles('filters-actions') },
                        filtersNumber > 0 && (React.createElement(Button, { className: styles('remove-filter', 'filter'), noMargin: true, small: true, iconSize: "16px", color: ButtonColor.WHITE, onClick: resetFilters },
                            React.createElement(Icon, { name: IconName.FILTER }),
                            React.createElement(Icon, { name: IconName.SMALL_REMOVE }))),
                        FiltersForm && (React.createElement(React.Fragment, null,
                            React.createElement(Button, { className: styles('filter', filterOpen && 'open'), noMargin: true, small: true, iconSize: "16px", color: ButtonColor.WHITE, iconLeft: IconName.FILTER, onClick: function () {
                                    setFilterOpen(!filterOpen);
                                } },
                                filtersNumber > 0 && (React.createElement("div", { className: styles('text-circle', 'absolute', 'border', 'badge') }, filtersNumber)),
                                t(i18nKeys.FILTER))))))))),
            FiltersForm && (React.createElement("div", { className: styles('filters') },
                React.createElement("div", { className: styles('filters-container') }, filterToShow.map(function (data) {
                    var _a;
                    var _b = __read(data, 1), key = _b[0];
                    // Skip if end date
                    if (key.includes('_before') && filters[key.replace('_before', '_after')]) {
                        return null;
                    }
                    var values = Array.isArray(filters[key]) ? filters[key] : [filters[key]];
                    // Merge if start date
                    if (key.includes('_after') && filters[key.replace('_after', '_before')]) {
                        values = __spreadArray(__spreadArray([], __read(values), false), [filters[key.replace('_after', '_before')]], false).map(function (e) {
                            return formattedDate(e, currentLang);
                        });
                    }
                    if (filtersNames) {
                        if ((_a = filtersNames[key]) === null || _a === void 0 ? void 0 : _a.value) {
                            values = values.map(function (el) {
                                var _a;
                                return (_a = filtersNames[key].value.find(function (e) { return String(e.value) === String(el); })) === null || _a === void 0 ? void 0 : _a.description;
                            });
                        }
                    }
                    return (React.createElement("div", { key: key, className: styles('filter-item'), onClick: function () {
                            setFilterOpen(!filterOpen);
                        } },
                        React.createElement("div", null,
                            filtersNames ? filtersNames[key].description : key,
                            ":"),
                        React.createElement("div", null, key.includes('_after')
                            ? "".concat(values[0], " - ").concat(values[1])
                            : values.reduce(function (acc, key, i) { return (i > 0 ? "".concat(acc, ", ").concat(key) : key); }, '')),
                        React.createElement(Icon, { name: IconName.SMALL_REMOVE, onClick: function (e) {
                                e.stopPropagation();
                                applyFilters(omit(state.filters, key));
                            } })));
                })))),
            customTabs,
            React.createElement("div", { className: styles('listing-wrapper') },
                React.createElement("div", { className: styles('card-style', 'card') },
                    React.createElement("div", { className: styles('listing', noShadow && 'no-shadow'), style: { maxHeight: maxHeight } },
                        React.createElement("table", { style: style },
                            React.createElement("thead", null,
                                React.createElement("tr", null,
                                    React.createElement("th", { className: styles('box-wrapper', noCheckbox && 'small') },
                                        React.createElement("div", { className: styles('not-sortable-head') }, !noCheckbox && (React.createElement("div", { onClick: masterCheck, className: styles('box', 'checkbox', state.masterCheck && 'checked') }))),
                                        React.createElement("div", { className: styles('custom-border') })),
                                    headers.map(function (item, index) { return (React.createElement("th", { key: item.title || index, style: { width: item.width }, className: styles(index === headers.length - 1 && 'last', index === 0 && 'first') }, item.key ? (React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: styles('sortable-head', notSortable && 'no-pointer'), onClick: !notSortable ? setOrder(item.key) : function () { } },
                                            item.title,
                                            filters.sort_by === item.key && (React.createElement(Icon, { name: IconName.TAILDOWN, size: "15px", className: styles('arrow', filters.sort_order === 'asc' && 'inverted') }))),
                                        React.createElement("div", { className: styles('custom-border') }))) : (React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: styles('not-sortable-head') }, item.title),
                                        React.createElement("div", { className: styles('custom-border') }))))); }))),
                            React.createElement("tbody", null,
                                setSelectAll &&
                                    state.masterCheck &&
                                    pagination &&
                                    pagination.total_objects >
                                        (state.filters.page_limit || profile.preferences.itemsPerPage) && (React.createElement("tr", null,
                                    React.createElement("td", { className: styles('select-all'), colSpan: 100 },
                                        React.createElement("div", null,
                                            React.createElement("div", null,
                                                t((selectAll ? pagination.total_objects : items.length) === 1
                                                    ? i18nKeys.SELECTED_ELEMENT
                                                    : i18nKeys.SELECTED_ELEMENTS, {
                                                    count: selectAll ? pagination.total_objects : items.length,
                                                }),
                                                React.createElement("span", { onClick: onSetSelectAll }, t((selectAll ? items.length : pagination.total_objects) === 1
                                                    ? i18nKeys.SELECT_ELEMENT
                                                    : i18nKeys.SELECT_ELEMENTS, {
                                                    count: selectAll ? items.length : pagination.total_objects,
                                                }))))))),
                                list.map(function (item, key) { return (React.createElement(ItemComponent, __assign({ index: key, headers: headers, action: function (e) { return onItemClick(e, item.id); }, item: item, key: customKey ? customKey(item) : item.id || key, noCheckbox: noCheckbox, onCheckBoxChange: onCheckBoxChange, reloadInfo: loadData }, itemProps))); }))),
                        loading && React.createElement("div", { className: styles('loading') }, t(i18nKeys.LOADING)),
                        loaded && list.length === 0 && (React.createElement("div", { className: styles('no-result') },
                            filtersNumber > 0 ? t(i18nKeys.NO_RESULT_FOR_THIS_SEARCH) : noResult,
                            pendingImport && (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: styles('pending') },
                                    React.createElement(Icon, { name: IconName.SYNC, color: IconColor.BLUE, rotate: true, absolute: true, size: "24px" })),
                                React.createElement("p", null, t(i18nKeys.PENDING_IMPORT))))))),
                    !noFooter && pagination && (React.createElement(TableFooter, { pagination: pagination, setPage: setPage, itemsLength: list.length, setPageLimit: setPageLimit })))),
            loadMore && pagination && pagination.current_page < pagination.last_page && (React.createElement("div", { className: styles('buttons') },
                React.createElement(Button, { noMargin: true, color: ButtonColor.BLUE, label: t(i18nKeys.SHARED.CUSTOM_TABLE.LOAD_MORE), onClick: function () { return loadMore(pagination); } })))));
    }
    return Table;
}
export default CustomTable;
