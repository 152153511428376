import { __read, __spreadArray } from "tslib";
import React from 'react';
import { useProfile } from 'app/App';
import { nice, ticks } from 'd3-array';
import { i18nKeys, useTranslation } from 'locales';
import { clone, initial, last, mergeWith, subtract } from 'lodash-es';
import { first } from 'remeda';
import { BarChartTooltip } from 'shared/charts';
import { useAgedBalanceColors } from 'shared/hooks';
import { formatAmount, useSafeLocalizedCompactCurrencyFormatter } from 'shared/utils/normalization';
import { useMantineTheme } from '@mantine/core';
import { ResponsiveBar } from '@nivo/bar';
var DESIRED_X_AXIS_TICKS = 6;
export var AgedBalanceChart = function (_a) {
    var agedBalanceCompact = _a.agedBalanceCompact;
    var t = useTranslation().t;
    var theme = useMantineTheme();
    var profile = useProfile();
    var colors = useAgedBalanceColors();
    var formatter = useSafeLocalizedCompactCurrencyFormatter();
    var netAgedBalance = mergeWith(clone(agedBalanceCompact.debits), agedBalanceCompact.credits, subtract);
    var barData = Object.entries(netAgedBalance).map(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        return ({
            value: value,
            label: key,
            color: colors[key],
        });
    });
    // Insert the total due amount which is not provided as such by the API
    var overdueAmount = netAgedBalance['0'] + netAgedBalance['30'] + netAgedBalance['60'] + netAgedBalance['90'];
    barData.splice(0, 0, { value: overdueAmount, label: 'totalDue', color: colors.totalDue });
    var barDataOrdered = __spreadArray([last(barData)], __read(initial(barData)), false).reverse();
    var maxValue = Math.max.apply(Math, __spreadArray([], __read(barDataOrdered.map(function (bar) { return bar.value; })), false));
    var minValue = Math.min.apply(Math, __spreadArray([], __read(barDataOrdered.map(function (bar) { return bar.value; })), false));
    var _b = __read(nice(minValue, maxValue, DESIRED_X_AXIS_TICKS), 2), rangeStart = _b[0], rangeEnd = _b[1];
    var xValues = ticks(rangeStart, rangeEnd, DESIRED_X_AXIS_TICKS);
    return (React.createElement(ResponsiveBar, { colors: function (barDatum) { return barDatum.data.color; }, margin: { bottom: 25, left: profile.locale === 'nl' ? 150 : 120, right: 95 }, layout: "horizontal", data: barDataOrdered, indexBy: "label", keys: ['value'], padding: 0.42, isInteractive: true, colorBy: "indexValue", label: function (d) { return formatAmount(d.value, '.', ' '); }, enableGridX: true, enableGridY: false, labelSkipWidth: 85, labelTextColor: theme.colors.gray[0], tooltip: function (_a) {
            var value = _a.data.value;
            // Fix for incorrect initial tooltip position, see https://github.com/plouc/nivo/issues/2161
            setTimeout(function () {
                var tooltip = document.getElementById('tooltip-fix');
                if (tooltip)
                    tooltip.style.opacity = '1';
            }, 50);
            return (React.createElement(BarChartTooltip, { id: "tooltip-fix", style: { opacity: 0, transition: 'all 0.1s ease-in-out' } }, formatAmount(value, '.', ' ')));
        }, axisBottom: {
            tickSize: 0,
            tickValues: xValues,
            format: function (val) { return formatter.format(val).replace(/(\.[0]+|,[0]+)$/, ''); },
        }, axisLeft: {
            tickPadding: xValues[0] < 0 ? 25 : 15,
            tickSize: 0,
            format: function (val) { return t(i18nKeys.ANALYTICS.AGED_BALANCE[val]); },
        }, animate: false, theme: {
            grid: {
                line: {
                    stroke: theme.colors.gray[3],
                    strokeDasharray: 7,
                    strokeDashoffset: 15,
                },
            },
            labels: {
                text: {
                    fontSize: 15,
                    fontWeight: 500,
                    fontFamily: 'Work Sans',
                    fill: theme.colors.gray[6],
                },
            },
            text: {
                fontSize: 14,
                fontFamily: 'Work Sans',
                fill: theme.colors.gray[6],
            },
        }, gridXValues: xValues, layers: [
            'grid',
            'axes',
            'bars',
            function (_a) {
                var bars = _a.bars, innerWidth = _a.innerWidth;
                var _b = __read(__spreadArray([], __read(bars), false).reverse(), 3), secondBar = _b[1], thirdBar = _b[2];
                var secondBarEndPositionY = secondBar.y + secondBar.height;
                var halfwayPointY = (secondBarEndPositionY + thirdBar.y) / 2;
                return (React.createElement("line", { x1: -65, y1: halfwayPointY, x2: innerWidth + 65, y2: halfwayPointY, stroke: "#CED4DA", strokeWidth: "1.5", strokeDasharray: 7 }));
            },
            // See https://github.com/plouc/nivo/issues/146#issuecomment-1009184119
            function (_a) {
                var _b, _c;
                var bars = _a.bars, labelSkipWidth = _a.labelSkipWidth;
                var negativeBars = bars.filter(function (bar) { var _a; return ((_a = bar.data.value) !== null && _a !== void 0 ? _a : 0) < 0; });
                var sortedNegativeBars = __spreadArray([], __read(negativeBars), false).sort(function (a, b) { var _a, _b; return ((_a = a.data.value) !== null && _a !== void 0 ? _a : 0) - ((_b = b.data.value) !== null && _b !== void 0 ? _b : 0); });
                var yAxisNegativeValuePadding = (_c = (_b = first(sortedNegativeBars)) === null || _b === void 0 ? void 0 : _b.width) !== null && _c !== void 0 ? _c : 0;
                return (React.createElement("g", null, bars.map(function (_a) {
                    var width = _a.width, height = _a.height, y = _a.y, data = _a.data;
                    return width < labelSkipWidth ? (React.createElement("text", { key: data.value, fontSize: 16, fontWeight: 500, fontFamily: "Work Sans", fill: theme.colors.gray[6], transform: "translate(".concat(width + 10 + yAxisNegativeValuePadding, ", ").concat(y + height / 2, ")"), textAnchor: "left", dominantBaseline: "central" }, formatAmount(data.value, '.', ' '))) : null;
                })));
            },
        ] }));
};
