import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import { useLoadAgedBalance, useLoadAgedBalanceCompact, useLoadAgedBalanceOverTime, } from 'shared/hooks';
import { PageTitle } from 'shared/layout';
import { ActionIcon, Box, Card, LoadingOverlay } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { UpdatedAt } from '../components';
import { AgedBalanceChart, AgedBalanceOverTimeChart, AgedBalanceTable } from '../components/charts';
export var AgedBalance = function () {
    var history = useHistory();
    var t = useTranslation().t;
    var _a = useLoadAgedBalanceCompact(), agedBalanceCompact = _a.agedBalanceCompact, isAgedBalanceCompactLoading = _a.isAgedBalanceCompactLoading;
    var _b = useLoadAgedBalance(), agedBalance = _b.agedBalance, isAgedBalanceLoading = _b.isAgedBalanceLoading;
    var _c = __read(useState('overTime'), 2), agedBalanceVersion = _c[0], setAgedBalanceVersion = _c[1];
    var _d = useLoadAgedBalanceOverTime(), agedBalanceOverTime = _d.agedBalanceOverTime, isAgedBalanceOverTimeLoading = _d.isAgedBalanceOverTimeLoading;
    // If there are too few dates for the balance over time to display nicely, fallback to compact balance
    useEffect(function () {
        if (!isAgedBalanceOverTimeLoading && agedBalanceOverTime != null) {
            dispatchChartComponent(agedBalanceOverTime);
        }
    }, [isAgedBalanceOverTimeLoading, agedBalanceOverTime]);
    var dispatchChartComponent = function (_agedBalanceOverTime) {
        var amountOfDatesPresent = Object.keys(_agedBalanceOverTime.data).length;
        var shouldFallbackToCompactBalance = amountOfDatesPresent < 2;
        if (shouldFallbackToCompactBalance)
            setAgedBalanceVersion('compact');
    };
    var _e = __read(agedBalanceVersion === 'compact'
        ? [isAgedBalanceCompactLoading, React.createElement(AgedBalanceChart, { agedBalanceCompact: agedBalanceCompact })]
        : [
            isAgedBalanceOverTimeLoading,
            React.createElement(AgedBalanceOverTimeChart, { agedBalanceOverTime: agedBalanceOverTime }),
        ], 2), isLoading = _e[0], chartComponent = _e[1];
    return (React.createElement("div", null,
        React.createElement(PageTitle, null,
            React.createElement(ActionIcon, { onClick: function () { return history.push('/analytics'); }, size: "xl", variant: "light" },
                React.createElement(IconArrowLeft, null)),
            t(i18nKeys.ANALYTICS.AGED_BALANCE.TITLE),
            React.createElement(PageTitle.Actions, null,
                React.createElement(UpdatedAt, null))),
        React.createElement(Card, { radius: "md", shadow: "sm", style: { overflow: 'visible' } },
            React.createElement(Box, { w: "100%" }, isLoading ? React.createElement(LoadingOverlay, { visible: true }) : chartComponent)),
        React.createElement(AgedBalanceTable, { agedBalance: agedBalance, isLoading: isAgedBalanceLoading })));
};
