import { __assign } from "tslib";
import React, { useEffect } from 'react';
import { useProfile } from 'app/App';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import UserInfo from 'shared/components/UserInfo';
import { useLoadLightUsers } from 'shared/hooks';
import { AmountFields, CustomSelect, DateSelector, Input, IntervalFields } from 'shared/io';
import { useFilterForm } from 'shared/utils/hooks';
import { currencySymbol } from 'shared/utils/normalization';
import styleIdentifiers from './KanbanFiltersForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function KanbanFiltersForm(_a) {
    var initialValues = _a.initialValues, onSubmit = _a.onSubmit, filterObject = _a.filterObject;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var constants = useSelector(function (state) { return state.app.constants; });
    var profile = useProfile();
    var _b = useLoadLightUsers().users, users = _b === void 0 ? [] : _b;
    var _c = useFilterForm({
        onSubmit: onSubmit,
        initialValues: initialValues,
        fieldToRegister: [],
    }), form = _c.form, formData = _c.formData, resetForm = _c.resetForm, submit = _c.submit;
    useEffect(function () {
        filterObject.reset = function () {
            resetForm();
        };
    }, [filterObject, resetForm]);
    var handleSubmit = form.handleSubmit, setValue = form.setValue, register = form.register;
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('kanban-filters-form'), onSubmit: handleSubmit(submit) },
            React.createElement("div", { className: styles('fields') },
                React.createElement(DateSelector, { className: styles('input', 'date-selector'), name: "start_date", endName: "end_date", placeholder: t(i18nKeys.OF), endPlaceholder: t(i18nKeys.FORM.TO), withBorder: true, shadow: true, noMargin: true, noMinDate: true, label: t(i18nKeys.ISSUE_DATE), handleChange: function (value) {
                        setValue('start_date', value);
                        submit();
                    }, handleEndChange: function (value) {
                        setValue('end_date', value);
                        submit();
                    } }),
                React.createElement(Input, { register: register('debtor_name'), className: styles('input'), noMargin: true, withBorder: true, shadow: true, type: "text", label: t(i18nKeys.COMMON.CLIENT), onValueChanged: submit }),
                React.createElement(Input, { register: register('reference'), className: styles('input'), noMargin: true, withBorder: true, shadow: true, type: "text", label: t(i18nKeys.FORM.REFERENCE), onValueChanged: submit }),
                React.createElement(IntervalFields, { register: register, className: styles('spacing'), nameFrom: "min_days_late", nameTo: "max_days_late", label: t(i18nKeys.FORM.DAY_OF_DELAY), placeholderTo: t(i18nKeys.FORM.TO), placeholderFrom: t(i18nKeys.FORM.FROM), onChange: submit }),
                React.createElement(AmountFields, { className: styles('spacing'), name: "remaining_balance", label: t(i18nKeys.BALANCE_CURRENCY, {
                        text: currencySymbol(undefined, company, constants),
                    }), onChange: submit }),
                (profile.isAdmin || profile.roles.includes('all_invoices')) && (React.createElement(Controller, { defaultValue: "", name: "account_manager_id", render: function () { return (React.createElement(CustomSelect, { noMargin: true, selectClassName: styles('xl'), keyValue: "id", onValueChanged: submit, withBorder: true, shadow: true, itemRendering: function (props) { return React.createElement(UserInfo, __assign({ picture: true }, props)); }, valueRendering: function (props) { return React.createElement(UserInfo, __assign({}, props, { inline: true })); }, name: "account_manager_id", items: users, label: t(i18nKeys.ACCOUNT_MANAGER) })); } }))))));
}
