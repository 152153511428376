import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import Logo from 'shared/components/Logo';
import styleIdentifiers from './MaintenancePage.scss';
var styles = classNames.bind(styleIdentifiers);
export default function MaintenancePage(_a) {
    var _b = _a.staticPage, staticPage = _b === void 0 ? false : _b;
    var t = useTranslation().t;
    return (React.createElement("div", { className: styles('maintenance-page') },
        React.createElement("div", { className: styles('content-wrapper') },
            React.createElement("div", { className: styles('content', 'card') },
                React.createElement("h1", null, staticPage ? 'Maintenance' : t(i18nKeys.MAINTENANCE.TITLE)),
                React.createElement("p", null, staticPage
                    ? "Good news : an update of the application is in progress ! It won't last long, please refresh this page within a few minutes."
                    : t(i18nKeys.MAINTENANCE.DESCRIPTION))),
            React.createElement(Logo, { className: styles('logo'), type: "blue" }))));
}
