import { z } from 'zod';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { axiosInstance } from './utils';
export var UpdateFooterSchema = z.array(z
    .union([z.null(), z.string(), z.object({ field: z.string(), content: z.string() })])
    .transform(function (val) { return (val == null ? false : val); }));
export var useUpdateInvoiceFooter = function () {
    var client = useQueryClient();
    var mutation = useMutation({
        mutationFn: function (data) {
            return axiosInstance.put('invoice_footer_customisation', {
                fields: data,
            });
        },
        onSuccess: function (response) {
            notifications.show({
                message: response.data.message,
                color: 'green',
            });
            client.invalidateQueries(['invoice-footer']);
        },
    });
    return addActionNameToMutationResult('updateInvoiceFooter', mutation);
};
