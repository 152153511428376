import { __assign, __read, __spreadArray } from "tslib";
import React, { useEffect, useReducer, useState } from 'react';
import { ClientDetailForm } from 'app/Logged/Clients';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { IconName } from 'shared/components/Icon';
import SendingMedia from 'shared/components/SendingMedium/SendingMedia';
import FormSection from 'shared/forms/FormSection';
import { Button, ButtonColor, Checkbox, CustomSelect, Input, VatInput } from 'shared/io';
import { TvaTypes } from 'shared/io/VatInput/VatInput.types';
import { reducerState } from 'shared/utils/view';
import { appActions } from 'store/app/app.actions';
import { sideMenuSetAskBeforeClose } from 'store/view/view.actions';
import ClientAddressForm from './ClientAddressForm/ClientAddressForm';
import ClientCustomVariableForm from './ClientCustomVariableForm/ClientCustomVariableForm';
import BankAccounts from './BankAccounts';
import Emails from './Emails';
import Phones from './Phones';
import styleIdentifiers from './clientForm.scss';
var styles = classNames.bind(styleIdentifiers);
var getTvaType = function (vatNumber, companyCountryCode) {
    if (vatNumber === '')
        return companyCountryCode;
    if (vatNumber.includes(TvaTypes.BE))
        return TvaTypes.BE;
    if (vatNumber.includes(TvaTypes.LU))
        return TvaTypes.LU;
    if (vatNumber.includes(TvaTypes.FR))
        return TvaTypes.FR;
    if (vatNumber.includes(TvaTypes.NL))
        return TvaTypes.NL;
    return 'other';
};
export default function ClientForm(_a) {
    var onSubmit = _a.onSubmit, initialValues = _a.initialValues;
    var company = useSelector(function (state) { return state.account.company.data; });
    var constants = useSelector(function (state) { return state.app.constants; });
    var sending_medium = useSelector(function (state) { return state.app.constants; }).sending_medium;
    var _b = useTranslation(), t = _b.t, availableLang = _b.availableLang;
    var _c = __read(useState([]), 2), phonesTmp = _c[0], setPhonesTmp = _c[1];
    var _d = __read(useState([]), 1), bankAccountTmp = _d[0];
    var _e = __read(useReducer(reducerState, {}), 2), newData = _e[0], setData = _e[1];
    var defaultValues = __assign(__assign({}, initialValues), { tva_type: getTvaType(initialValues.vat_number, company.address_attributes.country_code), sending_media_attributes: ((initialValues === null || initialValues === void 0 ? void 0 : initialValues.sending_media_attributes) || []).map(function (medium) { return (__assign(__assign({}, medium), { force_sending: String(medium.force_sending) })); }) });
    var form = useForm({
        defaultValues: defaultValues,
    });
    var watch = form.watch, setValue = form.setValue, reset = form.reset, register = form.register, handleSubmit = form.handleSubmit, _f = form.formState, isDirty = _f.isDirty, errors = _f.errors, getValues = form.getValues;
    register('dirty');
    register('mainEmailIndex');
    var formValue = watch();
    var tva_type = formValue.tva_type, vat_number = formValue.vat_number, company_name = formValue.company_name, emails = formValue.emails, phones_attributes = formValue.phones_attributes, debtor_bank_accounts_attributes = formValue.debtor_bank_accounts_attributes;
    useEffect(function () {
        if (tva_type === 'BE'
            ? vat_number && vat_number.indexOf('BE') === -1
            : vat_number && vat_number.indexOf('_') > -1) {
            setValue('vat_number', '', { shouldDirty: false });
        }
    }, [tva_type]);
    useEffect(function () {
        sideMenuSetAskBeforeClose(isDirty);
    }, [isDirty]);
    var setFormDirty = function () {
        setTimeout(function () {
            setValue('dirty', formValue.dirty ? formValue.dirty + 1 : 1, {
                shouldDirty: true,
            });
        });
    };
    var addEmail = function () {
        reset(__assign(__assign({}, formValue), { emails: __spreadArray(__spreadArray([], __read((formValue.emails || [])), false), [{ email: '' }], false) }));
    };
    var addPhone = function () {
        reset(__assign(__assign({}, formValue), { phones_attributes: __spreadArray(__spreadArray([], __read((formValue.phones_attributes || [])), false), [{ number: '' }], false) }));
    };
    var addBankAccount = function () {
        reset(__assign(__assign({}, formValue), { debtor_bank_accounts_attributes: __spreadArray(__spreadArray([], __read((formValue.debtor_bank_accounts_attributes || [])), false), [
                { iban: '', bic: '' },
            ], false) }));
    };
    var sending_media_attributes = watch('sending_media_attributes');
    var addSendingMedium = function () {
        var defaultSendingMedium = (sending_medium.filter(function (e) { return !sending_media_attributes.some(function (el) { return e.value === el.name; }); }) || sending_medium)[0];
        if (sending_media_attributes.length < sending_medium.length) {
            setValue('sending_media_attributes', __spreadArray(__spreadArray([], __read(sending_media_attributes), false), [
                {
                    name: defaultSendingMedium.value,
                    order: sending_media_attributes.length + 1,
                    force_sending: 'true',
                },
            ], false), {
                shouldTouch: true,
            });
        }
    };
    var getAddress = function () {
        appActions.fetchAddress({
            data: {
                vat_number: vat_number,
            },
            callback: function (res) {
                var keys = Object.keys(res);
                for (var i = 0; i < keys.length; i += 1) {
                    var key = keys[i];
                    var elem = res[key];
                    if (key === 'company_name')
                        continue;
                    setValue("address_attributes.".concat(key), elem);
                }
                if (res.company_name && !company_name)
                    setValue('full_name', res.company_name);
            },
        });
    };
    var onFormArrayClose = function (name) { return function (isOpen) {
        if (isOpen) {
            setTimeout(function () { return setValue(name, name === 'phones_attributes' ? phonesTmp : bankAccountTmp); }, 100);
        }
        else {
            var values = getValues();
            setPhonesTmp(values[name]);
        }
    }; };
    var submit = function (data) {
        data = __assign(__assign({}, data), newData);
        delete data.dirty;
        if (data.recovery_plan_id === 'default')
            data.recovery_plan_id = null;
        if (data.default_invoice_template_id === 'default')
            data.default_invoice_template_id = null;
        if (data.default_credit_note_template_id === 'default')
            data.default_credit_note_template_id = null;
        if (data.send_to_all_emails === 'default')
            data.send_to_all_emails = null;
        if (data.allow_payment_plans === 'default')
            data.allow_payment_plans = null;
        if (initialValues.unpaid_invoices_present && initialValues.id) {
            onSubmit(data);
        }
        else {
            onSubmit(data);
        }
    };
    return company ? (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('ClientForm'), onSubmit: handleSubmit(submit) },
            React.createElement("div", { className: styles('head') }, initialValues && initialValues.id
                ? t(i18nKeys.FORM.CLIENT.EDIT_RECORD)
                : t(i18nKeys.NEW_CLIENT)),
            React.createElement("div", { className: styles('content') },
                React.createElement("div", { className: "grid-row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement(Input, { register: register('full_name', { required: true }), errorMessage: errors.full_name, className: styles('input', 'first-field'), withBorder: true, noMargin: true, type: "text", label: t(i18nKeys.FORM.CLIENT.FULL_NAME) })),
                    React.createElement("div", { className: "col-6" },
                        React.createElement(Checkbox, { register: register('moral_entity'), watch: watch, label: t(i18nKeys.FORM.CLIENT.LEGAL_ENTITY) })),
                    React.createElement("div", { className: "col-6" },
                        React.createElement(Controller, { defaultValue: "", name: "locale", render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "description", keyValue: "value", items: availableLang, withBorder: true, name: "locale", label: t(i18nKeys.FORM.CLIENT.CONTACT_LANG) })); } })),
                    React.createElement("div", { className: "col-12" },
                        React.createElement(VatInput, { className: styles('input', 'vat-input'), withBorder: true, name: "vat_number", label: t(i18nKeys.FORM.VAT) }),
                        vat_number && vat_number.indexOf('_') === -1 && (React.createElement("div", { className: styles('info') },
                            React.createElement(Button, { noMargin: true, noShadow: true, color: ButtonColor.BLUE, className: styles('link'), label: t(i18nKeys.FORM.CLIENT.PRE_FILL), onClick: getAddress })))),
                    React.createElement("div", { className: "col-12" },
                        React.createElement(Input, { register: register('reference'), errorMessage: errors.reference, className: styles('input'), withBorder: true, noMargin: true, type: "text", label: t(i18nKeys.DEBTOR.REFERENCE), placeholder: t(i18nKeys.DEBTOR.REFERENCE_PLACEHOLDER) })),
                    React.createElement("div", { className: "col-12" },
                        React.createElement(Controller, { defaultValue: company.currency, name: "currency", render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "description", keyValue: "value", items: constants.available_currency, withBorder: true, name: "currency", label: t(i18nKeys.FORM.CLIENT.CURRENCY) })); } })),
                    React.createElement("div", { className: "col-12" },
                        React.createElement(Checkbox, { register: register('co_contractor'), watch: watch, className: styles('input'), label: t(i18nKeys.FORM.CLIENT.COCONTRACTOR_VAT0) }),
                        React.createElement(Checkbox, { register: register('intracom'), watch: watch, className: styles('input'), label: t(i18nKeys.FORM.CLIENT.INTRA_COMMUNITY_VAT0) })),
                    React.createElement(FormSection, { className: styles('email-section'), title: t(i18nKeys.EMAILS), onAdd: addEmail },
                        React.createElement(Emails, { className: styles('input'), formArray: emails, setFormDirty: setFormDirty })),
                    React.createElement(FormSection, { title: t(i18nKeys.ADDRESS) },
                        React.createElement(ClientAddressForm, null)),
                    React.createElement(FormSection, { className: styles('phone-section'), title: t(i18nKeys.PHONE), onAdd: addPhone, onToggle: onFormArrayClose },
                        React.createElement(Phones, { className: styles('input'), formArray: phones_attributes, setFormDirty: setFormDirty })),
                    React.createElement(FormSection, { title: t(i18nKeys.COMMON.BANK_ACCOUNT), onAdd: addBankAccount },
                        React.createElement(BankAccounts, { className: styles('input'), formArray: debtor_bank_accounts_attributes, setFormDirty: setFormDirty })),
                    React.createElement(FormSection, { title: t(i18nKeys.DEBTOR.SENDING_MEDIUMS) },
                        React.createElement(SendingMedia, { fieldName: "sending_media_attributes", className: styles('input'), withBorder: true, required: false }),
                        sending_media_attributes.length < sending_medium.length && (React.createElement(Button, { color: ButtonColor.GREY, label: t(i18nKeys.ACTIONS.ADD), iconRight: IconName.PLUS, onClick: addSendingMedium, small: true }))),
                    React.createElement(FormSection, { title: t(i18nKeys.PREFERENCES) },
                        React.createElement(ClientDetailForm, { data: initialValues, setData: setData })),
                    React.createElement(FormSection, { title: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.LONG_TITLE) },
                        React.createElement(ClientCustomVariableForm, null)))),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { disabled: !isDirty, noMargin: true, label: t(i18nKeys.SAVE), type: "submit" }))))) : null;
}
