var _a;
import { __assign } from "tslib";
import { DialogShowId, viewActionTypes as events } from 'store/view/view.actions';
export var initialState = {
    dialogs: (_a = {
            count: 0
        },
        _a[DialogShowId.CONFIRM] = {
            active: false,
        },
        _a[DialogShowId.CUSTOM] = {
            active: false,
        },
        _a[DialogShowId.INVOICE] = {
            active: false,
        },
        _a),
    sideMenu: {
        active: false,
    },
    progress: {
        active: false,
    },
    backgroundLoader: {
        active: false,
        title: '',
        description: '',
    },
    inMaintenance: false,
    isMobile: false,
};
var reducer = function (state, action) {
    var _a, _b;
    if (state === void 0) { state = initialState; }
    var item;
    switch (action.type) {
        case events.dialog.show:
            item = action.payload;
            item.active = true;
            return __assign(__assign({}, state), { dialogs: __assign(__assign({}, state.dialogs), (_a = { count: state.dialogs.count + 1 }, _a[item.id] = item, _a)) });
        case events.dialog.hide:
            return __assign(__assign({}, state), { dialogs: __assign(__assign({}, state.dialogs), (_b = { count: state.dialogs.count - 1 }, _b[action.payload] = __assign(__assign({}, state.dialogs[action.payload]), { active: false }), _b)) });
        case events.sideMenu.show:
            return __assign(__assign({}, state), { sideMenu: __assign(__assign({}, action.payload), { active: true }) });
        case events.sideMenu.setAskBeforeClose:
            return __assign(__assign({}, state), { sideMenu: __assign(__assign({}, state.sideMenu), { askBeforeClose: action.payload }) });
        case events.sideMenu.hide:
            return __assign(__assign({}, state), { sideMenu: __assign(__assign({}, state.sideMenu), { active: false }) });
        case events.backgroundLoader.show:
            return __assign(__assign({}, state), { backgroundLoader: {
                    active: true,
                    title: action.payload.title,
                    description: action.payload.description,
                } });
        case events.backgroundLoader.hide:
            return __assign(__assign({}, state), { backgroundLoader: {
                    active: false,
                    title: '',
                    description: '',
                } });
        case events.progress.set:
            return __assign(__assign({}, state), { progress: __assign(__assign(__assign({}, state.progress), action.payload), { active: true, errors: undefined }) });
        case events.progress.reset:
            return __assign(__assign({}, state), { progress: {
                    active: false,
                    errors: undefined,
                    value: 0,
                } });
        case events.progress.error:
            return __assign(__assign({}, state), { progress: __assign(__assign({}, state.progress), { active: false, errors: action.payload.errors }) });
        case events.showMaintenancePage:
            return __assign(__assign({}, state), { inMaintenance: true });
        case events.onResizePage:
            return __assign(__assign({}, state), action.payload);
        default:
            return state;
    }
};
export default reducer;
