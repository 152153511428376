import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import qs from 'query-string';
import React from 'react';
import { useHistory } from 'react-router';
import { accountActions } from 'store/account/account.actions';
import styleIdentifiers from './createPassword.scss';
import { CreatePasswordForm } from './CreatePasswordForm';
var styles = classNames.bind(styleIdentifiers);
export default function CreatePassword(_a) {
    var location = _a.location;
    var t = useTranslation().t;
    var history = useHistory();
    var submit = function (values) {
        accountActions.acceptInvitation({
            noToken: true,
            data: values,
            callback: function () {
                history.push('/login');
            },
        });
    };
    var parsedSearch = qs.parse(location.search);
    return (React.createElement("div", { className: styles('CreatePassword') },
        React.createElement("div", { className: styles('content-wrapper') },
            React.createElement("div", { className: styles('content') },
                React.createElement("div", { className: styles('info') }, t(i18nKeys.LOGIN.CREATE_PASSWORD)),
                React.createElement(CreatePasswordForm, { initialValues: {
                        user: parsedSearch,
                    }, onSubmit: submit })))));
}
