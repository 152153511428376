import { __assign } from "tslib";
import { TaskFormDebtor, TaskFormInvoices } from 'app/Logged/Tasks';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { isNil, omit, omitBy } from 'lodash-es';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { CustomSelect } from 'shared/io';
import { PaymentPlanStatus } from '../PaymentPlan.constants';
import styleIdentifiers from './PaymentPlansList.scss';
var styles = classNames.bind(styleIdentifiers);
export var PaymentPlansListFilters = function (_a) {
    var onSubmit = _a.onSubmit, initialValues = _a.initialValues, filterObject = _a.filterObject;
    var t = useTranslation().t;
    var form = useForm({
        shouldUnregister: true,
        defaultValues: omit(initialValues, ['page', 'page_limit', 'sort_by', 'sort_order']),
    });
    var setValue = form.setValue, handleSubmit = form.handleSubmit, watch = form.watch, getValues = form.getValues;
    var values = watch();
    useEffect(function () {
        filterObject.reset = function () {
            Object.keys(getValues()).forEach(function (key) {
                return form.setValue(key, '');
            });
        };
    }, [filterObject, form, getValues, setValue]);
    useEffect(function () {
        onSubmit(omitBy(values, function (v) { return isNil(v) || (Array.isArray(v) && !v.length) || v === ''; }));
    }, [values, onSubmit]);
    var planStatus = Object.values(PaymentPlanStatus).map(function (value) { return ({
        description: t(i18nKeys[value]),
        value: value.toLowerCase(),
    }); });
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { onSubmit: handleSubmit(onSubmit), className: styles('ThirdpartyCasesFiltersForm') },
            React.createElement("div", { className: styles('container-fields') },
                React.createElement("div", { className: styles('fields') },
                    React.createElement(Controller, { name: "status", render: function () { return (React.createElement(CustomSelect, { name: "status", keyText: "description", keyValue: "value", items: planStatus, label: t(i18nKeys.TASK.ATTRIBUTES.STATUS), withBorder: true, multiple: true })); } }),
                    React.createElement(TaskFormDebtor, null),
                    React.createElement(TaskFormInvoices, null))))));
};
