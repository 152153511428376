import { __read } from "tslib";
import React, { useEffect } from 'react';
import { useGetPrivateConstants, useGetPublicConstants } from 'api';
import Logged from 'app/Logged';
import NotLogged from 'app/NotLogged';
import classNames from 'classnames/bind';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router';
import ErrorBoundary from 'shared/components/ErrorBoundary';
import Loader from 'shared/components/Loader';
import Modals from 'shared/components/Modals';
import SideMenu from 'shared/components/SideMenu';
import { useLoadCompany, useLoadProfile } from 'shared/hooks';
import { useValidateTenant } from 'shared/hooks/use-validate-tenant';
import apiService from 'shared/service/api.service';
import { getEnvIcon, isMaintenance } from 'shared/utils/environment';
import { useMediaQuery } from 'shared/utils/hooks';
import { useGetCompany } from 'shared/utils/selectors';
import { accountActions } from 'store/account/account.actions';
import { appActions } from 'store/app/app.actions';
import { translateAction } from 'store/translate/translate.actions';
import { onResizePage } from 'store/view/view.actions';
import * as Sentry from '@sentry/react';
import BadCompany from './NotLogged/BadCompany';
import DebtorReaction from './NotLogged/DebtorReaction';
import MaintenancePage from './MaintenancePage';
import ServerErrorPage from './ServerErrorPage';
import styleIdentifiers from './App.scss';
var styles = classNames.bind(styleIdentifiers);
export var tenantAtom = atom(undefined);
tenantAtom.debugLabel = 'Tenant';
export var useTenant = function () { return useAtomValue(tenantAtom); };
var PublicLoader = function (_a) {
    var _b, _c;
    var children = _a.children;
    var _d = useValidateTenant(apiService.tenant), tenant = _d.tenant, isTenantLoading = _d.isTenantLoading, tenantError = _d.tenantError;
    var setTenant = useSetAtom(tenantAtom);
    var history = useHistory();
    useEffect(function () {
        setTenant(tenant);
        Sentry.setTag('tenant', tenant === null || tenant === void 0 ? void 0 : tenant.domain);
    }, [tenant]);
    useEffect(function () {
        var _a, _b;
        if (((_a = tenantError === null || tenantError === void 0 ? void 0 : tenantError.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
            history.push('/bad-company');
        }
        if ((_b = tenantError === null || tenantError === void 0 ? void 0 : tenantError.response) === null || _b === void 0 ? void 0 : _b.status) {
            history.push('/server-error');
        }
    }, [(_b = tenantError === null || tenantError === void 0 ? void 0 : tenantError.response) === null || _b === void 0 ? void 0 : _b.status]);
    if (isTenantLoading)
        return React.createElement(Loader, null);
    if (((_c = tenantError === null || tenantError === void 0 ? void 0 : tenantError.response) === null || _c === void 0 ? void 0 : _c.status) === 404)
        return null;
    if (tenantError)
        return null;
    return React.createElement(React.Fragment, null, children);
};
// @ts-ignore
export var profileAtom = atom(null);
profileAtom.debugLabel = 'Profile';
export var useProfile = function () { return useAtomValue(profileAtom); };
// ? Work in progress: will eventually be moved to its own location
var LoggedLoader = function (_a) {
    var children = _a.children;
    var companyV1 = useSelector(function (state) { return state.account.company.data; });
    var locale = useSelector(function (state) { return state.translate.currentLang; });
    var _b = __read(useAtom(profileAtom), 2), profileFromAtom = _b[0], setProfile = _b[1];
    // We use this manually set local state because the same slot in the store is also used by the public constants
    // which will be loaded by the unauthenticated parts of the app.
    var _c = __read(React.useState(false), 2), arePrivateConstantsLoaded = _c[0], setArePrivateConstantsLoaded = _c[1];
    // The company's initial onboarding takes place in the "Logged" state, but only the `company` and `private constants`
    // requests are allowed at that time. Others will fail until onboarding is completed
    var isOnboardingCompleted = Boolean(companyV1 === null || companyV1 === void 0 ? void 0 : companyV1.onboarding_done);
    var profile = useLoadProfile({ enabled: isOnboardingCompleted }).profile;
    var company = useLoadCompany().company;
    var constantsRes = useGetPrivateConstants({
        locale: locale,
    }).data;
    useEffect(function () {
        accountActions.getCompany();
    }, []);
    useEffect(function () {
        if (constantsRes != null) {
            appActions.constantsRes(constantsRes, 'fulfilled', constantsRes);
            setArePrivateConstantsLoaded(true);
        }
    }, [constantsRes]);
    useEffect(function () {
        if (profile) {
            setProfile(profile);
            translateAction.changeLang(profile.locale);
            moment.locale(profile.locale);
            Sentry.setTag('email', profile.email);
            Sentry.setUser({
                id: String(profile.id),
                email: profile.email,
                username: profile.name.full,
                roles: profile.roles,
                isAdmin: profile.isAdmin,
            });
        }
    }, [profile, setProfile]);
    if (companyV1 == null || !arePrivateConstantsLoaded)
        return React.createElement(Loader, null);
    if (!isOnboardingCompleted)
        return React.createElement(React.Fragment, null, children); // <- This is fragile because we depend on the routes
    // mounted below to obey the invariants; i.e. remain in the onboarding flow where the company and the profile
    // are not needed.
    // If the onboarding is completed, we do block on those requests' completion because other routes
    // that do access these resources will now be mounted.
    // TODO: as a result, it would be better to extract the onboarding into its own flow, on the same level as
    // TODO: "Logged", where each can only mount the routes it will use.
    if (profileFromAtom == null || company == null)
        return React.createElement(Loader, null);
    return React.createElement(React.Fragment, null, children);
};
var NotLoggedLoader = function (_a) {
    var children = _a.children;
    var locale = useSelector(function (state) { return state.translate.currentLang; });
    var constants = useSelector(function (state) { return state.app.constants; });
    var constantsRes = useGetPublicConstants({
        locale: locale,
    }).data;
    useEffect(function () {
        if (constantsRes != null)
            appActions.constantsRes(constantsRes, 'fulfilled', constantsRes);
    }, [constantsRes]);
    if (constants == null)
        return React.createElement(Loader, null);
    return React.createElement(React.Fragment, null, children);
};
export default function Main() {
    var isMobile = useMediaQuery('(max-width: 480px)');
    var tenant = useTenant();
    var session = useSelector(function (state) { return state.session.data; });
    var company = useGetCompany();
    useEffect(function () {
        var _a;
        if ((_a = tenant === null || tenant === void 0 ? void 0 : tenant.customBranding) === null || _a === void 0 ? void 0 : _a.color) {
            document.body.style.setProperty('--main-color', tenant.customBranding.color);
            document.body.style.setProperty('--text-main-color', tenant.customBranding.color);
            document.body.style.setProperty('--main-lighter-color', tenant.customBranding.color + 30);
            document.body.style.setProperty('--main-lighter-2-color', tenant.customBranding.color + 50);
        }
    }, [tenant === null || tenant === void 0 ? void 0 : tenant.customBranding]);
    useEffect(function () {
        var _a;
        document.title = "".concat(getEnvIcon()).concat((_a = company === null || company === void 0 ? void 0 : company.name) !== null && _a !== void 0 ? _a : 'Recovr', " ");
    }, [company === null || company === void 0 ? void 0 : company.name]);
    useEffect(function () {
        onResizePage({
            isMobile: isMobile,
        });
    }, [isMobile]);
    if (isMaintenance()) {
        return (React.createElement("div", { className: styles('App') },
            React.createElement(ErrorBoundary, null,
                React.createElement(MaintenancePage, { staticPage: true }))));
    }
    return (React.createElement("div", { className: styles('App') },
        React.createElement(ErrorBoundary, null,
            React.createElement(Modals, null),
            React.createElement(SideMenu, null),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "/server-error", component: ServerErrorPage }),
                React.createElement(Route, { exact: true, path: "/bad-company", component: BadCompany }),
                React.createElement(PublicLoader, null,
                    React.createElement(Switch, null,
                        React.createElement(Route, { exact: true, path: "/invoices/:id/debtor-reaction", component: DebtorReaction }),
                        session ? (React.createElement(LoggedLoader, null,
                            React.createElement(Logged, null))) : (React.createElement(NotLoggedLoader, null,
                            React.createElement(NotLogged, null)))))))));
}
