import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, axiosInstance } from './utils';
var DsoSchema = z.record(z.coerce.string().datetime({ offset: true }), z.number().nullable());
export function useLoadDsoFn() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance.get('/analytics/dsos')];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, DsoSchema.parse(data)];
            }
        });
    });
}
export var useLoadDso = function () {
    var queryResult = useQuery({
        queryKey: ['dso'],
        queryFn: useLoadDsoFn,
    });
    return addResourceNameToQueryResult('dso', queryResult);
};
