import classNames from 'classnames/bind';
import React from 'react';
import Amount from 'shared/components/Amount';
import styleIdentifiers from './ClientDetailTabsBalance.scss';
var styles = classNames.bind(styleIdentifiers);
export var ClientDetailTabsBalanceItemCreditNote = function (_a) {
    var item = _a.item, showDetails = _a.showDetails;
    var _b = item.attributes, total_tvac = _b.total_tvac, remaining_balance = _b.remaining_balance, localized_money_object = _b.localized_money_object, currency = _b.currency, manual_payments = _b.manual_payments, matched_payments = _b.matched_payments;
    var totalTvac = Number(total_tvac);
    var localizedTotalTvac = Number(localized_money_object.total_tvac);
    var remainingBalance = Number(remaining_balance);
    var localizedRemainingBalance = Number(localized_money_object.remaining_balance);
    return (React.createElement(React.Fragment, null,
        React.createElement("td", { className: styles('debit') },
            React.createElement(Amount, { localizedValue: (totalTvac - remainingBalance) * -1, value: (localizedTotalTvac - localizedRemainingBalance) * -1, suffix: currency }),
            showDetails && (React.createElement("div", { className: styles('details') },
                React.createElement("p", null, "\u200B"),
                matched_payments.map(function (_a) {
                    var amount = _a.attributes.amount;
                    return (React.createElement(Amount, { localizedValue: amount, value: amount, suffix: currency }));
                }),
                manual_payments.map(function (_a) {
                    var amount = _a.attributes.amount;
                    return (React.createElement(Amount, { localizedValue: amount * -1, value: amount * -1, suffix: currency }));
                })))),
        React.createElement("td", { className: styles('credit') },
            React.createElement(Amount, { localizedValue: totalTvac * -1, value: localizedTotalTvac * -1, suffix: currency }),
            showDetails && (React.createElement("div", { className: styles('details') },
                React.createElement(Amount, { localizedValue: totalTvac * -1, value: localizedTotalTvac * -1, suffix: currency })))),
        React.createElement("td", { className: styles('balance') },
            React.createElement(Amount, { localizedValue: remainingBalance, value: localizedRemainingBalance, suffix: currency }))));
};
