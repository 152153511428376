import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button, CustomSelect, Input } from 'shared/io';
import styleIdentifiers from './addressForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function AddressForm(_a) {
    var onSubmit = _a.onSubmit, initialValues = _a.initialValues;
    var t = useTranslation().t;
    var constants = useSelector(function (state) { return state.app.constants; });
    var form = useForm({
        defaultValues: initialValues,
    });
    var handleSubmit = form.handleSubmit, errors = form.formState.errors, register = form.register;
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('AddressForm'), onSubmit: handleSubmit(onSubmit) },
            React.createElement("div", { className: styles('content') },
                React.createElement("div", { className: styles('grid-row') },
                    React.createElement("div", { className: styles('col-12') },
                        React.createElement(Input, { register: register('additional_line_1', { maxLength: 35 }), className: styles('input'), withBorder: true, maxLength: 35, errorMessage: errors.additional_line_1, type: "text", label: t(i18nKeys.ADDITIONAL_LINE_1) })),
                    React.createElement("div", { className: styles('col-12') },
                        React.createElement(Input, { register: register('additional_line_2', { maxLength: 35 }), errorMessage: errors.additional_line_2, className: styles('input'), withBorder: true, type: "text", label: t(i18nKeys.ADDITIONAL_LINE_2) })),
                    React.createElement("div", { className: styles('col-6') },
                        React.createElement(Input, { register: register('street_name', { required: true }), errorMessage: errors.street_name, className: styles('input'), withBorder: true, label: t(i18nKeys.FORM.ADDRESS.STREET) })),
                    React.createElement("div", { className: styles('col-3') },
                        React.createElement(Input, { register: register('street_number', { required: true }), errorMessage: errors.street_number, className: styles('input'), withBorder: true, label: t(i18nKeys.NUMBER), noErrorText: true })),
                    React.createElement("div", { className: styles('col-3') },
                        React.createElement(Input, { register: register('street_box'), className: styles('input'), withBorder: true, label: t(i18nKeys.FORM.ADDRESS.BOX), noErrorText: true })),
                    React.createElement("div", { className: styles('col-6') },
                        React.createElement(Input, { register: register('zip_code', { required: true }), errorMessage: errors.zip_code, className: styles('input'), withBorder: true, label: t(i18nKeys.FORM.ADDRESS.POSTAL_CODE) })),
                    React.createElement("div", { className: styles('col-6') },
                        React.createElement(Input, { register: register('city', { required: true }), errorMessage: errors.city, className: styles('input'), withBorder: true, label: t(i18nKeys.FORM.ADDRESS.CITY) })),
                    React.createElement("div", { className: styles('col-12') },
                        React.createElement(Controller, { defaultValue: "", name: "country_code", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { className: styles('input'), keyText: "description", name: "country_code", keyValue: "value", withBorder: true, filter: true, items: constants.countries, label: t(i18nKeys.FORM.ADDRESS.COUNTRY) })); } })))),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { label: t(i18nKeys.ACTIONS.ADD), type: "submit" })))));
}
