import React from 'react';
import { useGetReasons } from 'api';
import { ReasonListItem } from 'app/Logged/Tasks/TaskTypeList/ReasonListItem';
import classNames from 'classnames';
import { i18nKeys, useTranslation } from 'locales';
import CustomTable from 'shared/components/CustomTable';
import styleIdentifiers from './CustomizationsSettingsReasons.scss';
var styles = classNames.bind(styleIdentifiers);
var ReasonsTable = CustomTable(ReasonListItem);
export var CustomizationsSettingsReasons = function () {
    var _a;
    var t = useTranslation().t;
    var _b = useGetReasons(), reasonsListResponse = _b.data, reasonLoading = _b.isLoading, reasonSuccess = _b.isSuccess;
    var TABLE_HEADERS = [
        {
            key: 'id',
            title: 'ID',
        },
        {
            key: 'name',
            title: t(i18nKeys.NAME),
        },
        {
            key: 'reason_type',
            title: t(i18nKeys.FORM.TYPE),
        },
        {
            key: 'creation',
            title: t(i18nKeys.CREATION),
        },
        {
            title: t(i18nKeys.FORM.ACTIONS),
            width: 100,
        },
    ];
    return (React.createElement(ReasonsTable, { notSortable: true, headers: TABLE_HEADERS, items: (reasonsListResponse === null || reasonsListResponse === void 0 ? void 0 : reasonsListResponse.data) || [], pagination: (_a = reasonsListResponse === null || reasonsListResponse === void 0 ? void 0 : reasonsListResponse.metadata) === null || _a === void 0 ? void 0 : _a.pagination, loading: reasonLoading, loaded: reasonSuccess, noCheckbox: true, noShadow: true }));
};
