import { __read } from "tslib";
import React, { useLayoutEffect, useState } from 'react';
import { useGetDebtorsInfinite } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router';
import { flatten, map, pipe } from 'remeda';
import EditClient from 'shared/action-component/EditClient';
import { Button, CustomSelect } from 'shared/io';
import { treatClient } from 'shared/serializer';
import { getNextPageParam } from 'shared/utils';
import { getSearchInvoicesUrlParams } from 'shared/utils/hooks';
import { clientActions } from 'store/client/client.actions';
import styleIdentifiers from '../InvoiceForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var DebtorField = function (_a) {
    var _b;
    var t = useTranslation().t;
    var _c = useFormContext(), setValue = _c.setValue, watch = _c.watch;
    var _d = __read(useState(), 2), selectSearchValue = _d[0], setSelectSearchValue = _d[1];
    var _e = useGetDebtorsInfinite({ name: selectSearchValue }, {
        query: {
            getNextPageParam: getNextPageParam,
        },
    }), debtorsResponse = _e.data, fetchNextPage = _e.fetchNextPage;
    var location = useLocation();
    var params = getSearchInvoicesUrlParams(location);
    var currentDebtor = watch('debtor_attributes');
    useLayoutEffect(function () {
        var debtor_id = params.debtor_id;
        if (!currentDebtor && debtor_id) {
            clientActions.detail({
                id: Number(debtor_id),
                callback: function (_a) {
                    var dataDebtor = _a.data;
                    var findedDebtor = treatClient(dataDebtor);
                    if (!findedDebtor) {
                        return;
                    }
                    setValue('debtor_attributes', findedDebtor);
                },
            });
        }
    }, []);
    var addDebtor = function () {
        return EditClient({
            callback: function (newDebtor) {
                setValue('debtor_attributes', newDebtor);
            },
        });
    };
    var pages = (_b = debtorsResponse === null || debtorsResponse === void 0 ? void 0 : debtorsResponse.pages) !== null && _b !== void 0 ? _b : [];
    var debtors = pipe(pages, map(function (e) { return e.data; }), flatten, map(treatClient));
    return (React.createElement(React.Fragment, null,
        React.createElement(Controller, { name: "debtor_attributes", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { name: "debtor_attributes", items: debtors, keyText: "full_name", className: styles('input', 'invoice-select-input'), selectClassName: styles('input', 'invoice-custom-select'), valueClassName: styles('value'), errorClassName: styles('error'), noArrow: true, noBorder: true, filter: true, onChangeFilter: setSelectSearchValue, noMargin: true, infiniteScroll: true, placeholderFilter: t(i18nKeys.FORM.RESEARCH), placeholder: t(i18nKeys.INVOICING.CREATE.SELECT_CLIENT), load: fetchNextPage })); } }),
        React.createElement(Button, { label: "+", onClick: addDebtor, noMargin: true })));
};
