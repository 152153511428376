import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, legacyAxiosInstance } from './utils';
var ViewSchema = z
    .object({
    attributes: z.object({
        name: z.string(),
    }),
    id: z.string(),
})
    .transform(function (data) { return ({ id: data.id, name: data.attributes.name }); });
var ViewsSchema = z.object({ data: z.array(ViewSchema) }).transform(function (data) { return data.data; });
export function loadViewsQueryFn(_a) {
    return __awaiter(this, arguments, void 0, function (_b) {
        var data;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, legacyAxiosInstance.get('views', {
                        params: {
                            view_type: queryKey[1],
                        },
                    })];
                case 1:
                    data = (_c.sent()).data;
                    return [2 /*return*/, ViewsSchema.parse(data)];
            }
        });
    });
}
export function useLoadViews(_a) {
    var resourceType = _a.resourceType;
    var queryResult = useQuery({
        queryKey: ['views', resourceType],
        queryFn: loadViewsQueryFn,
    });
    return addResourceNameToQueryResult('views', queryResult);
}
