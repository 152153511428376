import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { axiosInstance } from './utils';
export var useArchiveReport = function () {
    var client = useQueryClient();
    var mutation = useMutation({
        mutationFn: function (id) {
            return axiosInstance.put("/reports/export_tasks/".concat(id, "/archive"));
        },
        onSuccess: function (response) {
            notifications.show({
                message: response.data.message,
                color: 'green',
            });
            client.invalidateQueries(['reports']);
        },
    });
    return addActionNameToMutationResult('archiveReport', mutation);
};
