import { z } from 'zod';
import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { addActionNameToMutationResult, axiosInstance } from './utils';
export var AcceptGcollectRatesVariablesSchema = z.object({
    thirdPartyCaseIds: z.array(z.number()),
    acceptRates: z.boolean(),
});
export function useAcceptGcollectRates() {
    var mutation = useMutation({
        mutationFn: function (vars) {
            var data = axiosInstance.post('gcollect_cases/accept_rates', AcceptGcollectRatesVariablesSchema.parse(vars));
            return data;
        },
        onError: function (error) {
            var _a;
            console.error(error);
            notifications.show({
                title: 'Error',
                color: 'red',
                message: (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.error_message,
            });
        },
    });
    return addActionNameToMutationResult('acceptGcollectRates', mutation);
}
