import { __assign } from "tslib";
import { useGetDebtorSendingOptions } from 'api';
import { SendingMediaNames } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Loader from 'shared/components/Loader';
import AddressPartialForm from 'shared/forms/partial/AddressPartialForm';
import SendInvoiceFormEmail from 'shared/forms/SendInvoiceForm/SendInvoiceFormEmail';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { Button, Checkbox } from 'shared/io';
import { useSelector } from 'react-redux';
import { dialogHide, DialogShowId } from 'store/view/view.actions';
import styleIdentifiers from '../ToHandleDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export default function InvoiceSendingOptions(_a) {
    var invoiceId = _a.invoiceId, sendingOptions = _a.sendingOptions, debtorId = _a.debtorId;
    var t = useTranslation().t;
    var sending_medium = useSelector(function (state) { return state.app.constants; }).sending_medium;
    var _b = useGetDebtorSendingOptions(debtorId), sendingOptionsResponse = _b.data, isError = _b.isError, isLoading = _b.isLoading;
    var mediaEnabled = function (medium) {
        return Boolean(sendingOptions.sending_media_attributes.find(function (_a) {
            var name = _a.name;
            return name === medium;
        }));
    };
    var defaultValues = {
        emails: sendingOptions.emails,
        addresses_attributes: sendingOptions.addresses_attributes,
        send_email: mediaEnabled(SendingMediaNames.email),
        send_post: mediaEnabled(SendingMediaNames.post),
        send_registered_post: mediaEnabled(SendingMediaNames.registered_post),
    };
    var form = useForm({
        defaultValues: defaultValues,
    });
    var watch = form.watch, register = form.register;
    if (isLoading && !sendingOptionsResponse) {
        return React.createElement(Loader, null);
    }
    if (isError || !sendingOptionsResponse) {
        return React.createElement("span", null, "Error");
    }
    var addresses = sendingOptionsResponse.addresses, emails = sendingOptionsResponse.emails;
    var sendEmail = watch('send_email');
    var sendPost = watch('send_post');
    var sendRegisteredPost = watch('send_registered_post');
    var saveSendingOptions = function () {
        invoiceActions.setSendingOptions({
            id: invoiceId,
            data: {
                emails: watch('emails'),
                addresses_attributes: watch('addresses_attributes'),
                send_email: sendEmail,
                send_post: sendPost,
                send_registered_post: sendRegisteredPost,
            },
            callback: function () {
                dialogHide(DialogShowId.CUSTOM);
            },
        });
    };
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('invoice-sending-options-form') },
            React.createElement("div", { className: styles('send-by') },
                React.createElement("div", { className: styles('label-input') }, t(i18nKeys.INVOICE.SEND_REMINDERS_BY)),
                React.createElement("div", { className: styles('sending-media') }, sending_medium.map(function (_a) {
                    var value = _a.value, description = _a.description;
                    return (React.createElement(Checkbox, { key: value, register: register("send_".concat(value)), watch: watch, label: React.createElement("span", null, description), disabled: (value === SendingMediaNames.post && watch('send_registered_post')) ||
                            (value === SendingMediaNames.registered_post && watch('send_post')) }));
                }))),
            sendEmail && (React.createElement("div", { className: styles('section') },
                React.createElement("div", { className: styles('input-wrapper') },
                    React.createElement(SendInvoiceFormEmail, { emails: emails })))),
            (sendPost || sendRegisteredPost) && (React.createElement("div", { className: styles('section') },
                React.createElement("div", { className: styles('input-wrapper') },
                    React.createElement(AddressPartialForm, { addresses: addresses })))),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { onClick: saveSendingOptions, noShadow: true, label: t(i18nKeys.CONFIRM) })))));
}
