import { useGetDebtor } from 'api';
import classNames from 'classnames/bind';
import React from 'react';
import { useParams } from 'react-router';
import Loader from 'shared/components/Loader';
import styleIdentifiers from './ClientDetail.scss';
import { ClientDetailHeader } from './ClientDetailHeader';
import { ClientDetailTabs } from './ClientDetailTabs';
var styles = classNames.bind(styleIdentifiers);
export var ClientDetail = function () {
    var debtorId = useParams().id;
    var _a = useGetDebtor(debtorId), debtorDetailsResponse = _a.data, isLoading = _a.isLoading, isError = _a.isError, refetch = _a.refetch;
    if (isLoading && !debtorDetailsResponse) {
        return React.createElement(Loader, null);
    }
    if (isError || !debtorDetailsResponse) {
        return React.createElement("span", null, "Error");
    }
    var debtor = debtorDetailsResponse.data;
    return (React.createElement("div", { className: styles('client-detail') },
        React.createElement(ClientDetailHeader, { debtor: debtor, refetch: function () { return refetch(); } }),
        React.createElement(ClientDetailTabs, { debtor: debtor, refetch: function () { return refetch(); } })));
};
