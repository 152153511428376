import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { get } from 'lodash-es';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button, Checkbox, CustomSelect, Input, VatInput } from 'shared/io';
import { config } from 'store/constants';
import styleIdentifiers from './SignupAccountForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function SignupAccountForm(_a) {
    var _b;
    var onSubmit = _a.onSubmit, active = _a.active, initialValues = _a.initialValues;
    var _c = useTranslation(), t = _c.t, currentLang = _c.currentLang;
    var constants = useSelector(function (state) { return state.app.constants; });
    var form = useForm({
        shouldUnregister: true,
        defaultValues: initialValues,
    });
    var register = form.register, watch = form.watch, setValue = form.setValue, handleSubmit = form.handleSubmit, errors = form.formState.errors, getValues = form.getValues;
    var vatType = watch('tva_type');
    useEffect(function () {
        if (vatType !== 'BE') {
            setValue('user.vat_number', '');
        }
    }, [vatType]);
    var tabIndex = active ? undefined : -1;
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('signup-account-form'), onSubmit: handleSubmit(onSubmit) },
            React.createElement("div", { className: styles('grid-row') },
                React.createElement("div", { className: styles('col-6') },
                    React.createElement(Input, { register: register('user.first_name', { required: true }), tabIndex: tabIndex, errorMessage: get(errors, 'user.first_name'), type: "text", label: t(i18nKeys.FORM.FIRSTNAME) })),
                React.createElement("div", { className: styles('col-6') },
                    React.createElement(Input, { register: register('user.last_name', { required: true }), tabIndex: tabIndex, errorMessage: get(errors, 'user.last_name'), type: "text", label: t(i18nKeys.FORM.LASTNAME) }))),
            React.createElement(Input, { register: register('user.email', { required: true }), tabIndex: -1, type: "email", label: t(i18nKeys.EMAIL), readOnly: true }),
            React.createElement(Input, { register: register('user.password', { required: true }), errorMessage: get(errors, 'user.password'), tabIndex: tabIndex, type: "password", label: t(i18nKeys.PASSWORD) }),
            React.createElement(Input, { register: register('user.confirm-password', {
                    required: true,
                    validate: {
                        passwordNotMatch: function (value) { return getValues().user.password === value; },
                    },
                }), errorMessage: get(errors, 'user.confirm-password'), tabIndex: tabIndex, type: "password", label: t(i18nKeys.FORM.SIGNUP.CONFIRM_PASSWORD) }),
            React.createElement(Controller, { defaultValue: "EUR", name: "user.currency", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { name: "user.currency", tabIndex: tabIndex, keyText: "description", keyValue: "value", filter: true, items: constants.available_currency, label: t(i18nKeys.FORM.COMPANY.CURRENCY) })); } }),
            React.createElement(VatInput, { className: styles('vat-input'), tabIndex: tabIndex, name: "user.vat_number", error: (_b = errors.user) === null || _b === void 0 ? void 0 : _b.vat_number, label: t(i18nKeys.FORM.VAT), vatRequired: true }),
            React.createElement("div", { style: { display: 'flex' } },
                React.createElement("div", { style: { flex: '1' } },
                    React.createElement(Checkbox, { register: register('user.has_accepted_gc', { required: true }), errorMessage: get(errors, 'user.has_accepted_gc'), watch: watch, tabIndex: tabIndex },
                        t(i18nKeys.READ_AND_APPROVE),
                        ' ',
                        React.createElement("a", { href: config.terms[currentLang], target: "_blank", rel: "noopener noreferrer" }, t(i18nKeys.FORM.GENERAL_CONDITIONS_OF_USE)))),
                React.createElement("div", { className: styles('button-wrapper') },
                    React.createElement(Button, { tabIndex: tabIndex, label: t(i18nKeys.NEXT), type: "submit" }))))));
}
