import { __awaiter, __generator } from "tslib";
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { axiosInstance } from './utils';
export var useResendInvitation = function () {
    var client = useQueryClient();
    var mutation = useMutation({
        mutationFn: function (userId) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, axiosInstance.put("send_invitations/".concat(userId))];
            });
        }); },
        onSuccess: function (response) {
            notifications.show({
                message: response.data.message,
                color: 'green',
            });
            client.invalidateQueries(['users']);
        },
    });
    return addActionNameToMutationResult('resendInvitation', mutation);
};
