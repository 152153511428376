import { isObject } from 'remeda';
export function isPropertyPresent(obj, property) {
    return Object.prototype.hasOwnProperty.call(obj, property);
}
export function arePropertiesPresent(obj, properties) {
    return properties.every(function (property) { return isPropertyPresent(obj, property); });
}
export function isDescVal(candidate) {
    return isObject(candidate) && arePropertiesPresent(candidate, ['description', 'value']);
}
export function isDynamicFooterField(candidate) {
    return isObject(candidate) && arePropertiesPresent(candidate, ['field', 'content']);
}
