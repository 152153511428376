import { __assign } from "tslib";
import { i18nKeys } from 'locales';
import { IconName } from '../Icon';
import { getIntegrationRights, isEnabledIntegration } from './components/Integration.utils';
import { Integration, IntegrationCategory } from './Integrations.constants';
export var isAccountingIntegration = function (integration) {
    switch (integration) {
        case Integration.YUKI:
        case Integration.HORUS:
        case Integration.TEAMLEADER:
        case Integration.EXACT:
        case Integration.BILLIT:
        case Integration.DBASICS:
            return true;
        default:
            return false;
    }
};
export var getIntegrationCard = function (integration, company, t) {
    var title = Integration[integration];
    var base = {
        title: title,
        icon: IconName[integration],
        description: t(i18nKeys.SETTINGS.INTEGRATIONS[integration].DESCRIPTION),
    };
    switch (title) {
        case Integration.TEAMLEADER:
            return __assign(__assign(__assign({}, base), { category: IntegrationCategory.ERP }), getIntegrationRights(title, company));
        case Integration.DIGITEAL:
            return __assign(__assign(__assign({}, base), { icon: IconName.CREDIT_CARD, category: IntegrationCategory.BANKING }), getIntegrationRights(title, company));
        case Integration.CODABOX:
        case Integration.PONTO:
            return __assign(__assign(__assign({}, base), { category: IntegrationCategory.BANKING }), getIntegrationRights(title, company));
        default:
            return __assign(__assign(__assign({}, base), { category: IntegrationCategory.ACCOUNTING }), getIntegrationRights(title, company));
    }
};
export var getIntegrationsCards = function (company, t) {
    return Object.keys(Integration)
        .map(function (key) { return getIntegrationCard(key, company, t); })
        .filter(function (_a) {
        var title = _a.title;
        return isEnabledIntegration(title);
    });
};
export var getIntegrationsCategories = function (t) { return [
    {
        text: t(i18nKeys.SETTINGS.INTEGRATIONS.ALL),
        category: IntegrationCategory.ALL,
    },
    {
        text: t(i18nKeys.SETTINGS.INTEGRATIONS.ACCOUNTING),
        category: IntegrationCategory.ACCOUNTING,
    },
    {
        text: t(i18nKeys.SETTINGS.INTEGRATIONS.ERP),
        category: IntegrationCategory.ERP,
    },
    {
        text: t(i18nKeys.SETTINGS.INTEGRATIONS.BANKING),
        category: IntegrationCategory.BANKING,
    },
    {
        text: t(i18nKeys.SETTINGS.INTEGRATIONS.MY_INTEGRATIONS),
        category: IntegrationCategory.MY_INTEGRATIONS,
    },
]; };
