import { i18nKeys, useTranslation } from 'locales';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { axiosInstance } from './utils';
export var useRefreshBalance = function () {
    var client = useQueryClient();
    var t = useTranslation().t;
    var mutation = useMutation({
        mutationFn: function () {
            return axiosInstance.post('analytics/aged-balance/refresh');
        },
        onSuccess: function (response) {
            setTimeout(function () {
                client.refetchQueries({ queryKey: ['aged-balance'] });
            }, 5 * 60 * 1000);
            notifications.show({
                message: t(i18nKeys.ANALYTICS.REFRESH_BALANCE_SUCCESS, {
                    email: response.data.notifiedEmail,
                }),
                color: 'blue',
            });
        },
        onError: function () {
            notifications.show({
                message: t(i18nKeys.ANALYTICS.REFRESH_BALANCE_ERROR),
                color: 'blue',
            });
        },
    });
    return addActionNameToMutationResult('refreshBalance', mutation);
};
