import React from 'react';
import { useTenant } from 'app/App';
import Twikey from 'app/Logged/Onboarding/PaymentMethod/Twikey';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import Cards from 'react-credit-cards';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Icon, IconName } from 'shared/components/Icon';
import { Integration, INTEGRATION_ROUTE } from 'shared/components/Integrations';
import PaymentForm from 'shared/forms/PaymentForm';
import { Button, ButtonColor } from 'shared/io';
import { accountActions } from 'store/account/account.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import '!style-loader!css-loader!react-credit-cards/es/styles-compiled.css';
import styleIdentifiers from './paymentSettingsForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function PaymentSettingsForm(_a) {
    var onSubmit = _a.onSubmit, initialValues = _a.initialValues;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var tenant = useTenant();
    var card = (initialValues && initialValues.card_information) || {};
    var myPackage = (initialValues && initialValues.package) || {};
    var history = useHistory();
    var editBankingInfo = function (data) {
        accountActions.editBankingInfo({
            data: data,
            callback: function () {
                accountActions.getCompany();
                dialogHide(DialogShowId.CUSTOM);
            },
        });
    };
    var openBankingModal = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            keepMountOnExit: true,
            title: t(i18nKeys.CREDIT_CARD),
            children: React.createElement(PaymentForm, { onSubmit: editBankingInfo }),
        });
    };
    var goToDigitealIntegration = function () {
        history.push(INTEGRATION_ROUTE[Integration.DIGITEAL]);
    };
    var chooseTwikey = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.ONBOARDING.STEP1.DIRECT_DEBIT.TITLE),
            children: React.createElement(Twikey, { onSubmit: function () { } }),
        });
    };
    return (React.createElement("form", { className: styles('PaymentSettingsForm'), onSubmit: onSubmit },
        React.createElement("div", { className: styles('content', (tenant === null || tenant === void 0 ? void 0 : tenant.customBranding) && 'reseller') },
            !(tenant === null || tenant === void 0 ? void 0 : tenant.customBranding) && (React.createElement("div", { className: styles('card', initialValues.payment_method === 'twikey' && 'domiciliation') },
                React.createElement("div", null,
                    React.createElement("h3", null, t(i18nKeys.SETTINGS.PACKAGE.PAYMENT_METHOD)),
                    !company.payment_method && (React.createElement("span", { className: styles('text-red') },
                        React.createElement(Icon, { name: IconName.ALERT_CIRCLE }),
                        " ",
                        t(i18nKeys.NO_PAYMENT_METHOD)))),
                initialValues.payment_method === 'twikey' ? (React.createElement("span", null,
                    React.createElement(Icon, { name: IconName.DOMICILIATION, className: styles('dark'), size: "25px" }),
                    t(i18nKeys.SETTINGS.PACKAGE.SEPA_MANDATE_VIA_TWIKEY))) : company.card_information.can_edit_card ? (React.createElement(React.Fragment, null,
                    React.createElement(Cards, { preview: true, number: (card && "************".concat(card.last4_digits)) || '', name: card.holder_name || '', expiry: (card && "".concat(card.expiry_month, "/").concat(card.expiry_year)) || '', cvc: card.verification_numbers || '', issuer: card.card_brand }),
                    company.card_information.can_edit_card && (React.createElement(Button, { onClick: openBankingModal, noMargin: true, label: t(i18nKeys.SETTINGS.PACKAGE.EDIT_BANKING_INFO) })))) : (!company.payment_method && (React.createElement("div", { className: styles('no-payment-section') },
                    React.createElement(Button, { iconSize: "18px", iconLeft: IconName.DOMICILIATION, color: ButtonColor.GREEN, label: t(i18nKeys.ONBOARDING.STEP1.DIRECT_DEBIT.TITLE), onClick: chooseTwikey }),
                    React.createElement(Button, { iconSize: "18px", iconLeft: IconName.CREDIT_CARD, label: t(i18nKeys.CREDIT_CARD), onClick: openBankingModal })))),
                company.api.direct_billing_by_integrator && (React.createElement("span", null,
                    React.createElement(Icon, { name: IconName.INFO, className: styles('dark'), size: "25px" }),
                    t(i18nKeys.SETTINGS.PACKAGE.PAYMENT_BY_INTEGRATOR))))),
            React.createElement("div", { className: styles('package') },
                React.createElement("h3", null, t(i18nKeys.SETTINGS.PACKAGE.YOUR_PACKAGE)),
                React.createElement("ul", { className: styles('features') },
                    React.createElement("li", null,
                        React.createElement("div", { className: styles('single') },
                            React.createElement("span", { className: styles('description') }, t(i18nKeys.SETTINGS.PACKAGE.NUMBER_PHONE_BY_MONTH)),
                            React.createElement("span", { className: styles('value') }, myPackage.included_callcenter_count))),
                    React.createElement("li", null,
                        React.createElement("div", { className: styles('single') },
                            React.createElement("span", { className: styles('description') },
                                t(i18nKeys.SETTINGS.PACKAGE.NUMBER_LEGAL_CREDITS),
                                ' '),
                            React.createElement("span", { className: styles('value') }, myPackage.included_legal_credits_count))),
                    React.createElement("li", null,
                        React.createElement("div", { className: styles('single') },
                            React.createElement("span", { className: styles('description') }, t(i18nKeys.ONLINE_PAYMENT)),
                            React.createElement("span", { className: styles('value') },
                                React.createElement(Button, { iconRight: IconName.PENCIL, noMargin: true, color: ButtonColor.TRANSPARENT, noShadow: true, small: true, onClick: goToDigitealIntegration, label: t(company.digiteal_integration.status === 'in_process'
                                        ? i18nKeys.IN_PROGRESS
                                        : company.digiteal_integration.status === 'activated'
                                            ? i18nKeys.ACTIVATED
                                            : i18nKeys.DEACTIVATED) })))))))));
}
