import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button, Checkbox, CustomSelect, DateSelector, Input } from 'shared/io';
import { sideMenuSetAskBeforeClose } from 'store/view/view.actions';
import { VarType } from '../CustomVariables.types';
import styleIdentifiers from './CustomVariablesBatchForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function CustomVariablesBatchForm(_a) {
    var onSubmit = _a.onSubmit, resourceType = _a.resourceType;
    var t = useTranslation().t;
    var availableCustomVariables = useSelector(function (state) { var _a, _b; return (_b = (_a = state.account.company) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.custom_variables_attributes; });
    var resourceVariables = availableCustomVariables === null || availableCustomVariables === void 0 ? void 0 : availableCustomVariables.filter(function (variable) { return variable.model_type === resourceType; });
    var initialValues = { custom_variables: {}, blank_data: false };
    var form = useForm({
        shouldUnregister: true,
        defaultValues: initialValues,
    });
    var register = form.register, handleSubmit = form.handleSubmit, setValue = form.setValue, watch = form.watch, isDirty = form.formState.isDirty;
    useEffect(function () {
        sideMenuSetAskBeforeClose(isDirty);
    }, [isDirty]);
    var resourceTypeKey = resourceType === 'invoice' ? 'INVOICES' : 'CLIENTS';
    if (resourceVariables.length === 0)
        return (React.createElement("div", { className: styles('no-content') },
            React.createElement("p", null, t(i18nKeys.ERROR.NO_CUSTOM_VARIABLE, {
                resourceType: t(i18nKeys[resourceTypeKey]).toLowerCase(),
            }))));
    var submit = function (values) {
        onSubmit(__assign({}, values));
    };
    var setEdit = function (customVarKey) {
        setValue("custom_variables.".concat(customVarKey, ".edit"), true);
    };
    var resetValue = function (value, customVarKey) {
        if (value)
            return;
        setValue("custom_variables.".concat(customVarKey, ".value"), null);
    };
    var accurateInput = function (customVarKey, varType) {
        switch (varType) {
            case VarType.BOOLEAN:
                return (React.createElement(Controller, { defaultValue: "", name: "custom_variables.".concat(customVarKey, ".value"), render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "description", keyValue: "value", items: [
                            {
                                description: t(i18nKeys.YES),
                                value: 'true',
                            },
                            {
                                description: t(i18nKeys.NO),
                                value: 'false',
                            },
                        ], withBorder: true, name: "custom_variables.".concat(customVarKey, ".value"), label: customVarKey.replaceAll('_', ' '), onValueChanged: function () { return setEdit(customVarKey); } })); } }));
            case VarType.DATE:
                return (React.createElement(DateSelector, { name: "custom_variables.".concat(customVarKey, ".value"), className: styles('input'), noMinDate: true, withBorder: true, placeholder: " ", label: customVarKey.replaceAll('_', ' '), handleChange: function (value) {
                        setEdit(customVarKey);
                        setValue("custom_variables.".concat(customVarKey, ".value"), value);
                    } }));
            default:
                return (React.createElement(Input, { register: register("custom_variables.".concat(customVarKey, ".value")), withBorder: true, label: customVarKey.replaceAll('_', ' '), className: styles('input'), type: varType === VarType.NUMBER ? 'number' : 'text', step: "0.01", noMargin: true, onValueChanged: function () { return setEdit(customVarKey); } }));
        }
    };
    var renderInput = function (customVarKey) {
        var _a;
        var varType = (_a = availableCustomVariables.find(function (el) { return el.column_name === customVarKey; })) === null || _a === void 0 ? void 0 : _a.var_type;
        return (React.createElement("div", { key: customVarKey, className: styles('content') },
            React.createElement(Checkbox, { watch: watch, register: register("custom_variables.".concat(customVarKey, ".edit")), onChange: function (value) { return resetValue(value, customVarKey); } }),
            accurateInput(customVarKey, varType)));
    };
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('custom-variables-batch-form'), onSubmit: handleSubmit(submit) },
            React.createElement("div", { className: styles('form-wrapper') }, resourceVariables.map(function (_a) {
                var column_name = _a.column_name;
                return renderInput(column_name);
            })),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { noMargin: true, label: t(i18nKeys.SAVE), type: "submit" })))));
}
