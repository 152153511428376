import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { Button, ButtonColor } from 'shared/io';
import { dialogHide, DialogShowId } from 'store/view/view.actions';
import styleIdentifiers from './AYSModal.scss';
var styles = classNames.bind(styleIdentifiers);
export default function AYSModal(_a) {
    var text = _a.text, customButtonText = _a.customButtonText, customAction = _a.customAction, confirmButtonText = _a.confirmButtonText, cancelButtonText = _a.cancelButtonText, confirmButtonColor = _a.confirmButtonColor, onCancel = _a.onCancel, onConfirm = _a.onConfirm;
    var t = useTranslation().t;
    var onClickOnButton = function (e) {
        e.preventDefault();
        dialogHide(DialogShowId.CONFIRM);
    };
    return (React.createElement("div", { className: styles('ays-modal') },
        text,
        React.createElement("form", { onSubmit: onClickOnButton },
            onCancel && (React.createElement(Button, { color: ButtonColor.GREY, type: "submit", label: cancelButtonText || t(i18nKeys.CANCEL), onClick: onCancel })),
            customAction && React.createElement(Button, { type: "submit", label: customButtonText, onClick: customAction }),
            onConfirm && (React.createElement(Button, { type: "submit", color: confirmButtonColor, label: confirmButtonText || t(i18nKeys.CONFIRM), onClick: onConfirm })))));
}
