import { __assign, __read, __rest, __spreadArray } from "tslib";
import React, { useCallback, useState } from 'react';
import { SendingMediaNames } from 'api/models';
import ImportOriginalInvoice from 'app/Logged/Invoices/ToHandleDetail/ImportOriginalInvoice';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import { ThirdPartyType, useLoadThirdParties } from 'shared/hooks';
import { Dropdown } from 'shared/io';
import { treatInvoice } from 'shared/serializer';
import { formattedDate } from 'shared/utils/view';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog, sideMenuHide, } from 'store/view/view.actions';
import { modals } from '@mantine/modals';
import EditClient from '../../action-component/EditClient';
import AYSModal from '../AYSModal';
import { hasSendingMedium } from '../SendingMedium';
import AdditionalReminder from './AdditionalReminder';
import ChooseInvoicesForm from './ChooseInvoicesForm';
import { ChooseThirdPartyTypeForm } from './ChooseThirdPartyTypeForm';
import ConfirmBailiffForm from './ConfirmBailiffForm';
import FormalNotice from './FormalNotice';
import { GcollectModal } from './GcollectModal';
import SelectThirdPartyForm from './SelectThirdPartyForm';
import styleIdentifiers from './invoiceActions.scss';
var styles = classNames.bind(styleIdentifiers);
export var InvoiceActionsDropdown = function (_a) {
    var reload = _a.reload, invoice = _a.invoice, className = _a.className, item = _a.item, topElement = _a.topElement, selectorClass = _a.selectorClass, _b = _a.shouldDeferRequest, _shouldDeferRequest = _b === void 0 ? false : _b;
    var _c = useTranslation(), t = _c.t, currentLang = _c.currentLang;
    var _d = __read(useState(_shouldDeferRequest), 2), shouldDeferRequest = _d[0], setShouldDeferRequest = _d[1];
    var enableRequest = function () { return setShouldDeferRequest(false); };
    var company = useSelector(function (state) { return state.account.company.data; });
    var constants = useSelector(function (state) { return state.app.constants; });
    var thirdParties = useLoadThirdParties({
        id: invoice.id,
        enabled: !shouldDeferRequest,
    }).thirdParties;
    var additionalReminderAction = useCallback(function (fullInvoice) {
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.LATE_FEES),
            children: (React.createElement(AYSModal, { text: t(i18nKeys.ADD_LATE_FEES), cancelButtonText: t(i18nKeys.NO), confirmButtonText: t(i18nKeys.YES), onCancel: function () {
                    additionalReminderOptions({ add_late_fees: false, fullInvoice: fullInvoice });
                }, onConfirm: function () {
                    additionalReminderOptions({ add_late_fees: true, fullInvoice: fullInvoice });
                } })),
        });
    }, [invoice]);
    // Sentry 18Z: Apparently CustomTable can pass a nullish invoice since it is typed as any
    if (!invoice)
        return null;
    var callbackAction = function () {
        sideMenuHide();
        reload === null || reload === void 0 ? void 0 : reload();
    };
    var isInactive = function (type) { return !invoice.actions.includes(type); };
    var sendOriginalInvoice = function () {
        callbackAction();
        dialogHide(DialogShowId.CUSTOM);
    };
    var confirmThirdParty = function (additionalInvoices) { return function (value) {
        dialogHide(DialogShowId.CUSTOM);
        invoiceActions.actionsThirdParty({
            id: invoice.id,
            callback: callbackAction,
            data: {
                third_party_company_id: value.third_party_company_id,
                additional_invoices: additionalInvoices,
            },
        });
    }; };
    var openImportOriginalInvoiceModal = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            keepMountOnExit: true,
            title: t(i18nKeys.INVOICE_IMPORT),
            children: (React.createElement(ImportOriginalInvoice, { onEditOriginalFile: sendOriginalInvoice, invoice: invoice })),
        });
    };
    var canSendByPost = !(!constants.external_services.postgreen.active &&
        hasSendingMedium(company.sending_media_attributes, SendingMediaNames.post));
    var selectGroupedInvoices = function (_a) {
        var type = _a.type, onSubmit = _a.onSubmit, text = _a.text, _b = _a.data, data = _b === void 0 ? {} : _b;
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.LARGE,
            title: t(text || i18nKeys.FORM.CHOOSE_INVOICES.TITLE),
            children: (React.createElement(ChooseInvoicesForm, { getData: function (callback) {
                    invoiceActions.getGroupedInvoices({
                        id: invoice.id,
                        data: __assign({ debtor_id: invoice.debtor_id, status: 'late_unpaid', group_type: type }, data),
                        callback: callback,
                    });
                }, type: type, onSubmit: function (submitData) {
                    onSubmit(submitData);
                } })),
        });
    };
    var additionalReminderOptions = function (_a) {
        var fullInvoice = _a.fullInvoice, lateFeesOptions = __rest(_a, ["fullInvoice"]);
        selectGroupedInvoices({
            type: 'additional_reminder',
            data: lateFeesOptions,
            onSubmit: function (_a) {
                var invoiceActionData = _a.invoiceActionData, items = __rest(_a, ["invoiceActionData"]);
                return showDialog({
                    id: DialogShowId.CUSTOM,
                    size: DialogShowSize.LARGE,
                    title: t(i18nKeys.FORM.ADDITIONAL_REMINDER.CONFIRM_SENDING_OPTIONS),
                    children: (React.createElement(AdditionalReminder, { items: __assign(__assign({}, items), lateFeesOptions), onSubmit: function (values) {
                            var _a;
                            invoiceActions.actionsAdditionalReminder({
                                id: invoice.id,
                                data: __assign(__assign(__assign(__assign({}, values), items), lateFeesOptions), { template_id: ((_a = values.template_id) === null || _a === void 0 ? void 0 : _a.id) || values.template_id }),
                                callback: function () {
                                    dialogHide(DialogShowId.CUSTOM);
                                    callbackAction();
                                },
                            });
                        }, invoiceActionsData: invoiceActionData, initialValues: fullInvoice.sending_options_attributes, invoice: __assign(__assign({}, fullInvoice), { debtor_attributes: __assign(__assign({}, fullInvoice.debtor_attributes), { emails: __spreadArray(__spreadArray([], __read(fullInvoice.debtor_attributes.emails), false), __read(fullInvoice.sending_options_attributes.emails), false) }) }) })),
                });
            },
        });
    };
    var additionalReminder = function () {
        if (isInactive('additional_reminder') || !canSendByPost)
            return;
        if (invoice.debtor_attributes) {
            additionalReminderAction(invoice);
        }
        else {
            invoiceActions.detail({
                id: invoice.id,
                callback: function (_a) {
                    var data = _a.data;
                    additionalReminderAction(treatInvoice(data));
                },
            });
        }
    };
    var editClient = function (debtor) {
        EditClient({
            client: debtor,
            callback: callbackAction,
        });
    };
    var sendFormalNotice = function (items) { return function (data) {
        invoiceActions.actionsFormalNotice({
            data: __assign(__assign({}, data), items),
            id: invoice.id,
            callback: function () {
                dialogHide(DialogShowId.CUSTOM);
                callbackAction();
            },
        });
    }; };
    var openFormalNoticeModal = function (selectedInvoice) {
        selectGroupedInvoices({
            type: 'formal_notice',
            onSubmit: function (_a) {
                var invoiceActionData = _a.invoiceActionData, items = __rest(_a, ["invoiceActionData"]);
                return showDialog({
                    id: DialogShowId.CUSTOM,
                    size: DialogShowSize.LARGE,
                    keepMountOnExit: true,
                    title: t(i18nKeys.FORM.FORMAL_NOTICE.CONFIRM),
                    children: (React.createElement(FormalNotice, { invoiceActionData: invoiceActionData, invoice: selectedInvoice, items: items, onSubmit: sendFormalNotice(items) })),
                });
            },
        });
    };
    var formalNotice = function () {
        if (isInactive('formal_notice') || !canSendByPost)
            return;
        sideMenuHide();
        if (invoice.type === 'actionable_invoice') {
            invoiceActions.detail({
                id: invoice.id,
                callback: function (_a) {
                    var data = _a.data;
                    openFormalNoticeModal(treatInvoice(data));
                },
            });
        }
        else {
            openFormalNoticeModal(invoice);
        }
    };
    var markAsToBeProcessed = function () {
        sideMenuHide();
        invoiceActions.actionsMarkAsToBeProccesed({
            id: invoice.id,
            callback: callbackAction,
        });
    };
    var openGcollectFlow = function (data, additionalInvoiceIds) {
        dialogHide(DialogShowId.CUSTOM);
        modals.open({
            title: 'Gcollect',
            centered: true,
            children: (React.createElement(GcollectModal, { gcollectId: data.third_party_company_id, invoiceIds: [invoice.id], additionalInvoiceIds: additionalInvoiceIds.map(Number) })),
        });
    };
    var openCallCenterForm = function (fullInvoice) {
        sideMenuHide();
        selectGroupedInvoices({
            type: 'callcenter',
            onSubmit: function (_a) {
                var additional_invoices = _a.additional_invoices;
                showDialog({
                    id: DialogShowId.CUSTOM,
                    size: DialogShowSize.SMALL,
                    title: t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.SEND_TO_CALLCENTER),
                    children: (React.createElement(SelectThirdPartyForm, { thirdParties: thirdParties, editClient: function () {
                            editClient(fullInvoice.debtor_attributes);
                            dialogHide(DialogShowId.CUSTOM);
                        }, invoice: fullInvoice, selectedThirdPartyType: ThirdPartyType.CALL_CENTER, onSubmit: confirmThirdParty(additional_invoices) })),
                });
            },
        });
    };
    var callCenter = function () {
        if (invoice.debtor_attributes) {
            openCallCenterForm(invoice);
        }
        else {
            invoiceActions.detail({
                id: invoice.id,
                callback: function (_a) {
                    var data = _a.data;
                    openCallCenterForm(treatInvoice(data));
                },
            });
        }
    };
    var lawyer = function () {
        if (!invoice.has_pdf) {
            openImportOriginalInvoiceModal();
        }
        else {
            sideMenuHide();
            selectGroupedInvoices({
                type: ThirdPartyType.LAWYER,
                onSubmit: function (_a) {
                    var additional_invoices = _a.additional_invoices;
                    return showDialog({
                        id: DialogShowId.CUSTOM,
                        size: DialogShowSize.SMALL,
                        keepMountOnExit: true,
                        title: t(i18nKeys.FORM.LAWYER_TITLE),
                        children: (React.createElement(SelectThirdPartyForm, { invoice: invoice, selectedThirdPartyType: ThirdPartyType.LAWYER, thirdParties: thirdParties, onSubmit: confirmThirdParty(additional_invoices) })),
                    });
                },
            });
        }
    };
    var debtCollector = function () {
        if (!invoice.has_pdf) {
            openImportOriginalInvoiceModal();
        }
        else {
            sideMenuHide();
            selectGroupedInvoices({
                type: ThirdPartyType.DEBT_COLLECTOR,
                onSubmit: function (_a) {
                    var _b = _a.additional_invoices, additional_invoices = _b === void 0 ? [] : _b;
                    return showDialog({
                        id: DialogShowId.CUSTOM,
                        size: DialogShowSize.SMALL,
                        keepMountOnExit: true,
                        title: t(i18nKeys.FORM.DEBT_COLLECTOR_TITLE),
                        children: (React.createElement(SelectThirdPartyForm, { invoice: invoice, selectedThirdPartyType: ThirdPartyType.DEBT_COLLECTOR, thirdParties: thirdParties, onSubmit: confirmThirdParty(additional_invoices), onSubmitGcollect: function (data) { return openGcollectFlow(data, additional_invoices); } })),
                    });
                },
            });
        }
    };
    var sendBailiff = function (values) {
        dialogHide(DialogShowId.CUSTOM);
        values.contested = !values.notContested;
        invoiceActions.actionsThirdParty({
            id: invoice.id,
            data: values,
            callback: callbackAction,
        });
    };
    var bailiff = function () {
        if (!invoice.has_pdf) {
            openImportOriginalInvoiceModal();
        }
        else {
            sideMenuHide();
            selectGroupedInvoices({
                type: 'bailiff',
                text: i18nKeys.FORM.CHOOSE_INVOICES.BAILIFF_TITLE,
                onSubmit: function (_a) {
                    var additional_invoices = _a.additional_invoices;
                    return showDialog({
                        id: DialogShowId.CUSTOM,
                        size: DialogShowSize.MEDIUM,
                        keepMountOnExit: true,
                        title: t(i18nKeys.FORM.CONFIRM_BAILIFF.BAILIFF_CHOICE),
                        children: (React.createElement(SelectThirdPartyForm, { thirdParties: thirdParties, selectedThirdPartyType: ThirdPartyType.BAILIFF, invoice: invoice, onSubmit: function (chosenBailiff) {
                                showDialog({
                                    id: DialogShowId.CUSTOM,
                                    size: DialogShowSize.MEDIUM,
                                    keepMountOnExit: true,
                                    title: t(i18nKeys.FORM.CONFIRM_BAILIFF.IS_INVOICE_CONTESTABLE),
                                    children: (React.createElement(ConfirmBailiffForm, { bailiffId: chosenBailiff.third_party_company_id, onSubmit: function (values) {
                                            return sendBailiff(__assign(__assign(__assign({}, chosenBailiff), values), { additional_invoices: additional_invoices }));
                                        } })),
                                });
                            } })),
                    });
                },
            });
        }
    };
    var selectThirdPartyType = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.LARGE,
            title: t(i18nKeys.FORM.CHOOSE_THIRD_PARTY_TYPE.TITLE),
            children: (React.createElement(ChooseThirdPartyTypeForm, { inheritedThirdParties: thirdParties, invoices: [invoice], onSubmit: function (selectedThirdPartType) {
                    switch (selectedThirdPartType) {
                        case ThirdPartyType.CALL_CENTER:
                            callCenter();
                            break;
                        case ThirdPartyType.LAWYER:
                            lawyer();
                            break;
                        case ThirdPartyType.BAILIFF:
                            bailiff();
                            break;
                        case ThirdPartyType.DEBT_COLLECTOR:
                            debtCollector();
                            break;
                        default:
                            break;
                    }
                } })),
        });
    };
    return (React.createElement("div", { onClick: function (e) { return e.stopPropagation(); } },
        React.createElement(Dropdown, { dropdownContentStyle: { top: topElement }, selectorClass: selectorClass, contentClass: styles('content-class'), className: styles('InvoiceActions', className), selectorContent: React.createElement("div", { onClick: enableRequest }, item), toggleContent: true, sideMenuInMobile: true, sideMenuTitle: t(i18nKeys.INVOICE.SELECT_ACTION) },
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: "dropdown-item ".concat(styles((isInactive('additional_reminder') || !canSendByPost) && 'disabled')), onClick: additionalReminder },
                    React.createElement("div", { className: styles('header') }, t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.SEND_REMINDER)),
                    !canSendByPost && (React.createElement("div", { className: styles('warn-message') }, t(i18nKeys.SERVICE_UNAVAILABLE_UNTIL, {
                        date: formattedDate(constants.external_services.postgreen.unavailable_until, currentLang),
                    })))),
                React.createElement("div", { className: "dropdown-item ".concat(styles((isInactive('formal_notice') || !canSendByPost) && 'disabled')), onClick: isInactive('formal_notice') || !canSendByPost ? function () { } : formalNotice },
                    React.createElement("div", { className: styles('header') }, t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.SEND_FORMAL_NOTICE)),
                    React.createElement("div", { className: styles('sub') }, t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.FORMAL_NOTICE_SEND_BY_MAIL)),
                    !canSendByPost && (React.createElement("div", { className: styles('warn-message') }, t(i18nKeys.SERVICE_UNAVAILABLE_UNTIL, {
                        date: formattedDate(constants.external_services.postgreen.unavailable_until, currentLang),
                    })))),
                React.createElement("div", { className: "dropdown-item ".concat(styles(!(thirdParties === null || thirdParties === void 0 ? void 0 : thirdParties.areAnyThirdPartiesAccessible) && 'disabled')), onClick: function () {
                        if (!(thirdParties === null || thirdParties === void 0 ? void 0 : thirdParties.areAnyThirdPartiesAccessible))
                            return;
                        selectThirdPartyType();
                    } },
                    React.createElement("div", { className: styles('header') }, t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.SEND_TO_THIRD_PARTY)),
                    React.createElement("div", { className: styles('sub') }, t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.THIRD_PARTIES))),
                React.createElement("div", { className: "dropdown-item ".concat(styles(isInactive('mark_as_to_be_processed') && 'disabled')), onClick: isInactive('mark_as_to_be_processed') ? function () { } : markAsToBeProcessed },
                    React.createElement("div", { className: styles('header') }, t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.TAKE_OUT_OF_PROCESS)),
                    React.createElement("div", { className: styles('sub') },
                        ' ',
                        t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.NO_MORE_AUTOMATIC_ACTIONS)))))));
};
