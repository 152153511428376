var _a;
import { __assign, __awaiter, __generator } from "tslib";
import * as R from 'remeda';
import { omit } from 'remeda';
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, axiosInstance } from './utils';
var thirdPartyCompanySchema = z.object({
    id: z.number(),
    name: z.string().min(1),
});
// Debt collectors are a special case, because they have a boolean field `gcollect`; other than that they
// should be identical to any other third party company
var debtCollectorCompanySchema = z
    .object({
    id: z.number(),
    name: z.string().min(1),
    gcollect: z.boolean(),
})
    .transform(function (debtCollector) { return (__assign(__assign({}, omit(debtCollector, ['gcollect'])), { isGcollect: debtCollector.gcollect })); });
var debtCollectorSchema = z
    .object({
    isAllowed: z.boolean(),
    availableCompanies: z.array(debtCollectorCompanySchema),
})
    .transform(function (category) { return (__assign(__assign({}, category), { canBeAccessed: category.isAllowed && category.availableCompanies.length > 0 })); });
var thirdPartyCategorySchema = z
    .object({
    isAllowed: z
        .boolean()
        .describe('Whether or not the status of the invoice permits using this type of third party'),
    availableCompanies: z
        .array(thirdPartyCompanySchema)
        .describe('The array of third parties of this type that are available for the debtor/invoice country'),
})
    .transform(function (category) { return (__assign(__assign({}, category), { canBeAccessed: category.isAllowed && category.availableCompanies.length > 0 })); });
export var ThirdPartyType;
(function (ThirdPartyType) {
    ThirdPartyType["BAILIFF"] = "bailiff";
    ThirdPartyType["CALL_CENTER"] = "callcenter";
    ThirdPartyType["DEBT_COLLECTOR"] = "debtCollector";
    ThirdPartyType["LAWYER"] = "lawyer";
})(ThirdPartyType || (ThirdPartyType = {}));
var thirdPartiesSchema = z
    .object((_a = {},
    _a[ThirdPartyType.BAILIFF] = thirdPartyCategorySchema,
    _a[ThirdPartyType.CALL_CENTER] = thirdPartyCategorySchema,
    _a[ThirdPartyType.LAWYER] = thirdPartyCategorySchema,
    _a[ThirdPartyType.DEBT_COLLECTOR] = debtCollectorSchema,
    _a))
    .transform(function (thirdParties) { return (__assign(__assign({}, thirdParties), { areAnyThirdPartiesAccessible: R.pipe(thirdParties, Object.values, R.map(R.prop('canBeAccessed')), function (arr) { return arr.some(function (val) { return val; }); }) })); });
export function loadThirdPartiesQueryFn(_a) {
    return __awaiter(this, arguments, void 0, function (_b) {
        var data;
        var _c;
        var queryKey = _b.queryKey;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0: return [4 /*yield*/, axiosInstance.get('third_party_companies', {
                        params: (_c = {},
                            _c[Array.isArray(queryKey[1]) ? 'invoice_ids' : 'invoice_id'] = queryKey[1],
                            _c),
                    })];
                case 1:
                    data = (_d.sent()).data;
                    return [2 /*return*/, thirdPartiesSchema.parse(data)];
            }
        });
    });
}
export function useLoadThirdParties(_a) {
    var _b = _a.enabled, enabled = _b === void 0 ? true : _b, id = _a.id, ids = _a.ids;
    var queryResult = useQuery({
        enabled: enabled,
        queryKey: ['thirdParty', id !== null && id !== void 0 ? id : ids],
        queryFn: loadThirdPartiesQueryFn,
    });
    return addResourceNameToQueryResult('thirdParties', queryResult);
}
