import { __read } from "tslib";
import React, { useState } from 'react';
import { useGetDebtorsInfinite } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, useFormContext } from 'react-hook-form';
import { flatten, map, pipe } from 'remeda';
import { CustomSelect } from 'shared/io';
import { treatClient } from 'shared/serializer';
import { getNextPageParam } from 'shared/utils';
import styleIdentifiers from './StepsCalculation.scss';
var styles = classNames.bind(styleIdentifiers);
export var StepsCalculationDebtor = function (_a) {
    var _b;
    var _c = _a.name, name = _c === void 0 ? 'debtor_id' : _c, currentDebtor = _a.currentDebtor, setCurrentDebtor = _a.setCurrentDebtor;
    var t = useTranslation().t;
    var setValue = useFormContext().setValue;
    var _d = __read(useState(), 2), selectSearchValue = _d[0], setSelectSearchValue = _d[1];
    var _e = useGetDebtorsInfinite({ name: selectSearchValue }, {
        query: {
            getNextPageParam: getNextPageParam,
        },
    }), debtorsResponse = _e.data, fetchNextPage = _e.fetchNextPage;
    var pages = (_b = debtorsResponse === null || debtorsResponse === void 0 ? void 0 : debtorsResponse.pages) !== null && _b !== void 0 ? _b : [];
    var concatenatedDebtors = pipe(pages, map(function (e) { return e.data; }), flatten());
    return (React.createElement(Controller, { name: name, rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), filter: true, withBorder: true, noMargin: true, infiniteScroll: true, items: concatenatedDebtors.map(treatClient), keyValue: "id", keyText: "full_name", onChange: function (id) {
                setCurrentDebtor(concatenatedDebtors.find(function (e) { return e.id === id; }));
                setValue('invoice_ids', []);
            }, onChangeFilter: setSelectSearchValue, name: name, label: t(i18nKeys.COMMON.CLIENT), load: fetchNextPage })); } }));
};
