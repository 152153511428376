import { __assign, __read, __spreadArray, __values } from "tslib";
import React, { useState } from 'react';
import { PaymentPlanDetail } from 'app/Logged/PaymentPlans';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import qs from 'qs';
import { useSelector } from 'react-redux';
import CustomTableViews from 'shared/components/CustomTable/CustomTableViews';
import PostponeForm from 'shared/forms/PostponeForm';
import { Button, ButtonColor, Dropdown } from 'shared/io';
import { queryParamParser, reloadCustomView } from 'shared/utils/view';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog, sideMenuHide, } from 'store/view/view.actions';
import ToHandleDetail from '../ToHandleDetail';
import styleIdentifiers from './imminent-reminders.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ImminentReminders() {
    var _a;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var invoicesData = useSelector(function (state) { return state.invoice.postponable || {}; });
    var _b = __read(useState([]), 2), selectedInvoices = _b[0], setSelectedInvoices = _b[1];
    var _c = __read(useState(false), 2), areAllItemsAcrossPagesSelected = _c[0], setAreAllItemsAcrossPagesSelected = _c[1];
    var _d = __read(useState({
        cancel: true,
        confirm: true,
    }), 2), actionsDisable = _d[0], setActionsDisable = _d[1];
    var handleFilters = function (filters) {
        var newFilters = __assign({}, filters);
        // need to be linked
        if ((newFilters.total_tvac_operator && !newFilters.total_tvac) ||
            (!newFilters.total_tvac_operator && newFilters.total_tvac))
            return false;
        // need to be linked
        if ((newFilters.remaining_balance_operator && !newFilters.remaining_balance) ||
            (!newFilters.remaining_balance_operator && newFilters.remaining_balance))
            return false;
        return newFilters;
    };
    var askDurationToPostpone = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.POSTPONE),
            children: (React.createElement(PostponeForm, { invoices: areAllItemsAcrossPagesSelected ? 'all' : selectedInvoices, onSubmit: postponeSelectedInvoices, close: function () { return dialogHide(DialogShowId.CUSTOM); } })),
        });
    };
    var onChangeSelection = function (value) {
        var e_1, _a;
        setSelectedInvoices(value);
        if (value.length === 0) {
            setActionsDisable({
                cancel: true,
                confirm: true,
            });
        }
        else if (!company.automatic_reminders) {
            var isCancelDisabled = true;
            var isConfirmDisabled = true;
            try {
                for (var _b = __values(__spreadArray([], __read(value), false)), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var item = _c.value;
                    if (item.attributes.force_reminder) {
                        isCancelDisabled = false;
                    }
                    else {
                        isConfirmDisabled = false;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            setActionsDisable({
                cancel: isCancelDisabled,
                confirm: isConfirmDisabled,
            });
        }
    };
    var postponeSelectedInvoices = function (_a) {
        var days_delayed = _a.days_delayed;
        invoiceActions.actionsPostponeMultiple({
            data: {
                days_delayed: days_delayed,
                invoices: areAllItemsAcrossPagesSelected
                    ? undefined
                    : selectedInvoices.map(function (item) { return ({
                        id: item.id,
                        payment_plan_id: item.attributes.payment_plan_id,
                    }); }),
                filter_params: queryParamParser(qs.parse(location.search)),
                view_id: location.hash.slice(1),
            },
            callback: function () {
                reloadCustomView(invoiceActions.postponableRes);
                dialogHide(DialogShowId.CUSTOM);
                sideMenuHide();
                setActionsDisable({
                    cancel: true,
                    confirm: true,
                });
            },
        });
    };
    var forceReminder = function (force_reminder) { return function () {
        invoiceActions.actionsForceReminder({
            data: {
                force_reminder: force_reminder,
                invoices: areAllItemsAcrossPagesSelected
                    ? undefined
                    : selectedInvoices.map(function (item) { return ({
                        id: item.id,
                        payment_plan_id: item.attributes.payment_plan_id,
                    }); }),
                filter_params: queryParamParser(qs.parse(location.search)),
                view_id: location.hash.slice(1),
            },
            callback: function () {
                reloadCustomView(invoiceActions.postponableRes);
                sideMenuHide();
                setActionsDisable({
                    cancel: true,
                    confirm: true,
                });
            },
        });
    }; };
    var showInvoice = function (item) { return function () {
        var children = item.attributes.payment_plan_id ? (React.createElement(PaymentPlanDetail, { planId: item.attributes.payment_plan_id, inModal: true })) : (React.createElement(ToHandleDetail, { id: item.id, modal: true }));
        showDialog({
            id: DialogShowId.INVOICE,
            size: DialogShowSize.LARGE,
            children: children,
        });
    }; };
    return (React.createElement("div", { className: styles('Actions') },
        React.createElement(CustomTableViews, { title: t(i18nKeys.NAV.IMINENT_REMINDERS), callbackAction: invoiceActions.postponableRes, onClickRow: showInvoice, isLoading: !invoicesData.loaded, pagination: (_a = invoicesData.metadata) === null || _a === void 0 ? void 0 : _a.pagination, handleFilters: handleFilters, items: invoicesData.pages || [], currentPage: invoicesData.currentPage, onChangeSelectionCallback: onChangeSelection, onChangeSelectItemsAcrossAllPagesCallback: setAreAllItemsAcrossPagesSelected, tableName: "postponable_invoices", actions: React.createElement("div", { className: styles('button-wrapper', !company.automatic_reminders && 'manual') },
                React.createElement(Button, { disabled: selectedInvoices.length === 0, onClick: askDurationToPostpone, label: t(i18nKeys.POSTPONE), noMargin: true }),
                !company.automatic_reminders && (React.createElement(React.Fragment, null,
                    React.createElement(Button, { disabled: actionsDisable.cancel, onClick: forceReminder(false), color: ButtonColor.GREY, label: t(i18nKeys.INVOICING.IMPENDING_REMINDERS.CANCEL_REMINDER), noMargin: true }),
                    React.createElement(Button, { disabled: actionsDisable.confirm, onClick: forceReminder(true), color: ButtonColor.MAIN, label: t(i18nKeys.INVOICE.SEND_REMINDER), noMargin: true }))),
                React.createElement(Dropdown, { sideMenuInMobile: true, className: styles('actions-dropdown'), sideMenuTitle: t(i18nKeys.FORM.ACTIONS), selectorClass: styles(actionsDisable.cancel &&
                        actionsDisable.confirm &&
                        selectedInvoices.length === 0 &&
                        'disabled'), selectorContent: React.createElement(Button, { color: ButtonColor.WHITE, label: t(i18nKeys.FORM.ACTIONS), noMargin: true }) },
                    React.createElement("div", { className: styles('dropdown-item'), onClick: askDurationToPostpone }, t(i18nKeys.POSTPONE)),
                    !company.automatic_reminders && !actionsDisable.confirm && (React.createElement("div", { className: styles('dropdown-item'), onClick: forceReminder(true) }, t(i18nKeys.INVOICE.SEND_REMINDER))),
                    !company.automatic_reminders && !actionsDisable.cancel && (React.createElement("div", { className: styles('dropdown-item'), onClick: forceReminder(false) }, t(i18nKeys.INVOICING.IMPENDING_REMINDERS.CANCEL_REMINDER))))) })));
}
