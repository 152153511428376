import { __assign, __awaiter, __generator } from "tslib";
import { omit } from 'remeda';
import { string, z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, axiosInstance } from './utils';
var LightUserSchema = z
    .object({
    email: z.string().email(),
    firstName: string().nonempty(),
    id: z.number(),
    isActive: z.boolean(),
    lastName: string().nonempty(),
    profilePicture: z.string().url().nullable(),
})
    .transform(function (data) { return (__assign(__assign({}, omit(data, ['firstName', 'lastName'])), { name: {
        full: "".concat(data.firstName, " ").concat(data.lastName),
        first: data.firstName,
        last: data.lastName,
        initials: "".concat(data.firstName[0]).concat(data.lastName[0]),
    } })); });
var LightUsersSchema = z.array(LightUserSchema);
// TODO: How to get type info for the key here?
export function loadLightUsersQueryFn(_a) {
    return __awaiter(this, arguments, void 0, function (_b) {
        var data;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axiosInstance.get("/company/users?".concat(new URLSearchParams({
                        fullResponse: 'false',
                        showInactive: queryKey[1].loadInactiveUsers.toString(),
                    }).toString()))];
                case 1:
                    data = (_c.sent()).data;
                    return [2 /*return*/, LightUsersSchema.parse(data)];
            }
        });
    });
}
export var useLoadLightUsers = function (loadInactiveUsers) {
    if (loadInactiveUsers === void 0) { loadInactiveUsers = false; }
    var queryResult = useQuery({
        queryKey: ['users', { loadInactiveUsers: loadInactiveUsers }],
        queryFn: loadLightUsersQueryFn,
    });
    return addResourceNameToQueryResult('users', queryResult);
};
