import { __read, __spreadArray } from "tslib";
import { omitBy } from 'remeda';
function _areFieldsDirty(form) {
    var fields = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        fields[_i - 1] = arguments[_i];
    }
    return fields.some(function (field) { return form.isDirty(field); });
}
export function createAreFieldsDirty(form) {
    return function () {
        var fields = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            fields[_i] = arguments[_i];
        }
        return _areFieldsDirty.apply(void 0, __spreadArray([form], __read(fields), false));
    };
}
export function getDirtyFields(form) {
    var fields = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        fields[_i - 1] = arguments[_i];
    }
    return omitBy(form.values, function (_, key) { return !form.isDirty(key) || !fields.includes(key); });
}
export function createGetDirtyFields(form) {
    return function () {
        var fields = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            fields[_i] = arguments[_i];
        }
        return getDirtyFields.apply(void 0, __spreadArray([form], __read(fields), false));
    };
}
function _areFieldsValid(form) {
    var fields = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        fields[_i - 1] = arguments[_i];
    }
    return fields.every(function (field) { return !form.errors[field]; });
}
export function createAreFieldsValid(form) {
    return function () {
        var fields = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            fields[_i] = arguments[_i];
        }
        return _areFieldsValid.apply(void 0, __spreadArray([form], __read(fields), false));
    };
}
