import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import { useProfile } from 'app/App';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { UpdatedAt } from 'modules/Analytics/components';
import { isEmpty } from 'remeda';
import { useLoadAgedBalanceCompact, useLoadLightUsers } from 'shared/hooks';
import { PageTitle } from 'shared/layout/PageTitle';
import { useGetDashboard } from 'shared/utils/selectors';
import { accountActions } from 'store/account/account.actions';
import { Grid, Group, Select, Title } from '@mantine/core';
import { IconUser } from '@tabler/icons-react';
import { BalanceGraphic } from './BalanceGraphic';
import BankStatus from './BankStatus';
import { DashboardChartLoader } from './DashboardChartLoader';
import { OutstandingGraphic } from './OutstandingGraphic';
import RecentActivities from './RecentActivities';
import styleIdentifiers from './dashboard.scss';
var styles = classNames.bind(styleIdentifiers);
export default function Dashboard() {
    var t = useTranslation().t;
    var profile = useProfile();
    var dashboard = useGetDashboard();
    var _a = useLoadLightUsers().users, users = _a === void 0 ? [] : _a;
    var _b = useLoadAgedBalanceCompact(), agedBalanceCompact = _b.agedBalanceCompact, isAgedBalanceCompactLoading = _b.isAgedBalanceCompactLoading;
    var _c = __read(useState(), 2), accountManagerId = _c[0], setAccountManagerId = _c[1];
    useEffect(function () {
        accountActions.dashboard({ noFeedback: true, data: { account_manager_id: accountManagerId } });
    }, [accountManagerId]);
    if (dashboard === null || profile == null)
        return null;
    return (React.createElement("div", { className: styles('Dashboard') },
        React.createElement(PageTitle, null,
            t(i18nKeys.COMMON.DASHBOARD),
            React.createElement(PageTitle.Actions, null, (profile.isAdmin || profile.roles.includes('all_invoices')) && (React.createElement(Select, { w: 235, comboboxProps: { shadow: 'sm' }, placeholder: t(i18nKeys.ACCOUNT_MANAGER), clearable: true, disabled: isEmpty(users), leftSection: React.createElement(IconUser, null), onChange: function (value) { return setAccountManagerId(value == null ? undefined : Number(value)); }, data: users.map(function (_a) {
                    var name = _a.name, id = _a.id;
                    return ({ value: id.toString(), label: name.full });
                }) })))),
        React.createElement(Grid, { style: { overflow: 'visible' } },
            React.createElement(Grid.Col, { span: profile.isAdmin ? 7 : 12 },
                React.createElement(RecentActivities, { accountManagerId: accountManagerId, data: dashboard.recent_activities })),
            profile.isAdmin && (React.createElement(Grid.Col, { span: 5 },
                React.createElement(BankStatus, { data: dashboard.financial_data }))),
            React.createElement(Grid.Col, { span: 12, pt: "lg" },
                React.createElement(Group, { justify: "space-between" },
                    React.createElement(Title, { order: 3 }, "Analytics"),
                    React.createElement(UpdatedAt, null))),
            React.createElement(Grid.Col, { span: 5 }, isAgedBalanceCompactLoading ? (React.createElement(DashboardChartLoader, { titleKey: i18nKeys.DASHBOARD.CUSTOMER_RECEIVABLES.TITLE })) : (React.createElement(OutstandingGraphic, { agedBalanceCompact: agedBalanceCompact, accountManagerId: accountManagerId }))),
            React.createElement(Grid.Col, { span: 7 }, isAgedBalanceCompactLoading ? (React.createElement(DashboardChartLoader, { titleKey: i18nKeys.DASHBOARD.DELAY_GRAPHIC.TITLE })) : (React.createElement(BalanceGraphic, { agedBalanceCompact: agedBalanceCompact }))))));
}
