import React from 'react';
import { upperFirst } from 'lodash-es';
import { useHistory } from 'react-router';
import { times } from 'remeda';
import { useArchiveReport, useLoadReports } from 'shared/hooks';
import { PageTitle } from 'shared/layout';
import { ActionIcon, Button, Card, Group, Skeleton, Table, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react';
import { PeriodicityLabel } from '../components';
export var ReportsIndex = function () {
    var history = useHistory();
    var reports = useLoadReports().reports;
    var archiveReport = useArchiveReport().archiveReport;
    var handleArchiveReport = function (e, id) {
        e.stopPropagation();
        modals.openConfirmModal({
            centered: true,
            title: 'Delete report',
            children: React.createElement(Text, null, "Are you sure you want to delete this report?"),
            labels: { cancel: 'Cancel', confirm: 'Delete' },
            confirmProps: { color: 'red' },
            onConfirm: function () { return archiveReport(id); },
            onCancel: modals.closeAll,
        });
    };
    var tableBody = reports
        ? reports.map(function (task, index) { return (React.createElement(Table.Tr, { key: index, style: { cursor: 'pointer' }, onClick: function () { return history.push("/reports/".concat(task.id)); } },
            React.createElement(Table.Td, null,
                React.createElement(Text, { fz: 14 }, upperFirst(task.name))),
            React.createElement(Table.Td, null,
                React.createElement(PeriodicityLabel, { type: task.periodicity.type })),
            React.createElement(Table.Td, null, task.lastExecutedAt ? (task.lastExecutedAt.format('dddd MMMM D, YYYY [at] hh:mm')) : (React.createElement(Text, { fz: 14, fs: "italic", c: "gray.5" }, "Not executed yet"))),
            React.createElement(Table.Td, null,
                React.createElement(Group, null,
                    React.createElement(Button, { size: "xs", variant: "light", rightSection: React.createElement(IconEdit, { width: 16, height: 16 }) }, "Edit"),
                    React.createElement(ActionIcon, { onClick: function (e) { return handleArchiveReport(e, task.id); }, size: "input-xs", variant: "light", color: "red", h: "100%" },
                        React.createElement(IconTrash, { stroke: 1.5, size: 16 })))))); })
        : times(5, function (index) { return (React.createElement(Table.Tr, { key: index },
            React.createElement(Table.Td, null,
                React.createElement(Skeleton, { height: 20, w: "80%" })),
            React.createElement(Table.Td, null,
                React.createElement(Skeleton, { height: 20, w: "80%" })),
            React.createElement(Table.Td, null,
                React.createElement(Skeleton, { height: 20, w: "80%" })),
            React.createElement(Table.Td, null,
                React.createElement(Skeleton, { height: 20, w: 20, mr: 15 }),
                React.createElement(Skeleton, { height: 20, w: 20 })))); });
    return (React.createElement(React.Fragment, null,
        React.createElement(PageTitle, null,
            "Reports",
            React.createElement(PageTitle.Actions, null,
                React.createElement(Button, { onClick: function () {
                        localStorage.removeItem('persist-report-creation');
                        history.push('/reports/new');
                    }, size: "md", rightSection: React.createElement(IconPlus, { stroke: 1.5 }) }, "New report"))),
        React.createElement(Card, { radius: "md", shadow: "xs", mt: "md", p: 0 },
            React.createElement(Table, { highlightOnHover: true, horizontalSpacing: "md", verticalSpacing: "sm" },
                React.createElement(Table.Thead, null,
                    React.createElement(Table.Tr, { bg: "#fafafa", style: { borderBottom: '2px solid #e3e3e3' } },
                        React.createElement(Table.Th, null,
                            React.createElement(Text, { fz: 14, fw: 450 }, "NAME")),
                        React.createElement(Table.Th, null,
                            React.createElement(Text, { fz: 14, fw: 450 }, "PERIODICITY")),
                        React.createElement(Table.Th, null,
                            React.createElement(Text, { fz: 14, fw: 450 }, "LAST EXECUTED AT")),
                        React.createElement(Table.Th, null,
                            React.createElement(Text, { fz: 14, fw: 450 }, "ACTIONS")))),
                React.createElement(Table.Tbody, null, tableBody)))));
};
