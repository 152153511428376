import { ModelType, VarType } from 'app/Logged/CustomVariables/CustomVariables.types';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CustomSelect, DateSelector, Input } from 'shared/io';
import styleIdentifiers from '../clientForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ClientCustomVariableForm() {
    var t = useTranslation().t;
    var availableCustomVariables = useSelector(function (state) {
        var _a, _b, _c;
        return (_c = (_b = (_a = state.account.company) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.custom_variables_attributes) === null || _c === void 0 ? void 0 : _c.filter(function (el) { return el.model_type === ModelType.DEBTOR; });
    });
    var _a = useFormContext(), register = _a.register, setValue = _a.setValue;
    var accurateInput = function (customVarKey, varType) {
        switch (varType) {
            case VarType.BOOLEAN:
                return (React.createElement(Controller, { defaultValue: "", name: "custom_variables.".concat(customVarKey), render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "description", keyValue: "value", items: [
                            {
                                description: t(i18nKeys.YES),
                                value: 'true',
                            },
                            {
                                description: t(i18nKeys.NO),
                                value: 'false',
                            },
                        ], withBorder: true, name: "custom_variables.".concat(customVarKey), label: customVarKey.replaceAll('_', ' ') })); } }));
            case VarType.DATE:
                return (React.createElement(DateSelector, { name: "custom_variables.".concat(customVarKey), className: styles('input'), noMinDate: true, withBorder: true, placeholder: " ", label: customVarKey.replaceAll('_', ' '), handleChange: function (value) {
                        setValue("custom_variables.".concat(customVarKey), value);
                    } }));
            default:
                return (React.createElement(Input, { register: register("custom_variables.".concat(customVarKey)), withBorder: true, label: customVarKey.replaceAll('_', ' '), className: styles('input'), type: varType === VarType.NUMBER ? 'number' : 'text', step: "0.01", noMargin: true }));
        }
    };
    var renderInput = function (customVar) {
        var varType = customVar.var_type;
        return (React.createElement("div", { key: customVar.column_name, className: styles('col-12') }, accurateInput(customVar.column_name, varType)));
    };
    return (React.createElement(React.Fragment, null, availableCustomVariables
        .sort(function (a, b) { return a.name.localeCompare(b.name); })
        .map(function (customVar) { return renderInput(customVar); })));
}
