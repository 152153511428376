import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import AYSModal from 'shared/components/AYSModal';
import { IconName } from 'shared/components/Icon';
import OnboardingItem from 'shared/components/OnboardingItem';
import PaymentForm from 'shared/forms/PaymentForm';
import { accountActions } from 'store/account/account.actions';
import { onBoardingActions } from 'store/onBoarding/onBoarding.actions';
import { dialogHide, showDialog, DialogShowId, DialogShowSize } from 'store/view/view.actions';
import styleIdentifiers from './paymentMethod.scss';
import Twikey from './Twikey';
var styles = classNames.bind(styleIdentifiers);
export default function PaymentMethod(_a) {
    var onValidStep = _a.onValidStep;
    var t = useTranslation().t;
    var callback = function () {
        dialogHide(DialogShowId.CUSTOM);
        accountActions.getCompany({
            callback: function () {
                onValidStep();
            },
            actionFailure: function (response) {
                onValidStep(response.data.next_step);
            },
        });
    };
    var addStripe = function (data) {
        onBoardingActions.stripe({
            callback: callback,
            data: data,
        });
    };
    var chooseStripe = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            title: t(i18nKeys.CREDIT_CARD),
            children: React.createElement(PaymentForm, { onSubmit: addStripe }),
        });
    };
    var chooseTwikey = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.ONBOARDING.STEP1.DIRECT_DEBIT.TITLE),
            children: React.createElement(Twikey, { onboarding: true, onSubmit: function () { return onValidStep(); } }),
        });
    };
    var chooseLater = function () {
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRM),
            children: (React.createElement(AYSModal, { text: t(i18nKeys.ONBOARDING.STEP1.AYS_TO_NOT_ADD_PAYMENT_METHOD), onConfirm: function () {
                    onBoardingActions.noPayment({
                        callback: function () {
                            dialogHide(DialogShowId.CONFIRM);
                            accountActions.getCompany({
                                callback: function () {
                                    onValidStep();
                                },
                                actionFailure: function (response) {
                                    onValidStep(response.data.next_step);
                                },
                            });
                        },
                    });
                } })),
        });
    };
    return (React.createElement("div", { className: styles('payment-method') },
        React.createElement("div", { className: styles('steps') },
            React.createElement(OnboardingItem, { title: t(i18nKeys.ONBOARDING.STEP1.DIRECT_DEBIT.TITLE), step: "1", icon: IconName.DOMICILIATION, onClick: chooseTwikey, paddingBottom: true, description: t(i18nKeys.ONBOARDING.STEP1.DIRECT_DEBIT.DESCRIPTION) }),
            React.createElement(OnboardingItem, { title: t(i18nKeys.CREDIT_CARD), step: "2", icon: IconName.CREDIT_CARD, onClick: chooseStripe, paddingBottom: true, description: t(i18nKeys.ONBOARDING.STEP1.CREDIT_CARD.DESCRIPTION) }),
            React.createElement(OnboardingItem, { title: t(i18nKeys.ADD_LATER), step: "2", icon: IconName.LATER, onClick: chooseLater, paddingBottom: true }))));
}
