import { __assign } from "tslib";
import moment from 'moment';
import qs from 'query-string';
import { mapKeys } from 'remeda';
import { appActions } from 'store/app/app.actions';
import { browserHistory } from 'store/setupClientStore';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/nl';
export var animationClassList = function (name, styles) { return ({
    appear: styles("".concat(name, "-appear")),
    appearActive: styles("".concat(name, "-appear-active")),
    enter: styles("".concat(name, "-enter")),
    enterActive: styles("".concat(name, "-enter-active")),
    enterDone: styles("".concat(name, "-enter-done")),
    exit: styles("".concat(name, "-exit")),
    exitActive: styles("".concat(name, "-exit-active")),
    exitDone: styles("".concat(name, "-exit-done")),
}); };
export default animationClassList;
// Only used once, easy to replace by getQueryParams in url.ts
export var getQueryVariable = function (query, variable) {
    if (!query)
        return '';
    query = query.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return '';
};
// Only used once
export function downloadBlobFile(blobURL, filename, blob) {
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        if (blob) {
            window.navigator.msSaveBlob(blob, filename);
        }
        else {
            fetch(blobURL)
                .then(function (res) { return res.blob(); })
                .then(function (resBlob) {
                window.navigator.msSaveBlob(resBlob, filename);
            });
        }
    }
    else {
        var tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', filename);
        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
    }
}
/**
 * Download a file from an api
 */
export function downloadFile(data, filename, mime) {
    var blob = new Blob([data], { type: mime || 'application/octet-stream' });
    var blobUrl = window.URL.createObjectURL(blob);
    downloadBlobFile(blobUrl, filename, blob);
    window.URL.revokeObjectURL(blobUrl);
}
/**
|--------------------------------------------------
| Date function
|--------------------------------------------------
*/
export function formattedDate(date, lg, format) {
    return (date &&
        moment
            .utc(date)
            .local()
            .locale((lg || 'fr'))
            .format(format || 'DD/MM/YYYY'));
}
export function dateFromNow(date, lg) {
    return (date &&
        moment
            .utc(date)
            .local()
            .locale(lg !== null && lg !== void 0 ? lg : 'fr')
            .fromNow());
}
export var extractFilename = function (headers) {
    var disposition = headers['content-disposition'];
    var filename = disposition.match('filename="(.*)"');
    filename = filename && filename[1];
    return filename;
};
export var removeAttributes = function (item) {
    item = __assign(__assign({}, item), item.attributes);
    delete item.attributes;
    return item;
};
// Only used twice, easy to replace
export var updateQueryFilters = function (queryStrings) {
    browserHistory.push({
        pathname: location.pathname,
        search: qs.stringify(queryStrings, { arrayFormat: 'comma' }),
    });
};
export var reducerState = function (prevState, updatedProperty) {
    return __assign(__assign({}, prevState), updatedProperty);
};
export var getBankImage = function (bic) {
    var banks = {
        ARSPBE22: 'argenta.png',
        AXABBE22: 'axa.png',
        GKCCBEBB: 'belfius.png',
        CTBKBEBX: 'beobank.png',
        GEBABEBB: 'bnp.png',
        BPOTBEB1: 'bpost.png',
        CREGBEBB: 'cbc.png',
        CEKVBE81: 'ckv.png',
        NICABEBB: 'crelan.png',
        DEUTBEBE: 'deutschebank.png',
        EURBBE99: 'europabank.png',
        BBRUBEBB: 'ing.png',
        KREDBEBB: 'kbc.png',
        SGPBBE99: 'societe-generale.png',
        TRIOBEBB: 'triodos.png',
    };
    return banks[bic] && require("./assets/bank/".concat(banks[bic]));
};
export var queryParamParser = function (value) {
    Object.keys(value).forEach(function (key) {
        if (key.indexOf('_start') !== -1) {
            var fieldName = key.substring(0, key.indexOf('_start'));
            value[fieldName] = [value["".concat(fieldName, "_start")] || null, value["".concat(fieldName, "_end")] || null];
        }
        else if (key.indexOf('_end') !== -1) {
            var fieldName = key.substring(0, key.indexOf('_end'));
            value[fieldName] = [value["".concat(fieldName, "_start")] || null, value["".concat(fieldName, "_end")] || null];
        }
    });
    return mapKeys(value, function (k) { return String(k).replace('?', ''); });
};
export var reloadCustomView = function (callback) {
    appActions.getView({
        id: location.hash.slice(1),
        data: queryParamParser(qs.parse(location.search, { arrayFormat: 'comma' })),
        callback: function (data) {
            callback(data, 'fulfilled');
        },
    });
};
