import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import LoginForm from 'shared/forms/LoginForm';
import { login } from 'store/session/session.actions';
import styleIdentifiers from './signIn.scss';
var styles = classNames.bind(styleIdentifiers);
export default function SignIn() {
    var t = useTranslation().t;
    var email = useSelector(function (state) { var _a, _b, _c; return (_c = (_b = (_a = state.account.signupInfo) === null || _a === void 0 ? void 0 : _a.requestData) === null || _b === void 0 ? void 0 : _b.user) === null || _c === void 0 ? void 0 : _c.email; });
    return (React.createElement("div", { className: styles('SignIn') },
        React.createElement("div", { className: styles('info') },
            React.createElement("span", null, t(i18nKeys.LOGIN.TITLE)),
            React.createElement("b", null, t(i18nKeys.RECOVR))),
        React.createElement("div", { className: styles('form') },
            React.createElement(LoginForm, { onSubmit: login, initialValues: email && { email: email } })),
        React.createElement("div", { className: styles('links') },
            React.createElement(NavLink, { className: styles('link', 'password'), to: "/auth/password/reset" },
                React.createElement("b", null, t(i18nKeys.LOGIN.YOU_FORGOT)),
                React.createElement("span", null,
                    "\u00A0",
                    t(i18nKeys.LOGIN.YOUR_PASSWORD))),
            React.createElement("div", { className: styles('link') },
                React.createElement("span", null,
                    t(i18nKeys.LOGIN.NOT_YET_ACCOUNT),
                    "\u00A0"),
                React.createElement("a", { href: "mailto:info@recovr.be" }, t(i18nKeys.LOGIN.ASK_ACCOUNT))))));
}
