import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { useSelector } from 'react-redux';
import HTML from 'shared/components/HTML/HTML';
import styleIdentifiers from './signupCompany.scss';
import SignupCompanyForm from './SignupCompanyForm';
var styles = classNames.bind(styleIdentifiers);
export default function SignupCompany(_a) {
    var back = _a.back, className = _a.className, initialValues = _a.initialValues, onSubmit = _a.onSubmit, active = _a.active;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var initValue = initialValues;
    if (company) {
        initValue = __assign(__assign({}, initialValues), { id: company.id, phoneNumber: company.phones_attributes.number });
    }
    initValue = __assign({}, initialValues);
    return (React.createElement("div", { className: styles('signup-company', className) },
        React.createElement("div", { className: styles('info') },
            React.createElement("div", null,
                React.createElement(HTML, { html: t(i18nKeys.ONBOARDING.SIGNUP_COMPANY_TITLE) }))),
        React.createElement("div", { className: styles('form') },
            React.createElement(SignupCompanyForm, { active: active, back: back, initialValues: initValue, onSubmit: onSubmit }))));
}
