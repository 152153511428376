import classNames from 'classnames/bind';
import React from 'react';
import { useSelector } from 'react-redux';
import styleIdentifiers from './statusItem.scss';
var styles = classNames.bind(styleIdentifiers);
export default function StatusItem(_a) {
    var _b, _c, _d;
    var className = _a.className, step = _a.step, isLate = _a.isLate, status = _a.status;
    var constants = useSelector(function (state) { return state.app.constants; });
    var description = (_c = (_b = constants.step_collection_names) === null || _b === void 0 ? void 0 : _b[step]) === null || _c === void 0 ? void 0 : _c.description;
    // Sentry 171 - It seems this can happen when the private constants are not loaded yet,
    // probably through a race condition when navigating directly to this page from login.
    // Skipping this render and waiting for the request to complete should fix it.
    if (constants.step_collection_names == null)
        return null;
    return (React.createElement("div", { className: styles('StatusItem', "step-".concat(step + 1), className) },
        React.createElement("div", { className: styles('label') }, (_d = status !== null && status !== void 0 ? status : description) !== null && _d !== void 0 ? _d : ''),
        React.createElement("div", { className: styles('indicator') }, (step > 0 || isLate) &&
            new Array(6)
                .fill(0)
                .map(function (it, key) { return (React.createElement("span", { key: it.id || key, className: styles('indic', step < 5 && key <= step && 'selected') })); }))));
}
