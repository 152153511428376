import { __assign, __read } from "tslib";
import React, { useLayoutEffect, useState } from 'react';
import { useDeleteUnvalidatedImports, useGetDebtorsToConfirm } from 'api';
import { useProfile } from 'app/App';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { pick } from 'lodash-es';
import { useHistory, useLocation } from 'react-router-dom';
import ClientListItem from 'shared/components/ClientListItem/ClientListItem';
import CustomTable from 'shared/components/CustomTable';
import Loader from 'shared/components/Loader';
import { Button, ButtonColor } from 'shared/io';
import { accountActions } from 'store/account/account.actions';
import { clientActions } from 'store/client/client.actions';
import styleIdentifiers from './ClientsToConfirmList.scss';
var styles = classNames.bind(styleIdentifiers);
var EnhancedTable = CustomTable(ClientListItem);
export var ClientsToConfirmList = function () {
    var t = useTranslation().t;
    var history = useHistory();
    var location = useLocation();
    var profile = useProfile();
    var _a = __read(useState({
        page_limit: profile.preferences.itemsPerPage,
        page: 1,
    }), 2), params = _a[0], setParams = _a[1];
    var _b = __read(useState(false), 2), isDeleting = _b[0], setIsDeleting = _b[1];
    useLayoutEffect(function () {
        setParams(function (currentParams) { return (__assign(__assign({}, currentParams), pick(Object.fromEntries(new URLSearchParams(location.search)), [
            'page',
            'page_limit',
            'sort_by',
            'sort_order',
        ]))); });
    }, []);
    var _c = useGetDebtorsToConfirm(params), debtorsToConfirmListResponse = _c.data, isLoading = _c.isLoading, isSuccess = _c.isSuccess, isError = _c.isError;
    var deleteUnvalidatedImports = useDeleteUnvalidatedImports().mutate;
    if (isLoading && !debtorsToConfirmListResponse) {
        return React.createElement(Loader, null);
    }
    if (isError || !debtorsToConfirmListResponse) {
        return React.createElement("span", null, "Error");
    }
    var handleDeleteUnvalidatedImports = function () {
        setIsDeleting(true);
        deleteUnvalidatedImports({ data: { only_debtor: true } }, {
            onError: function () {
                setIsDeleting(false);
            },
            onSuccess: function () {
                accountActions.getCompany();
                history.push('/clients');
            },
        });
    };
    var items = debtorsToConfirmListResponse.data || [];
    var pendingImport = debtorsToConfirmListResponse.pending_import;
    var CLIENTS_TABLE_HEADERS = [
        {
            key: 'full_name',
            title: t(i18nKeys.NAME),
            width: 160,
        },
        {
            key: 'vat_number',
            title: t(i18nKeys.VAT_NUMBER),
            width: 140,
        },
        {
            key: 'emails',
            title: t(i18nKeys.EMAIL),
            width: 160,
        },
        {
            key: 'main_phone',
            title: t(i18nKeys.PHONE),
            width: 140,
        },
        {
            key: 'city',
            title: t(i18nKeys.FORM.ADDRESS.CITY),
            width: 120,
        },
        {
            key: 'country',
            title: t(i18nKeys.FORM.ADDRESS.COUNTRY),
            width: 120,
        },
        {
            title: '',
            width: 120,
        },
    ];
    var handleFilters = function (newValues) {
        var optimisticResult = __assign(__assign({}, params), pick(newValues, ['page_limit', 'page']));
        setParams(function (current) { return (__assign(__assign({}, current), pick(newValues, ['page_limit', 'page']))); });
        return optimisticResult;
    };
    var onSortChange = function (newParams) {
        setParams(function (currentParams) { return (__assign(__assign({}, currentParams), pick(newParams, ['sort_by', 'sort_order', 'page_limit', 'page']))); });
    };
    var action = function (actionToSend) { return function () {
        return actionToSend({
            callback: function () {
                history.push('/clients');
            },
        });
    }; };
    return (React.createElement("div", { className: styles('client-list-to-confirm') },
        React.createElement(EnhancedTable, { title: t(i18nKeys.CLIENT.IMPORT.CLIENTS_TO_CONFIRM), actions: pendingImport || items.length === 0 ? null : (React.createElement(React.Fragment, null,
                React.createElement(Button, { onClick: handleDeleteUnvalidatedImports, color: ButtonColor.GREY, label: t(i18nKeys.INVOICING.IMPORT.DELETE_ALL), noMargin: true, disabled: items.filter(function (item) { return !item.attributes.csv_updated; }).length === 0, isLoading: isDeleting }),
                React.createElement(Button, { onClick: action(clientActions.validateAllImportedClients), color: ButtonColor.GREEN, label: t(i18nKeys.INVOICING.IMPORT.VALIDATE_ALL), noMargin: true, disabled: isDeleting }))), noCheckbox: true, onSortChange: onSortChange, loading: isLoading, loaded: isSuccess, pagination: pendingImport ? undefined : debtorsToConfirmListResponse.metadata.pagination, headers: CLIENTS_TABLE_HEADERS, handleFilters: handleFilters, items: pendingImport ? [] : items, itemProps: { notValidate: true, isLoading: isDeleting }, pendingImport: pendingImport })));
};
