import React from 'react';
import { useGetTaskTypes } from 'api';
import { TaskTypeListItem } from 'app/Logged/Tasks/TaskTypeList/TaskTypeListItem';
import classNames from 'classnames';
import { i18nKeys, useTranslation } from 'locales';
import CustomTable from 'shared/components/CustomTable';
import styleIdentifiers from './CustomizationsSettingsTaskTypes.scss';
var styles = classNames.bind(styleIdentifiers);
var TaskTypesTable = CustomTable(TaskTypeListItem);
export var CustomizationsSettingsTaskTypes = function () {
    var _a;
    var t = useTranslation().t;
    var _b = useGetTaskTypes(), taskTypesListResponse = _b.data, isLoading = _b.isLoading, isSuccess = _b.isSuccess, refetch = _b.refetch;
    var TABLE_HEADERS = [
        {
            key: 'id',
            title: t(i18nKeys.SETTINGS.TASKS.TASK_ID),
        },
        {
            key: 'name',
            title: t(i18nKeys.SETTINGS.TASKS.TASK_VALUE),
        },
        {
            key: 'creation',
            title: t(i18nKeys.CREATION),
        },
        {
            title: t(i18nKeys.FORM.ACTIONS),
            width: 100,
        },
    ];
    return (React.createElement(TaskTypesTable, { notSortable: true, headers: TABLE_HEADERS, items: (taskTypesListResponse === null || taskTypesListResponse === void 0 ? void 0 : taskTypesListResponse.data) || [], pagination: (_a = taskTypesListResponse === null || taskTypesListResponse === void 0 ? void 0 : taskTypesListResponse.metadata) === null || _a === void 0 ? void 0 : _a.pagination, itemProps: { refetch: refetch }, loading: isLoading, loaded: isSuccess, noCheckbox: true, noShadow: true }));
};
