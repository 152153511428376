import { __assign, __read } from "tslib";
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { treatInvoice } from 'shared/serializer';
import { clientActions } from 'store/client/client.actions';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { captureMessage } from '@sentry/browser';
import { reducerState } from './view';
// todo: shouldn't be needed
export function useForceUpdate() {
    var _a = __read(useState(0), 2), setTick = _a[1];
    var update = useCallback(function () {
        setTick(function (tick) { return tick + 1; });
    }, []);
    return update;
}
// todo: shouldn't be needed
export function useRefresh() {
    var _a = __read(useState(0), 2), refresh = _a[0], setRefresh = _a[1];
    var doRefresh = function () {
        setRefresh(function (prev) { return prev + 1; });
    };
    return { refresh: refresh, doRefresh: doRefresh };
}
export function usePrevious(value) {
    var ref = useRef(null);
    useEffect(function () {
        ref.current = value;
    });
    return ref.current;
}
export function useCombinedRefs() {
    var refs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        refs[_i] = arguments[_i];
    }
    var targetRef = useRef(null);
    useEffect(function () {
        refs.forEach(function (ref) {
            if (!ref)
                return;
            if (typeof ref === 'function') {
                ref(targetRef.current);
            }
            else {
                ref.current = targetRef.current;
            }
        });
    }, [refs]);
    return targetRef;
}
export function useFilterForm(_a) {
    var initialValues = _a.initialValues, fieldToRegister = _a.fieldToRegister, onSubmit = _a.onSubmit, _b = _a.submitParser, submitParser = _b === void 0 ? function (values) { return values; } : _b, _c = _a.shouldUnregister, shouldUnregister = _c === void 0 ? true : _c;
    var _d = __read(useReducer(reducerState, {
        form: useForm({ shouldUnregister: shouldUnregister }),
    }), 2), _e = _d[0], submit = _e.submit, form = _e.form, setState = _d[1];
    var getValues = form.getValues;
    var resetForm = function () {
        for (var key in getValues()) {
            form.setValue(key, '');
        }
        onSubmit(submitParser(form.getValues()));
    };
    useEffect(function () {
        fieldToRegister &&
            fieldToRegister.forEach(function (field) {
                form.register(field);
            });
        for (var key in initialValues) {
            form.setValue(key, initialValues[key]);
        }
        setState({
            submit: function () { return onSubmit(submitParser(form.getValues())); },
        });
    }, []);
    var formData = getValues();
    return {
        formData: formData,
        form: form,
        submit: submit,
        resetForm: resetForm,
    };
}
export function useMediaQuery(queryString) {
    var query = useMemo(function () { return window.matchMedia(queryString); }, [queryString]);
    var _a = __read(useState(query.matches), 2), doesMatch = _a[0], setMatches = _a[1];
    useEffect(function () {
        var listener = function (e) { return setMatches(e.matches); };
        if (query.addEventListener != null) {
            query.addEventListener('change', listener);
            return function () { return query.removeEventListener('change', listener); };
        }
        captureMessage('In useMediaQuery, query.addEventListener is null.', {
            extra: { query: query, queryType: typeof query, queryKeys: Object.keys(query) },
        });
        return function () { };
    }, [query]);
    return doesMatch;
}
export var getSearchInvoicesUrlParams = function (_a) {
    var search = _a.search;
    var params = new URLSearchParams(search);
    return {
        reference: params.has('reference') ? params.getAll('reference') : undefined,
        exclude: params.has('exclude') ? params.getAll('exclude') : undefined,
        currency: params.get('currency') || undefined,
        debtor_id: params.get('debtor_id') || undefined,
    };
};
export var useSearchInvoices = function () {
    var invoices = useSelector(function (state) { var _a; return (((_a = state.creditNote.invoiceSearch) === null || _a === void 0 ? void 0 : _a.data) || []); }).map(function (e) { return treatInvoice(e); });
    var getInvoices = function (params, callback) {
        creditNoteActions.invoiceSearch(__assign(__assign({}, params), { callback: callback }));
    };
    return { invoices: invoices, getInvoices: getInvoices };
};
export var useSearchDebtors = function () {
    var debtors = useSelector(function (state) { var _a, _b; return (_b = (_a = state.client) === null || _a === void 0 ? void 0 : _a.search) === null || _b === void 0 ? void 0 : _b.data; }) || [];
    var getDebtors = function (params) {
        clientActions.search(params);
    };
    return { debtors: debtors, getDebtors: getDebtors };
};
