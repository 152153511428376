import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { Billit, Codabox, Digiteal, Exact, Horus, Integration, INTEGRATION_ROUTE, INTEGRATIONS_ROUTE, isEnabledIntegration, Ponto, Teamleader, Yuki, } from 'shared/components/Integrations';
import { DBasics } from 'shared/components/Integrations/components/DBasics/DBasics';
import { PageTitle } from 'shared/layout/PageTitle';
import { accountActions } from 'store/account/account.actions';
import AutomaticAssignationDetail from '../AutomaticAssignations/Detail/AutomaticAssignationDetail';
import EditDebtCollectionCustomization from '../Templates/EditDebtCollectionCustomization';
import TemplateEditor from '../Templates/EditDebtCollectionCustomization/TemplateEditor';
import { WorkflowFormAdd, WorkflowFormEdit } from '../Workflows';
import { CollaboratorsSettings } from './CollaboratorsSettings/CollaboratorsSettings';
import { CompanySettings } from './CompanySettings/CompanySettings';
import { CustomizationSettings } from './CustomzationsSettings/CustomizationsSettings';
import { ExternalMailboxSettings } from './ExternalMailboxSettings/ExternalMailboxSettings';
import { IntegrationsSettings } from './IntegrationsSettings/IntegrationsSettings';
import { InvoiceSettings } from './InvoiceSettings/InvoiceSettings';
import { PackageSettings } from './PackageSettings/PackageSettings';
import { PreferenceSettings } from './PreferenceSettings/PreferenceSettings';
import { TemplatesSettings } from './TemplatesSettings/TemplatesSettings';
import { WorkflowsSettings } from './WorkflowsSettings/WorkflowsSettings';
import { SettingsCard } from './SettingsCard';
import { UserSettings } from './UserSettings';
import styleIdentifiers from './Settings.scss';
var styles = classNames.bind(styleIdentifiers);
export default function Settings() {
    var t = useTranslation().t;
    useEffect(function () {
        accountActions.getCompany({
            noLoading: true,
            noFeedback: true,
        });
    }, []);
    return (React.createElement("div", { className: styles('Settings') },
        React.createElement(Switch, null,
            React.createElement(Route, { path: "/settings/user", component: UserSettings }),
            React.createElement(Route, { path: "/settings/company", component: CompanySettings }),
            React.createElement(Route, { path: "/settings/preferences", component: PreferenceSettings }),
            React.createElement(Route, { path: "/settings/package", component: PackageSettings }),
            React.createElement(Route, { path: "/settings/external-mailbox", component: ExternalMailboxSettings }),
            React.createElement(Route, { path: "/settings/billing", component: InvoiceSettings }),
            React.createElement(Route, { path: "/settings/document-customization/edit/:id", component: TemplateEditor }),
            React.createElement(Route, { path: "/settings/document-customization", component: TemplatesSettings }),
            React.createElement(Route, { path: "/settings/workflows/recovery-plans/create", component: EditDebtCollectionCustomization }),
            React.createElement(Route, { path: "/settings/workflows/recovery-plans/edit/:id", component: EditDebtCollectionCustomization }),
            React.createElement(Route, { path: "/settings/workflows/:planType/create", component: WorkflowFormAdd }),
            React.createElement(Route, { path: "/settings/workflows/:planType/edit/:id", component: WorkflowFormEdit }),
            React.createElement(Route, { exact: true, path: "/settings/workflows", render: function () { return React.createElement(Redirect, { to: "/settings/workflows/recovery-plans" }); } }),
            React.createElement(Route, { path: "/settings/workflows", component: WorkflowsSettings }),
            React.createElement(Route, { path: "/settings/automatic-assignation/:id", component: AutomaticAssignationDetail }),
            React.createElement(Route, { path: "/settings/collaborators", component: CollaboratorsSettings }),
            React.createElement(Route, { path: INTEGRATIONS_ROUTE, exact: true, component: IntegrationsSettings }),
            React.createElement(Route, { exact: true, path: "/settings/customization", render: function () { return React.createElement(Redirect, { to: "/settings/customization/custom-variables" }); } }),
            React.createElement(Route, { path: "/settings/customization", component: CustomizationSettings }),
            React.createElement(Route, { path: "/settings/integration", render: function () { return (React.createElement(React.Fragment, null,
                    React.createElement(PageTitle, null, t(i18nKeys.SETTINGS.INTEGRATIONS.TITLE)),
                    React.createElement(SettingsCard, null,
                        isEnabledIntegration(Integration.YUKI) && (React.createElement(Route, { path: INTEGRATION_ROUTE[Integration.YUKI], component: Yuki })),
                        isEnabledIntegration(Integration.HORUS) && (React.createElement(Route, { path: INTEGRATION_ROUTE[Integration.HORUS], component: Horus })),
                        isEnabledIntegration(Integration.TEAMLEADER) && (React.createElement(Route, { path: INTEGRATION_ROUTE[Integration.TEAMLEADER], component: Teamleader })),
                        isEnabledIntegration(Integration.EXACT) && (React.createElement(Route, { path: INTEGRATION_ROUTE[Integration.EXACT], component: Exact })),
                        isEnabledIntegration(Integration.DBASICS) && (React.createElement(Route, { path: INTEGRATION_ROUTE[Integration.DBASICS], component: DBasics })),
                        isEnabledIntegration(Integration.CODABOX) && (React.createElement(Route, { path: INTEGRATION_ROUTE[Integration.CODABOX], component: Codabox })),
                        isEnabledIntegration(Integration.PONTO) && (React.createElement(Route, { path: INTEGRATION_ROUTE[Integration.PONTO], component: Ponto })),
                        isEnabledIntegration(Integration.DIGITEAL) && (React.createElement(Route, { path: INTEGRATION_ROUTE[Integration.DIGITEAL], component: Digiteal })),
                        isEnabledIntegration(Integration.BILLIT) && (React.createElement(Route, { path: INTEGRATION_ROUTE[Integration.BILLIT], component: Billit }))))); } }))));
}
