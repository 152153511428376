import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import React from 'react';
import BankAccount from 'shared/components/BankAccount';
import { Checkbox } from 'shared/io';
import { currencySymbol, formatAmount } from 'shared/utils/normalization';
import styleIdentifiers from './DebtorReactionCardHeader.scss';
var styles = classNames.bind(styleIdentifiers);
export default function DebtorReactionCardHeader(_a) {
    var data = _a.data, hasPaymentOptions = _a.hasPaymentOptions, selectAll = _a.selectAll, onChangeSelectAll = _a.onChangeSelectAll;
    var constants = useSelector(function (state) { return state.app.constants; });
    var t = useTranslation().t;
    return (React.createElement("div", { className: styles('debtor-reaction-card-header') },
        React.createElement("div", null,
            React.createElement("span", null,
                "IBAN : ",
                React.createElement(BankAccount, { value: (data === null || data === void 0 ? void 0 : data.company_data.iban) || '' })),
            React.createElement("span", null,
                t(i18nKeys.INVOICE.DUE_AMOUNT),
                " :",
                ' ',
                formatAmount(data === null || data === void 0 ? void 0 : data.totalRemainingBalance, ',', '.', 2, currencySymbol(undefined, data.debtor_data, constants)))),
        hasPaymentOptions && (React.createElement("div", null,
            React.createElement(Checkbox, { noMargin: true, label: t(selectAll ? 'FORM.DESELECT_ALL' : 'FORM.SELECT_ALL'), name: "", onChange: onChangeSelectAll, checked: selectAll })))));
}
