import { __assign, __read, __rest } from "tslib";
import React, { useLayoutEffect, useState } from 'react';
import { useGetPaymentPlans } from 'api/index';
import { useProfile } from 'app/App';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { pick } from 'lodash-es';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import CustomTable from 'shared/components/CustomTable';
import { IconName } from 'shared/components/Icon';
import { Button } from 'shared/io';
import { PaymentPlanStatus, TABLE_HEADERS } from '../PaymentPlan.constants';
import { PaymentPlansListFilters } from './PaymentPlansListFilters';
import { PaymentPlanListItem } from './PaymentPlansListItem';
import styleIdentifiers from './PaymentPlansList.scss';
var styles = classNames.bind(styleIdentifiers);
var EnhancedTable = CustomTable(PaymentPlanListItem, PaymentPlansListFilters);
export var PaymentPlansList = function () {
    var t = useTranslation().t;
    var history = useHistory();
    var location = useLocation();
    var profile = useProfile();
    var can_use_payment_plans = useSelector(function (state) { return state.account.company.data; }).package.can_use_payment_plans;
    var _a = __read(useState({
        page_limit: profile.preferences.itemsPerPage,
        page: 1,
    }), 2), params = _a[0], setParams = _a[1];
    useLayoutEffect(function () {
        setParams(function (currentParams) { return (__assign(__assign({}, currentParams), pick(Object.fromEntries(new URLSearchParams(location.search)), [
            'page',
            'page_limit',
            'sort_by',
            'sort_order',
            'status',
            'debtor_id',
            'invoice_ids',
        ]))); });
    }, []);
    var _b = useGetPaymentPlans(params), PaymentPlansListResponse = _b.data, isLoading = _b.isLoading, isSuccess = _b.isSuccess, refetch = _b.refetch;
    var addPaymentPlan = function () {
        history.push('/payment-plans/create');
    };
    var headers = TABLE_HEADERS.map(function (_a) {
        var title = _a.title, rest = __rest(_a, ["title"]);
        return (__assign({ title: title && t(title) }, rest));
    });
    var onSortChange = function (newParams) {
        setParams(function (currentParams) { return (__assign(__assign({}, currentParams), pick(newParams, ['sort_by', 'sort_order', 'page_limit', 'page']))); });
    };
    var handleFilters = function (data) {
        var filters = __assign(__assign({}, pick(params, ['page_limit', 'page'])), data);
        setParams(filters);
        return filters;
    };
    var planStatus = Object.values(PaymentPlanStatus).map(function (value) { return ({
        description: t(i18nKeys[value]),
        value: value.toLowerCase(),
    }); });
    var filtersNames = {
        status: {
            description: t(i18nKeys.TASK.ATTRIBUTES.STATUS),
            value: planStatus,
        },
        invoice_ids: {
            description: t(i18nKeys.TASK.ATTRIBUTES.INVOICE),
        },
        debtor_id: {
            description: t(i18nKeys.TASK.ATTRIBUTES.DEBTOR),
        },
    };
    return (React.createElement("div", { className: styles('listing') },
        React.createElement(EnhancedTable, { title: t(i18nKeys.NAV.PAYMENT_PLAN), headers: headers, items: (PaymentPlansListResponse === null || PaymentPlansListResponse === void 0 ? void 0 : PaymentPlansListResponse.data) || [], pagination: PaymentPlansListResponse === null || PaymentPlansListResponse === void 0 ? void 0 : PaymentPlansListResponse.metadata.pagination, loading: isLoading, loaded: isSuccess, itemProps: { refetch: refetch }, showShadow: true, handleFilters: handleFilters, onSortChange: onSortChange, filtersNames: filtersNames, noCheckbox: true, actions: React.createElement(Button, { className: styles('action-button'), noMargin: true, label: t(i18nKeys.NEW_PAYMENT_PLAN), onClick: addPaymentPlan, iconRight: IconName.PLUS, disabled: !can_use_payment_plans, title: can_use_payment_plans ? '' : t(i18nKeys.NOT_INCLUDED) }) })));
};
