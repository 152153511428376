import { __assign } from "tslib";
import React, { useContext, useMemo } from 'react';
import { useCreateTask } from 'api';
import { TaskForm } from 'app/Logged/Tasks';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { HideIf, InvoiceActionsDropdown } from 'shared/components';
import AYSModal from 'shared/components/AYSModal';
import { IconName } from 'shared/components/Icon';
import { LinkInvoiceForm } from 'shared/forms/LinkInvoiceForm';
import { Button, ButtonColor, Dropdown } from 'shared/io';
import { PageTitle } from 'shared/layout';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog, sideMenuHide, sideMenuShow, } from 'store/view/view.actions';
import InvoiceDetailContext from '../InvoiceDetail.context';
import InvoiceSendingOptions from '../InvoiceSendingOptions';
import MarkedInvoiceActions from '../MarkedInvoiceActions';
import styleIdentifiers from '../ToHandleDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export default function InvoiceDetailHeader() {
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var history = useHistory();
    var _a = useContext(InvoiceDetailContext), data = _a.data, credit = _a.credit, unmatchPayment = _a.unmatchPayment, reloadInfo = _a.reloadInfo;
    var settleCreditNote = function (settle) { return function () {
        creditNoteActions.settle({
            id: data.id,
            data: {
                settle: settle,
            },
            callback: function () {
                creditNoteActions.info({
                    id: data.id,
                    noReset: true,
                    noLoading: true,
                });
            },
        });
    }; };
    var linkToInvoices = function (values) {
        var invoices_to_link = values.invoices_to_link
            .filter(function (e) { return e.amount > 0; })
            .filter(function (e) {
            return !data.matched_payments_attributes.some(function (el) { return Number(el.invoice_attributes.id) === Number(e.invoice_id); });
        })
            .map(function (e) { return ({
            id: e.invoice_id,
            amount: e.amount,
        }); });
        creditNoteActions.linkToInvoices({
            data: {
                id: data.id,
                invoices_to_link: invoices_to_link,
            },
            callback: function () {
                reloadInfo();
                dialogHide(DialogShowId.CUSTOM);
            },
        });
    };
    var linkCreditNote = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.LARGE,
            keepMountOnExit: true,
            title: t(i18nKeys.INVOICE.LINK_TO_INVOICE),
            children: (React.createElement(LinkInvoiceForm, { onSubmit: linkToInvoices, creditNote: data, reload: reloadInfo })),
        });
    };
    var duplicate = function () {
        dialogHide(DialogShowId.INVOICE);
        sideMenuHide();
        if (!data)
            return;
        if (credit) {
            creditNoteActions.copy({
                id: data.id,
                callback: function (responseData) { return history.push("/credit-notes/edit/".concat(responseData.data.id)); },
            });
        }
        else {
            invoiceActions.copy({
                id: data.id,
                callback: function (responseData) {
                    history.push("/invoices/edit/".concat(responseData.data.id));
                },
            });
        }
    };
    var deleteInvoice = function () {
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.DELETE),
            keepMountOnExit: true,
            children: (React.createElement(AYSModal, { text: t(credit
                    ? i18nKeys.INVOICE.AYS_TO_DELETE_CREDIT_NOTE
                    : i18nKeys.AYS.INVOICE_ACTION_DELETE), onConfirm: function () {
                    (credit ? creditNoteActions : invoiceActions).delete({
                        id: data.id,
                        callback: function () {
                            sideMenuHide();
                            dialogHide(DialogShowId.INVOICE);
                            history.push("/".concat(credit ? 'credit-notes' : 'invoices', "/listing"));
                        },
                    });
                } })),
        });
    };
    // TODO: Probably obsolete, thridparties have been removed from the invoice actions field
    var noRecoverActionAvailable = useMemo(function () {
        return !data.actions.some(function (action) {
            switch (action) {
                case 'additional_reminder':
                case 'callcenter':
                case 'bailiff':
                case 'formal_notice':
                case 'lawyer':
                    return true;
                default:
                    return false;
            }
        });
    }, [data === null || data === void 0 ? void 0 : data.actions]);
    var ignoreLateFees = function () {
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRMATION),
            children: (React.createElement(AYSModal, { text: t(i18nKeys.AYS.IGNORE_LATE_FEES), onConfirm: function () {
                    invoiceActions.actionsIgnoreLateFees({
                        id: data.id,
                        callback: function () {
                            sideMenuHide();
                            reloadInfo();
                        },
                    });
                } })),
        });
    };
    var openSOForm = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.INVOICE.SENDING_OPTIONS),
            children: (React.createElement(InvoiceSendingOptions, { invoiceId: data.id, sendingOptions: data.sending_options_attributes, debtorId: data.debtor_id })),
        });
    };
    var createTask = useCreateTask().mutate;
    var addTask = function () {
        sideMenuShow({
            unmount: true,
            content: (React.createElement(TaskForm, { invoiceId: data.id, onSubmit: function (taskData) {
                    return createTask({
                        data: __assign(__assign({}, taskData), { debtor_id: data.debtor_id, invoice_ids: [data.id] }),
                    }, {
                        onSettled: function () {
                            sideMenuHide();
                        },
                    });
                } })),
        });
    };
    var canLinkOrSettle = data.paid === true || Number(data.remaining_balance) === 0;
    return (React.createElement(PageTitle, null,
        data.reference || data.id,
        React.createElement(PageTitle.Actions, null, credit ? (React.createElement(React.Fragment, null,
            data.actions.includes('delete') && (React.createElement(Button, { className: styles('button'), noMargin: true, noShadow: true, label: t(i18nKeys.DELETE), onClick: deleteInvoice, color: ButtonColor.GREY })),
            React.createElement(HideIf, { connectorActive: true },
                React.createElement(Button, { className: styles('button'), noMargin: true, noShadow: true, label: t(i18nKeys.INVOICE.LINK_TO_INVOICE), disabled: canLinkOrSettle, onClick: linkCreditNote, color: ButtonColor.BLUE })),
            React.createElement(Button, { className: styles('button'), noMargin: true, noShadow: true, label: t(i18nKeys.INVOICE.BALANCE_CREDIT_NOTE), onClick: settleCreditNote(true), disabled: canLinkOrSettle, color: ButtonColor.GREEN }))) : (React.createElement(React.Fragment, null,
            React.createElement(Dropdown, { selectorContent: React.createElement(Button, { noShadow: true, noMargin: true, label: t(i18nKeys.INVOICE.OTHER_ACTIONS), className: styles('button'), color: ButtonColor.GREY, iconRight: IconName.MINIMAL_DOWN }), sideMenuInMobile: true, sideMenuTitle: t(i18nKeys.INVOICE.OTHER_ACTIONS) },
                company.package.can_use_tasks && (React.createElement("div", { className: styles('dropdown-item'), onClick: addTask }, t(i18nKeys.NEW_TASK))),
                React.createElement("div", { className: styles('dropdown-item'), onClick: openSOForm }, t(i18nKeys.INVOICE.EDIT_SENDING_OPTIONS)),
                React.createElement(HideIf, { connectorActive: true, isFrench: true },
                    React.createElement("div", { className: styles('dropdown-item'), onClick: duplicate }, t(i18nKeys.DUPLICATE))),
                React.createElement(HideIf, { connectorActive: true },
                    React.createElement("div", { className: styles('dropdown-item', !data.actions.includes('unmatch_payment') && 'disabled'), onClick: unmatchPayment }, t(i18nKeys.INVOICE.UNMATCH_PAYMENT.UNMATCH_PAYMENT))),
                React.createElement(HideIf, { connectorActive: true },
                    React.createElement("div", { className: styles('dropdown-item', data.actions.indexOf('delete') === -1 && 'disabled'), onClick: deleteInvoice }, t(i18nKeys.DELETE))),
                data.late_fees_calculated_at && (React.createElement("div", { className: styles('dropdown-item', !data.actions.includes('ignore_late_fees') && 'disabled'), onClick: ignoreLateFees }, t(i18nKeys.IGNORE_LATE_FEES)))),
            React.createElement(MarkedInvoiceActions, { invoice: data, element: React.createElement(Button, { noShadow: true, noMargin: true, label: t(i18nKeys.INVOICE.CHANGE_STATUS), className: styles('button'), iconRight: IconName.MINIMAL_DOWN }) }),
            React.createElement(InvoiceActionsDropdown, { reload: reloadInfo, invoice: data, selectorClass: styles(noRecoverActionAvailable && 'disabled'), item: React.createElement(Button, { noShadow: true, noMargin: true, label: t(i18nKeys.INVOICE.RECOVER_ACTIONS), disabled: noRecoverActionAvailable, className: styles('button'), color: ButtonColor.GREEN, iconRight: IconName.MINIMAL_DOWN }) }))))));
}
