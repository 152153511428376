import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, axiosInstance } from './utils';
var ActionableInvoicesSchema = z
    .object({
    totalInvoicesActionable: z.preprocess(function (val) { return Number(val); }, z.number()),
})
    .transform(function (_a) {
    var totalInvoicesActionable = _a.totalInvoicesActionable;
    return totalInvoicesActionable;
});
export function useLoadActionableInvoicesFn() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance.get('analytics/actionable_invoices')];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, ActionableInvoicesSchema.parse(data)];
            }
        });
    });
}
export var useLoadActionableInvoices = function () {
    var queryResult = useQuery({
        queryKey: ['actionable-invoices'],
        queryFn: useLoadActionableInvoicesFn,
    });
    return addResourceNameToQueryResult('actionableInvoices', queryResult);
};
