import { __assign, __read } from "tslib";
import React, { useLayoutEffect, useState } from 'react';
import { useGetDebtorTimeline } from 'api';
import { ObjectType, } from 'api/models';
import { useProfile } from 'app/App';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { pick } from 'lodash-es';
import TableFooter from 'shared/components/CustomTable/TableFooter';
import Loader from 'shared/components/Loader';
import { ClientDetailTabsTimelineFilter } from './ClientDetailTabsTimelineFilter';
import { ClientDetailTabsTimelineItem } from './ClientDetailTabsTimelineItem';
import { ClientDetailTabsTimelineItemActivity } from './ClientDetailTabsTimelineItemActivity';
import { ClientDetailTabsTimelineItemEmail } from './ClientDetailTabsTimelineItemEmail';
import { ClientDetailTabsTimelineItemReaction } from './ClientDetailTabsTimelineItemReaction';
import styleIdentifiers from './ClientDetailTabsTimeline.scss';
var styles = classNames.bind(styleIdentifiers);
export var ClientDetailTabsTimeline = function (_a) {
    var debtor = _a.debtor;
    var t = useTranslation().t;
    var profile = useProfile();
    var _b = __read(useState({
        page_limit: profile.preferences.itemsPerPage,
        page: 1,
    }), 2), params = _b[0], setParams = _b[1];
    useLayoutEffect(function () {
        setParams(function (currentParams) { return (__assign(__assign({}, currentParams), pick(Object.fromEntries(new URLSearchParams(location.search)), [
            'page',
            'page_limit',
            'types',
        ]))); });
    }, []);
    var _c = useGetDebtorTimeline(debtor.id, params), debtorTimelineResponse = _c.data, isLoading = _c.isLoading, isError = _c.isError;
    if (isLoading && !debtorTimelineResponse) {
        return React.createElement(Loader, null);
    }
    if (isError || !debtorTimelineResponse) {
        return React.createElement("span", null, "Error");
    }
    var data = debtorTimelineResponse.data, metadata = debtorTimelineResponse.metadata;
    var setPage = function (_a) {
        var selected = _a.selected;
        setParams(__assign(__assign({}, params), { page: selected + 1 }));
    };
    var setPageLimit = function (page_limit) { return function () {
        setParams(__assign(__assign({}, params), { page_limit: page_limit }));
    }; };
    return (React.createElement("section", { className: styles('tab-item', 'timeline') },
        React.createElement(ClientDetailTabsTimelineFilter, { params: params, setParams: setParams }),
        React.createElement("article", { className: styles('items') }, data.length ? (React.createElement("div", null, data.map(function (e) {
            switch (e.type) {
                case ObjectType.external_mail:
                    return (React.createElement(ClientDetailTabsTimelineItem, { type: e.type, key: "".concat(e.type, "-").concat(e.id) },
                        React.createElement(ClientDetailTabsTimelineItemEmail, { email: e })));
                case ObjectType.debtor_reaction:
                    return (React.createElement(ClientDetailTabsTimelineItem, { type: e.type, key: "".concat(e.type, "-").concat(e.id) },
                        React.createElement(ClientDetailTabsTimelineItemReaction, { reaction: e })));
                default:
                    return (React.createElement(ClientDetailTabsTimelineItem, { type: e.type, key: "".concat(e.type, "-").concat(e.id) },
                        React.createElement(ClientDetailTabsTimelineItemActivity, { activity: e })));
            }
        }))) : (React.createElement("div", { className: styles('no-result') },
            React.createElement("p", null, t(i18nKeys.NO_RESULT))))),
        React.createElement(TableFooter, { pagination: metadata.pagination, setPage: setPage, itemsLength: metadata.total, setPageLimit: setPageLimit, className: styles('sticky') })));
};
