import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Input } from 'shared/io';
import { email } from 'shared/utils/validation';
import styleIdentifiers from './loginForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function LoginForm(_a) {
    var onSubmit = _a.onSubmit, initialValues = _a.initialValues;
    var t = useTranslation().t;
    var _b = useForm({
        shouldUnregister: true,
        defaultValues: initialValues,
    }), register = _b.register, handleSubmit = _b.handleSubmit, errors = _b.formState.errors;
    return (React.createElement("form", { className: styles('LoginForm'), onSubmit: handleSubmit(onSubmit) },
        React.createElement(Input, { errorMessage: errors.email, register: register('email', { required: true, validate: { email: email } }), type: "email", label: t(i18nKeys.EMAIL) }),
        React.createElement(Input, { errorMessage: errors.password, register: register('password', { required: true }), type: "password", label: t(i18nKeys.PASSWORD) }),
        React.createElement(Button, { noShadow: true, label: t(i18nKeys.LOG_IN), type: "submit" })));
}
