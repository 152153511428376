import { __assign, __rest } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { Button, ButtonColor } from 'shared/io';
import { formattedDate } from 'shared/utils/view';
import { INTEGRATIONS_ROUTE } from '../Integrations.constants';
import { useSynchroniseIntegration } from './Integration.hooks';
import styleIdentifiers from './IntegrationHeader.scss';
import { ResetButton } from './ResetButton';
import { isAccountingIntegration } from '../Integrations.utils';
var styles = classNames.bind(styleIdentifiers);
export var IntegrationHeader = function (_a) {
    var name = _a.name, goBack = _a.goBack, children = _a.children, _b = _a.isActive, isActive = _b === void 0 ? false : _b, _c = _a.isConnected, isConnected = _c === void 0 ? false : _c, props = __rest(_a, ["name", "goBack", "children", "isActive", "isConnected"]);
    var _d = useTranslation(), t = _d.t, currentLang = _d.currentLang;
    var history = useHistory();
    var _e = useSynchroniseIntegration(), synchronize = _e.synchronize, canSynchronize = _e.canSynchronize, nextPossibleSynchronizeDate = _e.nextPossibleSynchronizeDate, lastImportDate = _e.lastImportDate;
    var lastSettingsScreen = useSelector(function (state) { return state.app.lastSettingsScreen; });
    return (React.createElement("header", __assign({ className: styles('header') }, props),
        React.createElement("button", { type: "button", className: styles('previous'), onClick: function () {
                history.push("".concat(INTEGRATIONS_ROUTE, "?category=").concat(lastSettingsScreen));
            } },
            React.createElement(Icon, { color: IconColor.GREY, size: "18px", name: IconName.MINIMAL_LEFT })),
        React.createElement("h3", null, name),
        React.createElement("div", { className: styles('synchronize') },
            isActive && (React.createElement(React.Fragment, null,
                lastImportDate && (React.createElement("p", null, t(i18nKeys.FORM.INTEGRATION.LAST_IMPORT_THE, {
                    date: formattedDate(lastImportDate, currentLang, 'LLL'),
                }))),
                React.createElement(Tooltip, { icon: IconName.INFO, iconClassName: styles('icon') },
                    t(i18nKeys.FORM.INTEGRATION.MANUAL_SYNC_DESCRIPTION),
                    !canSynchronize && (React.createElement("span", { className: styles('info') }, t(i18nKeys.FORM.INTEGRATION.NEXT_IMPORT_THE, {
                        date: formattedDate(nextPossibleSynchronizeDate, currentLang, 'LLL'),
                    })))),
                React.createElement(Button, { disabled: !canSynchronize, onClick: synchronize, iconRight: IconName.SYNC, label: t(i18nKeys.SYNCHRONIZE), noMargin: true, color: ButtonColor.WHITE }))),
            isAccountingIntegration(name) && isConnected ? React.createElement(ResetButton, null) : null)));
};
