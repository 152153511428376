import React from 'react';
import { useProfile } from 'app/App';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'shared/io';
import { logout } from 'store/session/session.actions';
import Avatar from '../Avatar';
import styleIdentifiers from './userItem.scss';
var styles = classNames.bind(styleIdentifiers);
export default function UserItem() {
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var profile = useProfile();
    if (!(company === null || company === void 0 ? void 0 : company.onboarding_done))
        return null;
    var logoff = function () {
        logout({
            api: true,
        });
    };
    return (React.createElement(Dropdown, { className: styles('UserItem'), toggleContent: true, selectorContent: React.createElement(Avatar, { size: 35, name: profile.name.first, secondName: profile.name.last, logoUrl: profile.profilePicture }) },
        React.createElement("div", { className: styles('menu') },
            React.createElement(NavLink, { className: styles('item'), to: "/settings/preferences", activeClassName: styles('active') }, t(i18nKeys.PREFERENCES)),
            React.createElement(NavLink, { className: styles('item'), to: "/settings/user", activeClassName: styles('active') }, t(i18nKeys.HEADER.USER_ACTIONS.EDIT_PROFILE)),
            React.createElement("div", { onClick: logoff, className: styles('item') }, t(i18nKeys.HEADER.USER_ACTIONS.LOGOUT)))));
}
