import { __assign } from "tslib";
import withReduxEnhancer from 'addon-redux/enhancer';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import Cookies from 'cookies-js';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';
import createSagaMiddleware from 'redux-saga';
import * as reducers from 'store/root.reducer';
export var browserHistory = createBrowserHistory();
var split = window.location.hostname.split('.');
var domain = "".concat(split[split.length - 2], ".").concat(split[split.length - 1]);
var persistConfig = {
    key: 'recovr-storage',
    whitelist: ['session'],
    storage: new CookieStorage(Cookies, {
        setCookieOptions: {
            domain: domain,
        },
    }),
};
var connectedReducers = function (hist) { return combineReducers(__assign(__assign({}, reducers), { router: connectRouter(hist) })); };
var rootReducer = function (hist) { return persistReducer(persistConfig, connectedReducers(hist)); };
var sagaMiddleware = createSagaMiddleware();
// eslint-disable-next-line no-underscore-dangle
var composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export var store = createStore(rootReducer(browserHistory), {}, composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(browserHistory)), withReduxEnhancer));
var persistor = persistStore(store);
var storeSetup = {
    store: store,
    persistor: persistor,
    sagaRun: sagaMiddleware.run,
};
if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('store/root.reducer', function () {
        var nextConnectedReducers = function () { return combineReducers(__assign({}, require('store/root.reducer'))); };
        var nextRootReducer = function () { return persistReducer(persistConfig, nextConnectedReducers()); };
        store.replaceReducer(nextRootReducer());
    });
}
export default storeSetup;
