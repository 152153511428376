import React, { useContext } from 'react';
import { useProfile } from 'app/App';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useHistory } from 'react-router-dom';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import ChooseCollaboratorModal from 'shared/components/ChooseCollaboratorModal';
import DateItem from 'shared/components/DateItem';
import DownloadInvoice from 'shared/components/DownloadInvoice';
import { Icon, IconName } from 'shared/components/Icon';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import LinkDocument from '../../LinkDocument';
import ImportOriginalInvoice from '../ImportOriginalInvoice';
import InvoiceDetailContext from '../InvoiceDetail.context';
import ToHandleDetail from '..';
import styleIdentifiers from '../ToHandleDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export default function InvoiceInfo(_a) {
    var modal = _a.modal;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var profile = useProfile();
    var _c = useContext(InvoiceDetailContext), data = _c.data, credit = _c.credit, noValidate = _c.noValidate, reloadInfo = _c.reloadInfo;
    var history = useHistory();
    var seePdf = function () {
        dialogHide(DialogShowId.INVOICE);
        history.push("/invoices/detail/".concat(data.id));
    };
    var sendOriginalInvoice = function () {
        if (!credit) {
            invoiceActions.detail({ id: data.id });
        }
        dialogHide(DialogShowId.CUSTOM);
    };
    var openLinkDocument = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            title: t(i18nKeys.INVOICE.JOIN_FILE),
            children: React.createElement(LinkDocument, { invoice: data }),
        });
    };
    var importOriginalInvoice = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            title: t(i18nKeys.INVOICE.ORIGINAL_FILE),
            children: React.createElement(ImportOriginalInvoice, { onEditOriginalFile: sendOriginalInvoice, invoice: data }),
        });
    };
    var showInvoice = function (linkedInvoice) {
        if (!modal) {
            history.push("/invoices/".concat(linkedInvoice.id));
        }
        else {
            showDialog({
                id: DialogShowId.INVOICE,
                size: DialogShowSize.LARGE,
                children: React.createElement(ToHandleDetail, { id: linkedInvoice.id, modal: true }),
            });
        }
    };
    var editAccountManager = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CHOOSE_ACCOUNT_MANAGER),
            children: (React.createElement(ChooseCollaboratorModal, { initialValues: data.account_manager, onSubmit: function (values) {
                    invoiceActions.assignToAccManager({
                        id: data.id,
                        data: values,
                        callback: function () {
                            reloadInfo();
                            dialogHide(DialogShowId.CUSTOM);
                        },
                    });
                } })),
        });
    };
    var unlinkCn = function (linkedInvoice) {
        creditNoteActions.unlinkToInvoices({
            id: data.id,
            data: {
                unlink_invoice: linkedInvoice.id,
            },
            callback: function () {
                dialogHide(DialogShowId.CUSTOM);
                reloadInfo();
            },
        });
    };
    return (React.createElement(Card, { actionHead: data.type === 'invoice' ? seePdf : undefined, title: t(credit ? 'CREDIT_NOTE' : 'COMMON.INVOICE'), className: styles('invoice-info', 'box') },
        React.createElement("div", null,
            React.createElement("div", { className: styles('item') },
                React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.FORM.REFERENCE)),
                React.createElement("span", { className: styles('value', 'blue', 'bigger') }, data.reference)),
            credit && !!(data === null || data === void 0 ? void 0 : data.matched_payments_attributes.length) && (React.createElement("div", { className: styles('item') },
                React.createElement("span", { className: styles('subtitle') }, t((data === null || data === void 0 ? void 0 : data.matched_payments_attributes.length) > 1
                    ? i18nKeys.INVOICE.LINK_INVOICES
                    : i18nKeys.INVOICE.LINK_INVOICE)),
                React.createElement("span", { className: styles('value', 'blue', 'bigger', 'clickable'), style: { flexDirection: 'column' } }, data.matched_payments_attributes.map(function (_a, i) {
                    var invoice_attributes = _a.invoice_attributes;
                    var key = "".concat(invoice_attributes.id).concat(i);
                    return (React.createElement("span", { key: key, style: { display: 'flex', alignItems: 'center' } },
                        React.createElement("span", { onClick: function () { return showInvoice(invoice_attributes); } }, invoice_attributes.reference),
                        React.createElement(Icon, { name: IconName.UNLINK, onClick: function () { return unlinkCn(invoice_attributes); }, className: styles('ml-10'), title: t(i18nKeys.INVOICE.UNLINK) })));
                })))),
            React.createElement("div", { className: styles('item') },
                React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.ISSUE_DATE)),
                React.createElement(DateItem, { date: data.issue_date, lg: currentLang, format: "DD/MM/YY", className: styles('value', 'blue', 'bigger') })),
            React.createElement("div", { className: styles('item') },
                React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.TOTAL_VAT_PRICE)),
                React.createElement(Amount, { localizedValue: data.total_tvac, value: data.localized_money_object.total_tvac, suffix: data.currency, className: styles('value', 'bigger') })),
            React.createElement("div", { className: styles('item') },
                React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.ACCOUNT_MANAGER)),
                React.createElement("div", { className: styles('value') },
                    data.account_manager
                        ? "".concat(data.account_manager.first_name, " ").concat(data.account_manager.last_name)
                        : t(i18nKeys.NOT_ASSIGNED),
                    (profile === null || profile === void 0 ? void 0 : profile.isAdmin) && (React.createElement(Icon, { name: IconName.PENCIL, className: styles('ml-10'), onClick: editAccountManager }))))),
        !noValidate && (React.createElement("div", { className: styles('card-header') },
            React.createElement(DownloadInvoice, { importOriginalInvoice: importOriginalInvoice, className: styles('icon'), invoice: data, ubl: data.has_ubl }),
            data.additional_files_attributes && (React.createElement("div", { className: styles('inline'), onClick: openLinkDocument, title: t(i18nKeys.INVOICE.ADDITIONAL_DOCUMENT) },
                React.createElement(Icon, { name: IconName.ADDITIONAL_DOCUMENT, className: styles('icon') }),
                React.createElement("span", { className: styles('text-circle', 'text-circle-top') }, data.additional_files_attributes.length)))))));
}
