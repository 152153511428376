import { __assign, __read } from "tslib";
import React, { useState } from 'react';
import CustomVariablesBatchForm from 'app/Logged/CustomVariables/CustomVariablesBatchForm/CustomVariablesBatchForm';
import { SetReasonForm } from 'app/Logged/Reasons/SetReasonForm/SetReasonForm';
import { TaskForm } from 'app/Logged/Tasks';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import MoveRecoveryPlanInvoices from 'shared/action-component/MoveRecoveryPlanInvoices';
import AYSModal from 'shared/components/AYSModal';
import ChooseCollaboratorModal from 'shared/components/ChooseCollaboratorModal';
import ChooseRecoveryPlanModal from 'shared/components/ChooseRecoveryPlanModal';
import ListResModal from 'shared/components/DeleteModal';
import { Icon, IconName } from 'shared/components/Icon';
import { ButtonColor } from 'shared/io';
import { treatRecoveryPlan } from 'shared/serializer';
import { useRefresh } from 'shared/utils/hooks';
import { queryParamParser, reloadCustomView } from 'shared/utils/view';
import { clientActions } from 'store/client/client.actions';
import { settingsActions } from 'store/settings/settings.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog, sideMenuHide, } from 'store/view/view.actions';
import { PaymentDelayModal } from '../../Detail/PaymentDelayModal';
import { ChooseDefaultTemplate } from '../ChooseDefaultTemplate/ChooseDefaultTemplate';
import { BATCH_ACTIONS } from '../ClientsList.constants';
import styleIdentifiers from '../../../Invoices/Listing/BatchActions.scss';
var styles = classNames.bind(styleIdentifiers);
export var Categories;
(function (Categories) {
    Categories["RECOVERY"] = "RECOVERY";
    Categories["FOLLOW_UP"] = "FOLLOW_UP";
    Categories["ASSIGNMENT"] = "ASSIGNMENT";
    Categories["OTHER"] = "OTHER";
})(Categories || (Categories = {}));
export default function DebtorBatchActions(_a) {
    var debtorsSelected = _a.debtorsSelected, isSelectedAll = _a.isSelectedAll;
    var t = useTranslation().t;
    var _b = __read(useState(isSelectedAll), 2), selectedAll = _b[0], setSelectedAll = _b[1];
    var company = useSelector(function (state) { return state.account.company.data; });
    var clientData = useSelector(function (state) { return state.client.list; });
    var doRefresh = useRefresh().doRefresh;
    var aysModal = function (action_type) { return function (onSubmit) {
        var confirmText = '';
        if (action_type === BATCH_ACTIONS.DELETE) {
            if (debtorsSelected.length === 0) {
                confirmText = i18nKeys.CLIENT.AYS_TO_DELETE_CLIENT_ALL;
            }
            else {
                confirmText =
                    debtorsSelected.length === 1
                        ? i18nKeys.CLIENT.AYS_TO_DELETE_CLIENT
                        : i18nKeys.CLIENT.AYS_TO_DELETE_CLIENT_MULTIPLE;
            }
        }
        else {
            var pauseDebtor = action_type === BATCH_ACTIONS.PAUSE;
            if (debtorsSelected.length === 0) {
                confirmText = pauseDebtor
                    ? i18nKeys.CLIENT.AYS_TO_PAUSE_CLIENT_ALL
                    : i18nKeys.CLIENT.AYS_TO_UNPAUSE_CLIENT_ALL;
            }
            else if (debtorsSelected.length === 1) {
                confirmText = pauseDebtor
                    ? i18nKeys.CLIENT.AYS_TO_PAUSE_CLIENT
                    : i18nKeys.CLIENT.AYS_TO_UNPAUSE_CLIENT;
            }
            else {
                confirmText = pauseDebtor
                    ? i18nKeys.CLIENT.AYS_TO_PAUSE_CLIENT_MULTIPLE
                    : i18nKeys.CLIENT.AYS_TO_UNPAUSE_CLIENT_MULTIPLE;
            }
        }
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRMATION),
            children: (React.createElement(AYSModal, { text: t(confirmText, { count: debtorsSelected.length }), onConfirm: function () { return onSubmit({}); } })),
        });
    }; };
    var batchAction = function (action_type, action) { return function () {
        sideMenuHide();
        var data = {};
        if (selectedAll) {
            data.filter_params = queryParamParser(qs.parse(location.search));
            data.view_id = location.hash.slice(1);
        }
        else {
            data.debtor_ids = debtorsSelected === null || debtorsSelected === void 0 ? void 0 : debtorsSelected.map(function (_a) {
                var id = _a.id;
                return id;
            });
        }
        action(function (values) {
            clientActions.batchAction({
                data: __assign(__assign(__assign({}, data), values), { action_type: action_type }),
                callback: function (data) {
                    showDialog({
                        id: DialogShowId.CUSTOM,
                        size: DialogShowSize.MEDIUM,
                        title: t(i18nKeys.RESULT),
                        children: React.createElement(ListResModal, { data: data }),
                    });
                    reloadCustomView(clientActions.listPageRes);
                    setSelectedAll(false);
                    doRefresh();
                },
            });
        });
    }; };
    var selectStepPlan = function (newPlan, oldPlan, counter, maxItems, callback, step_switchs) {
        if (step_switchs === void 0) { step_switchs = []; }
        MoveRecoveryPlanInvoices({
            newPlan: newPlan,
            oldPlan: oldPlan,
            title: t(i18nKeys.PLAN.MOVE_INVOICES),
            onSubmit: function (steps_switch) {
                step_switchs.push({ to: newPlan.id, from: oldPlan.id, steps_switch: steps_switch });
                if (counter === maxItems) {
                    callback(step_switchs);
                }
                else {
                    selectStepPlan(newPlan, oldPlan, counter + 1, maxItems, callback, step_switchs);
                }
            },
        });
    };
    var setDefaultPlan = function () {
        sideMenuHide();
        var scope = {
            scope: 'debtor',
        };
        if (isSelectedAll) {
            scope = __assign({ scope: 'debtor' }, qs.parse(location.search));
            scope.view_id = location.hash.slice(1);
        }
        else {
            scope.debtor_ids = debtorsSelected.map(function (debtor) { return debtor.id; });
        }
        settingsActions.recoveryPlanList({
            callback: function (_a) {
                var data = _a.data;
                var recoveryPlans = data.map(function (item) { return treatRecoveryPlan(item); });
                showDialog({
                    title: t(i18nKeys.PLAN.WHERE_MOVE_INVOICES),
                    id: DialogShowId.CUSTOM,
                    size: DialogShowSize.MEDIUM,
                    children: (React.createElement(ChooseRecoveryPlanModal, { recoverPlans: recoveryPlans, onClick: function (planId) {
                            settingsActions.getRecoveryPlan({
                                id: planId,
                                callback: function (response) {
                                    showDialog({
                                        id: DialogShowId.CUSTOM,
                                        title: t(i18nKeys.PLAN.WHERE_MOVE_INVOICES),
                                        children: (React.createElement(AYSModal, { text: t(i18nKeys.CLIENT.MOVE_INVOICES_IN_PLAN), cancelButtonText: t(i18nKeys.NO), confirmButtonText: t(i18nKeys.YES), onCancel: function () {
                                                settingsActions.getPlansInvoices({
                                                    data: scope,
                                                    callback: function (res) {
                                                        settingsActions.switchPlansInvoices({
                                                            data: __assign(__assign({}, scope), { plans_switch: res.data.map(function (plan) { return ({
                                                                    from: plan.id,
                                                                    to: planId,
                                                                    steps_switch: [],
                                                                }); }) }),
                                                            callback: function () {
                                                                dialogHide(DialogShowId.CUSTOM);
                                                            },
                                                        });
                                                    },
                                                });
                                            }, onConfirm: function () {
                                                var newPlan = treatRecoveryPlan(response.data);
                                                settingsActions.getPlansInvoices({
                                                    data: scope,
                                                    callback: function (res) {
                                                        var plans = res.data.map(function (plan) { return treatRecoveryPlan(plan); });
                                                        if (plans.length > 0) {
                                                            selectStepPlan(newPlan, plans[0], 1, plans.length, function (plans_switch) {
                                                                settingsActions.switchPlansInvoices({
                                                                    data: __assign({ plans_switch: plans_switch }, scope),
                                                                    callback: function () {
                                                                        dialogHide(DialogShowId.CUSTOM);
                                                                    },
                                                                });
                                                            });
                                                        }
                                                        else {
                                                            settingsActions.switchPlansInvoices({
                                                                data: __assign({}, scope),
                                                                callback: function () {
                                                                    dialogHide(DialogShowId.CUSTOM);
                                                                },
                                                            });
                                                        }
                                                    },
                                                });
                                            } })),
                                    });
                                },
                            });
                        } })),
                });
            },
        });
    };
    var assignToAccManager = function (onSubmit) {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CHOOSE_ACCOUNT_MANAGER),
            children: React.createElement(ChooseCollaboratorModal, { onSubmit: onSubmit }),
        });
    };
    var taskDetails = function (onSubmit) {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.FORM.TASK.NEW),
            children: React.createElement(TaskForm, { batch: true, debtors: true, onSubmit: onSubmit }),
        });
    };
    var handlePause = function (onSubmit) {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CLIENT.PAUSE),
            children: React.createElement(SetReasonForm, { onSubmit: onSubmit, reasonType: "pause" }),
        });
    };
    var handleDispute = function (onSubmit) {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.MARK_AS_DISPUTED),
            children: React.createElement(SetReasonForm, { onSubmit: onSubmit, reasonType: "dispute" }),
        });
    };
    var editDefaultTemplate = function (onSubmit) {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CLIENT.EDITING_DEFAULT_TEMPLATE),
            children: React.createElement(ChooseDefaultTemplate, { onSubmit: onSubmit }),
        });
    };
    var selectDefaultCgv = function (onSubmit) {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CLIENT.DETAIL.CHANGE_TERMS_AND_CONDITIONS),
            children: React.createElement(PaymentDelayModal, { onSubmit: onSubmit }),
        });
    };
    var customVariablesDetails = function (onSubmit) {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.LONG_TITLE),
            children: React.createElement(CustomVariablesBatchForm, { onSubmit: onSubmit, resourceType: "debtor" }),
        });
    };
    var allowPaymentPlans = function (onSubmit) {
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRMATION),
            children: (React.createElement(AYSModal, { text: t(i18nKeys.BATCH.ACTIONS.ALLOW_PAYMENT_PLANS.TEXT), cancelButtonText: t(i18nKeys.NO), confirmButtonText: t(i18nKeys.YES), onConfirm: function () { return onSubmit({ allow_payment_plans: true }); }, onCancel: function () { return onSubmit({ allow_payment_plans: false }); } })),
        });
    };
    var actions = [
        {
            name: i18nKeys.CLIENT.PAUSE,
            function: batchAction(BATCH_ACTIONS.PAUSE, handlePause),
            icon: IconName.PAUSE,
            category: Categories.RECOVERY,
        },
        {
            name: i18nKeys.CLIENT.UNPAUSE,
            function: batchAction(BATCH_ACTIONS.UNPAUSE, aysModal(BATCH_ACTIONS.UNPAUSE)),
            icon: IconName.PLAY,
            category: Categories.RECOVERY,
        },
        {
            name: i18nKeys.BATCH.ACTIONS.CUSTOM_VARIABLES,
            function: batchAction('custom_variables', customVariablesDetails),
            icon: IconName.PENCIL,
            category: Categories.OTHER,
            disabled: !company.package.can_use_custom_variables,
        },
        {
            name: i18nKeys.BATCH.ACTIONS.ALLOW_PAYMENT_PLANS.TITLE,
            function: batchAction('allow_payment_plans', allowPaymentPlans),
            icon: IconName.PAYMENT_BOOK,
            category: Categories.OTHER,
            disabled: !company.package.can_use_advanced_payment_plans,
        },
        {
            name: i18nKeys.DELETE,
            function: batchAction(BATCH_ACTIONS.DELETE, aysModal(BATCH_ACTIONS.DELETE)),
            icon: IconName.TRASH_SIMPLE,
            category: Categories.OTHER,
            color: ButtonColor.RED,
        },
        {
            name: i18nKeys.FORM.TASK.NEW,
            function: batchAction(BATCH_ACTIONS.TASK, taskDetails),
            icon: IconName.COMMENT,
            category: Categories.FOLLOW_UP,
            disabled: !company.package.can_use_tasks,
        },
        {
            name: i18nKeys.PLAN.MOVE_DEBTOR,
            function: setDefaultPlan,
            icon: IconName.REPLACE,
            category: Categories.ASSIGNMENT,
        },
        {
            name: i18nKeys.CLIENT.DETAIL.CHANGE_TERMS_AND_CONDITIONS,
            function: batchAction(BATCH_ACTIONS.ASSIGN_CGV, selectDefaultCgv),
            icon: IconName.REPLACE,
            category: Categories.ASSIGNMENT,
        },
        {
            name: i18nKeys.ASSIGN_ACCOUNT_MANAGER_SHORT,
            function: batchAction(BATCH_ACTIONS.ASSIGN, assignToAccManager),
            icon: IconName.USER,
            category: Categories.ASSIGNMENT,
        },
        {
            name: i18nKeys.CLIENT.EDIT_DEFAULT_TEMPLATE,
            function: batchAction(BATCH_ACTIONS.ASSIGN_TEMPLATE, editDefaultTemplate),
            icon: IconName.SEND,
            category: Categories.FOLLOW_UP,
        },
        {
            name: i18nKeys.MARK_AS_DISPUTED,
            function: batchAction(BATCH_ACTIONS.DISPUTE, handleDispute),
            icon: IconName.ALERT_CIRCLE,
            category: Categories.RECOVERY,
        },
    ];
    return (React.createElement("div", { className: styles('batch-actions-wrapper') }, Object.values(Categories).map(function (category) { return (React.createElement("div", { key: category },
        React.createElement("h5", null, t(i18nKeys.BATCH.CATEGORIES[category])),
        React.createElement("hr", null),
        React.createElement("div", { className: styles('batch-actions') }, actions
            .filter(function (action) { return action.category === category; })
            .map(function (action) { return (React.createElement("div", { className: styles('batch-action', action.color, action.disabled && 'disabled'), onClick: action.function, key: action.name },
            React.createElement(Icon, { name: action.icon }),
            React.createElement("p", null, t(action.name)))); })))); })));
}
