import { __read } from "tslib";
import React from 'react';
import { ActionIcon, Card, Center, Group, Overlay, Text } from '@mantine/core';
import { useClickOutside, useDisclosure, useHotkeys } from '@mantine/hooks';
import { styled } from '@stitches/react';
import { IconHelpOctagonFilled, IconX } from '@tabler/icons-react';
var CloseButtonWrapper = styled('div', {
    position: 'absolute',
    top: '16px',
    right: '16px',
});
export var AnalyticsCard = function (_a) {
    var title = _a.title, helpContent = _a.helpContent, actions = _a.actions, children = _a.children;
    var _b = __read(useDisclosure(), 2), isHelpOpen = _b[0], helpHandlers = _b[1];
    var ref = useClickOutside(helpHandlers.close);
    useHotkeys([['Escape', helpHandlers.close]]);
    return (React.createElement(Card, { radius: "md", shadow: "sm" },
        React.createElement(Card.Section, { inheritPadding: true, withBorder: true, py: "xs", mb: "md" },
            React.createElement(Group, { justify: "space-between" },
                React.createElement(Group, null,
                    React.createElement(Text, { fw: 500, size: "lg", ff: "Roboto" }, title),
                    helpContent != null && (React.createElement(ActionIcon, { variant: "transparent", color: "blue.4", onClick: helpHandlers.open },
                        React.createElement(IconHelpOctagonFilled, { stroke: 1.5 })))),
                actions != null && actions)),
        isHelpOpen && (React.createElement(Overlay, { ref: ref, p: "xl", c: "white", zIndex: 1, color: "#000", backgroundOpacity: 0.88 },
            React.createElement(CloseButtonWrapper, null,
                React.createElement(ActionIcon, { variant: "transparent", color: "white", onClick: helpHandlers.close },
                    React.createElement(IconX, { stroke: 1.5 }))),
            React.createElement(Center, null, helpContent))),
        children));
};
