import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { useSelector } from 'react-redux';
import { Icon, IconName } from '../Icon';
import styleIdentifiers from './addressBlock.scss';
var styles = classNames.bind(styleIdentifiers);
export default function AddressBlock(_a) {
    var _b;
    var address = _a.address, className = _a.className, inlined = _a.inlined, clean = _a.clean, edit = _a.edit, remove = _a.remove;
    var t = useTranslation().t;
    var countries = useSelector(function (state) { return state.app.constants; }).countries;
    if (!address) {
        return null;
    }
    var _c = (clean ? address.attributes : address) || {}, additional_line_1 = _c.additional_line_1, additional_line_2 = _c.additional_line_2, street_name = _c.street_name, street_number = _c.street_number, street_box = _c.street_box, zip_code = _c.zip_code, country = _c.country, country_code = _c.country_code, city = _c.city;
    var countryCleaned = country || ((_b = countries.find(function (countryObject) { return countryObject.value === country_code; })) === null || _b === void 0 ? void 0 : _b.description);
    var commonAddressBlock = (React.createElement(React.Fragment, null,
        React.createElement("div", null, additional_line_1 && additional_line_1),
        React.createElement("div", null, additional_line_2 && additional_line_2),
        React.createElement("div", null,
            street_name,
            street_number && " ".concat(street_number),
            street_box && " ".concat(t(i18nKeys.FORM.ADDRESS.BOX), " ").concat(street_box)),
        inlined && React.createElement("span", null, ",\u00A0"),
        React.createElement("div", null,
            zip_code && "".concat(zip_code, " "),
            city),
        inlined && country && React.createElement("span", null, ",\u00A0"),
        countryCleaned && React.createElement("div", null, countryCleaned)));
    if (edit) {
        return (React.createElement("div", { className: styles('AddressBlock', 'flex', 'small') },
            React.createElement("div", null, commonAddressBlock),
            React.createElement("div", null,
                React.createElement(Icon, { className: styles('action-icon'), name: IconName.PENCIL, onClick: edit }),
                remove && (React.createElement(Icon, { className: styles('action-icon'), name: IconName.TRASH_SIMPLE, onClick: remove })))));
    }
    return (React.createElement("div", { className: styles('AddressBlock', className, inlined && 'inlined') }, commonAddressBlock));
}
