import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import Logo from 'shared/components/Logo';
import ApiService from 'shared/service/api.service';
import styleIdentifiers from './badCompany.scss';
var styles = classNames.bind(styleIdentifiers);
export default function BadCompany() {
    var t = useTranslation().t;
    return (React.createElement("div", { className: styles('server-error-page') },
        React.createElement("div", { className: styles('content-wrapper') },
            React.createElement("div", { className: styles('content', 'card') },
                React.createElement("h1", null, t(i18nKeys.LOGIN.BAD_SUBDOMAIN)),
                React.createElement("p", null,
                    t(i18nKeys.LOGIN.VERIFY_SUBDOMAIN),
                    " : ",
                    React.createElement("br", null),
                    " ",
                    React.createElement("b", null, ApiService.tenant))),
            React.createElement("div", { className: styles('links') },
                React.createElement("div", { className: styles('link') },
                    React.createElement("span", null,
                        t(i18nKeys.LOGIN.NOT_YET_ACCOUNT),
                        "\u00A0"),
                    React.createElement("a", { href: "mailto:info@recovr.be" }, t(i18nKeys.LOGIN.ASK_ACCOUNT)))),
            React.createElement(Logo, { className: styles('logo'), type: "blue" }))));
}
