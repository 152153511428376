import { __assign } from "tslib";
import { captureException } from '@sentry/browser';
export var Environment;
(function (Environment) {
    Environment["PRODUCTION"] = "production";
    Environment["STAGING"] = "staging";
    Environment["DEMO"] = "demo";
    Environment["DEVELOPMENT"] = "development";
    Environment["MAINTENANCE"] = "maintenance";
    Environment["PREVIEW"] = "preview";
})(Environment || (Environment = {}));
function isValidEnv(env) {
    return Object.values(Environment).includes(env);
}
if (!isValidEnv(__ENV__))
    throw new Error("Received a bad environment: ".concat(__ENV__));
export function getEnv() {
    // Type cast okay because we validate above or crash the app
    return __ENV__;
}
export function isProduction() {
    return getEnv() === Environment.PRODUCTION;
}
export function isStaging() {
    return getEnv() === Environment.STAGING;
}
export function isPreview() {
    return getEnv() === Environment.PREVIEW;
}
export function isDemo() {
    return getEnv() === Environment.DEMO;
}
export function isDevelopment() {
    return getEnv() === Environment.DEVELOPMENT;
}
export function isMaintenance() {
    return getEnv() === Environment.MAINTENANCE;
}
export function isDeployedEnvironment() {
    return isProduction() || isStaging() || isPreview() || isDemo() || isMaintenance();
}
// By public/private here we mean: Will or won't clients and prospects ever see it
export function isPublicEnvironment() {
    return isProduction() || isDemo() || isMaintenance();
}
export function isPrivateEnvironment() {
    return isStaging() || isPreview() || isDevelopment();
}
export function isOneOfEnvs() {
    var envs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        envs[_i] = arguments[_i];
    }
    return envs.includes(getEnv());
}
var environmentFlags = {
    isProd: isProduction(),
    isStaging: isStaging(),
    isPreview: isPreview(),
    isDemo: isDemo(),
    isMaintenance: isMaintenance(),
    isDev: isDevelopment(),
};
// @ts-ignore Exposed for debugging purposes
window.APP_ENV = __assign({ env: getEnv() }, environmentFlags);
if (Object.values(environmentFlags).filter(Boolean).length !== 1) {
    captureException(new Error("Multiple environments are active: ".concat(JSON.stringify(environmentFlags))));
}
export function getEnvPrefix() {
    switch (getEnv()) {
        case Environment.STAGING:
            return '[S] ';
        case Environment.DEMO:
            return '[DM] ';
        case Environment.DEVELOPMENT:
            return '[D] ';
        case Environment.PREVIEW:
            return '[P] ';
        case Environment.PRODUCTION:
        case Environment.MAINTENANCE:
        default:
            return '';
    }
}
export function getEnvName() {
    switch (getEnv()) {
        case Environment.STAGING:
            return 'Staging';
        case Environment.DEMO:
            return 'Demo';
        case Environment.DEVELOPMENT:
            return 'Development';
        case Environment.PRODUCTION:
            return 'Production';
        case Environment.MAINTENANCE:
            return 'Maintenance';
        case Environment.PREVIEW:
            return 'Preview ';
        default:
            return 'Unknown';
    }
}
export function getEnvIcon() {
    switch (getEnv()) {
        case Environment.STAGING:
            return '🔍 ';
        case Environment.DEMO:
            return '📈 ';
        case Environment.DEVELOPMENT:
            return '🛠️ ';
        case Environment.PREVIEW:
            return '🫠 ';
        case Environment.PRODUCTION:
        case Environment.MAINTENANCE:
        default:
            return '';
    }
}
