import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, axiosInstance } from './utils';
var AvailableFooterFieldSchema = z.object({
    name: z.string().describe('Translated display name'),
    field: z.string().describe('Slugified name'),
    content: z.string(),
});
var FooterFieldSchema = z.union([
    z.object({
        field: z.string(),
        content: z.string(),
    }),
    z.string(),
    z.null(),
]);
var InvoiceFooterSchema = z.object({
    availableFields: z.array(AvailableFooterFieldSchema),
    fields: z.array(FooterFieldSchema),
});
export function useLoadInvoiceFooterQueryFn() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance.get('invoice_footer_customisation')];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, InvoiceFooterSchema.parse(data)];
            }
        });
    });
}
export var useLoadInvoiceFooter = function () {
    var queryResult = useQuery({
        queryKey: ['invoice-footer'],
        queryFn: useLoadInvoiceFooterQueryFn,
    });
    return addResourceNameToQueryResult('invoiceFooter', queryResult);
};
