import { __assign, __read, __spreadArray } from "tslib";
import { treatActivity, treatCompany, treatKanban } from 'shared/serializer';
import { removeAttributes } from 'shared/utils/view';
import { clientConstants } from 'store/client/client.actions';
import { addDataAction, baseReducerData, baseReducerListPage, flattenItem, handlePageRequest, handlePageResponse, handleRequest, handleResponse, handleUpdatePaginationResponse, } from 'store/reducers';
import { settingsConstants } from 'store/settings/settings.actions';
import { invoiceConstants } from '../invoice/invoice.actions';
import { sessionConstants as session } from '../session/session.actions';
import { accountConstants as events } from './account.actions';
export var initialState = {
    activity: baseReducerListPage,
    dashboard: baseReducerData,
    kanban: __assign(__assign({}, baseReducerData), { invoiceToNotFetch: [] }),
    company: baseReducerData,
    signupInfo: null,
    activityDetail: null,
};
var changeUnvalidateInvoices = function (state, unvalidated_invoices_count, unvalidated_debtors_count) {
    return __assign(__assign({}, state), { company: __assign(__assign({}, state.company), { data: __assign(__assign({}, state.company.data), { unvalidated_invoices_count: unvalidated_invoices_count, unvalidated_debtors_count: unvalidated_debtors_count }) }) });
};
var changeUnvalidateClients = function (state, unvalidated_debtors_count) {
    return __assign(__assign({}, state), { company: __assign(__assign({}, state.company), { data: __assign(__assign({}, state.company.data), { unvalidated_debtors_count: unvalidated_debtors_count }) }) });
};
var updateCustomVariablesList = function (state, custom_variables) {
    var custom_variables_attributes = custom_variables.map(function (cusVarAttributes) {
        return removeAttributes(cusVarAttributes);
    });
    return __assign(__assign({}, state), { company: __assign(__assign({}, state.company), { data: __assign(__assign({}, state.company.data), { custom_variables_attributes: custom_variables_attributes }) }) });
};
var addKanbanInvoices = function (state, action) {
    var _a = action.payload, invoices = _a.invoices, column = _a.column;
    var kanban = __assign({}, state.kanban.data);
    kanban[column].invoices.data = __spreadArray(__spreadArray([], __read(kanban[column].invoices.data), false), __read(invoices.data
        .filter(function (invoice) {
        return state.kanban.invoiceToNotFetch
            ? state.kanban.invoiceToNotFetch.indexOf(+invoice.id) === -1
            : true;
    })
        .map(function (invoice) { return removeAttributes(invoice); })), false);
    kanban[column].invoices.metadata = invoices.metadata;
    kanban[column].loaded = true;
    kanban[column].loading = false;
    return __assign(__assign({}, state), { kanban: __assign(__assign({}, state.kanban), { data: __assign({}, kanban) }) });
};
var updateKanbanInvoice = function (state, _a) {
    var payload = _a.payload, requestData = _a.requestData;
    var updatedInvoice;
    var updateInvoiceColumn;
    var newKanban = __assign({}, state.kanban.data);
    var invoiceToNotFetch = state.kanban.invoiceToNotFetch || [];
    // search new invoice if exist
    for (var key in payload) {
        if (typeof payload[key] === 'object') {
            updatedInvoice = payload[key].invoices.data.find(function (invoice) { return invoice.id == requestData.invoice_id; });
            if (updatedInvoice) {
                updatedInvoice = removeAttributes(updatedInvoice);
                updateInvoiceColumn = key;
                break;
            }
        }
    }
    // search old invoice
    for (var key in state.kanban.data) {
        if (typeof state.kanban.data[key] === 'object') {
            var index = state.kanban.data[key].invoices.data.findIndex(function (invoice) { return invoice.id === requestData.invoice_id; });
            if (index !== -1) {
                if (updateInvoiceColumn === key) {
                    newKanban[key].invoices.data[index] = updatedInvoice;
                }
                else {
                    newKanban[key].invoices.data.splice(index, 1);
                    if (updatedInvoice) {
                        newKanban[updateInvoiceColumn].invoices.data.push(updatedInvoice);
                    }
                }
                break;
            }
        }
    }
    updatedInvoice && invoiceToNotFetch.push(updatedInvoice.id);
    return __assign(__assign({}, state), { kanban: __assign(__assign({}, state.kanban), { invoiceToNotFetch: invoiceToNotFetch, data: __assign({}, newKanban) }) });
};
var reducer = function (state, action) {
    var _a;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case events.getCompany.result:
            return handleResponse(state, action, 'company', treatCompany);
        case events.updateCompany.result:
            return handleResponse(state, action, 'company', treatCompany);
        case events.setLocalCompany.request:
            return __assign(__assign({}, state), { company: __assign(__assign({}, state.company), { data: action.payload }) });
        // Other account value
        case events.dashboard.request:
            return handleRequest(state, action, 'dashboard');
        case events.dashboard.result:
            return handleResponse(state, action, 'dashboard');
        case events.dashboardBalance.result:
            return __assign(__assign({}, state), { dashboard: __assign(__assign({}, state.dashboard), { data: __assign(__assign({}, state.dashboard.data), action.payload.data) }) });
        case events.kanban.request:
            return handleRequest(state, action, 'kanban');
        case events.reloadKanban.result:
            return updateKanbanInvoice(state, action);
        case events.kanbanColumn.request:
            return __assign(__assign({}, state), { kanban: __assign(__assign({}, state.kanban), { data: __assign(__assign({}, state.kanban.data), (_a = {}, _a[action.payload.column] = __assign(__assign({}, state.kanban.data[action.payload.column]), { loaded: false, loading: true }), _a)) }) });
        case events.addKanbanInvoices.request:
            return addKanbanInvoices(state, action);
        case events.kanban.result:
            return handleResponse(state, addDataAction(action), 'kanban', treatKanban);
        case events.activityPage.request:
            return handlePageRequest(state, action, 'activity');
        case events.activityPage.result:
            return handlePageResponse(state, action, 'activity', treatActivity);
        case events.activityDetail.request:
            return handlePageRequest(state, action, 'activityDetail');
        case events.activityDetail.result:
            return handlePageResponse(state, action, 'activityDetail', treatActivity);
        case events.activitySeen.result:
            var tmp = handleUpdatePaginationResponse(state, action, 'activity', treatActivity);
            return tmp;
        case invoiceConstants.importInvoiceCSV.result:
            return changeUnvalidateInvoices(state, action.payload.unvalidated_invoices_count, action.payload.unvalidated_debtors_count);
        case invoiceConstants.validateAllImportedInvoices.result:
            return changeUnvalidateInvoices(state, 0, action.payload.unvalidated_debtors_count);
        case invoiceConstants.deleteInvoiceToConfirm.result:
            return changeUnvalidateInvoices(state, state.company.data.unvalidated_invoices_count - 1, action.payload.unvalidated_debtors_count);
        case events.signupInfo.result:
            return handleResponse(state, action, 'signupInfo', flattenItem);
        case clientConstants.getAllToConfirm.result:
            return changeUnvalidateClients(state, action.payload.data.length);
        case clientConstants.validateAllImportedClients.result:
            return changeUnvalidateClients(state, action.payload.unvalidated_debtors_count);
        case clientConstants.deleteClientToConfirm.result:
            if (action.status === 'fulfilled') {
                return changeUnvalidateClients(state, state.company.data.unvalidated_debtors_count - 1);
            }
            return state;
        case settingsConstants.customVariableListPage.result:
            return updateCustomVariablesList(state, action.payload.data);
        case session.logout.result:
            return initialState;
        default:
            return state;
    }
};
export default reducer;
