import { __assign } from "tslib";
import { sessionConstants as events } from 'store/session/session.actions';
import { translateActions } from 'store/translate/translate.actions';
export var initialState = {
    data: null,
    currentLang: '',
};
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case 'updateEmail':
            return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { uid: action.payload }) });
        case events.login.result:
            return action.status === 'fulfilled'
                ? {
                    data: action.payload,
                }
                : state;
        case translateActions.changeLang.request:
            return __assign(__assign({}, state), { currentLang: action.payload });
        case events.logout.result:
            return initialState;
        default:
            return state;
    }
};
export default reducer;
