import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, axiosInstance } from './utils';
var InvoiceFeeSchema = z.object({
    id: z.number(),
    reference: z.string(),
    collectionFee: z.preprocess(Number, z.number()),
    gcollectCollectionRate: z.preprocess(Number, z.number()),
    remainingAmount: z.preprocess(Number, z.number()),
});
var GcollectCaseFeeSchema = z.object({
    invoices: z.array(InvoiceFeeSchema),
});
export function loadGcollectCaseFeeFn(_a) {
    return __awaiter(this, arguments, void 0, function (_b) {
        var params, data;
        var queryKey = _b.queryKey;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    params = new URLSearchParams();
                    queryKey[1].forEach(function (caseId) { return params.append('third_party_case_ids[]', caseId); });
                    return [4 /*yield*/, axiosInstance.get("/gcollect_cases/rates?".concat(params.toString()))];
                case 1:
                    data = (_c.sent()).data;
                    return [2 /*return*/, GcollectCaseFeeSchema.parse(data)];
            }
        });
    });
}
export var useLoadGcollectCaseFee = function (caseIds) {
    var queryResult = useQuery({
        queryKey: ['gcollectCaseFee', caseIds],
        queryFn: loadGcollectCaseFeeFn,
    });
    return addResourceNameToQueryResult('gcollectCaseFee', queryResult);
};
