import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useLoadDso } from 'shared/hooks';
import { Box, LoadingOverlay } from '@mantine/core';
import { AnalyticsCard } from '../AnalyticsCard';
import { DsoChart } from '../charts';
export var DsoCard = function () {
    var t = useTranslation().t;
    var _a = useLoadDso(), _b = _a.dso, dso = _b === void 0 ? {} : _b, isDsoLoading = _a.isDsoLoading;
    return (React.createElement(AnalyticsCard, { title: t(i18nKeys.ANALYTICS.DSO.TITLE) },
        React.createElement(Box, { w: "100%", h: "400px" }, isDsoLoading ? React.createElement(LoadingOverlay, { visible: true }) : React.createElement(DsoChart, { dso: dso }))));
};
