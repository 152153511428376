import ToHandleDetail from 'app/Logged/Invoices/ToHandleDetail';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import EditClient from 'shared/action-component/EditClient';
import Amount from 'shared/components/Amount';
import DateItem from 'shared/components/DateItem';
import { IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { DropdownDirection } from 'shared/io';
import { accountActions as actions } from 'store/account/account.actions';
import { showDialog, DialogShowId, DialogShowSize } from 'store/view/view.actions';
// import debtCollectorIcon from '../InvoiceActionsDropdown/ChooseThirdPartyTypeForm/debt-collector.svg';
import debtCollectorIcon from '../../../../shared/components/InvoiceActionsDropdown/ChooseThirdPartyTypeForm/debt-collector.svg';
import styleIdentifiers from './followupItem.scss';
import { styled } from '@stitches/react';
var styles = classNames.bind(styleIdentifiers);
var WhiteOnHover = styled('div', {
    width: '23px',
    height: '23px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '1px',
    '&:hover': {
        // Found no other way to make the icon white on hover
        filter: 'invert(1)',
    },
});
export default function FollowupItem(_a) {
    var item = _a.item, status = _a.status;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var detailModal = function () {
        showDialog({
            id: DialogShowId.INVOICE,
            size: DialogShowSize.LARGE,
            children: React.createElement(ToHandleDetail, { id: item.id, modal: true }),
            onClose: function () {
                actions.reloadKanban({
                    data: {
                        invoice_id: item.id,
                    },
                });
            },
        });
    };
    var editClient = function () {
        EditClient({
            client: {
                id: item.debtor_id,
            },
        });
    };
    return (React.createElement("div", { className: styles('FollowupItem'), key: item.id },
        React.createElement("div", { className: styles('single-invoice', status && "status-".concat(item.step_collection + 1)), onClick: detailModal },
            React.createElement("div", { className: styles('actions') },
                (item.address_missing || item.email_missing) && (React.createElement("div", { onClick: function (e) {
                        e.stopPropagation();
                    } },
                    React.createElement(Tooltip, { className: styles('action-drop'), contentClass: styles('action-content'), item: React.createElement("div", { onClick: editClient, className: styles('circle') }, "!") },
                        React.createElement("ul", null,
                            item.address_missing && React.createElement("li", null, t(i18nKeys.INCOMPLETE_ADRESS)),
                            item.email_missing && React.createElement("li", null,
                                " ",
                                t(i18nKeys.MISSING_EMAIL)))))),
                item.callcenter_case && (React.createElement(Tooltip, { className: styles('action-drop'), icon: IconName.PHONE_CALL },
                    React.createElement("span", null, "".concat(item.callcenter_case.description, " ")),
                    React.createElement("b", null,
                        React.createElement(DateItem, { lg: currentLang, date: item.callcenter_case.date })))),
                item.lawyer_case && (React.createElement(Tooltip, { className: styles('action-drop'), icon: IconName.LAW },
                    React.createElement("span", null, "".concat(item.lawyer_case.description, " ")),
                    React.createElement("b", null,
                        React.createElement(DateItem, { lg: currentLang, date: item.lawyer_case.date })))),
                item.bailiff_case && (React.createElement(Tooltip, { className: styles('action-drop'), icon: IconName.BALANCE },
                    React.createElement("span", null, "".concat(item.bailiff_case.description, " ")),
                    React.createElement("b", null,
                        React.createElement(DateItem, { lg: currentLang, date: item.bailiff_case.date })))),
                item.debt_collector_case && (React.createElement(Tooltip, { className: styles('action-drop'), item: React.createElement(WhiteOnHover, null,
                        React.createElement("img", { width: "18px", height: "18px", src: debtCollectorIcon, alt: "Debt collector" })) },
                    React.createElement("span", null, "".concat(item.debt_collector_case.description, " ")),
                    React.createElement("b", null,
                        React.createElement(DateItem, { lg: currentLang, date: item.debt_collector_case.date }))))),
            React.createElement("div", { className: styles('invoice-title') }, item.reference),
            React.createElement("div", { className: styles('content') },
                React.createElement("div", { className: styles('client') }, item.debtor_name),
                React.createElement("div", { className: styles('bottom') },
                    React.createElement("div", { className: styles('content', 'amount') },
                        React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.BALANCE)),
                        React.createElement(Amount, { value: item.localized_money_object.remaining_balance, localizedValue: item.remaining_balance, suffix: item.currency, direction: DropdownDirection.KANBAN, className: styles('txt') })),
                    React.createElement("div", { className: styles('content', 'delay') },
                        React.createElement("span", { className: styles('subtitle') },
                            " ",
                            t(i18nKeys.LATE),
                            " "),
                        React.createElement("span", { className: styles('day-delay') }, t(i18nKeys.FOLLOW_UP.SUMMARY.DELAY_J, {
                            count: item.days_late || 0,
                        }))))))));
}
