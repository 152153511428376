import { __awaiter, __generator } from "tslib";
import React, { useContext } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import MoveRecoveryPlanInvoices from 'shared/action-component/MoveRecoveryPlanInvoices';
import Card from 'shared/components/Card';
import ChooseRecoveryPlanModal from 'shared/components/ChooseRecoveryPlanModal';
import MoveRecoveryPlanInvoiceModal from 'shared/components/MoveRecoveryPlanInvoiceModal';
import { Button, ButtonColor } from 'shared/io';
import { treatRecoveryPlan } from 'shared/serializer';
import { settingsActions } from 'store/settings/settings.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import InvoiceDetailContext from '../InvoiceDetail.context';
import styleIdentifiers from '../ToHandleDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export default function InvoiceRecoveryPlan() {
    var _this = this;
    var _a, _b, _c, _d;
    var t = useTranslation().t;
    var constants = useSelector(function (state) { return state.app.constants; });
    var _e = useContext(InvoiceDetailContext), data = _e.data, reloadInfo = _e.reloadInfo;
    var setRecoveryPlan = function () {
        settingsActions.recoveryPlanList({
            callback: function (res) {
                var plans = res.data.map(function (plan) { return treatRecoveryPlan(plan); });
                showDialog({
                    id: DialogShowId.CUSTOM,
                    size: DialogShowSize.MEDIUM,
                    title: t(i18nKeys.PLAN.SELECT_PLAN),
                    children: (React.createElement(ChooseRecoveryPlanModal, { recoverPlans: plans, onClick: function (planId) {
                            settingsActions.getRecoveryPlan({
                                id: planId,
                                callback: function (plan) { return __awaiter(_this, void 0, void 0, function () {
                                    var newPlan, stepsSwitch;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                newPlan = treatRecoveryPlan(plan.data);
                                                return [4 /*yield*/, new Promise(function (resolve) {
                                                        if (data.recovery_step_id) {
                                                            MoveRecoveryPlanInvoices({
                                                                title: t(i18nKeys.PLAN.INVOICES),
                                                                newPlan: newPlan,
                                                                oldPlanId: data.recovery_plan_id,
                                                                currentStep: data.recovery_information.step_order,
                                                                onSubmit: resolve,
                                                            });
                                                        }
                                                        else {
                                                            showDialog({
                                                                id: DialogShowId.CUSTOM,
                                                                size: DialogShowSize.LARGE,
                                                                title: t(i18nKeys.PLAN.MOVE_INVOICES),
                                                                children: (React.createElement(MoveRecoveryPlanInvoiceModal, { onSubmit: resolve, plan: newPlan })),
                                                            });
                                                        }
                                                    })];
                                            case 1:
                                                stepsSwitch = _a.sent();
                                                settingsActions.switchPlansInvoices({
                                                    data: {
                                                        scope: 'invoice',
                                                        plans_switch: [
                                                            {
                                                                from: data.recovery_step_id ? data.recovery_plan_id : null,
                                                                to: planId,
                                                                steps_witch: data.recovery_step_id
                                                                    ? stepsSwitch
                                                                    : [
                                                                        {
                                                                            from: null,
                                                                            to: newPlan.recovery_steps[stepsSwitch].id,
                                                                        },
                                                                    ],
                                                            },
                                                        ],
                                                        invoice_ids: [data.id],
                                                    },
                                                    callback: function () {
                                                        reloadInfo();
                                                        settingsActions.recoveryPlanList();
                                                        dialogHide(DialogShowId.CUSTOM);
                                                    },
                                                });
                                                dialogHide(DialogShowId.CUSTOM);
                                                return [2 /*return*/];
                                        }
                                    });
                                }); },
                            });
                        } })),
                });
            },
        });
    };
    // Sentry 1A0
    var sendingOptionAttributes = (_b = (_a = data.sending_options_attributes) === null || _a === void 0 ? void 0 : _a.sending_media) !== null && _b !== void 0 ? _b : [];
    var sendingMedia = (sendingOptionAttributes === null || sendingOptionAttributes === void 0 ? void 0 : sendingOptionAttributes.length) > 0
        ? data.sending_options_attributes.sending_media
        : (_d = (_c = data.recovery_information) === null || _c === void 0 ? void 0 : _c.sending_media) !== null && _d !== void 0 ? _d : [];
    return (React.createElement(Card, { title: t(i18nKeys.RECOVERY_PLAN), infosRight: data.actions.indexOf('switch_plan') !== -1 && (React.createElement(Button, { noMargin: true, color: ButtonColor.GREY, label: t(i18nKeys.EDIT), onClick: setRecoveryPlan })), className: styles('box', 'recovery-plan') }, data.recovery_step_id ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles('item', 'block') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.NAME)),
            React.createElement("div", { className: styles('value', 'medium') }, data.recovery_information.recovery_plan_name)),
        React.createElement("div", { className: styles('item', 'block') },
            React.createElement("span", { className: styles('subtitle') },
                " ",
                t(i18nKeys.NEXT_STEP)),
            React.createElement("div", { className: styles('value', 'medium') },
                React.createElement("div", null,
                    t(i18nKeys.ONBOARDING.STEP),
                    " ",
                    data.recovery_information.step_order + 1))),
        React.createElement("div", { className: styles('item', 'block', 'subtitle-item') },
            React.createElement("span", { className: styles('subtitle') }, data.recovery_information.task_title
                ? t(i18nKeys.COMMON.TASK)
                : t(i18nKeys.COMMON.TEMPLATE)),
            React.createElement("div", { className: styles('value', 'medium') },
                React.createElement("div", null, data.recovery_information.template_name || data.recovery_information.task_title))),
        !data.recovery_information.task_title && (React.createElement("div", { className: styles('item', 'block', 'subtitle-item') },
            React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.DEBTOR.SENDING_MEDIUMS)),
            React.createElement("div", { className: styles('value', 'medium') },
                React.createElement("div", null, sendingMedia.map(function (sendingMedium, index) {
                    return "".concat(index !== 0 ? ' - ' : '').concat(constants.sending_medium.find(function (item) { return sendingMedium.name === item.value; })
                        .description);
                }))))))) : (React.createElement("div", { className: styles('item', 'block') },
        React.createElement("span", { className: styles('subtitle') }, t(i18nKeys.PLAN.EXECUTED)),
        React.createElement("div", { className: styles('value', 'medium') }, data.recovery_information.recovery_plan_name)))));
}
