import { __assign, __read, __rest, __spreadArray } from "tslib";
import React, { useReducer } from 'react';
import { SettingsCard } from 'app/Logged/Settings';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AYSModal from 'shared/components/AYSModal';
import NavigationPrompt from 'shared/components/BlockNavigation';
import { Icon, IconName } from 'shared/components/Icon';
import { InvoiceFooterCustomization } from 'shared/components/InvoiceFooter';
import SendingMedia from 'shared/components/SendingMedium/SendingMedia';
import { Button as OldButton, ButtonColor, ColorPicker, CustomSelect, UploadZone } from 'shared/io';
import { treatTemplate } from 'shared/serializer';
import { reducerState } from 'shared/utils/view';
import { accountActions } from 'store/account/account.actions';
import { settingsActions } from 'store/settings/settings.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { Button, Stack } from '@mantine/core';
import InvoiceJournal from './InvoiceJournal';
import styleIdentifiers from './invoiceSettingsForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function InvoiceSettingsForm(_a) {
    var _b;
    var initialValues = _a.initialValues, onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var constants = useSelector(function (state) { return state.app.constants; });
    var company = useSelector(function (state) { return state.account.company.data; });
    var _c = __read(useReducer(reducerState, {
        invoiceTemplate: [],
        cnTemplate: [],
    }), 2), _d = _c[0], cnTemplate = _d.cnTemplate, invoiceTemplate = _d.invoiceTemplate, setState = _c[1];
    var getTemplates = function (step_type) { return function () {
        settingsActions.templateList({
            data: {
                step_type: step_type,
            },
            noLoading: true,
            callback: function (_a) {
                var _b;
                var data = _a.data;
                setState((_b = {},
                    _b[step_type === 'invoice' ? 'invoiceTemplate' : 'cnTemplate'] = data.map(function (template) {
                        return treatTemplate(template);
                    }),
                    _b));
            },
        });
    }; };
    var form = useForm({
        defaultValues: initialValues,
    });
    var handleSubmit = form.handleSubmit, control = form.control, dirtyFields = form.formState.dirtyFields, reset = form.reset, getValues = form.getValues, setValue = form.setValue, watch = form.watch;
    var isDirty = !!Object.keys(dirtyFields).length;
    var formArray = useFieldArray({
        control: control,
        name: 'billing_logs_attributes',
        keyName: 'rhfId',
    });
    var addJournal = function () {
        formArray.append({});
    };
    var submit = function (callback) {
        handleSubmit(onSubmit(callback))();
    };
    var updateLogo = function (logo) {
        if (logo) {
            accountActions.updateCompanyFiles({
                fileUpload: true,
                data: {
                    logo: logo,
                },
                success: 'FORM.PREFERENCES.SUCCESSFULL_LOGO_UPDATE',
            });
        }
        else {
            showDialog({
                id: DialogShowId.CONFIRM,
                size: DialogShowSize.MEDIUM,
                title: t(i18nKeys.CLIENT.CLIENT_DELETION),
                children: (React.createElement(AYSModal, { text: t(i18nKeys.SETTINGS.INVOICING.AYS_DELETE_LOGO), confirmButtonColor: ButtonColor.RED, onCancel: function () {
                        dialogHide(DialogShowId.CONFIRM);
                        setValue('logo', initialValues.logo);
                    }, onConfirm: function () {
                        accountActions.updateCompanyFiles({
                            fileUpload: true,
                            data: {
                                logo: null,
                            },
                            success: 'FORM.PREFERENCES.SUCCESSFULL_LOGO_UPDATE',
                        });
                    } })),
            });
        }
    };
    var submitReset = function (value) {
        var formValues = getValues();
        onSubmit()(value);
        reset(formValues);
    };
    var logoImg = watch('logo');
    // Sentry 16P
    var sendingMedia = (_b = watch('sending_media')) !== null && _b !== void 0 ? _b : [];
    var addSendingMedium = function () {
        var _a;
        var defaultSendingMedium = (constants.sending_medium.filter(function (e) { return !sendingMedia.some(function (el) { return e.value === el.name; }); }) || constants.sending_medium)[0];
        // Sentry 16P
        if (sendingMedia.length < ((_a = constants.sending_medium) !== null && _a !== void 0 ? _a : []).length) {
            setValue('sending_media', __spreadArray(__spreadArray([], __read(sendingMedia), false), [
                {
                    name: defaultSendingMedium.value,
                    order: sendingMedia.length + 1,
                    force_sending: 'true',
                },
            ], false), {
                shouldTouch: true,
            });
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SettingsCard, null,
            React.createElement(FormProvider, __assign({}, form),
                React.createElement(NavigationPrompt, { when: isDirty, onSaveAndQuit: submit }),
                React.createElement("form", { className: styles('InvoiceSettingsForm'), onSubmit: handleSubmit(submitReset) },
                    React.createElement("h3", null, t(i18nKeys.FORM.INVOICING.CUSTOM_INVOICE)),
                    React.createElement("div", { className: styles('content', 'section') },
                        React.createElement("div", { className: styles('col', 'company-logo') },
                            React.createElement("h4", null, t(i18nKeys.FORM.INVOICING.YOUR_LOGO)),
                            React.createElement("div", { className: styles('upload-zone') },
                                React.createElement(UploadZone, { onChange: updateLogo, accept: "image/*", info: t(i18nKeys.FORM.INVOICING.IMPORT_LOGO), subinfo: t(i18nKeys.FORM.INVOICING.FILE_FORMAT), errorMessage: t(i18nKeys.ERROR.INVALID_FILE_FORMAT), value: logoImg, small: true, img: true, logo: true, noMargin: true, noResize: true }))),
                        React.createElement("div", { className: styles('col', 'company-color') },
                            React.createElement("h4", null, t(i18nKeys.FORM.INVOICING.YOUR_COLOR)),
                            React.createElement(Controller, { control: control, defaultValue: initialValues.color, name: "color", render: function (_a) {
                                    var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                                    return React.createElement(ColorPicker, __assign({}, values));
                                } }))),
                    React.createElement("h3", null, t(i18nKeys.SENDING_SETTINGS)),
                    React.createElement("div", { className: styles('content', 'section', 'grid-row') },
                        React.createElement("div", { className: styles('col-6') },
                            React.createElement(Controller, { defaultValue: "", name: "generate_invoice_in_debtor_locale", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { label: t(i18nKeys.DOCUMENTS_LOCALE), keyText: "description", keyValue: "value", name: "generate_invoice_in_debtor_locale", items: [
                                        {
                                            description: t(i18nKeys.DEBTOR_LOCALE),
                                            value: 'true',
                                        },
                                        {
                                            description: t(i18nKeys.COMPANY_LOCALE),
                                            value: 'false',
                                        },
                                    ] })); } })),
                        React.createElement("div", { className: styles('col-6') },
                            React.createElement(Controller, { rules: { required: true }, defaultValue: "", name: "default_invoice_template_id", render: function () { return (React.createElement(CustomSelect, { label: t(i18nKeys.DEFAULT_INVOICE_TEMPLATE), keyText: "name", keyValue: "id", hideOnLoading: true, name: "default_invoice_template_id", load: getTemplates('invoice'), items: invoiceTemplate })); } })),
                        React.createElement("div", { className: styles('col-6') },
                            React.createElement(Controller, { rules: { required: true }, defaultValue: "", name: "default_credit_note_template_id", render: function () { return (React.createElement(CustomSelect, { label: t(i18nKeys.DEFAULT_CN_TEMPLATE), keyText: "name", keyValue: "id", hideOnLoading: true, name: "default_credit_note_template_id", load: getTemplates('credit_note'), items: cnTemplate })); } })),
                        React.createElement("div", { className: styles('col-6') },
                            React.createElement(Controller, { defaultValue: "", name: "display_qr_code", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { info: !company.package.can_use_online_payment ? t(i18nKeys.NOT_INCLUDED) : '', notAllowed: !company.package.can_use_online_payment, noArrow: !company.package.can_use_online_payment, label: t(i18nKeys.DISPLAY_QR_CODE), keyText: "description", keyValue: "value", name: "display_qr_code", items: [
                                        { description: t(i18nKeys.YES), value: 'true' },
                                        { description: t(i18nKeys.NO), value: 'false' },
                                    ] })); } }))),
                    React.createElement("h3", null, t(i18nKeys.DEBTOR.SENDING_MEDIUMS)),
                    React.createElement("div", { className: styles('content', 'section', 'grid-row') },
                        React.createElement("div", { className: styles('col-6') },
                            React.createElement(SendingMedia, { fieldName: "sending_media", className: styles('input') }),
                            sendingMedia.length < constants.sending_medium.length && (React.createElement(OldButton, { color: ButtonColor.GREY, label: t(i18nKeys.ACTIONS.ADD), iconRight: IconName.PLUS, onClick: addSendingMedium, small: true })))),
                    React.createElement("div", { className: styles('invoice-journal') },
                        React.createElement("h3", null,
                            t(i18nKeys.FORM.INVOICING.INVOICING_BOOK),
                            ' ',
                            React.createElement(Icon, { name: IconName.PLUS, title: t(i18nKeys.FORM.INVOICING.ADD_NEW_BOOK), onClick: addJournal, className: styles('add-icon') })),
                        React.createElement(InvoiceJournal, { formArray: formArray })),
                    React.createElement(Stack, { align: "end" },
                        React.createElement(Button, { disabled: !isDirty, type: "submit" }, t(i18nKeys.SAVE)))))),
        React.createElement(SettingsCard, null,
            React.createElement("div", { className: styles('section') },
                React.createElement("h3", null, t(i18nKeys.SETTINGS.INVOICING.FOOTER.TITLE)),
                React.createElement("p", null, t(i18nKeys.SETTINGS.INVOICING.FOOTER.DESCRIPTION)),
                React.createElement(InvoiceFooterCustomization, null)))));
}
