import { __assign, __awaiter, __generator } from "tslib";
import { omit } from 'remeda';
import apiService from 'shared/service/api.service';
import { LocaleSchema } from 'types';
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, axiosInstance } from './utils';
var TenantSchema = z
    .object({
    id: z.number(),
    subdomain: z.string().min(1),
    companyName: z.string().min(1),
    logo: z.string().url().nullable(),
})
    .transform(function (data) { return (__assign(__assign({}, data), { url: window.location.origin.replace(apiService.tenant, data.subdomain) })); });
var ProfileSchema = z
    .object({
    id: z.number(),
    profilePicture: z.string().url().nullable(),
    accessibleTenants: z.array(TenantSchema),
    email: z.string().email(),
    firstName: z.string().min(1),
    hasAcceptedGc: z.boolean(),
    intercomHash: z.string(),
    isActive: z.boolean(),
    lastName: z.string().min(1),
    locale: LocaleSchema,
    preferences: z.object({
        itemsPerPage: z.number(),
        emails: z.object({
            daily: z.boolean(),
            weekly: z.boolean(),
            notifications: z.boolean(),
        }),
    }),
    roles: z.array(z.string()),
    signature: z.string().nullable(),
})
    .transform(function (data) { return (__assign(__assign({}, omit(data, ['firstName', 'lastName'])), { isAdmin: data.roles.includes('admin'), name: {
        first: data.firstName,
        last: data.lastName,
        full: "".concat(data.firstName, " ").concat(data.lastName),
        initials: "".concat(data.firstName.charAt(0).toUpperCase()).concat(data.lastName.charAt(0).toUpperCase()),
    } })); });
export function loadProfileQueryFn() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance.get('/profile')];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, ProfileSchema.parse(data)];
            }
        });
    });
}
export var useLoadProfile = function (_a) {
    var _b = _a.enabled, enabled = _b === void 0 ? true : _b;
    var queryResult = useQuery({
        enabled: enabled,
        queryKey: ['profile'],
        queryFn: loadProfileQueryFn,
    });
    return addResourceNameToQueryResult('profile', queryResult);
};
