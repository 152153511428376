import { __assign, __awaiter, __generator } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import HTML from 'shared/components/HTML/HTML';
import { CustomRecoveryPartialForm, GeneralConditionsPartialSendTos, PostalPreferencesPartialForm, SalePreferencesPartialForm, SalePreferencesPartialFormUpload, } from 'shared/forms/PreferencesForm';
import { Button, ButtonColor } from 'shared/io';
import styleIdentifiers from './SetupDebtCollectionForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function SetupDebtCollectionForm(_a) {
    var _this = this;
    var defaultValues = _a.defaultValues, step = _a.step, previousStep = _a.previousStep, nextStep = _a.nextStep;
    var t = useTranslation().t;
    var nodeRef = useRef(null);
    var form = useForm({
        defaultValues: defaultValues,
    });
    var getValues = form.getValues, reset = form.reset, trigger = form.trigger;
    useEffect(function () {
        reset(defaultValues);
    }, [defaultValues, reset]);
    var submit = function () { return __awaiter(_this, void 0, void 0, function () {
        var isFormValid;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, trigger()];
                case 1:
                    isFormValid = _a.sent();
                    if (isFormValid) {
                        nextStep(getValues());
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: styles('setup-debt-collection-form') },
        React.createElement(SwitchTransition, null,
            React.createElement(CSSTransition, { key: step, timeout: 500, nodeRef: nodeRef, classNames: {
                    appear: styles('form-animation-appear'),
                    appearActive: styles('form-animation-appear-active'),
                    appearDone: styles('form-animation-appear-done'),
                    enter: styles('form-animation-enter'),
                    enterActive: styles('form-animation-enter-active'),
                    enterDone: styles('form-animation-enter-done'),
                    exit: styles('form-animation-exit'),
                    exitActive: styles('form-animation-exit-active'),
                    exitDone: styles('form-animation-exit-done'),
                } },
                React.createElement(FormProvider, __assign({}, form),
                    React.createElement("div", { ref: nodeRef },
                        React.createElement("div", { className: styles('description-test', "step".concat(step)) },
                            React.createElement(HTML, { html: t(i18nKeys.ONBOARDING.STEP3["DESCRIPTION_STEP".concat(step)]) }),
                            step === 1 && React.createElement(HTML, { html: t(i18nKeys.ONBOARDING.STEP3.DESCRIPTION_STEP1_2) })),
                        step === 1 && React.createElement(SalePreferencesPartialForm, { onboarding: true }),
                        step === 2 && (React.createElement(CustomRecoveryPartialForm, { onboarding: true, onlinePayment: defaultValues.package.can_use_online_payment })),
                        step === 3 && React.createElement(PostalPreferencesPartialForm, null),
                        step === 4 && (React.createElement(React.Fragment, null,
                            React.createElement(SalePreferencesPartialFormUpload, { onboarding: true }),
                            React.createElement("div", null,
                                React.createElement(GeneralConditionsPartialSendTos, null))))),
                    React.createElement("div", { className: styles('button-wrapper') },
                        step >= 2 && (React.createElement(Button, { color: ButtonColor.GREY, onClick: previousStep, label: t(i18nKeys.PREVIOUS) })),
                        React.createElement(Button, { onClick: submit, label: step === 4 ? t(i18nKeys.ONBOARDING.NEXT_STEP) : t(i18nKeys.NEXT) })))))));
}
