import { useSelector } from 'react-redux';
export var getSession = function (state) {
    return state.session && state.session.data ? state.session.data : false;
};
export var getCgv = function (company, debtor) {
    var _a;
    return (company === null || company === void 0 ? void 0 : company.terms_and_conditions_attributes.find(function (e) { return Number(e.id) === Number(debtor === null || debtor === void 0 ? void 0 : debtor.terms_and_conditions_id); })) || ((_a = company === null || company === void 0 ? void 0 : company.terms_and_conditions_attributes) === null || _a === void 0 ? void 0 : _a.find(function (e) { return e.default === true; }));
};
export var useGetConstants = function () {
    return useSelector(function (state) { return state.app.constants; });
};
export var useGetDialogs = function () {
    return useSelector(function (state) { return state.view.dialogs; });
};
export var useGetDashboard = function () {
    return useSelector(function (state) { return state.account.dashboard.data; });
};
export var useGetCompany = function () {
    return useSelector(function (state) { return state.account.company.data; });
};
export var useIsMobile = function () {
    return useSelector(function (_state) { return _state.view.isMobile; });
};
export var useGetTableTypes = function () {
    return useSelector(function (_state) { return _state.app.tableTypes; });
};
export var useGetClientState = function () {
    return useSelector(function (state) { return state.client; });
};
export var useGetInvoiceState = function () {
    return useSelector(function (state) { return state.invoice; });
};
