import { __read } from "tslib";
import { PlanType } from 'api/models';
import ListDebtCollectionCustomization from 'app/Logged/Templates/ListDebtCollectionCustomization';
import { WorkflowsList } from 'app/Logged/Workflows/';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Card from 'shared/components/Card';
import Tabs from 'shared/components/Tabs';
import { PageTitle } from 'shared/layout/PageTitle';
import styleIdentifiers from './WorkflowsSettings.scss';
var styles = classNames.bind(styleIdentifiers);
export var WorkflowsSettings = function (_a) {
    var location = _a.location;
    var t = useTranslation().t;
    var history = useHistory();
    var tabItems = [
        {
            title: t(i18nKeys.BATCH.CATEGORIES.RECOVERY),
            path: '/settings/workflows/recovery-plans',
            children: React.createElement(ListDebtCollectionCustomization, null),
        },
        {
            title: t(i18nKeys.PAYMENT_PLAN.TITLE.ONE),
            path: "/settings/workflows/".concat(PlanType.payment),
            children: React.createElement(WorkflowsList, { planType: PlanType.payment }),
        },
    ];
    var _b = __read(useState(tabItems.map(function (e) { return e.path; }).indexOf(location.pathname)), 2), tabIndex = _b[0], setTabIndex = _b[1];
    return (React.createElement("div", { className: styles('workflows') },
        React.createElement(PageTitle, null, t(i18nKeys.AUTOMATED_PLANS)),
        React.createElement(Tabs, { className: styles('workflows__tabs'), tabIndex: tabIndex, items: tabItems.map(function (e) { return (React.createElement("h3", null, e.title)); }), noBody: true, onChange: function (e) {
                setTabIndex(e);
                history.push(tabItems[e].path);
            } }),
        tabItems.map(function (_a, i) {
            var title = _a.title, children = _a.children;
            return tabIndex === i && (React.createElement(Card, { key: "tab-".concat(title), noRadiusTop: true, noHead: true }, children));
        })));
};
