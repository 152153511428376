import { useCreateWorkflow } from 'api';
import classNames from 'classnames/bind';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { WorkflowForm } from './WorkflowForm';
import styleIdentifiers from './WorkflowForm.scss';
import { formatValues } from './WorkflowForm.utils';
var styles = classNames.bind(styleIdentifiers);
export var WorkflowFormAdd = function () {
    var planType = useRouteMatch().params.planType;
    var history = useHistory();
    var createWorkflow = useCreateWorkflow().mutate;
    var onSubmit = function (data) {
        return createWorkflow({ data: formatValues(data) }, {
            onSuccess: function (response) {
                history.push("/settings/workflows/".concat(planType, "/edit/").concat(response.data.id));
            },
        });
    };
    return (React.createElement("div", { className: styles('workflows-add') },
        React.createElement(WorkflowForm, { planType: planType, onSubmit: onSubmit })));
};
