import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import { SettingsCard } from 'app/Logged/Settings';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IconName } from 'shared/components/Icon';
import { Button, ButtonColor, Dropdown } from 'shared/io';
import { PageTitle } from 'shared/layout/PageTitle';
import { getQueryParams, setQueryParams } from 'shared/utils/url';
import { appActions } from 'store/app/app.actions';
import { sideMenuHide } from 'store/view/view.actions';
import { isEnabledIntegration } from './components/Integration.utils';
import { Integration, INTEGRATION_ROUTE, IntegrationCategory, IntegrationQueryParams, } from './Integrations.constants';
import { getIntegrationCard, getIntegrationsCards, getIntegrationsCategories, } from './Integrations.utils';
import { IntegrationsCard } from './IntegrationsCard';
import styleIdentifiers from './Integrations.scss';
var styles = classNames.bind(styleIdentifiers);
export var Integrations = function (_a) {
    var _b;
    var _c = _a.isOnBoarding, isOnBoarding = _c === void 0 ? false : _c, onIntegrationSelect = _a.onIntegrationSelect;
    var t = useTranslation().t;
    var history = useHistory();
    var company = useSelector(function (state) { return state.account.company.data; });
    var _d = __read(useState(IntegrationCategory.ALL), 2), currentCategory = _d[0], setCurrentCategory = _d[1];
    var queryIntegration = getQueryParams(IntegrationQueryParams.NAME);
    var queryCategory = getQueryParams(IntegrationQueryParams.CATEGORY);
    useEffect(function () {
        if (queryIntegration && onIntegrationSelect) {
            var integration = queryIntegration.toUpperCase();
            if (isEnabledIntegration(Integration[integration])) {
                onIntegrationSelect(getIntegrationCard(integration, company, t));
            }
        }
    }, [queryIntegration, company, onIntegrationSelect, t]);
    useEffect(function () {
        if (queryCategory &&
            queryCategory !== currentCategory &&
            Object.keys(IntegrationCategory).includes(queryCategory)) {
            setCurrentCategory(queryCategory);
        }
    }, []);
    useEffect(function () {
        setQueryParams(IntegrationQueryParams.CATEGORY, currentCategory);
        appActions.updateLastSettingsScreen(currentCategory);
    }, [currentCategory]);
    var onClickCard = function (integration) {
        if (onIntegrationSelect) {
            onIntegrationSelect(integration);
        }
        else {
            history.push(INTEGRATION_ROUTE[integration.title]);
        }
    };
    var setCategory = function (newCategory) {
        setCurrentCategory(newCategory);
        sideMenuHide();
    };
    var categories = getIntegrationsCategories(t).filter(function (e) {
        return isOnBoarding ? e.category !== IntegrationCategory.BANKING : true;
    });
    var integrationList = getIntegrationsCards(company, t)
        .filter(function (e) { return (isOnBoarding ? e.category !== IntegrationCategory.BANKING : true); })
        .filter(function (e) { return (company.isFrenchClient ? e.title !== Integration.CODABOX : true); })
        .filter(function (e) {
        return currentCategory === IntegrationCategory.ALL ||
            e.category === currentCategory ||
            (currentCategory === IntegrationCategory.MY_INTEGRATIONS && e.isActive);
    });
    return (React.createElement("div", { className: styles('integrations') },
        React.createElement(PageTitle, null, t(i18nKeys.SETTINGS.INTEGRATIONS.TITLE)),
        React.createElement(SettingsCard, null,
            React.createElement("div", { className: styles('categories-menu') },
                React.createElement("div", { className: styles('title') }, t(i18nKeys.SETTINGS.INTEGRATIONS.CATEGORIES)),
                React.createElement(Dropdown, { sideMenuInMobile: true, sideMenuTitle: t(i18nKeys.SETTINGS.INTEGRATIONS.CATEGORIES), className: styles('category-dropdown'), toggleContent: true, selectorContent: React.createElement(Button, { className: styles('action'), noMargin: true, color: ButtonColor.MAIN, label: (_b = categories.find(function (category) { return currentCategory === category.category; })) === null || _b === void 0 ? void 0 : _b.text, iconRight: IconName.ARROW_BOTTOM_ROUNDED }) }, categories.map(function (_a) {
                    var text = _a.text, category = _a.category;
                    return (React.createElement("div", { key: category, onClick: function () { return setCategory(category); }, className: styles('dropdown-item', {
                            active: category === currentCategory,
                        }) }, text));
                })),
                React.createElement("div", { className: styles('category-list') }, categories.map(function (_a) {
                    var text = _a.text, category = _a.category;
                    return (React.createElement("div", { key: category, onClick: function () { return setCategory(category); }, className: styles({ active: category === currentCategory }) }, text));
                }))),
            React.createElement("div", { className: styles('integration-list') }, integrationList.map(function (integration) { return (React.createElement(IntegrationsCard, { key: integration.title, integration: integration, onClick: onClickCard })); })))));
};
