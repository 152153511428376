import { __read } from "tslib";
import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import { InvoiceActionsDropdown } from 'shared/components';
import CustomTableViews from 'shared/components/CustomTable/CustomTableViews';
import { Button, ButtonColor } from 'shared/io';
import { reloadCustomView } from 'shared/utils/view';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { captureMessage } from '@sentry/browser';
import BatchActions from '../Listing/BatchActions';
import ToHandleDetail from '../ToHandleDetail';
import styleIdentifiers from './toHandle.scss';
var styles = classNames.bind(styleIdentifiers);
export default function InvoicesToHandle() {
    var _a;
    var t = useTranslation().t;
    var invoicesData = useSelector(function (state) { return state.invoice.toHandle; });
    var _b = __read(useState([]), 2), selectedInvoices = _b[0], setSelectedInvoices = _b[1];
    var _c = __read(useState(false), 2), areAllItemsAcrossPagesSelected = _c[0], setAreAllItemsAcrossPagesSelected = _c[1];
    var _d = __read(useState('0'), 2), topElement = _d[0], setTopElement = _d[1];
    var showInvoice = function (item) { return function () {
        showDialog({
            id: DialogShowId.INVOICE,
            size: DialogShowSize.LARGE,
            children: React.createElement(ToHandleDetail, { id: item.id, modal: true }),
        });
    }; };
    var reloadData = function () {
        reloadCustomView(invoiceActions.toHandleRes);
    };
    var actionsCol = function (item) {
        if (item && typeof item === 'object' && 'actions' in item) {
            return (React.createElement(InvoiceActionsDropdown, { shouldDeferRequest: true, reload: reloadData, className: styles('action'), topElement: topElement, invoice: item, item: React.createElement(Button, { onClick: function (e) {
                        var elPosition = e.target.getBoundingClientRect();
                        setTopElement("".concat(elPosition.y - elPosition.height - 30, "px"));
                    }, label: t(i18nKeys.PROCESS), color: ButtonColor.BLUE, className: styles('button'), noMargin: true, noShadow: true }) }));
        }
        captureMessage("Tried to pass an `invoice` prop to InvoiceActionsDropdown that wasn't an object, or didn't respond to the `actions` field.", { extra: { item: item } });
        return null;
    };
    var openBatchActionsModal = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            title: t(i18nKeys.FORM.ACTIONS),
            children: (React.createElement(BatchActions, { selectedInvoices: selectedInvoices, isSelectedAll: areAllItemsAcrossPagesSelected, reload: reloadData })),
        });
    };
    return (React.createElement("div", { className: styles('InvoicesToHandle') },
        React.createElement(CustomTableViews, { title: t(i18nKeys.INVOICE_TO_PROCESS), tableName: "actionable_invoices", onClickRow: showInvoice, callbackAction: invoiceActions.toHandleRes, actions: selectedInvoices.length > 0 && (React.createElement(Button, { noMargin: true, label: t(i18nKeys.FORM.ACTIONS), onClick: openBatchActionsModal })), isLoading: !invoicesData.loaded, pagination: (_a = invoicesData.metadata) === null || _a === void 0 ? void 0 : _a.pagination, items: invoicesData.pages, currentPage: invoicesData.currentPage, onChangeSelectionCallback: function (values) { return setSelectedInvoices(values); }, onChangeSelectItemsAcrossAllPagesCallback: setAreAllItemsAcrossPagesSelected, noResultMessage: t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.NO_INVOICE_WITH_DELAY), actionsCol: actionsCol })));
}
