import { ObjectType } from 'api/models';
import classNames from 'classnames/bind';
import React from 'react';
import Amount from 'shared/components/Amount';
import styleIdentifiers from './ClientDetailTabsBalance.scss';
var styles = classNames.bind(styleIdentifiers);
export var ClientDetailTabsBalanceItemPayment = function (_a) {
    var item = _a.item;
    var type = item.type, _b = item.attributes, remaining_balance = _b.remaining_balance, localized_money_object = _b.localized_money_object, currency = _b.currency;
    var remainingBalance = Number(remaining_balance);
    var localizedRemainingBalance = Number(localized_money_object.remaining_balance);
    return (React.createElement(React.Fragment, null,
        React.createElement("td", { className: styles('debit') }, type === ObjectType.accounting_payment && Number(remainingBalance) < 0 && (React.createElement(Amount, { localizedValue: Number(-1 * remainingBalance), value: Number(-1 * localizedRemainingBalance), suffix: currency }))),
        React.createElement("td", { className: styles('credit') }, Number(remainingBalance) > 0 && (React.createElement(Amount, { localizedValue: remainingBalance, value: localizedRemainingBalance, suffix: currency }))),
        React.createElement("td", { className: styles('balance') },
            React.createElement(Amount, { localizedValue: -1 * remainingBalance, value: -1 * localizedRemainingBalance, suffix: currency }))));
};
