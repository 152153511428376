import { isDemo, isDevelopment, isPreview, isProduction, isStaging, } from 'shared/utils/environment';
var constants = (function () {
    if (isPreview()) {
        return {
            domain: window.location.hostname,
            baseUrl: __BACKEND_URL__,
            exactClientId: 'fda9fd0c-05af-49c3-baff-a0a8bef11c81',
            billitBaseUrl: 'https://my.sandbox.billit.be/',
        };
    }
    if (isStaging()) {
        return {
            domain: 'staging.recovr.be',
            baseUrl: 'https://backend.staging.recovr.be',
            exactClientId: 'fda9fd0c-05af-49c3-baff-a0a8bef11c81',
            billitBaseUrl: 'https://my.sandbox.billit.be/',
        };
    }
    if (isDemo()) {
        return {
            domain: 'demo.recovr.be',
            baseUrl: 'https://backend.demo.recovr.be',
            exactClientId: '7d34895f-ad73-4322-9a87-a9b561f2f9ac',
            billitBaseUrl: 'https://my.sandbox.billit.be/',
        };
    }
    if (isDevelopment()) {
        return {
            domain: 'lvh.me:3000',
            baseUrl: 'http://lvh.me:3000',
            exactClientId: 'ecbfc6ed-321a-4f15-bf20-8baa16c8f8a6',
            billitBaseUrl: 'https://my.sandbox.billit.be/',
        };
    }
    if (isProduction()) {
        return {
            domain: 'recovr.be',
            baseUrl: 'https://backend.recovr.be',
            exactClientId: '90ebbaf2-f2c4-4f69-9b19-0f3a21a150cc',
            billitBaseUrl: 'https://my.billit.be/',
        };
    }
    return {
        domain: 'staging.recovr.be',
        baseUrl: 'https://backend.staging.recovr.be',
        exactClientId: 'fda9fd0c-05af-49c3-baff-a0a8bef11c81',
        billitBaseUrl: 'https://my.sandbox.billit.be/',
    };
})();
export var config = {
    localHistory: 'recovr-storage',
    api: {
        domain: constants.domain,
        baseUrl: constants.baseUrl,
        defaultCompany: 'recovr',
    },
    twikeyToken: 'EE70359317F6A7B0CA27FB1B4B412AD960FB5356',
    bailiffTerms: {
        // Leroy
        7: {
            fr: 'https://recovr.s3.eu-west-3.amazonaws.com/public/conditions-generales-leroy-partners.pdf',
            en: 'https://recovr.s3.eu-west-3.amazonaws.com/public/conditions-generales-leroy-partners.pdf',
            nl: 'https://recovr.s3.eu-west-3.amazonaws.com/public/conditions-generales-leroy-partners.pdf',
        },
        // Modero
        4: {
            fr: 'https://s3.eu-west-3.amazonaws.com/recovr/CGV/CG+SOLID+SA.pdf',
            en: 'https://s3.eu-west-3.amazonaws.com/recovr/CGV/CG+SOLID+SA.pdf',
            nl: 'https://recovr.s3.eu-west-3.amazonaws.com/CGV/Algemene+voorwaarden+solid+nv.pdf',
        },
        // Intermediance
        5: {
            fr: 'https://recovr.s3.eu-west-3.amazonaws.com/CGV/CGV+Intermediance+Conoreco.pdf',
            en: 'https://recovr.s3.eu-west-3.amazonaws.com/CGV/CGV+Intermediance+Conoreco.pdf',
            nl: 'https://recovr.s3.eu-west-3.amazonaws.com/CGV/CGV+Intermediance+Conoreco.pdf',
        },
    },
    terms: {
        fr: 'https://s3.eu-west-3.amazonaws.com/recovr/CGV/Conditions+G%C3%A9n%C3%A9rales.pdf',
        en: 'https://s3.eu-west-3.amazonaws.com/recovr/CGV/Conditions+G%C3%A9n%C3%A9rales.pdf',
        nl: 'https://recovr.s3.eu-west-3.amazonaws.com/CGV/Algemene+voorwarden.pdf',
    },
    exact: {
        client_id: constants.exactClientId,
    },
    billit: {
        client_id: 'F1XlbAKqMIOsOVnNvIwvhlas761lFfal2mX',
        base_url: constants.billitBaseUrl,
    },
};
export default config;
