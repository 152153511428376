import { z } from 'zod';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { axiosInstance } from './utils';
var InvitationSchema = z.object({
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    email: z.string().email(),
});
export var useSendInvitation = function () {
    var client = useQueryClient();
    var mutation = useMutation({
        mutationFn: function (data) {
            return axiosInstance.post('send_invitations', InvitationSchema.parse(data));
        },
        onSuccess: function (response) {
            notifications.show({
                message: response.data.message,
                color: 'green',
            });
            client.invalidateQueries(['users']);
        },
    });
    return addActionNameToMutationResult('sendInvitation', mutation);
};
