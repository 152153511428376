import { __read } from "tslib";
import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AYSModal from 'shared/components/AYSModal';
import { Button, Input } from 'shared/io';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { Integration } from '../../Integrations.constants';
import { getIntegrationRights } from '../Integration.utils';
import styleIdentifiers from '../IntegrationForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var CodaboxFormFieldsName;
(function (CodaboxFormFieldsName) {
    CodaboxFormFieldsName["CODABOX_TOKEN"] = "codabox_token";
})(CodaboxFormFieldsName || (CodaboxFormFieldsName = {}));
export var CodaboxForm = function (_a) {
    var onSubmit = _a.onSubmit, tokenRequest = _a.tokenRequest;
    var t = useTranslation().t;
    var codaboxMessage = useSelector(function (state) { return state.onBoarding.codaboxMessage; });
    var company = useSelector(function (state) { return state.account.company.data; });
    var codaboxToken = company.codabox_integration.codabox_token;
    var _b = __read(useState(!codaboxToken), 2), createNewToken = _b[0], setCreateNewToken = _b[1];
    var isActive = getIntegrationRights(Integration.CODABOX, company).isActive;
    var _c = useForm(), register = _c.register, handleSubmit = _c.handleSubmit;
    var handleTokenRequest = function () {
        if (!isActive) {
            tokenRequest();
        }
        else {
            showDialog({
                id: DialogShowId.CONFIRM,
                size: DialogShowSize.SMALL,
                keepMountOnExit: true,
                title: t(i18nKeys.CONFIRM),
                children: (React.createElement(AYSModal, { text: t(i18nKeys.SETTINGS.INTEGRATIONS.CODABOX.ALERT_NEW_TOKEN), onConfirm: function () {
                        tokenRequest();
                    } })),
            });
        }
    };
    if (!createNewToken) {
        return (React.createElement("div", { className: styles('integration-form') },
            React.createElement("div", { className: styles('row') },
                React.createElement("div", { className: styles('attribute') },
                    t(i18nKeys.SETTINGS.INTEGRATIONS.CODABOX.CODABOX_TOKEN),
                    ":"),
                React.createElement("div", { className: styles('value') },
                    "*****",
                    codaboxToken)),
            React.createElement("div", { className: styles('button-wrapper') },
                React.createElement(Button, { className: styles('button'), onClick: function () {
                        setCreateNewToken(true);
                    }, label: t(i18nKeys.SETTINGS.INTEGRATIONS.CODABOX.NEW_TOKEN), type: "button" }))));
    }
    return (React.createElement("form", { className: styles('integration-form'), onSubmit: handleSubmit(onSubmit) },
        !isActive ? (React.createElement("div", { className: styles('info') },
            React.createElement("p", null, t(i18nKeys.ONBOARDING.STEP2.CODABOX.EASY_TO_VALID)),
            React.createElement("p", null,
                t(i18nKeys.ONBOARDING.STEP2.CODABOX.FIRSTLY),
                ' ',
                React.createElement("button", { type: "button", className: styles('info-link'), onClick: function () { return handleTokenRequest(); } }, t(i18nKeys.ONBOARDING.STEP2.CODABOX.CLICK_TO_RECEIVE_TOKEN)),
                ' ',
                t(i18nKeys.OF),
                ' ',
                React.createElement("a", { href: "https://www.codabox.com", target: "_blank", rel: "noopener noreferrer" }, Integration.CODABOX),
                ' ',
                t(i18nKeys.ONBOARDING.STEP2.CODABOX.CODE_SENT_BY_EMAIL)),
            codaboxMessage && React.createElement("p", null, codaboxMessage),
            React.createElement("p", null, t(i18nKeys.ONBOARDING.STEP2.CODABOX.ENTER_TOKEN_BELOW)))) : (React.createElement("div", null,
            React.createElement("button", { type: "button", className: styles('info-link'), onClick: function () { return handleTokenRequest(); } }, t(i18nKeys.SETTINGS.INTEGRATIONS.CODABOX.ASK_TOKEN)))),
        React.createElement(Input, { type: "text", label: "Token Codabox", register: register(CodaboxFormFieldsName.CODABOX_TOKEN, {
                required: true,
            }) }),
        React.createElement("div", { className: styles('button-wrapper') },
            React.createElement(Button, { type: "submit", className: styles('button'), label: t(i18nKeys.SAVE) }))));
};
