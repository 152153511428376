import { __assign, __read, __spreadArray } from "tslib";
import React, { useEffect, useState } from 'react';
import KanbanFiltersForm from 'app/Logged/MyFollowup/KanbanFiltersForm';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Swiper from 'react-swipe';
import Amount from 'shared/components/Amount';
import { Icon, IconName } from 'shared/components/Icon';
import { InfiniteScroll } from 'shared/components/InfiniteScroll';
import StatusItem from 'shared/components/StatusItem';
import FiltersSideMenu from 'shared/forms/FiltersSideMenu';
import { Button, ButtonColor } from 'shared/io';
import { updateQueryFilters } from 'shared/utils/view';
import { accountActions } from 'store/account/account.actions';
import { sideMenuShow } from 'store/view/view.actions';
import FollowupItem from './FollowupItem';
import styleIdentifiers from './myFollowup.scss';
var styles = classNames.bind(styleIdentifiers);
var timeoutDebounce;
export default function MyFollowup() {
    var t = useTranslation().t;
    var isMobile = useSelector(function (state) { return state.view.isMobile; });
    var kanban = useSelector(function (state) { var _a; return (_a = state.account.kanban) === null || _a === void 0 ? void 0 : _a.data; });
    var _a = __read(useState({}), 2), filterValue = _a[0], setFilterValue = _a[1];
    var _b = __read(useState(0), 2), currentCol = _b[0], setCurrentCol = _b[1];
    var location = useLocation();
    var reactSwipeEl;
    var steps = [
        {
            id: 'new_late_invoices',
            step: 0,
        },
        {
            id: 'first_reminder_sent',
            step: 1,
        },
        {
            id: 'last_reminder_sent',
            step: 2,
        },
        {
            id: 'formal_notice_sent',
            step: 3,
        },
        {
            id: 'currently_processed',
            step: 4,
        },
        {
            id: 'dispute_litigation_invoices',
            step: 5,
        },
    ];
    useEffect(function () {
        setFilterValue(qs.parse(location.search));
        accountActions.kanban({
            data: qs.parse(location.search),
            noLoading: true,
        });
    }, []);
    var loadMoreColumn = function (id, column) {
        accountActions.kanbanColumn({
            id: id,
            column: column,
            noReset: true,
            noLoading: true,
            data: __assign(__assign({}, filterValue), { page: kanban[column].invoices.metadata.pagination.current_page + 1 }),
            callback: function (invoices) {
                accountActions.addKanbanInvoices({ column: column, invoices: invoices });
            },
        });
    };
    var onFilterChanged = function (filters) {
        for (var key in filters) {
            !filters[key] && delete filters[key];
        }
        updateQueryFilters(filters);
        setFilterValue(filters);
        if (timeoutDebounce) {
            clearTimeout(timeoutDebounce);
        }
        var timeout = setTimeout(function () {
            accountActions.kanban({
                data: filters,
                noLoading: true,
            });
        }, 500);
        timeoutDebounce = timeout;
    };
    var filterObject = { reset: function () { } };
    var filtersNumber = Object.keys(filterValue).filter(function (value) { return value; }).length || 0;
    var resetFilters = function () {
        onFilterChanged({});
        filterObject.reset && filterObject.reset();
    };
    var setFilterOpen = function () {
        sideMenuShow({
            unmount: true,
            content: (React.createElement(FiltersSideMenu, { onRemoveFilter: resetFilters },
                React.createElement(KanbanFiltersForm, { filterObject: filterObject, onSubmit: onFilterChanged, initialValues: qs.parse(location.search) }))),
        });
    };
    var changeCol = function (next) { return function () {
        if (next)
            reactSwipeEl.next();
        else
            reactSwipeEl.prev();
    }; };
    return (React.createElement("div", { className: styles('MyFollowup') },
        React.createElement("div", { className: styles('head') },
            React.createElement("h1", null, t(i18nKeys.FOLLOW_UP.SUMMARY.TITLE)),
            React.createElement("div", null,
                React.createElement("div", { className: styles('amount-late') },
                    React.createElement("span", { className: styles('label') },
                        " ",
                        t(i18nKeys.DELAY.OVERDUE)),
                    React.createElement(Amount, { className: styles('amount'), value: kanban === null || kanban === void 0 ? void 0 : kanban.total_saldo })),
                React.createElement("div", { className: styles('filter-container') },
                    filtersNumber > 0 && (React.createElement(Button, { className: styles('remove-filter', 'filter'), noMargin: true, small: true, iconSize: "16px", color: ButtonColor.WHITE, onClick: resetFilters },
                        React.createElement(Icon, { name: IconName.FILTER }),
                        React.createElement(Icon, { name: IconName.SMALL_REMOVE }))),
                    React.createElement(Button, { className: styles('filter'), noMargin: true, small: true, iconSize: "16px", color: ButtonColor.WHITE, iconLeft: IconName.FILTER, onClick: setFilterOpen },
                        filtersNumber > 0 && (React.createElement("div", { className: styles('text-circle', 'absolute', 'border', 'badge') }, filtersNumber)),
                        t(i18nKeys.FILTER))))),
        isMobile ? (React.createElement(Swiper, { className: styles('swiper'), swipeOptions: { continuous: false }, ref: function (el) { return (reactSwipeEl = el); } }, steps.map(function (item, key) {
            var _a;
            return (React.createElement("div", { key: item.id, className: styles('col-wrapper') },
                React.createElement("div", { className: styles('steps', 'card') },
                    React.createElement("div", { className: styles('step-wrapper') },
                        key > 0 && (React.createElement("div", { className: styles('arrow', 'arrow-before') },
                            React.createElement(Icon, { name: IconName.MINIMAL_LEFT, size: "9px", onClick: changeCol(false) }))),
                        React.createElement("div", { className: styles('step') },
                            React.createElement(StatusItem, { step: item.step, isLate: true, className: styles("step-".concat(item.step)) }),
                            React.createElement("div", { className: styles('amount') },
                                React.createElement("div", { className: styles('value') },
                                    React.createElement(Amount, { value: kanban && ((_a = kanban[item.id]) === null || _a === void 0 ? void 0 : _a.saldo) })))),
                        key < steps.length - 1 && (React.createElement("div", { className: styles('arrow', 'arrow-next') },
                            React.createElement(Icon, { name: IconName.MINIMAL_RIGHT, size: "9px", onClick: changeCol(true) }))))),
                kanban ? (React.createElement("div", { className: styles('steps-col') },
                    kanban.total_saldo > 0 && (React.createElement(InfiniteScroll, { load: function () { return loadMoreColumn(key, item.id); }, key: item.id, hasMore: kanban[item.id].invoices.metadata.pagination.last_page !==
                            kanban[item.id].invoices.metadata.pagination.current_page, items: kanban[item.id].invoices.data, loading: kanban[item.id].loading, className: styles('single-col') }, kanban[item.id].invoices.data.map(function (data, index) { return (React.createElement(FollowupItem, { item: data, status: "".concat(item.step, "1"), key: data.id || index })); }))),
                    kanban.total_saldo === 0 && (React.createElement("div", { className: styles('no-result') }, t(filtersNumber > 0
                        ? i18nKeys.NO_RESULT_FOR_THIS_SEARCH
                        : i18nKeys.FOLLOW_UP.SUMMARY.GREAT_NO_INVOICE))))) : (React.createElement("div", { className: styles('single-col'), key: item.id }, __spreadArray([], __read(Array(Math.round(Math.random() * 4))), false).map(function (val, i) { return (React.createElement("div", { key: i, className: styles('loading-card') },
                    React.createElement("div", { className: styles('line') }),
                    React.createElement("div", { className: styles('line') }),
                    React.createElement("div", { className: styles('half-line') }),
                    React.createElement("div", { className: styles('half-line') }))); })))));
        }))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles('steps', 'card') }, steps.map(function (item, key) {
                var _a;
                return (React.createElement("div", { key: item.id, className: styles('step-wrapper') },
                    key > 0 && (React.createElement("div", { className: styles('arrow', 'arrow-before') },
                        React.createElement(Icon, { name: IconName.MINIMAL_LEFT, size: "9px" }))),
                    React.createElement("div", { className: styles('step') },
                        React.createElement(StatusItem, { status: item.step === 4
                                ? t(i18nKeys.THIRD_PARTY_CASES.CURRENTLY_PROCESSED)
                                : undefined, step: item.step, isLate: true, className: styles("step-".concat(item.step)) }),
                        React.createElement("div", { className: styles('amount') },
                            React.createElement("div", { className: styles('value') },
                                React.createElement(Amount, { value: kanban && ((_a = kanban[item.id]) === null || _a === void 0 ? void 0 : _a.saldo) })))),
                    key < steps.length - 1 && (React.createElement("div", { className: styles('arrow', 'arrow-next') },
                        React.createElement(Icon, { name: IconName.MINIMAL_RIGHT, size: "9px" })))));
            })),
            React.createElement("div", { className: styles('steps-col') }, kanban ? (React.createElement(React.Fragment, null, kanban.total_saldo !== 0 ? (steps.map(function (item, key) {
                return kanban[item.id] && (React.createElement(InfiniteScroll, { load: function () { return loadMoreColumn(key, item.id); }, key: item.id, hasMore: kanban[item.id].invoices.metadata.pagination.last_page !==
                        kanban[item.id].invoices.metadata.pagination.current_page, items: kanban[item.id].invoices.data, loading: kanban[item.id].loading, className: styles('single-col', key > currentCol ? 'after' : key < currentCol && 'before') }, kanban[item.id].invoices.data.map(function (data, index) { return (React.createElement(FollowupItem, { item: data, status: "".concat(item.step, "1"), key: data.id || index })); })));
            })) : (React.createElement("div", { className: styles('no-result') }, t(filtersNumber > 0
                ? i18nKeys.NO_RESULT_FOR_THIS_SEARCH
                : i18nKeys.FOLLOW_UP.SUMMARY.GREAT_NO_INVOICE))))) : (steps.map(function (item) { return (React.createElement("div", { className: styles('single-col'), key: item.id }, __spreadArray([], __read(Array(Math.round(Math.random() * 4))), false).map(function (val, i) { return (React.createElement("div", { key: i, className: styles('loading-card') },
                React.createElement("div", { className: styles('line') }),
                React.createElement("div", { className: styles('line') }),
                React.createElement("div", { className: styles('half-line') }),
                React.createElement("div", { className: styles('half-line') }))); }))); })))))));
}
