import { __read } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useReducer } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { Checkbox, CustomSelect, Input } from 'shared/io';
import { treatTemplate } from 'shared/serializer';
import { currencySymbol } from 'shared/utils/normalization';
import { email } from 'shared/utils/validation';
import { reducerState } from 'shared/utils/view';
import { settingsActions } from 'store/settings/settings.actions';
import { CustomRecoveryFieldsName, ReminderPreferencesFieldNames, } from '../PreferencesForm.constants';
import styleIdentifiers from './CustomRecoveryPartialForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var CustomRecoveryPartialForm = function (_a) {
    var onboarding = _a.onboarding, onlinePayment = _a.onlinePayment;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var constants = useSelector(function (state) { return state.app.constants; });
    var _b = __read(useReducer(reducerState, {
        additionalReminderTemplate: [],
        fnTemplate: [],
    }), 2), _c = _b[0], fnTemplate = _c.fnTemplate, additionalReminderTemplate = _c.additionalReminderTemplate, setState = _b[1];
    var _d = useFormContext(), register = _d.register, watch = _d.watch;
    var sendCopy = watch(CustomRecoveryFieldsName.SEND_COPY);
    var getTemplates = function (step_type) { return function () {
        settingsActions.templateList({
            data: {
                step_type: step_type,
            },
            noLoading: true,
            callback: function (_a) {
                var _b;
                var data = _a.data;
                setState((_b = {},
                    _b[step_type === 'formal_notice' ? 'fnTemplate' : 'additionalReminderTemplate'] = data.map(function (template) { return treatTemplate(template); }),
                    _b));
            },
        });
    }; };
    return (React.createElement("div", { className: styles('grid-row', 'custom-recovery-partial-form') },
        onboarding && (React.createElement("div", { className: styles('col-6') },
            React.createElement(Controller, { defaultValue: "", name: "fee_starting_step", render: function () { return (React.createElement(CustomSelect, { name: "fee_starting_step", label: React.createElement("span", null,
                        t(i18nKeys.FORM.PREFERENCES.CLAIM_THE_ADDITIONAL_COSTS),
                        ' ',
                        React.createElement(Tooltip, { icon: IconName.INFO, size: "14px", text: t('FORM.PREFERENCES.TOOLTIP.CLAIM_THE_ADDITIONAL_COSTS') })), keyText: "description", keyValue: "value", items: constants.step_applicable_fees })); } }))),
        React.createElement("div", { className: styles('col-6') },
            React.createElement(Controller, { rules: { required: true }, defaultValue: "", name: "automatic_reminders", render: function () { return (React.createElement(CustomSelect, { label: t(i18nKeys.FORM.PREFERENCES.REMINDER_MANAGEMENT), keyText: "description", keyValue: "value", name: "automatic_reminders", items: [
                        {
                            description: t(i18nKeys.FORM.PREFERENCES.AUTOMATIC),
                            value: 'true',
                        },
                        {
                            description: t(i18nKeys.FORM.PREFERENCES.MANUAL),
                            value: 'false',
                        },
                    ] })); } })),
        React.createElement("div", { className: styles('col-6') },
            React.createElement(Input, { label: t(i18nKeys.FORM.PREFERENCES.MIN_DAYS_BETWEEN_REMINDER), type: "number", min: "0", register: register(CustomRecoveryFieldsName.DAYS_BETWEEN_REMINDER) })),
        !onboarding && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles('col-6') },
                React.createElement(Controller, { rules: { required: true }, defaultValue: "", name: CustomRecoveryFieldsName.DEFAULT_FORMAL_NOTICE_TEMPLATE_ID, render: function () { return (React.createElement(CustomSelect, { label: t(i18nKeys.DEFAULT_FORMAL_NOTICE), keyText: "name", keyValue: "id", hideOnLoading: true, name: CustomRecoveryFieldsName.DEFAULT_FORMAL_NOTICE_TEMPLATE_ID, load: getTemplates('formal_notice'), items: fnTemplate })); } })),
            React.createElement("div", { className: styles('col-6') },
                React.createElement(Controller, { rules: { required: true }, defaultValue: "", name: CustomRecoveryFieldsName.DEFAULT_ADDITIONAL_REMINDER_TEMPLATE_ID, render: function () { return (React.createElement(CustomSelect, { label: t(i18nKeys.DEFAULT_ADDITIONAL_REMINDER), keyText: "name", keyValue: "id", hideOnLoading: true, name: CustomRecoveryFieldsName.DEFAULT_ADDITIONAL_REMINDER_TEMPLATE_ID, load: getTemplates('additional_reminder'), items: additionalReminderTemplate })); } })))),
        onboarding && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles('col-6') },
                React.createElement(Input, { label: t(i18nKeys.FORM.PREFERENCES.DAYS_AFTER_DEADLINE), type: "number", min: "0", register: register(CustomRecoveryFieldsName.FIRST_REMINDER_DELAY) })),
            React.createElement("div", { className: styles('col-6') },
                React.createElement(Input, { type: "number", label: t(i18nKeys.FORM.PREFERENCES.DAYS_BETWEEN_REMINDERS), register: register(CustomRecoveryFieldsName.LAST_REMINDER_DELAY), min: "0" })),
            ' ')),
        React.createElement("div", { className: styles('col-6') },
            React.createElement(Input, { type: "number", label: React.createElement("span", null,
                    t(i18nKeys.FORM.PREFERENCES.DONT_SEND_REMINDER_UNDER, {
                        text: currencySymbol(undefined, company, constants),
                    }),
                    ' ',
                    React.createElement(Tooltip, { icon: IconName.INFO, size: "14px", text: t('FORM.PREFERENCES.TOOLTIP.DONT_SEND_REMINDER_UNDER') })), register: register(CustomRecoveryFieldsName.THRESHOLD_AMOUNT), min: "0" })),
        React.createElement("div", { className: styles('col-6') },
            React.createElement(Input, { type: "number", label: React.createElement("span", null,
                    t(i18nKeys.FORM.PREFERENCES.DONT_SEND_REMINDER_TO_DEBTOR_UNDER, {
                        text: currencySymbol(undefined, company, constants),
                    }),
                    React.createElement(Tooltip, { icon: IconName.INFO, size: "14px", text: t('FORM.PREFERENCES.TOOLTIP.DONT_SEND_REMINDER_UNDER') })), register: register(CustomRecoveryFieldsName.MINIMUM_SALDO_SENDING), min: "0" })),
        React.createElement("div", { className: styles('col-6') },
            React.createElement(Controller, { name: ReminderPreferencesFieldNames.REMINDER_THRESHOLD_TYPE, rules: { required: true }, render: function () { return (React.createElement("div", null,
                    React.createElement(CustomSelect, { name: ReminderPreferencesFieldNames.REMINDER_THRESHOLD_TYPE, label: t(i18nKeys.FORM.PREFERENCES.THRESHOLD), items: [
                            {
                                description: t(i18nKeys.FORM.PREFERENCES.REMAINING_BALANCE),
                                value: 'remaining_balance',
                            },
                            {
                                description: t(i18nKeys.FORM.PREFERENCES.OVERDUE_BALANCE),
                                value: 'overdue_balance',
                            },
                        ], keyText: "description", keyValue: "value" }))); } })),
        onboarding && (React.createElement("div", { className: styles('col-6') },
            React.createElement(Controller, { defaultValue: "", name: "generate_invoice_in_debtor_locale", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { label: t(i18nKeys.DOCUMENTS_LOCALE), keyText: "description", keyValue: "value", name: "generate_invoice_in_debtor_locale", items: [
                        {
                            description: t(i18nKeys.DEBTOR_LOCALE),
                            value: 'true',
                        },
                        {
                            description: t(i18nKeys.COMPANY_LOCALE),
                            value: 'false',
                        },
                    ] })); } }))),
        onboarding && (React.createElement("div", { className: styles('col-6') },
            React.createElement(Controller, { defaultValue: "", name: "display_qr_code", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { info: !onlinePayment ? t(i18nKeys.NOT_INCLUDED) : '', notAllowed: !onlinePayment, noArrow: !onlinePayment, label: t(i18nKeys.DISPLAY_QR_CODE), keyText: "description", keyValue: "value", name: "display_qr_code", items: [
                        { description: t(i18nKeys.YES), value: 'true' },
                        { description: t(i18nKeys.NO), value: 'false' },
                    ] })); } }))),
        React.createElement("div", { className: styles('col-6') },
            React.createElement(Checkbox, { register: register('tp_accounting.automatic_reminders'), watch: watch, label: t(i18nKeys.FORM.PREFERENCES.SEND_AUTOMATIC_REMINDERS) })),
        onboarding && (React.createElement("div", { className: styles('col-6') },
            React.createElement(Checkbox, { register: register(CustomRecoveryFieldsName.SEND_COPY), watch: watch, label: React.createElement("span", null,
                    t(i18nKeys.FORM.PREFERENCES.RECEIVE_COPY),
                    ' ',
                    React.createElement(Tooltip, { icon: IconName.INFO, size: "14px", text: t(i18nKeys.FORM.PREFERENCES.TOOLTIP.RECEIVE_COPY) })) }),
            sendCopy && (React.createElement(Controller, { defaultValue: "", name: CustomRecoveryFieldsName.SEND_COPY_EMAILS, render: function () { return (React.createElement(CustomSelect, { name: CustomRecoveryFieldsName.SEND_COPY_EMAILS, className: styles('send-copy-email'), multiple: true, noSuggestions: true, noArrow: true, canAdd: true, itemsClassName: styles(onboarding && 'no-suggestion'), itemRendering: function (_a) {
                        var item = _a.item;
                        return (React.createElement(React.Fragment, null,
                            React.createElement("div", null,
                                item.first_name,
                                " ",
                                item.last_name),
                            React.createElement("div", null, item.email)));
                    }, keyText: "email", keyValue: "email", items: [], label: t(i18nKeys.FORM.PREFERENCES.RECEIVE_ON), inputValidation: email, registerMultipleInput: register(CustomRecoveryFieldsName.SEND_COPY_EMAILS_INPUT, {
                        validate: {
                            emailInputNotEmpty: function (value) { return !value; },
                        },
                    }) })); } }))))));
};
