import { __assign, __read } from "tslib";
import React, { useEffect, useState } from 'react';
import { useGetSimilarDebtors } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useHistory } from 'react-router-dom';
import AYSModal from 'shared/components/AYSModal';
import CustomTable from 'shared/components/CustomTable';
import ListResModal from 'shared/components/DeleteModal';
import { IconName } from 'shared/components/Icon';
import { Button } from 'shared/io';
import { clientActions } from 'store/client/client.actions';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import DuplicatesClientListItem from './DuplicatesClientListItem';
import styleIdentifiers from './DuplicatesClients.scss';
var styles = classNames.bind(styleIdentifiers);
var EnhancedTable = CustomTable(DuplicatesClientListItem);
export var DuplicatesClients = function () {
    var t = useTranslation().t;
    var history = useHistory();
    var _a = useGetSimilarDebtors({}), similarDebtorsListResponse = _a.data, isLoading = _a.isLoading, isSuccess = _a.isSuccess, refetch = _a.refetch;
    var CLIENTS_TABLE_HEADERS = [
        {
            title: "".concat(t(i18nKeys.CONTACT), " 1"),
            width: 160,
        },
        {
            title: "".concat(t(i18nKeys.CONTACT), " 2"),
            width: 140,
        },
        {
            title: t(i18nKeys.INVOICING.ACTION),
            width: 120,
        },
    ];
    var handleFilters = function (filters) {
        var newFilters = __assign(__assign({}, filters), { debtor: undefined });
        return newFilters;
    };
    var orderField = function (field) {
        return field;
    };
    var items = (similarDebtorsListResponse === null || similarDebtorsListResponse === void 0 ? void 0 : similarDebtorsListResponse.data) || [];
    useEffect(function () {
        if (isSuccess && items.length === 0) {
            history.push('/clients');
        }
    }, [isSuccess]);
    var _b = __read(useState({
        selectAll: false,
        selectedItems: [],
    }), 2), _c = _b[0], selectAll = _c.selectAll, selectedItems = _c.selectedItems, setSelection = _b[1];
    var onCheckBoxChange = function (newSelectedItems) {
        setSelection(function (currentState) { return (__assign(__assign({}, currentState), { selectedItems: newSelectedItems })); });
    };
    var onSelectAll = function (value) {
        setSelection(function (currentState) { return (__assign(__assign({}, currentState), { selectedItems: items, selectAll: value })); });
    };
    var ignoreDuplicates = function (action) {
        var data = {};
        data.debtor_ids = selectAll ? null : selectedItems === null || selectedItems === void 0 ? void 0 : selectedItems.map(function (_a) {
            var id = _a.id;
            return id;
        });
        action(function () {
            clientActions.batchAction({
                data: __assign(__assign({}, data), { action_type: 'ignore_duplicates' }),
                callback: function (response) {
                    showDialog({
                        id: DialogShowId.CUSTOM,
                        size: DialogShowSize.MEDIUM,
                        title: t(i18nKeys.RESULT),
                        children: React.createElement(ListResModal, { data: response }),
                    });
                    setSelection(function (currentState) { return (__assign(__assign({}, currentState), { selectedItems: [], selectAll: false })); });
                    refetch();
                    // TODO: @Antoine, I can't get the new list's length
                },
            });
        });
    };
    var aysModal = function (action_type) { return function (onSubmit) {
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRMATION),
            children: React.createElement(AYSModal, { text: t(action_type), onConfirm: function () { return onSubmit({}); } }),
        });
    }; };
    return (React.createElement("div", { className: styles('client-list-to-confirm') },
        React.createElement(EnhancedTable, { notSortable: true, title: t(i18nKeys.DUPLICATES), orderField: orderField, loading: isLoading, loaded: isSuccess, pagination: similarDebtorsListResponse === null || similarDebtorsListResponse === void 0 ? void 0 : similarDebtorsListResponse.metadata.pagination, headers: CLIENTS_TABLE_HEADERS, handleFilters: handleFilters, items: items, itemProps: { checkbox: true, refetch: refetch }, onCheckBoxChange: onCheckBoxChange, setSelectAll: onSelectAll, actions: (selectAll || !!selectedItems.length) && (React.createElement(Button, { className: styles('action-button'), noMargin: true, label: t(i18nKeys.CLIENT.MERGE.IGNORE), onClick: function () { return ignoreDuplicates(aysModal(i18nKeys.AYS.IGNORE_DUPLICATES)); }, iconRight: IconName.SIMPLE_REMOVE })) })));
};
