import { ExternalMailDetail } from 'app/Logged/ExternalMailbox';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import React from 'react';
import { dateFromNow } from 'shared/utils/view';
import { showDialog, DialogShowId, DialogShowSize } from 'store/view/view.actions';
import styleIdentifiers from './ClientDetailTabsTimeline.scss';
var styles = classNames.bind(styleIdentifiers);
export var ClientDetailTabsTimelineItemEmail = function (_a) {
    var email = _a.email;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var _c = email.attributes, subject = _c.subject, date = _c.date, from_name = _c.from_name, from_mail = _c.from_mail;
    var onClick = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.LARGE,
            title: t(i18nKeys.BODY),
            children: React.createElement(ExternalMailDetail, { emailId: email.id }),
        });
    };
    return (React.createElement("div", { onClick: onClick },
        React.createElement("span", { className: styles('date') }, dateFromNow(date, currentLang)),
        React.createElement("div", { className: styles('from') },
            from_name,
            " <",
            React.createElement("a", { href: "mailto:".concat(from_mail) }, from_mail),
            ">"),
        React.createElement("p", { className: styles('sub-detail') }, t(i18nKeys.EMAIL)),
        React.createElement("div", { className: styles('message') }, subject)));
};
