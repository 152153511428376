import { __assign, __read, __rest } from "tslib";
import React, { useLayoutEffect, useState } from 'react';
import { useGetTasks } from 'api/index';
import { useProfile } from 'app/App';
import classNames from 'classnames/bind';
import { useTranslation } from 'locales';
import { pick } from 'lodash-es';
import { useLocation } from 'react-router';
import CustomTable from 'shared/components/CustomTable';
import { TABLE_HEADERS } from '../Task.constants';
import { TaskListItem } from './TasksListItem';
import styleIdentifiers from './TasksList.scss';
var styles = classNames.bind(styleIdentifiers);
var EnhancedTable = CustomTable(TaskListItem);
export var TasksList = function (_a) {
    var debtor = _a.debtor;
    var t = useTranslation().t;
    var location = useLocation();
    var profile = useProfile();
    var _b = __read(useState({
        page_limit: profile.preferences.itemsPerPage,
        page: 1,
        debtor_id: debtor === null || debtor === void 0 ? void 0 : debtor.id,
    }), 2), params = _b[0], setParams = _b[1];
    useLayoutEffect(function () {
        setParams(function (currentParams) { return (__assign(__assign({}, currentParams), pick(Object.fromEntries(new URLSearchParams(location.search)), [
            'page',
            'page_limit',
            'sort_by',
            'sort_order',
            'title',
            'description',
            'status',
            'user_id',
            'debtor_id',
            'invoice_ids',
            'mandatory',
            'task_type_id',
            'created_at_before',
            'created_at_after',
            'due_date_before',
            'due_date_after',
        ]))); });
    }, []);
    var _c = useGetTasks(params), tasksListResponse = _c.data, isLoading = _c.isLoading, isFetched = _c.isFetched, refetch = _c.refetch;
    var onSortChange = function (newParams) {
        setParams(function (currentParams) { return (__assign(__assign({}, currentParams), pick(newParams, ['sort_by', 'sort_order', 'page_limit', 'page']))); });
    };
    var headers = TABLE_HEADERS.map(function (_a) {
        var title = _a.title, rest = __rest(_a, ["title"]);
        return (__assign({ title: t(title) }, rest));
    });
    return (React.createElement("div", null,
        React.createElement(EnhancedTable, { headers: headers, items: (tasksListResponse === null || tasksListResponse === void 0 ? void 0 : tasksListResponse.data) || [], pagination: tasksListResponse === null || tasksListResponse === void 0 ? void 0 : tasksListResponse.metadata.pagination, loading: isLoading, loaded: isFetched, onSortChange: onSortChange, itemProps: { refetch: refetch }, noShadow: true, noCheckbox: true })));
};
