import { __assign, __read } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Icon, IconName } from 'shared/components/Icon';
import { ButtonColor, InputFile } from 'shared/io';
import { invoiceActions } from 'store/invoice/invoice.actions';
import styleIdentifiers from './LinkDocument.scss';
var styles = classNames.bind(styleIdentifiers);
export default function LinkDocument(_a) {
    var invoice = _a.invoice;
    var _b = __read(useState(false), 2), showDocument = _b[0], setShowDocument = _b[1];
    var _c = __read(useState(1), 2), page = _c[0], setPage = _c[1];
    var _d = __read(useState(1), 2), numPages = _d[0], setNumPages = _d[1];
    var _e = __read(useState({ url: '', id: -1 }), 2), activeDocument = _e[0], setActiveDocument = _e[1];
    var _f = __read(useState(false), 2), isLoadingFile = _f[0], setIsLoadingFile = _f[1];
    var _g = __read(useState([]), 2), additionnalFiles = _g[0], setAdditionnalFiles = _g[1];
    var t = useTranslation().t;
    useEffect(function () {
        setAdditionnalFiles(JSON.parse(JSON.stringify(invoice.additional_files_attributes)));
        return function () {
            additionnalFiles.map(function (file) {
                file.url && URL.revokeObjectURL(file.url);
            });
        };
    }, []);
    useEffect(function () {
        if (additionnalFiles.length > 0) {
            displayDocument(additionnalFiles[0]);
        }
    }, [additionnalFiles]);
    var downloadFile = function (file) {
        invoiceActions.document({
            id: invoice.id,
            noLoading: true,
            document_type: 'invoice_additional_pdf',
            data: {
                additional_file_id: file.id,
            },
        });
    };
    var displayDocument = function (additional_file) {
        if (additional_file.url) {
            setActiveDocument({
                url: additional_file.url,
                id: additional_file.id,
            });
        }
        else {
            invoiceActions.document({
                id: invoice.id,
                noLoading: true,
                document_type: 'invoice_additional_pdf',
                data: {
                    additional_file_id: additional_file.id,
                },
                callback: function (data) {
                    var url = URL.createObjectURL(data);
                    var index = additionnalFiles.findIndex(function (file) { return file.id === additional_file.id; });
                    additionnalFiles[index].url = url;
                    setActiveDocument({
                        url: url,
                        id: additional_file.id,
                    });
                    setAdditionnalFiles(additionnalFiles);
                },
            });
        }
    };
    var removeFile = function (idFile) {
        invoiceActions.deleteAdditionalFile({
            id: idFile,
            callback: function () {
                var newfiles = additionnalFiles.slice();
                var indexFile = additionnalFiles.findIndex(function (file) { return file.id === idFile; });
                newfiles[indexFile].url && URL.revokeObjectURL(newfiles[indexFile].url);
                newfiles.splice(indexFile, 1);
                setAdditionnalFiles(newfiles);
            },
        });
    };
    var addFile = function (file, send_by_post) {
        invoiceActions.addAdditionalFile({
            data: {
                invoice_id: invoice.id,
                file: file,
                send_by_post: send_by_post,
                is_original_invoice_file: false,
            },
            callback: function (_a) {
                var data = _a.data;
                var newFile = __assign(__assign({}, data.attributes), { id: data.id });
                var url = URL.createObjectURL(file);
                newFile.url = url;
                additionnalFiles.push(newFile);
                setAdditionnalFiles(additionnalFiles);
                displayDocument(newFile);
            },
        });
    };
    var onDocumentLoadSuccess = function (pdf) {
        setNumPages(pdf._pdfInfo.numPages);
    };
    return (React.createElement("div", { className: styles('link-document', showDocument ? 'show-document' : 'show-document-list', additionnalFiles.length === 0 && 'empty', invoice.status === 'Brouillon' && 'brouillon') },
        React.createElement("div", { className: styles('left') },
            isLoadingFile ? (React.createElement("div", { className: styles('loading') },
                React.createElement("div", null, t(i18nKeys.LOADING)))) : (React.createElement("div", { className: styles('file-container', 'pdf-actions', {
                    empty: additionnalFiles.length === 0,
                }) }, activeDocument.url && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles('container-actions') },
                    page > 1 && (React.createElement(Icon, { name: IconName.MINIMAL_UP, onClick: function () {
                            setPage(page - 1);
                        } })),
                    numPages > page && (React.createElement(Icon, { name: IconName.MINIMAL_DOWN, onClick: function () {
                            setPage(page + 1);
                        } }))),
                React.createElement(Document, { file: activeDocument.url, onLoadSuccess: onDocumentLoadSuccess },
                    React.createElement(Page, { width: 540, pageNumber: page })))))),
            React.createElement("div", { className: styles('show-document-button'), onClick: function () { return setShowDocument(!showDocument); } },
                t(i18nKeys.BACK),
                " ",
                React.createElement(Icon, { name: IconName.MINIMAL_RIGHT }))),
        React.createElement("form", { className: styles('right') },
            React.createElement("div", { className: styles('container-right') },
                React.createElement("div", { className: styles('actions-container', additionnalFiles.length === 0 && 'center') },
                    React.createElement(InputFile, { color: ButtonColor.MAIN, name: "additional-file", label: t(i18nKeys.INVOICE.IMPORT_NEW_DOC), accept: "application/pdf", onChange: function (file) { return addFile(file, false); } })),
                React.createElement("div", { className: styles('recovr-list') }, additionnalFiles.map(function (file) { return (React.createElement("div", { key: file.id, className: styles({ active: activeDocument.id === file.id }) },
                    React.createElement("span", { className: styles('name'), onClick: function () { return displayDocument(file); } }, file.file_name),
                    React.createElement("div", { className: styles('actions') },
                        React.createElement(Icon, { name: IconName.CLOUD_DOWNLOAD, size: "18px", onClick: function () { return downloadFile(file); } }),
                        React.createElement(Icon, { name: IconName.TRASH_SIMPLE, onClick: function () { return removeFile(file.id); }, size: "18px" })))); })),
                additionnalFiles.length > 0 && (React.createElement("div", { className: styles('show-document-button'), onClick: function () { return setShowDocument(!showDocument); } },
                    React.createElement(Icon, { name: IconName.MINIMAL_LEFT }),
                    " ",
                    t(i18nKeys.SHOW_DOCUMENT)))))));
}
