import React, { useEffect } from 'react';
import { useCreateExternalMailboxConfiguration, useGetExternalMailboxConfigurations } from 'api';
import classNames from 'classnames';
import { i18nKeys, useTranslation } from 'locales';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import CustomTable from 'shared/components/CustomTable';
import Loader from 'shared/components/Loader';
import { Button } from 'shared/io';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import { notifications } from '@mantine/notifications';
import { ExternalMailboxSettingsForm } from './ExternalMailboxSettingsForm';
import { ExternalMailboxSettingsItem } from './ExternalMailboxSettingsItem';
import styleIdentifiers from './ExternalMailboxSettings.scss';
var styles = classNames.bind(styleIdentifiers);
var ExternalMailboxSettingsCustomTable = CustomTable(ExternalMailboxSettingsItem);
export var ExternalMailboxSettings = function () {
    var t = useTranslation().t;
    var queryParams = qs.parse(location.search);
    var messages = queryParams.message;
    var _a = useSelector(function (state) { return state.account.company.data; }).package, can_add_mailbox = _a.can_add_mailbox, can_use_external_mails = _a.can_use_external_mails;
    var _b = useGetExternalMailboxConfigurations(), externalMailboxConfigurationsResponse = _b.data, isLoading = _b.isLoading, isFetched = _b.isFetched, isError = _b.isError, refetch = _b.refetch;
    var createExternalMailboxConfigurationMutation = useCreateExternalMailboxConfiguration().mutate;
    useEffect(function () {
        if (messages) {
            notifications.show({
                title: t(i18nKeys.SUCCESS),
                color: 'green',
                message: messages,
            });
        }
    }, [messages]);
    if (isLoading && !externalMailboxConfigurationsResponse) {
        return React.createElement(Loader, null);
    }
    if (isError || !externalMailboxConfigurationsResponse) {
        return React.createElement("span", null, "Error");
    }
    var createExternalMailboxConfiguration = function () {
        return sideMenuShow({
            unmount: true,
            content: (React.createElement(ExternalMailboxSettingsForm, { onSubmit: function (data) {
                    return createExternalMailboxConfigurationMutation({ data: data }, {
                        onSuccess: function () {
                            refetch();
                            sideMenuHide();
                        },
                    });
                } })),
        });
    };
    var TABLE_HEADERS = [
        {
            key: 'id',
            title: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.ID),
        },
        {
            key: 'host',
            title: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.HOST),
        },
        {
            key: 'port',
            title: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.PORT),
        },
        {
            key: 'ssl',
            title: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.SSL),
        },
        {
            key: 'login',
            title: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.LOGIN),
        },
        {
            title: t(i18nKeys.FORM.ACTIONS),
            width: 100,
        },
    ];
    var tooltipMessage = can_use_external_mails
        ? i18nKeys.SETTINGS.EXTERNAL_MAILBOX.LIMIT_EXCEEDED
        : i18nKeys.NOT_INCLUDED;
    return (React.createElement(ExternalMailboxSettingsCustomTable, { className: styles('Mailbox-settings'), title: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.TITLE), headers: TABLE_HEADERS, items: externalMailboxConfigurationsResponse.data || [], pagination: externalMailboxConfigurationsResponse.metadata.pagination, itemProps: { refetch: refetch }, loading: isLoading, loaded: isFetched, notSortable: true, noCheckbox: true, actions: React.createElement(Button, { label: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.ADD), onClick: createExternalMailboxConfiguration, noMargin: true, title: can_add_mailbox ? '' : t(tooltipMessage), disabled: !can_add_mailbox }) }));
};
