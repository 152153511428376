import classNames from 'classnames/bind';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { DropdownDirection } from 'shared/io/Dropdown/Dropdown';
import { currencySymbol } from 'shared/utils/normalization';
import { IconName } from '../Icon';
import Tooltip from '../Tooltip';
import styleIdentifiers from './amount.scss';
var styles = classNames.bind(styleIdentifiers);
export default function Amount(_a) {
    var _b = _a.tooltip, tooltip = _b === void 0 ? true : _b, _c = _a.direction, direction = _c === void 0 ? DropdownDirection.RIGHT : _c, style = _a.style, value = _a.value, className = _a.className, suffix = _a.suffix, prefix = _a.prefix, debtor = _a.debtor, localizedValue = _a.localizedValue, children = _a.children;
    var company = useSelector(function (state) { return state.account.company.data; });
    var constants = useSelector(function (state) { return state.app.constants; });
    var useSuffix = !(localizedValue === null || localizedValue === void 0 ? void 0 : localizedValue.toString()) && suffix ? suffix : undefined;
    var fromCurrencyItem = debtor || company;
    if (Number(value) === 0) {
        return (React.createElement("span", { className: styles('Amount', className), style: style },
            React.createElement(NumericFormat, { value: value, displayType: "text", prefix: prefix, suffix: " ".concat(currencySymbol(useSuffix, fromCurrencyItem, constants)), decimalSeparator: ".", fixedDecimalScale: true, decimalScale: 2, thousandSeparator: " " })));
    }
    if (children) {
        return (React.createElement("span", { className: styles('Amount', className), style: style },
            React.createElement(NumericFormat, { value: Number(value).toString(), displayType: "text", prefix: prefix, suffix: " ".concat(currencySymbol(useSuffix, fromCurrencyItem, constants)), decimalSeparator: ".", fixedDecimalScale: true, decimalScale: 2, thousandSeparator: " " }),
            tooltip ? (React.createElement(Tooltip, { icon: IconName.CURRENCY, direction: direction, iconClassName: styles('icon-tooltip'), contentClassName: styles('subject-tooltip') }, children)) : ({ children: children })));
    }
    return (React.createElement("span", { className: styles('Amount', className), style: style },
        React.createElement(NumericFormat, { value: value, displayType: "text", prefix: prefix, suffix: " ".concat(currencySymbol(useSuffix, fromCurrencyItem, constants)), decimalSeparator: ".", fixedDecimalScale: true, decimalScale: 2, thousandSeparator: " " }),
        localizedValue &&
            (fromCurrencyItem === null || fromCurrencyItem === void 0 ? void 0 : fromCurrencyItem.currency) !== suffix &&
            (tooltip ? (React.createElement(Tooltip, { icon: IconName.CURRENCY, direction: direction, iconClassName: styles('icon-tooltip'), contentClassName: styles('subject-tooltip') },
                React.createElement(NumericFormat, { value: localizedValue, displayType: "text", suffix: " ".concat(currencySymbol(suffix, fromCurrencyItem, constants)), decimalSeparator: ".", fixedDecimalScale: true, decimalScale: 2, thousandSeparator: " " }))) : (React.createElement(NumericFormat, { value: localizedValue, displayType: "text", prefix: "(", suffix: " ".concat(currencySymbol(suffix, fromCurrencyItem, constants), ")"), decimalSeparator: ".", fixedDecimalScale: true, decimalScale: 2, thousandSeparator: " ", style: { marginLeft: '10px' } })))));
}
