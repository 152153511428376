import { __awaiter, __generator } from "tslib";
import { LocaleSchema } from 'types';
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, axiosInstance } from './utils';
var TenantSchema = z.object({
    locale: LocaleSchema,
    companyName: z.string().min(1),
    domain: z.string(),
    customBranding: z
        .object({
        name: z.string().nullable(),
        logo: z.string().nullable(),
        color: z.string().nullable(),
    })
        .nullable(),
});
export var useValidateTenant = function (subdomain) {
    var queryResult = useQuery({
        queryKey: ['tenant'],
        retry: false,
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axiosInstance.get("/tenant?name=".concat(subdomain))];
                    case 1:
                        data = (_a.sent()).data;
                        data.domain = subdomain;
                        return [2 /*return*/, TenantSchema.parse(data)];
                }
            });
        }); },
    });
    return addResourceNameToQueryResult('tenant', queryResult);
};
