import { __assign } from "tslib";
import { treatTableViews } from 'shared/serializer';
import { appConstants as events } from 'store/app/app.actions';
import { flattenItem, handleResponse } from 'store/reducers';
export var initialState = {
    constants: null,
    ready: false,
    address: null,
    lastSettingsScreen: '',
    tableTypes: {},
};
var reducer = function (state, action) {
    var _a;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case events.getViews.result:
            return __assign(__assign({}, state), { tableTypes: __assign(__assign({}, state.tableTypes), (_a = {}, _a[action.requestData.view_type] = treatTableViews(action.payload.data).slice(), _a)) });
        // todo:  Check if it used
        case events.fetchAddress.result:
            return handleResponse(state, action, 'address', flattenItem);
        case events.constants.result:
            return handleResponse(state, action, 'constants');
        case events.updateLastSettingsScreen.request:
            return __assign(__assign({}, state), { lastSettingsScreen: action.payload });
        default:
            return state;
    }
};
export default reducer;
