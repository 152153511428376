import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import HTML from 'shared/components/HTML/HTML';
import styleIdentifiers from './signupAccount.scss';
import SignupAccountForm from './SignupAccountForm';
var styles = classNames.bind(styleIdentifiers);
export default function SignupAccount(_a) {
    var company = _a.company, onSubmit = _a.onSubmit, className = _a.className, active = _a.active, initialValues = _a.initialValues;
    var t = useTranslation().t;
    if (company) {
        initialValues.user = __assign(__assign({}, initialValues.user), { company_vat_number: company.vat_number });
    }
    return (React.createElement("div", { className: styles('signup-account', className) },
        React.createElement("div", { className: styles('info') },
            React.createElement(HTML, { html: t(i18nKeys.ONBOARDING.SIGNUP_ACCOUNT_TITLE) })),
        React.createElement("div", { className: styles('form') },
            React.createElement(SignupAccountForm, { active: active, initialValues: initialValues, onSubmit: onSubmit }))));
}
