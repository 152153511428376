import { __assign, __read } from "tslib";
import React, { useEffect, useRef, useState } from 'react';
import { useProfile } from 'app/App';
import { CommentForm } from 'app/Logged/Comments';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import DateItem from 'shared/components/DateItem';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconName } from 'shared/components/Icon';
import { Button, ButtonColor } from 'shared/io';
import { removeAttributes } from 'shared/utils/view';
import { invoiceActions } from 'store/invoice/invoice.actions';
import styleIdentifiers from './Conversation.scss';
var styles = classNames.bind(styleIdentifiers);
export default function Conversation(_a) {
    var caseId = _a.caseId, conversation = _a.conversation, onSubmit = _a.onSubmit, className = _a.className, invoiceId = _a.invoiceId, canNotComment = _a.canNotComment;
    var profile = useProfile();
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var _c = __read(useState(conversation.conversation), 2), messages = _c[0], setMessages = _c[1];
    var messageWrapperRef = useRef(null);
    useEffect(function () {
        messageWrapperRef.current.scrollTop = messageWrapperRef.current.scrollHeight;
    }, [messages]);
    var submitMessage = function (_a) {
        var attachment = _a.attachment, comment = _a.comment;
        invoiceActions.actionsConversationMessage({
            id: conversation.id,
            fileUpload: true,
            data: {
                body: comment,
                attachment: attachment,
            },
            callback: function (_a) {
                var data = _a.data;
                onSubmit();
                var newMessages = __assign({}, messages);
                newMessages.messages.push(removeAttributes(data));
                setMessages(newMessages);
            },
        });
    };
    var downloadSentLetter = function () {
        invoiceActions.document({
            id: invoiceId,
            document_type: 'lawyer_case_pdf',
            third_party_case_id: caseId,
        });
    };
    return (React.createElement("div", { className: styles('conversation', className) },
        conversation.has_tp_case_pdf && (React.createElement("div", null,
            React.createElement(Button, { className: styles('download-button'), noMargin: true, color: ButtonColor.GREY, label: t(i18nKeys.DOWNLOAD_SENT_LETTER), onClick: downloadSentLetter }))),
        React.createElement("div", { className: styles('message-container'), ref: messageWrapperRef }, messages.messages.map(function (message, key) { return (React.createElement("div", { key: message.id || key, className: styles('message', message.is_system && 'system', message.owner && 'right', message.author === "".concat(profile.name.full) && 'user') },
            React.createElement("div", { className: styles('message-wrapper') },
                React.createElement("div", { className: styles('message-info') },
                    React.createElement("span", { className: styles('date') },
                        React.createElement(DateItem, { date: message.date, lg: currentLang, format: "LLL" })),
                    React.createElement("span", { className: styles('from') }, message.is_system ? 'Robot' : message.author)),
                React.createElement("div", { className: styles('message') },
                    React.createElement(HTML, { html: message.body })),
                message.attachment_url && (React.createElement("div", { className: styles('actions') },
                    React.createElement("a", { href: message.attachment_url, target: "_blank", rel: "noopener noreferrer" },
                        React.createElement(Icon, { name: IconName.FILE }),
                        message.attachment_name)))))); })),
        !canNotComment && (React.createElement(CommentForm, { withFile: true, onSubmit: submitMessage, labelText: t(i18nKeys.NEW_MESSAGE), label: t(i18nKeys.ACTIVITIES.SEND_MESSAGE) }))));
}
