import { __assign, __read } from "tslib";
import React, { useLayoutEffect, useState } from 'react';
import { useGetDebtorBalanceDetail } from 'api';
import { ObjectType, } from 'api/models';
import ToHandleDetail from 'app/Logged/Invoices/ToHandleDetail';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import _, { pick } from 'lodash';
import Amount from 'shared/components/Amount';
import DebtorFinancialDataSummary from 'shared/components/DebtorFinancialDataSummary';
import { Icon, IconName } from 'shared/components/Icon';
import Loader from 'shared/components/Loader';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { ClientDetailTabsBalanceFilter } from './ClientDetailTabsBalanceFilter';
import { ClientDetailTabsBalanceItem } from './ClientDetailTabsBalanceItem';
import { ClientDetailTabsBalanceItemCreditNote } from './ClientDetailTabsBalanceItemCreditNote';
import { ClientDetailTabsBalanceItemInvoice } from './ClientDetailTabsBalanceItemInvoice';
import { ClientDetailTabsBalanceItemPayment } from './ClientDetailTabsBalanceItemPayment';
import styleIdentifiers from './ClientDetailTabsBalance.scss';
var styles = classNames.bind(styleIdentifiers);
export var ClientDetailTabsBalance = function (_a) {
    var debtor = _a.debtor;
    var t = useTranslation().t;
    var id = debtor.id, currency = debtor.attributes.currency;
    var _b = __read(useState({}), 2), params = _b[0], setParams = _b[1];
    useLayoutEffect(function () {
        setParams(function (currentParams) { return (__assign(__assign({}, currentParams), pick(Object.fromEntries(new URLSearchParams(location.search)), ['page', 'page_limit', 'start_date', 'end_date', 'status', 'types']))); });
    }, []);
    var _c = useGetDebtorBalanceDetail(id, params), debtorBalanceDetailResponse = _c.data, isLoading = _c.isLoading, isError = _c.isError;
    var _d = __read(useState(false), 2), openAll = _d[0], setOpenAll = _d[1];
    var _e = __read(useState({}), 2), openedInvoices = _e[0], setOpenedInvoices = _e[1];
    if (isLoading && !debtorBalanceDetailResponse) {
        return React.createElement(Loader, null);
    }
    if (isError || !debtorBalanceDetailResponse) {
        return React.createElement("span", null, "Error");
    }
    var data = debtorBalanceDetailResponse.data;
    var _f = data.attributes, balance_data = _f.balance_data, _g = _f.invoices_total_tvac, invoices_total_tvac = _g === void 0 ? 0 : _g, _h = _f.late_fees_sum, late_fees_sum = _h === void 0 ? 0 : _h, _j = _f.localized_money_object, _k = _j.invoices_total_tvac, localizedInvoices_total_tvac = _k === void 0 ? 0 : _k, _l = _j.late_fees_sum, localizedLate_fees_sum = _l === void 0 ? 0 : _l, _m = _j.payments_sum, localizedPayments_sum = _m === void 0 ? 0 : _m, _o = _j.remaining_balance, localizedRemaining_balance = _o === void 0 ? 0 : _o, _p = _f.payments_sum, payments_sum = _p === void 0 ? 0 : _p, _q = _f.remaining_balance, remaining_balance = _q === void 0 ? 0 : _q, financial_data = _f.financial_data;
    var invoiceCnIds = balance_data
        .filter(function (e) { return e.type === ObjectType.invoice || e.type === ObjectType.credit_note; })
        .map(function (e) { return e.id; });
    var toggleAllDetail = function () {
        if (Object.keys(openedInvoices).length === 0) {
            var initialState = _.fromPairs(invoiceCnIds.map(function (e) { return [e, true]; }));
            setOpenedInvoices(initialState);
        }
        else {
            var newState = _.fromPairs(invoiceCnIds.map(function (e) { return [e, !openAll]; }));
            setOpenedInvoices(newState);
        }
        setOpenAll(!openAll);
    };
    var toggleSingleDetail = function (invoiceOrCnId) {
        var _a, _b;
        if (Object.keys(openedInvoices).length === 0) {
            var initialState = _.fromPairs(invoiceCnIds.map(function (e) { return [e, false]; }));
            setOpenedInvoices(__assign(__assign({}, initialState), (_a = {}, _a[invoiceOrCnId] = true, _a)));
        }
        else {
            var newState = __assign(__assign({}, openedInvoices), (_b = {}, _b[invoiceOrCnId] = !openedInvoices[invoiceOrCnId], _b));
            setOpenedInvoices(newState);
        }
    };
    var openedInvoicesHasKeys = Object.keys(openedInvoices).length !== 0;
    var openInvoiceOrCreditNote = function (invOrCnid, credit) {
        if (credit === void 0) { credit = false; }
        showDialog({
            id: DialogShowId.INVOICE,
            size: DialogShowSize.LARGE,
            children: React.createElement(ToHandleDetail, { id: invOrCnid, isCreditNote: credit, modal: true }),
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(DebtorFinancialDataSummary, { financialData: financial_data }),
        React.createElement(ClientDetailTabsBalanceFilter, { params: params, setParams: setParams }),
        React.createElement("table", { className: styles('balance-table') },
            React.createElement("thead", { className: styles('line') },
                React.createElement("tr", null,
                    React.createElement("th", null),
                    React.createElement("th", { className: styles('reference', 'main'), onClick: toggleAllDetail },
                        React.createElement("div", { className: styles('summary') },
                            React.createElement(Icon, { name: IconName[openAll ? 'MINUS' : 'PLUS'], className: styles('arrow'), size: "15px" }),
                            t(i18nKeys.FORM.REFERENCE))),
                    React.createElement("th", null, t(i18nKeys.STATUS)),
                    React.createElement("th", null, t(i18nKeys.ISSUE_DATE_SHORT)),
                    React.createElement("th", null, t(i18nKeys.DUE_DATE_SHORT)),
                    React.createElement("th", { className: styles('debit') }, t(i18nKeys.DEBIT)),
                    React.createElement("th", { className: styles('credit') }, t(i18nKeys.CREDIT)),
                    React.createElement("th", null, t(i18nKeys.BALANCE)))),
            React.createElement("tbody", null, balance_data.length ? (React.createElement(React.Fragment, null, balance_data.map(function (item) {
                switch (item.type) {
                    case ObjectType.invoice: {
                        var _a = item, invoiceId_1 = _a.id, type = _a.type, _b = _a.attributes, reference = _b.reference, due_date = _b.due_date, issue_date = _b.issue_date, late_fees = _b.late_fees, linked_credit_notes = _b.linked_credit_notes, payments = _b.payments, matched_payments = _b.matched_payments, status = _b.status;
                        return (React.createElement(ClientDetailTabsBalanceItem, { key: "".concat(type, "-").concat(invoiceId_1), type: type, reference: reference, issueDate: issue_date, dueDate: due_date, label: t(i18nKeys.COMMON.INVOICE), hasLateFees: Number(late_fees) !== 0, linkedCreditNotes: linked_credit_notes, payments: payments, matchedPayments: matched_payments, isOpen: openedInvoicesHasKeys && openedInvoices[invoiceId_1], toggleDetail: function () {
                                toggleSingleDetail(invoiceId_1);
                            }, openInvoice: function (e) {
                                e.stopPropagation();
                                openInvoiceOrCreditNote(invoiceId_1);
                            }, status: status },
                            React.createElement(ClientDetailTabsBalanceItemInvoice, { item: item, showDetails: openedInvoicesHasKeys && openedInvoices[invoiceId_1] })));
                    }
                    case ObjectType.credit_note: {
                        var _c = item, creditNoteId_1 = _c.id, type = _c.type, _d = _c.attributes, reference = _d.reference, issue_date = _d.issue_date, due_date = _d.due_date, manual_payments = _d.manual_payments, matched_payments = _d.matched_payments, status = _d.status;
                        return (React.createElement(ClientDetailTabsBalanceItem, { key: "".concat(type, "-").concat(creditNoteId_1), type: type, reference: reference, label: t(i18nKeys.CREDIT_NOTE_SHORT), issueDate: issue_date, dueDate: due_date, settlements: manual_payments, linkedInvoices: matched_payments, status: status, isOpen: openedInvoicesHasKeys && openedInvoices[creditNoteId_1], toggleDetail: function () {
                                toggleSingleDetail(creditNoteId_1);
                            }, openInvoice: function (e) {
                                e.stopPropagation();
                                openInvoiceOrCreditNote(creditNoteId_1, true);
                            } },
                            React.createElement(ClientDetailTabsBalanceItemCreditNote, { item: item, showDetails: openedInvoicesHasKeys && openedInvoices[creditNoteId_1] })));
                    }
                    case ObjectType.accounting_payment: {
                        var _e = item, paymentId = _e.id, type = _e.type, _f = _e.attributes, external_id = _f.external_id, received_at = _f.received_at;
                        return (React.createElement(ClientDetailTabsBalanceItem, { key: "".concat(type, "-").concat(paymentId), type: type, reference: external_id, issueDate: received_at, label: t(i18nKeys.PAYMENT) },
                            React.createElement(ClientDetailTabsBalanceItemPayment, { item: item })));
                    }
                    case ObjectType.payment: {
                        var _g = item, paymentId = _g.id, type = _g.type, _h = _g.attributes, communication = _h.communication, value_date = _h.value_date;
                        return (React.createElement(ClientDetailTabsBalanceItem, { key: "".concat(type, "-").concat(paymentId), type: type, reference: React.createElement("span", { className: styles('info') }, communication), issueDate: value_date, label: t(i18nKeys.PAYMENT) },
                            React.createElement(ClientDetailTabsBalanceItemPayment, { item: item })));
                    }
                    default: {
                        var objectId = item.id, type = item.type;
                        return (React.createElement(ClientDetailTabsBalanceItem, { key: "".concat(type, "-").concat(objectId), type: type, reference: type, label: type }, "No supported"));
                    }
                }
            }))) : (React.createElement("tr", null,
                React.createElement("td", { colSpan: 7, className: styles('no-result') }, t(i18nKeys.NO_RESULT))))),
            React.createElement("tfoot", null,
                React.createElement("tr", null,
                    React.createElement("td", { colSpan: 5, className: styles('total') }, t(i18nKeys.TOTAL)),
                    React.createElement("td", { className: styles('debit') },
                        React.createElement(Amount, { localizedValue: +invoices_total_tvac + +late_fees_sum, value: +localizedInvoices_total_tvac + +localizedLate_fees_sum, suffix: currency })),
                    React.createElement("td", { className: styles('credit') },
                        React.createElement(Amount, { localizedValue: payments_sum, value: localizedPayments_sum, suffix: currency })),
                    React.createElement("td", null,
                        React.createElement(Amount, { localizedValue: remaining_balance, value: localizedRemaining_balance, suffix: currency })))))));
};
