import { __awaiter, __generator } from "tslib";
import { z } from 'zod';
import { useQuery } from '@tanstack/react-query';
import { addResourceNameToQueryResult, axiosInstance } from './utils';
var NotificationsCountSchema = z
    .object({ notificationsCount: z.number() })
    .transform(function (data) { return data.notificationsCount; });
export function useLoadNotificationsCountFn() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosInstance.get('/notifications/count')];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, NotificationsCountSchema.parse(data)];
            }
        });
    });
}
export var useLoadNotificationsCount = function () {
    var queryResult = useQuery({
        queryKey: ['notifications', 'count'],
        queryFn: useLoadNotificationsCountFn,
        placeholderData: 0,
    });
    return addResourceNameToQueryResult('notificationsCount', queryResult);
};
