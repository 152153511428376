import { __assign, __read, __spreadArray } from "tslib";
import { useGetPaymentPlan, useUpdatePaymentPlan, useUpdatePaymentStepCalculation } from 'api';
import { omit } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Loader from 'shared/components/Loader';
import { PaymentPlanFormWrapper } from '../PaymentPlanForm/PaymentPlanFormWrapper';
import { AddInvoicesForm } from './AddInvoicesForm/AddInvoicesForm';
import { PaymentPlanEditFormSteps, } from './PaymentPlanEditFormSteps';
export var PaymentPlanEditForm = function () {
    var paymentPlanId = useParams().id;
    var history = useHistory();
    var _a = useGetPaymentPlan(paymentPlanId), paymentPlanResponse = _a.data, isLoading = _a.isLoading, isError = _a.isError;
    var updatePaymentPlan = useUpdatePaymentPlan().mutate;
    var updatePaymentStepCalculation = useUpdatePaymentStepCalculation().mutate;
    var _b = __read(useState(false), 2), addInvoices = _b[0], setAddInvoices = _b[1];
    var _c = __read(useState([]), 2), paymentSteps = _c[0], setPaymentSteps = _c[1];
    var _d = __read(useState(), 2), newProperties = _d[0], setNewProperties = _d[1];
    useEffect(function () {
        if (paymentPlanResponse) {
            var payment_steps_attributes = paymentPlanResponse.data.attributes.payment_steps_attributes;
            setPaymentSteps(payment_steps_attributes.map(function (e) { return omit(__assign({ id: e.id }, e.attributes), ['days_late']); }));
        }
    }, [paymentPlanResponse]);
    if (isLoading && !paymentPlanResponse) {
        return React.createElement(Loader, null);
    }
    if (isError || !paymentPlanResponse) {
        return React.createElement("span", null, "Error");
    }
    if (addInvoices) {
        return (React.createElement(PaymentPlanFormWrapper, { edit: true },
            React.createElement(AddInvoicesForm, { debtor: paymentPlanResponse.data.attributes.debtor, onCancel: function () { return setAddInvoices(false); }, onSubmit: function (data) {
                    updatePaymentStepCalculation({ id: paymentPlanId, data: data }, {
                        onSuccess: function (response) {
                            setPaymentSteps(data.new_steps
                                ? __spreadArray(__spreadArray([], __read(paymentSteps), false), __read(response.payment_steps_attributes), false) : response.payment_steps_attributes);
                            setAddInvoices(false);
                            setNewProperties({
                                addedInvoiceIds: data.invoice_ids,
                                newBalance: response.new_remaining_balance,
                            });
                        },
                    });
                } })));
    }
    var paymentPlan = paymentPlanResponse.data;
    return (React.createElement(PaymentPlanEditFormSteps, { paymentPlan: paymentPlan, paymentSteps: paymentSteps, newProperties: newProperties, onButtonClick: function () { return setAddInvoices(true); }, onSubmit: function (data) {
            updatePaymentPlan({ id: paymentPlanId, data: data }, {
                onSuccess: function () {
                    history.push("/payment-plan/".concat(paymentPlanId));
                },
            });
        } }));
};
