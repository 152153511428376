import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useLoadCei } from 'shared/hooks';
import { Box, LoadingOverlay } from '@mantine/core';
import { AnalyticsCard } from '../AnalyticsCard';
import { CeiChart } from '../charts';
export var CeiCard = function () {
    var t = useTranslation().t;
    var _a = useLoadCei(), _b = _a.cei, cei = _b === void 0 ? {} : _b, isCeiLoading = _a.isCeiLoading;
    return (React.createElement(AnalyticsCard, { title: t(i18nKeys.ANALYTICS.CEI.TITLE) },
        React.createElement(Box, { w: "100%", h: "400px" }, isCeiLoading ? React.createElement(LoadingOverlay, { visible: true }) : React.createElement(CeiChart, { cei: cei }))));
};
