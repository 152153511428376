import { __assign, __generator } from "tslib";
import { channel } from 'redux-saga';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { api } from 'store/apis';
import { appActions, appConstants as events } from 'store/app/app.actions';
import { translateActions } from 'store/translate/translate.actions';
import { userInit } from '../account/account.sagas';
import { apiCall, sendApi } from '../sagas';
var externalChannel = channel();
export function externalWatcher() {
    var action;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 3];
                return [4 /*yield*/, take(externalChannel)];
            case 1:
                action = _a.sent();
                return [4 /*yield*/, put(action)];
            case 2:
                _a.sent();
                return [3 /*break*/, 0];
            case 3: return [2 /*return*/];
        }
    });
}
// Only exists to reload constants in the right locale after a language change
export function getConstants(_a) {
    var constants, connected;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(function (state) { return state.app.constants; })];
            case 1:
                constants = _b.sent();
                return [4 /*yield*/, select(function (state) { return state.session.data; })];
            case 2:
                connected = _b.sent();
                if (!constants) return [3 /*break*/, 4];
                return [4 /*yield*/, apiCall({
                        api: connected ? api.settings.privateConstants : api.settings.constants,
                        data: {
                            locale: payload,
                        },
                        actionRes: appActions.constantsRes,
                    })];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4: return [2 /*return*/];
        }
    });
}
function reloadCompanyConfiguration() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(userInit)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function AppWatchers() {
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = all;
                return [4 /*yield*/, takeLatest(events.reloadCompanyConfiguration.request, reloadCompanyConfiguration)];
            case 1:
                _b = [
                    _c.sent()
                ];
                return [4 /*yield*/, takeLatest(events.customUrl.request, sendApi(api.custom))];
            case 2:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getFiltersName.request, sendApi(api.tables.getFiltersName))];
            case 3:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getViews.request, sendApi(api.tables.getViews, appActions.getViewsRes))];
            case 4:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getView.request, sendApi(api.tables.getView))];
            case 5:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.updateViews.request, sendApi(api.tables.updateViews))];
            case 6:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getColumns.request, sendApi(api.tables.getColumnsAvailable))];
            case 7:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.getFilters.request, sendApi(api.tables.getFiltersAvailable))];
            case 8:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.export.request, sendApi(api.tables.export))];
            case 9:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(events.fetchAddress.request, sendApi(api.settings.fetchAddress, appActions.fetchAddressRes, function (payload) { return (__assign(__assign({}, payload), { actionFailure: payload.callback })); }))];
            case 10:
                _b = _b.concat([
                    _c.sent()
                ]);
                // todo: The language change is the only reason getConstants exists, we should get rid of it later
                return [4 /*yield*/, takeLatest(translateActions.changeLang.request, getConstants)];
            case 11: return [4 /*yield*/, _a.apply(void 0, [_b.concat([
                        // todo: The language change is the only reason getConstants exists, we should get rid of it later
                        _c.sent()
                    ])])];
            case 12:
                _c.sent();
                return [2 /*return*/];
        }
    });
}
export default function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(AppWatchers)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
