import { ObjectType, } from 'api/models';
import classNames from 'classnames/bind';
import { useTranslation } from 'locales';
import React from 'react';
import { formattedDate } from 'shared/utils/view';
import styleIdentifiers from './ClientDetailTabsBalance.scss';
var styles = classNames.bind(styleIdentifiers);
export var ClientDetailTabsBalanceItemDetailDates = function (_a) {
    var _b = _a.type, type = _b === void 0 ? ObjectType.invoice : _b, _c = _a.hasLateFees, hasLateFees = _c === void 0 ? false : _c, _d = _a.linkedCreditNotes, linkedCreditNotes = _d === void 0 ? [] : _d, _e = _a.payments, payments = _e === void 0 ? [] : _e, _f = _a.matchedPayments, matchedPayments = _f === void 0 ? [] : _f, _g = _a.linkedInvoices, linkedInvoices = _g === void 0 ? [] : _g, _h = _a.settlements, settlements = _h === void 0 ? [] : _h;
    var currentLang = useTranslation().currentLang;
    if (type === ObjectType.credit_note) {
        return (React.createElement("div", { className: styles('details') },
            React.createElement("p", null, "\u200B"), linkedInvoices === null || linkedInvoices === void 0 ? void 0 :
            linkedInvoices.map(function (_a) {
                var invId = _a.id, created_at = _a.attributes.created_at;
                return (React.createElement("p", { key: invId }, formattedDate(created_at, currentLang, 'DD/MM/YY')));
            }),
            settlements.map(function (_a) {
                var paymentId = _a.id, received_at = _a.attributes.received_at;
                return (React.createElement("p", { key: paymentId }, formattedDate(received_at, currentLang, 'DD/MM/YY')));
            })));
    }
    // else if (isInvoice)
    return (React.createElement("div", { className: styles('details') },
        React.createElement("p", null, "\u200B"),
        hasLateFees && React.createElement("p", null, "\u200B"), linkedCreditNotes === null || linkedCreditNotes === void 0 ? void 0 :
        linkedCreditNotes.map(function (_a) {
            var cnId = _a.id, matched_date = _a.attributes.matched_date;
            return (React.createElement("p", { key: cnId }, formattedDate(matched_date, currentLang, 'DD/MM/YY')));
        }),
        payments.map(function (_a) {
            var paymentId = _a.id, received_at = _a.attributes.received_at;
            return (React.createElement("p", { key: paymentId }, formattedDate(received_at, currentLang, 'DD/MM/YY')));
        }),
        matchedPayments.map(function (_a) {
            var matchedPaymentId = _a.id, created_at = _a.attributes.created_at;
            return (React.createElement("p", { key: matchedPaymentId }, formattedDate(created_at, currentLang, 'DD/MM/YY')));
        })));
};
