import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React from 'react';
import { Controller, get, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CustomSelect, Input } from 'shared/io';
import styleIdentifiers from './AddressForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function AddressForm() {
    var t = useTranslation().t;
    var constants = useSelector(function (state) { return state.app.constants; });
    var _a = useFormContext(), register = _a.register, errors = _a.formState.errors;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles('col-6', 'street-name') },
            React.createElement(Input, { errorMessage: get(errors, 'address_attributes.street_name'), className: styles('input'), withBorder: true, register: register('address_attributes.street_name', {
                    required: true,
                }), type: "text", label: t(i18nKeys.FORM.ADDRESS.STREET) })),
        React.createElement("div", { className: styles('col-3') },
            React.createElement(Input, { className: styles('input'), withBorder: true, register: register('address_attributes.street_number'), type: "text", label: t(i18nKeys.NUMBER) })),
        React.createElement("div", { className: styles('col-3') },
            React.createElement(Input, { className: styles('input'), withBorder: true, register: register('address_attributes.street_box'), type: "text", label: t(i18nKeys.FORM.ADDRESS.BOX) })),
        React.createElement("div", { className: "col-6" },
            React.createElement(Input, { errorMessage: get(errors, 'address_attributes.zip_code'), className: styles('input'), withBorder: true, register: register('address_attributes.zip_code', {
                    required: true,
                }), type: "text", label: t(i18nKeys.FORM.ADDRESS.POSTAL_CODE) })),
        React.createElement("div", { className: "col-6" },
            React.createElement(Input, { errorMessage: get(errors, 'address_attributes.city'), className: styles('input'), withBorder: true, register: register('address_attributes.city', {
                    required: true,
                }), type: "text", label: t(i18nKeys.FORM.ADDRESS.CITY) })),
        React.createElement("div", { className: "col-12" },
            React.createElement(Controller, { defaultValue: "", name: "address_attributes.country_code", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "description", keyValue: "value", withBorder: true, name: "address_attributes.country_code", filter: true, items: constants.countries, label: t(i18nKeys.FORM.ADDRESS.COUNTRY) })); } }))));
}
