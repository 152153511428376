import { __assign } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button, ButtonColor, CustomSelect, Input } from 'shared/io';
import styleIdentifiers from './signupCompanyForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function SignupCompanyForm(_a) {
    var back = _a.back, active = _a.active, initialValues = _a.initialValues, onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var constants = useSelector(function (state) { return state.app.constants; });
    var form = useForm({
        shouldUnregister: true,
        defaultValues: initialValues,
    });
    var register = form.register, handleSubmit = form.handleSubmit, errors = form.formState.errors, reset = form.reset, getValues = form.getValues;
    useEffect(function () {
        reset(initialValues);
    }, [initialValues]);
    var tabIndex = active ? undefined : -1;
    var goBack = function () {
        back(getValues());
    };
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('SignupCompanyForm'), onSubmit: handleSubmit(onSubmit) },
            React.createElement("div", { className: styles('grid-row') },
                React.createElement("div", { className: "col-6" },
                    React.createElement(Input, { register: register('street_name', { required: true }), tabIndex: tabIndex, errorMessage: errors.street_name, type: "text", label: t(i18nKeys.FORM.ADDRESS.STREET) })),
                React.createElement("div", { className: "col-3" },
                    React.createElement(Input, { register: register('street_number', { required: true }), tabIndex: tabIndex, errorMessage: errors.street_number, type: "text", label: t(i18nKeys.NUMBER) })),
                React.createElement("div", { className: "col-3" },
                    React.createElement(Input, { register: register('street_box'), tabIndex: tabIndex, type: "text", label: t(i18nKeys.FORM.ADDRESS.BOX) })),
                React.createElement("div", { className: "col-6" },
                    React.createElement(Input, { register: register('zip_code', { required: true }), errorMessage: errors.zip_code, tabIndex: tabIndex, type: "text", label: t(i18nKeys.FORM.ADDRESS.POSTAL_CODE) })),
                React.createElement("div", { className: "col-6" },
                    React.createElement(Input, { register: register('city', { required: true }), errorMessage: errors.city, tabIndex: tabIndex, type: "text", label: t(i18nKeys.FORM.ADDRESS.CITY) })),
                React.createElement("div", { className: "col-12" },
                    React.createElement(Controller, { defaultValue: "", name: "country_code", rules: { required: true }, render: function () { return (React.createElement(CustomSelect, { name: "country_code", tabIndex: tabIndex, keyText: "description", keyValue: "value", filter: true, items: constants.countries, label: t(i18nKeys.FORM.ADDRESS.COUNTRY) })); } })),
                React.createElement("div", { className: "col-12" },
                    React.createElement(Input, { register: register('phoneNumber'), tabIndex: tabIndex, type: "text", label: t(i18nKeys.ENTERPRISE_PHONE_NUMBER) }))),
            React.createElement("div", { className: styles('button-wrapper') },
                back && (React.createElement(Button, { tabIndex: tabIndex, label: t(i18nKeys.PREVIOUS), color: ButtonColor.GREY, onClick: goBack })),
                React.createElement(Button, { tabIndex: tabIndex, label: t(i18nKeys.NEXT), type: "submit" })))));
}
