import { __assign, __read, __spreadArray } from "tslib";
import AnimateNumber from 'animated-number-react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Amount from 'shared/components/Amount';
import DateItem from 'shared/components/DateItem';
import { Button } from 'shared/io';
import { treatInvoice } from 'shared/serializer';
import { useSearchInvoices } from 'shared/utils/hooks';
import { currencySymbol, formatAmount } from 'shared/utils/normalization';
import styleIdentifiers from './linkInvoiceForm.scss';
import { getCurrentValues, getLinkInvoiceFormFieldLine } from './LinkInvoiceForm.utils';
import { LinkInvoiceFormTable } from './LinkInvoiceFormTable';
var styles = classNames.bind(styleIdentifiers);
export var LinkInvoiceForm = function (_a) {
    var _b;
    var onSubmit = _a.onSubmit, reload = _a.reload;
    var _c = useTranslation(), t = _c.t, currentLang = _c.currentLang;
    var creditNote = useSelector(function (state) { return state.creditNote.current.data; });
    var _d = __read(useState([]), 2), invoices = _d[0], setInvoices = _d[1];
    var getInvoices = useSearchInvoices().getInvoices;
    useEffect(function () {
        if (creditNote != null) {
            var currency_1 = creditNote.currency, debtorId = creditNote.debtor_attributes.id, matchedPayments_1 = creditNote.matched_payments_attributes;
            setInvoices(matchedPayments_1.map(function (e) { return e.invoice_attributes; }));
            getInvoices({
                currency: currency_1,
                debtor_id: debtorId,
                exclude: invoices.map(function (e) { return e.id; }),
            }, function (_a) {
                var data = _a.data;
                setInvoices(function (currentInvoices) { return __spreadArray(__spreadArray([], __read(currentInvoices), false), __read(data
                    .filter(function (e) { return !matchedPayments_1.some(function (el) { return el.invoice_attributes.id === e.id; }); })
                    .map(function (e) { return treatInvoice(e); })), false); });
            });
        }
    }, [creditNote]);
    var constants = useSelector(function (state) { return state.app.constants; });
    var form = useForm({
        shouldUnregister: true,
        defaultValues: {
            invoices_to_link: ((_b = creditNote === null || creditNote === void 0 ? void 0 : creditNote.matched_payments_attributes) !== null && _b !== void 0 ? _b : []).map(function (e) {
                return getLinkInvoiceFormFieldLine(e.invoice_attributes, matchedPayments);
            }),
        },
    });
    if (creditNote == null)
        return null;
    var reference = creditNote.reference, issue_date = creditNote.issue_date, currency = creditNote.currency, totalTvac = creditNote.total_tvac, localized_money_object = creditNote.localized_money_object, full_name = creditNote.debtor_attributes.full_name, matchedPayments = creditNote.matched_payments_attributes;
    var handleSubmit = form.handleSubmit, watch = form.watch;
    var _e = getCurrentValues(watch('invoices_to_link'), localized_money_object), currentTotal = _e.currentTotal, maxTotal = _e.maxTotal, canSubmit = _e.canSubmit;
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("article", { className: styles('link-invoices-form') },
            React.createElement("header", { className: styles('header') },
                React.createElement("div", { className: styles('title') }, t(i18nKeys.CREDIT_NOTE)),
                React.createElement("div", { className: styles('info') },
                    React.createElement("div", null,
                        React.createElement("span", null,
                            t(i18nKeys.FORM.REFERENCE),
                            ":"),
                        React.createElement("span", { className: styles('value') }, reference)),
                    React.createElement("div", null,
                        React.createElement("span", null,
                            t(i18nKeys.TOTAL_VAT_PRICE),
                            ":"),
                        React.createElement(Amount, { localizedValue: totalTvac, value: maxTotal, suffix: currency, className: styles('value', 'bigger') })),
                    React.createElement("div", null,
                        React.createElement("span", null,
                            t(i18nKeys.DUE_DATE),
                            ": "),
                        React.createElement(DateItem, { date: issue_date, lg: currentLang, format: "DD/MM/YY", className: styles('value', 'blue', 'bigger') })),
                    React.createElement("div", null,
                        React.createElement("span", null,
                            t(i18nKeys.DEBTOR_NAME),
                            ":"),
                        React.createElement("span", { className: styles('value') }, full_name)))),
            React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
                React.createElement(LinkInvoiceFormTable, { className: styles('invoice-table'), invoices: invoices, creditNote: creditNote, reload: reload }),
                React.createElement("div", { className: styles('resume', !canSubmit && 'error') },
                    React.createElement("span", null,
                        t(i18nKeys.INVOICE.DUE_AMOUNT),
                        " : "),
                    React.createElement(AnimateNumber, { duration: 400, value: currentTotal, className: styles('total'), formatValue: function (value) {
                            return "".concat(formatAmount(value, ',', '.', 2, currencySymbol(currency, undefined, constants)));
                        } }),
                    ' / ',
                    formatAmount(maxTotal, ',', '.', 2, currencySymbol(currency, undefined, constants))),
                React.createElement("div", { className: styles('button-wrapper') },
                    React.createElement(Button, { noShadow: true, label: t(i18nKeys.SUBMIT), type: "submit", disabled: !canSubmit }))))));
};
