import React from 'react';
import { ObjectType, } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import styleIdentifiers from './ClientDetailTabsBalance.scss';
var styles = classNames.bind(styleIdentifiers);
export var ClientDetailTabsBalanceItemDetail = function (_a) {
    var _b = _a.type, type = _b === void 0 ? ObjectType.invoice : _b, _c = _a.hasLateFees, hasLateFees = _c === void 0 ? false : _c, _d = _a.linkedCreditNotes, linkedCreditNotes = _d === void 0 ? [] : _d, _e = _a.payments, payments = _e === void 0 ? [] : _e, _f = _a.matchedPayments, matchedPayments = _f === void 0 ? [] : _f, _g = _a.linkedInvoices, linkedInvoices = _g === void 0 ? [] : _g, _h = _a.settlements, settlements = _h === void 0 ? [] : _h;
    var t = useTranslation().t;
    if (type === ObjectType.credit_note) {
        return (React.createElement("div", { className: styles('details') },
            React.createElement("p", null, t(i18nKeys.AMOUNT_CREDITED)), linkedInvoices === null || linkedInvoices === void 0 ? void 0 :
            linkedInvoices.map(function (_a) {
                var invId = _a.id, invoice = _a.attributes.invoice;
                return (React.createElement("p", { key: invId },
                    t(i18nKeys.INVOICE.LINK_INVOICE),
                    " - ",
                    invoice.attributes.reference));
            }),
            settlements.map(function (_a) {
                var paymentId = _a.id;
                return (React.createElement("p", { key: paymentId }, t(i18nKeys.AMOUNT_SETTLED)));
            })));
    }
    return (React.createElement("div", { className: styles('details') },
        React.createElement("p", null, t(i18nKeys.AMOUNT_BILLED)),
        hasLateFees && React.createElement("p", null, t(i18nKeys.LATE_FEES)), linkedCreditNotes === null || linkedCreditNotes === void 0 ? void 0 :
        linkedCreditNotes.map(function (_a) {
            var cnId = _a.id, cnReference = _a.attributes.reference;
            return (React.createElement("p", { key: cnId },
                t(i18nKeys.CREDIT_NOTE_LINKED),
                " - ",
                cnReference));
        }),
        payments.map(function (_a) {
            var paymentId = _a.id, _b = _a.attributes, payment_modality_translated = _b.payment_modality_translated, external_id = _b.external_id;
            return (React.createElement("p", { key: paymentId },
                t(i18nKeys.LINKED_PAYMENT),
                " - ",
                payment_modality_translated || external_id));
        }),
        matchedPayments.map(function (_a) {
            var matchedPaymentId = _a.id, payment = _a.attributes.payment;
            return (React.createElement("p", { key: matchedPaymentId },
                t(i18nKeys.LINKED_PAYMENT),
                " -",
                ' ',
                payment.attributes.communication || payment.attributes.external_id));
        })));
};
