import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { get } from 'lodash-es';
import React from 'react';
import { useForm } from 'react-hook-form';
import { NavLink, useLocation } from 'react-router-dom';
import { Button, Input } from 'shared/io';
import { email } from 'shared/utils/validation';
import styleIdentifiers from './resetPasswordForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ResetPasswordForm(_a) {
    var onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    var search = useLocation().search;
    var searchParams = new URLSearchParams(search);
    var _b = useForm({
        defaultValues: {
            email: searchParams.get('email') || undefined,
        },
    }), handleSubmit = _b.handleSubmit, register = _b.register, errors = _b.formState.errors;
    return (React.createElement("form", { onSubmit: handleSubmit(onSubmit), className: styles('ResetPasswordForm') },
        React.createElement("div", { className: styles('info') }, t(i18nKeys.LOGIN.RESET_PASSWORD)),
        React.createElement(Input, { type: "email", label: t(i18nKeys.YOUR_EMAIL), errorMessage: get(errors, 'email'), register: register('email', {
                required: true,
                validate: { email: email },
            }) }),
        React.createElement(Button, { label: t(i18nKeys.SUBMIT), type: "submit" }),
        React.createElement(NavLink, { className: styles('link'), to: "/login" },
            React.createElement("b", null, t(i18nKeys.LOG_IN)))));
}
